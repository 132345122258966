import React from 'react'
import {
  Button, Popover
} from 'antd'
import { Popup } from 'antd-mobile'
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'

class HelpBox extends React.Component {
  state = {
    timer: null,
    calcDetails: {},
    purchasedIngredientsFocus: null,
    usedIngredientsFocus: null,
    capturedTimeFocus: null,
    fixedCostsFocus: null
  }
  render () {
    const { isMobile, title, content, visible, setVisible, category, component, buttonLabel } = this.props

    return (isMobile
      ? (
        <>
          {(component === 'button' && <Button onClick={() => setVisible(true)}>{buttonLabel}</Button>) ||
          <InfoCircleOutlined className='calculator-help-icon' onClick={() => setVisible(true)} />}
          <Popup placement='leftTop' onMaskClick={() => { setVisible(false) }} position='bottom' visible={visible}>
            <div className='calculator-help-popup'>
              <Button type='text' className='calculator-help-popup-close' onClick={() => { setVisible(false) }}><CloseOutlined /></Button>
              <h2>{title}</h2>
              <p>{content}</p>
            </div>
          </Popup>
        </>
      ) : (
        <Popover placement='leftTop' content={content} title=<b>{title}</b> trigger='click' onClick={() => { ReactGA.event({ category, action: title + ' Help Opened Desktop' }) }}>
          {(component === 'button' && <Button>{buttonLabel}</Button>) ||
          <InfoCircleOutlined className='calculator-help-icon' />}
        </Popover>
      )
    )
  }
}

export default HelpBox
