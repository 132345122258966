/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Drawer, Button
} from 'antd'
import { Popup } from 'antd-mobile'
import { withTranslation } from 'react-i18next'
import { CloseOutlined, MoreOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

class OrderHelp extends React.Component {

  render () {
    const { isMobile } = this.props

    const descMobile = (
      <>
        <h3>Zamówienia</h3>
        <p>
          Zamówienia masz do dyspozycji w dwóch widokach - listy i kalendarza. Zamówienie możesz dodać klikając w przycisk <Button type="primary" shape="circle" size='small'>+</Button> na dole ekranu w widoku zamówień lub po wejściu w konkretny dzień w kalendarzu.
          Aby dodać zamówienie najpierw należy wypełnić <Link to='/order/config'>Ustawienia</Link>, gdzie zdefiniujesz parametry niezbędne do poprawnego wyceniania Twoich zamówień. Jeśli masz swoje własne przepisy to zajrzyj do zakładek <Link to='/pantry'>Magazyn składników</Link> i <Link to='/recipe'>Przepisy</Link>. W nich możesz dodać swoje przepisy/składniki oraz podać aktualne ceny produktów, wtedy wyceny Twoich zamówień będą jeszcze dokładniejsze.
        </p>

        <h3>Praca z zamówieniami</h3>
        <p>
          Dodaj nowe zamówienie korzystając z przycisku <Button type="primary" shape="circle" size='small'>+</Button> lub linku <Link to='/order/add'>Dodaj zamówienie</Link> w menu <MoreOutlined /> i je zapisz. Gdy klient zaakceptuje cenę zamówienia, wejdź w zamówienie i w menu <MoreOutlined /> kliknij pozycję 'Oznacz jako zaakceptowane' - od tego momentu zmiany w 'Przepisach' i 'Magazynie składników' nie będą wpływać na już zatwierdzone zamówienie.
          Po zrealizowaniu zamówienia kliknij w widoku zamówienia w menu <MoreOutlined /> pozycję 'Oznacz jako zakończone', wtedy zamówienie domyślnie nie będzie się już wyświetlać w liście i kalendarzu.
        </p>

        <h3>Ustawienia</h3>
        <p>
          Ustawienia zamówień zawierają domyślne informacje o tym jak wyceniać wypieki (czy używać marży czy narzutu, wysokość marży lub narzutu i stawka za godzinę Twojej pracy). Dodatkowo możesz tam zdefiniować jakie są Twoje koszty stałe (comiesięczne) wraz z ilością wypieków jaką wykonujesz w miesiącu - dzięki temu do każdego zamówienia zostanie doliczony średni koszt utrzymania Twojej pracowni.
        </p>

        <h3>Magazyn składników</h3>
        <p>
          Każdy przepis składa się z produktów, zakupionych w określonych opakowaniach w aktualnych cenach. W magazynie składników możesz dodać te produkty i ustawić ceny ze sklepów z Twojej okolicy - wyceny przepisów i zamówień będą dokładniejsze i nie musisz martwić się o przeliczanie zużytych składników na dany przepis - zrobimy to za Ciebie :)
        </p>

        <h3>Przepisy</h3>
        <p>
          Przepis jest składową każdego zamówienia. Do każdego możesz dodać jeden lub więcej przepisów, dzięki czemu dokładnie będziesz wiedzieć co masz do przygotowania a my obliczymy jaki jest faktyczny koszt składników w danym zamówieniu.
        </p>
      </>
    )

    const descDesktop = (
      <>
        <h3>Zamówienia</h3>
        <p>
          Zamówienia masz do dyspozycji w dwóch widokach - tabeli i kalendarza. Zamówienie możesz dodać klikając w przycisk <Link to='/order/add'>Dodaj zamówienie</Link> lub po wejściu w konkretny dzień w kalendarzu.
          Aby dodać zamówienie najpierw należy wypełnić <Link to='/order/config'>Ustawienia</Link>, gdzie zdefiniujesz parametry niezbędne do poprawnego wyceniania Twoich zamówień. Jeśli masz swoje własne przepisy to zajrzyj do zakładek <Link to='/pantry'>Magazyn składników</Link> i <Link to='/recipe'>Przepisy</Link>. W nich możesz dodać swoje przepisy/składniki oraz podać aktualne ceny produktów, wtedy wyceny Twoich zamówień będą jeszcze dokładniejsze.
        </p>

        <h3>Praca z zamówieniami</h3>
        <p>
          Dodaj nowe zamówienie korzystając z linku <Link to='/order/add'>Dodaj zamówienie</Link> i je zapisz. Gdy klient zaakceptuje cenę zamówienia, wejdź w zamówienie i kliknij przycisk 'Oznacz jako zaakceptowane' - od tego momentu zmiany w 'Przepisach' i 'Magazynie składników' nie będą wpływać na już zatwierdzone zamówienie.
          Po zrealizowaniu zamówienia kliknij w przycisk 'Oznacz jako zakończone', wtedy zamówienie domyślnie nie będzie się już wyświetlać w tabeli i kalendarzu.
        </p>

        <h3>Ustawienia</h3>
        <p>
          Ustawienia zamówień zawierają domyślne informacje o tym jak wyceniać wypieki (czy używać marży czy narzutu, wysokość marży lub narzutu i stawka za godzinę Twojej pracy). Dodatkowo możesz tam zdefiniować jakie są Twoje koszty stałe (comiesięczne) wraz z ilością wypieków jaką wykonujesz w miesiącu - dzięki temu do każdego zamówienia zostanie doliczony średni koszt utrzymania Twojej pracowni.
        </p>

        <h3>Magazyn składników</h3>
        <p>
          Każdy przepis składa się z produktów, zakupionych w określonych opakowaniach w aktualnych cenach. W magazynie składników możesz dodać te produkty i ustawić ceny ze sklepów z Twojej okolicy - wyceny przepisów i zamówień będą dokładniejsze i nie musisz martwić się o przeliczanie zużytych składników na dany przepis - zrobimy to za Ciebie :)
        </p>

        <h3>Przepisy</h3>
        <p>
          Przepis jest składową każdego zamówienia. Do każdego możesz dodać jeden lub więcej przepisów, dzięki czemu dokładnie będziesz wiedzieć co masz do przygotowania a my obliczymy jaki jest faktyczny koszt składników w danym zamówieniu.
        </p>
      </>
    )

    return (
      isMobile
        ? (
          <Popup
            visible={this.props.visible}
            onMaskClick={() => {
              this.props.setVisible(false)
            }}
            bodyStyle={{ height: '50vh' }}
          >
            <div className='calculator-help-popup'>
              <Button type='text' className='calculator-help-popup-close' onClick={() => { this.props.setVisible(false) }}><CloseOutlined /></Button>
              <h2>Jak działają zamówienia?</h2>

              {descMobile}
            </div>
          </Popup>)
        : (
          <Drawer
            title='Jak działają zamówienia?'
            placement='right'
            width={450}
            onClose={() => {
              this.props.setVisible(false)
            }}
            visible={this.props.visible}
          >
            <div className='calculator-help-drawer'>
              {descDesktop}
            </div>
          </Drawer>
        )
    )
  }
}

export default withTranslation()(OrderHelp)
