/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Menu, Popconfirm, Button, Tag
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import OrderForm from '../../styledComponents/calcPro/orderForm'
import { updateOrder, getOrder, getRecipes, getOrdersConfig, setOrderStatus, deleteOrder } from '../../utils/actions'
import { openNotification, getOrderStatusColor } from '../../utils/helpers'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined } from '@ant-design/icons'
import { menuTypeHook } from '../common/menuTypeHook'

import { Redirect } from 'react-router'

class EditOrder extends React.Component {
  state = {
    formData: undefined,
    id: undefined,
    recipes: [],
    ordersConfig: {},
    formUnsaved: false
  }

  formRef = React.createRef()
  mobileDropdownRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  setFormUnsaved = (formUnsaved) => {
    this.setState({ formUnsaved })
  }

  getRecipes = () => {
    getRecipes().then((json) => {
      if (this._isMounted) {
        this.setState({ recipes: json })
      }
    })
  }

  getOrder = (id) => {
    getOrder(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })

        if (json.status === 'new') {
          this.getRecipes()
        }
      }
    })
  }

  getOrderAndOrdersSettings = (id) => {
    getOrdersConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ ordersConfig: json })
        this.getOrder(id)
      }
    })
  }

  updateOrder = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateOrder(_this.state.id, data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano zamówienie!')
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  deleteOrder = (data) => {
    deleteOrder(this.state.id).then((json) => {
      openNotification(this.props.t('Success'), 'Pomyślnie usunięto zamówienie!')
      this.redirect('/order')
    })
  }

  setOrderStatus = (status) => {
    setOrderStatus(this.state.id, status).then((json) => {
      openNotification(this.props.t('Success'), 'Pomyślnie zaktualizowano status zamówienia!')
      this.mobileDropdownRef?.current?.close()

      this.getOrder(this.state.id)
    })
  }

  componentDidMount () {
    const { id } = this.props.match.params

    this._isMounted = true
    this.props.location.state && this.setState({ from: this.props.location.state.from, back_to: this.props.location.state.back_to })

    this.setState({ id })
    this.getOrderAndOrdersSettings(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection, recipes, ordersConfig } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Popconfirm
          title='Czy zakończyć zamówienie? Zakończonego zamówienia nie da się już edytować'
          onConfirm={() => this.setOrderStatus('finished')}
          {...popconfirmProps}
        >
          <Menu.Item key='finish-order' disabled={this.state.formUnsaved}>
              Oznacz jako zakończone
          </Menu.Item>
        </Popconfirm>
        {formData && formData.status === 'new' &&
          <Popconfirm
            title='Czy zaakceptować zamówienie? Zaakceptowane zamówienie oznacza, że klient zgodził się na wycenę i od tej pory zmiana w przepisach/magazynie składników nie będzie wpływać na kalkulację tego zamówienia'
            onConfirm={() => this.setOrderStatus('accepted')}
            {...popconfirmProps}
          >
            <Menu.Item key='accept-order' disabled={this.state.formUnsaved}>
                Oznacz jako zaakceptowane
            </Menu.Item>
          </Popconfirm>}
        <Popconfirm
          title='Czy na pewno anulować zamówienie?'
          onConfirm={() => this.setOrderStatus('canceled')}
          {...popconfirmProps}
        >
          <Menu.Item key='cancel-order'>
              Anuluj zamówienie
          </Menu.Item>
        </Popconfirm>
        <Popconfirm
          title='Czy na pewno usunąć zamówienie?'
          onConfirm={() => this.deleteOrder()}
          {...popconfirmProps}
        >
          <Menu.Item key='delete-order'>
              Usuń
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Popconfirm
        title='Czy zakończyć zamówienie? Zakończonego zamówienia nie da się już edytować'
        onConfirm={() => this.setOrderStatus('finished')}
        {...popconfirmProps}
        key='finish-order'
        disabled={this.state.formUnsaved}
      >
        <Button disabled={this.state.formUnsaved}>Oznacz jako zakończone</Button>
      </Popconfirm>,
      (formData && formData.status === 'new' &&
        <Popconfirm
          title='Czy zaakceptować zamówienie? Zaakceptowane zamówienie oznacza, że klient zgodził się na wycenę i od tej pory zmiana w przepisach/magazynie składników nie będzie wpływać na kalkulację tego zamówienia'
          onConfirm={() => this.setOrderStatus('accepted')}
          {...popconfirmProps}
          key='accept-order'
          disabled={this.state.formUnsaved}
        >
          <Button disabled={this.state.formUnsaved}>Oznacz jako zaakceptowane</Button>
        </Popconfirm>),
      <Popconfirm
        title='Czy na pewno anulować zamówienie?'
        onConfirm={() => this.setOrderStatus('canceled')}
        {...popconfirmProps}
        key='cancel-order'
      >
        <Button>Anuluj zamówienie</Button>
      </Popconfirm>,
      <Popconfirm
        title='Czy na pewno usunąć zamówienie?'
        onConfirm={() => this.deleteOrder()}
        {...popconfirmProps}
        key='delete-order'
      >
        <Button>Usuń</Button>
      </Popconfirm>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to={this.state.from === 'calendar' ? '/calendar/' + this.state.back_to : '/order'} />
      ) : (
        <>
          <PageHeader
            title='Edytuj zamówienie'
            subTitle={formData && this.props.screenSize !== 'xs' && <Tag color={getOrderStatusColor(formData.status)}>{this.props.t(formData.status)}</Tag>}
            onBack={() => this.makeRedirection()}
            extra={formData && (formData.status === 'new' || formData.status === 'accepted') ? actions : []}
          />
          {formData && this.props.screenSize === 'xs' && <div style={{ textAlign: 'left', paddingLeft: '24px' }}><Tag color={getOrderStatusColor(formData.status)}>{this.props.t(formData.status)}</Tag></div>}
          <OrderForm
            formAction={this.updateOrder}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            recipes={recipes}
            ordersConfig={ordersConfig}
            screenSize={this.props.screenSize}
            setFormUnsaved={this.setFormUnsaved}
          />
        </>
      )
    )
  }
}

export default withTranslation()(menuTypeHook(EditOrder))
