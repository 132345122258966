/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Checkbox, Button
} from 'antd'
import { withTranslation } from 'react-i18next'

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

class TrainingFilterForm extends React.Component {
  state = {
    filersUsed: false,
    initialSearchUsed: false
  }

  formRef = React.createRef()

  handleSubmit = (values) => {
    this.props.formAction(values)

    this.setState({ filersUsed: values.type?.length > 0 || values.level?.length > 0 || values.author?.length > 0 || values.category?.length > 0 })
  }

  componentDidUpdate () {
    if (this.props.loaded && this.formRef?.current && this.props.initialFilter && !this.state.initialFilterUsed && this.formRef?.current.getFieldValue('search') !== this.props.initialFilter) {
      this.formRef?.current.setFieldsValue(this.props.initialFilter)

      this.props.formAction(this.props.initialFilter)
      this.setState({ initialFilterUsed: true, filersUsed: true })
    }
  }

  resetForm = () => {
    this.formRef.current?.setFieldsValue({ type: undefined, level: undefined, author: undefined, category: undefined })
    this.props.formAction({ type: undefined, level: undefined, author: undefined, category: undefined })
    this.setState({ filersUsed: false })
  }

  render () {
    // const { isMobile } = this.props

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='vertical'
          className={'training-filter'}
          ref={this.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          {/*<Form.Item name='categories' label='Kategoria'>
            <Checkbox.Group>
              {this.props.trainingCategories?.map(author =>
                <div>
                  <Checkbox
                    value={author.id}
                    style={{
                      lineHeight: '32px',
                    }}
                  >
                    {author.name}
                  </Checkbox>
                </div>
              )}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name='type' label='Typ'>
            <Checkbox.Group>
              <div>
                <Checkbox
                  value="free"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Darmowe
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="sub"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  W abonamencie
                </Checkbox>
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name='level' label='Poziom szkolenia'>
            <Checkbox.Group>
              <div>
                <Checkbox
                  value="1"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Podstawowe
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="2"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Średniozaawansowane
                </Checkbox>
              </div>
            </Checkbox.Group>
          </Form.Item>*/}

          <Form.Item name='author' label='Trenerka'>
            <Checkbox.Group>
              {this.props.authors?.map(author =>
                <div>
                  <Checkbox
                    value={author.id}
                    style={{
                      lineHeight: '32px',
                    }}
                  >
                    {author.name}
                  </Checkbox>
                </div>
              )}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item className='form-footer'>
            <Button htmlType='submit' type='primary'>Filtruj</Button>
            {this.state.filersUsed && <Button type='link' onClick={this.resetForm}>Resetuj</Button>}
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(TrainingFilterForm)
