import React from 'react'
import StyledRecipeIndex from '../../styledComponents/myrecipes/recipe'
import { Redirect } from 'react-router'
import {
  getMyRecipesRecipes, saveMyRecipesConfig, getMyRecipesConfig, getUserProfile, sendUpvote
} from '../../utils/actions'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import CtaModal from '../../styledComponents/common/ctaModal'
import { setCtaModalVisible } from '../../store/actions'
import { LikeOutlined } from '@ant-design/icons'
import ContentRight from '../common/contentRight'

class MyRecipeRecipeIndex extends React.Component {
  state = {
    makeRedirection: false,
    recipes: [],
    loaded: false,
    costCalc: false
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getRecipes()
      this.getMyRecipesConfig()
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getRecipes = () => {
    getMyRecipesRecipes().then((json) => {
      if (this._isMounted) {
        this.setState({ recipes: json, loaded: true })
      }
    })
  }

  saveMyRecipesConfig = (data) => {
    return new Promise(function (resolve, reject) {
      saveMyRecipesConfig(data).then((json) => {
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  getMyRecipesConfig = () => {
    getMyRecipesConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ costCalc: json.cost_calc, limitExceeded: json.limit_exceeded })
      }
    })
  }

  setCostCalc = (costCalc) => {
    this.setState({ costCalc })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { makeRedirection, recipes, loaded } = this.state

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText
    let buttonTextSize
    let buttonType
    let buttonCallback

    text = <>Hej! Na ten moment wyczerpany został limit w Twojej bazie przepisów... Jeśli chcesz przyspieszyć prace nad możliwością dodawania większej ilości przepisów, kliknij kciuk w górę :D</>
    requiredSubs = ['sub1', 'sub2', 'sub3']
    buttonText = <LikeOutlined />
    buttonTextSize = 50
    buttonType = 'text'
    buttonCallback = () => { sendUpvote('myrecipes_limit') }

    // if (authenticated && user?.sub_type === 'free' && !user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit przepisów w Twojej bazie przepisów. Możesz dodać więcej jeśli skorzystasz z któregoś ze Strefowych pakietów, wypróbuj konto testowe przez 7 dni bez opłat :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet testowy i dodaj kolejne przepisy'
    // } else if (authenticated && user?.sub_type === 'free' && user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit przepisów w Twojej bazie przepisów. Możesz dodać więcej jeśli skorzystasz z któregoś ze Strefowych pakietów :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i dodaj kolejne przepisy'
    // } else if (authenticated && user?.sub_type !== 'free' && user?.trial) {
    //   text = <>Ups! Wyczerpany został limit przepisów w Twojej bazie przepisów. Możesz wybrać wyższy pakiet gdzie jest możliwość dodania większej ilości przepisów.</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i dodaj kolejne przepisy'
    // } else if (authenticated && user?.sub_type !== 'free' && !user?.trial) {
    //   text = <>Ups! Wyczerpany został limit przepisów w Twojej bazie przepisów. Możesz wybrać wyższy pakiet gdzie jest możliwość dodania większej ilości przepisów.</>
    //   toRoute = '/zmien-pakiet'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Zmień pakiet i dodaj kolejne przepisy'
    // }

    return (
      makeRedirection ? (
        <Redirect to={authenticated ? '/panel' : '/'} />
      ) : (
        authenticated ? <>
          <CtaModal
            text={text}
            toRoute={toRoute}
            redirect={redirect}
            requiredSubs={requiredSubs}
            buttonText={buttonText}
            buttonTextSize={buttonTextSize}
            buttonType={buttonType}
            buttonCallback={buttonCallback}
          />
          <StyledRecipeIndex
            makeRedirection={this.makeRedirection}
            isMobile={isMobile}
            recipes={recipes}
            redirect={this.redirect}
            loaded={loaded}
            saveMyRecipesConfig={this.saveMyRecipesConfig}
            costCalc={this.state.costCalc}
            limitExceeded={this.state.limitExceeded}
            setCostCalc={this.setCostCalc}
            from_route={this.props.location.state?.from}
            setCtaModalVisible={this.props.setCtaModalVisible}
          />
        </> : <ModuleNotLoggedContent
          title='Moje przepisy'
          contentTitle='Ciągle szukasz swoich ulubionych przepisów? To jest miejsce specjalnie dla Ciebie!'
          showCtaBottom
          content={
            <ContentRight
              isMobile={isMobile}
              image='/images/recipes.png'
              title='Stwórz bazę swoich ulubionych przepisów 🎂'
              content='Tutaj możesz dodawać swoje ulubione przepisy i mieć je w jednym miejscu! Na wyciągnięcie ręki, zawsze kiedy potrzebujesz. Dodatkowo każdy z przepisów możesz przeliczyć na dowolną inną ilość, na tortownicę, blachę w zupełnie innym rozmiarze. A może chcesz zrobić tort w rancie o 2cm większym niż zawsze i chcesz szybko i łatwo przeliczyć przepis? Tutaj to zrobisz!'
            />
          }
          redirect={this.redirect}
          redirectRoute={this.props.location?.pathname}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  ctaModalVisible: state.appRedux.ctaModalVisible
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const MyRecipeRecipeIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRecipeRecipeIndex)

export default MyRecipeRecipeIndexContainer
