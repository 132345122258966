/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import RecipeForm from '../../styledComponents/calcPro/recipeForm'
import { getPantry, addRecipe } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'

import { Redirect } from 'react-router'

class AddRecipe extends React.Component {
  state = {
    formData: {},
    pantryIngredients: []
  }

  formRef = React.createRef()

  getPantry = () => {
    getPantry().then((json) => {
      if (this._isMounted) {
        this.setState({ pantryIngredients: json })
      }
    })
  }

  addRecipe = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addRecipe(data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie dodano przepis!')
        _this.makeRedirection()
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getPantry()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection, pantryIngredients } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/recipe' />
      ) : (
        <>
          <PageHeader
            title='Dodaj przepis'
            onBack={() => this.makeRedirection()}
          />
          <RecipeForm
            formAction={this.addRecipe}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            pantryIngredients={pantryIngredients}
          />
        </>
      )
    )
  }
}

export default withTranslation()(AddRecipe)
