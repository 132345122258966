import React from 'react'
import { Typography, Tag, Card, Space } from 'antd'
import { Link } from 'react-router-dom'

const { Paragraph } = Typography
const { Meta } = Card

class StyledTrainingItem extends React.Component {
  render () {
    const { redirect, item } = this.props

    return (
      <Card
        className='training-card'
        style={{ maxWidth: 250 }}
        bordered={false}
        onClick={() => redirect('/training/' + item.slug)}
        cover={<img alt={item.title + ' logo'} src={'/dimages/trainings/' + item.id + 'b.png'} />}
      >
        <Meta description={
          <div className='training-list'>
            {/*<Tag className='training-duration'>{item.durationNice}</Tag>*/}
            {item.visible === false && <Tag className='training-list-visible' color='red'>Niewidoczny</Tag>}
            <Link className='training-list-title' to={'/training/' + item.slug}>{item.title}</Link>
            {item.author && <Paragraph className='training-author-description'>
              <Link to={'/training/author/' + item.author.id} className='training-author'>
                {item.author.name}
              </Link>
            </Paragraph>}

            {item.categories && <p>{item.categories.map(cat => cat.name).join(', ')}</p>}

            <Paragraph className='training-list-text' ellipsis={{ rows: 2, expandable: false }}>
              {item.short_description}
            </Paragraph>

            {/*<img className='training-list-level' src={'/images/babeczka' + item.level + '.png'} alt={trainingLevel(item.level)} title={trainingLevel(item.level)} />*/}

            <div className='training-list-level'>
            <Space>
              <div>Poziom</div>
              <div class={'circle' + (item.level > 0 ? ' circle-full' : '')}></div>
              <div class={'circle' + (item.level > 1 ? ' circle-full' : '')}></div>
              <div class={'circle' + (item.level > 2 ? ' circle-full' : '')}></div>
            </Space>
            </div>

            <div className='button-wrapper'>
              {/*<Button size='large' style={{ backgroundColor: item.main_image_color }}><Link to={'/training/' + item.slug}>Zobacz więcej</Link></Button>*/}
              <Link to={'/training/' + item.slug}>Przejdź do szkolenia</Link>
            </div>
          </div>
        } />
      </Card>
    )
  }
}

export default StyledTrainingItem
