import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  pl: {
    translation: {
      'Name field is required': 'Pole nazwa jest wymagane',
      'IP/Hostname field is required': 'Pole IP/Hostname jest wymagane',
      Name: 'Nazwa',
      Description: 'Opis',
      'Monitoring enabled': 'Monitorowanie włączone',
      'Add server': 'Dodaj serwer',
      'Edit server details': 'Edytuj szczegóły serwera',
      // universal
      Save: 'Zapisz',
      Login: 'Zaloguj',
      'Your name': 'Twoje imię',
      Password: 'Hasło',
      'Repeat password': 'Powtórz hasło',
      // 'FB group password': 'Hasło dodatkowe dla testerów',
      // 'FB group password': 'Hasło z grupy FB',
      'FB group password': 'Słodko tajne hasło z grupy FB',
      'I accept': 'Akceptuję',
      Regulations: 'Regulamin',
      and: 'i',
      'Privacy policy': 'Politykę prywatności',
      Register: 'Zarejestruj',
      Cancel: 'Anuluj',
      'Don\'t have account? Register here': 'Nie masz konta? Zarejestruj się tutaj',
      'Back to index page': 'Wróć do strony głównej',
      'Password field is required': 'Hasło jest wymagane',
      'Email field is required': 'Email jest wymagany',
      'Group password field is required': 'Hasło dla członków grupy jest wymagane',
      'Agreement of Regulations and Privacy policy field is required': 'Wymagana jest akceptacja Regulaminu i Polityki prywatności',
      'Your name is required field': 'Twoje imię jest wymaganym polem',
      'Valid email address is required': 'Wymagany jest poprawny adres email',
      'You has been logged out': 'Nastąpiło poprawne wylogowanie',
      'See you later!': 'Do zobaczenia!',
      'I want to receive news notifications about news in application': 'Chcę otrzymywać powiadomienia o nowościach w aplikacji',
      Province: 'Województwo',
      Town: 'Miasto',
      'Province field is required': 'Pole województwo jest wymagane',
      'Town field is required': 'Pole miasto jest wymagane',
      'Select province': 'Wybierz województwo',
      'Log in': 'Logowanie',
      'Current version of application is only available for \'Torty i wypieki - Inspiracje Cukiernicze\' fb group members. Visit': 'Aktualna wersja aplikacji jest dostępna jedynie dla członków grupy fb \'Torty i wypieki - Inspiracje Cukiernicze\'. Odwiedź',
      'and ask administrator for secret password before registration.': 'i zapytaj administratora o tajne hasło niezbędne do rejestracji.',
      'group page': 'stronę grupy',
      'Do you want install this application on your\'s device?': 'Czy chcesz zainstalować aplikację na Twoim urządzeniu?',
      Install: 'Zainstaluj',
      Close: 'Zamknij',
      'Step 1: Tap Share icon': 'Krok 1: Kliknij ikonkę udostępniania',
      'Step 2: Tap "Add to Home screen"': 'Krok 2: Kliknij "Do ekranu początkowego"',
      'You has been successfully registered, you can log in now!': 'Zarejestrowano pomyślnie, teraz możesz się zalogować!',
      Success: 'Sukces',
      'Your profile data has been successfully saved!': 'Dane Twojego profilu zostały pomyślnie zapisane!',
      active: 'aktywne',
      finished: 'zakończone',
      canceled: 'anulowane',
      outOfDate: 'do zaktualizowania',
      'new,accepted': 'aktywne',
      all: 'wszystkie',
      new: 'nowe',
      accepted: 'zaakceptowane',
      error: 'błąd',
      toreview: 'do akceptacji',
      rejected: 'odrzucone'
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pl', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
