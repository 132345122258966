import React from 'react'
import { Modal } from 'antd'
import CtaBox from './ctaBox'
import { connect } from 'react-redux'
import { setCtaModalVisible } from '../../store/actions'

class CtaModal extends React.Component {
  render () {
    return (
      <Modal
        visible={this.props.ctaModalVisible}
        onCancel={() => this.props.setCtaModalVisible(undefined)}
        mask={false}
        footer={false}
      >
        <CtaBox
          text={this.props.text}
          toRoute={this.props.toRoute}
          redirect={this.props.redirect}
          buttonText={this.props.buttonText}
          requiredSubs={this.props.requiredSubs}
          buttonTextSize={this.props.buttonTextSize}
          buttonType={this.props.buttonType}
          buttonCallback={this.props.buttonCallback}
          callAfterClick={() => {
            this.props.buttonCallback && this.props.buttonCallback()
            this.props.setCtaModalVisible(undefined)
          }}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ctaModalVisible: state.appRedux.ctaModalVisible
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const CtaModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CtaModal)

export default CtaModalContainer
