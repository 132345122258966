import React from 'react'
import { PageHeader, Typography, Button, Space, Menu, Drawer } from 'antd'
import { Dropdown as DropdownMobile, Popup } from 'antd-mobile'
import { MoreOutlined, CloseOutlined } from '@ant-design/icons'
import { saveDeviceData, getDeviceData } from '../../utils/helpers'
import ReactGA from 'react-ga4'

import InspirationForm from './form'
import InspirationImage from './preview'

const { Text } = Typography;

class StyledInspirationIndex extends React.Component {
  state = {
    descBoxVisible: false
  }

  formRef = React.createRef()
  mobileDropdownRef = React.createRef()

  useIdea = (inspiration) => {
    this.props.setGenerateType('text')

    this.formRef.current.setFieldsValue({ prompt: inspiration.prompt, generation_type: 'text', object_type: inspiration.object_type })
    window.scrollTo(0, 0)

    ReactGA.event({ category: 'Generate Inspiration', action: 'Use Idea Used' })
  }

  randomizePrompt = () => {
    let nicePrompts = [
      { prompt: 'motyw jednorożca, tęczowe kolory, na 10 urodziny dziewczynki', object_type: 'cake' },
      { prompt: 'śliczny misio, kolory niebieski i beżowy, na chrzciny dla chłopca', object_type: 'cake' },
      { prompt: 'dla fana dżungli, figurka małpki, drzewo, banany', object_type: 'cake' },
      { prompt: 'jagody, krem czekoladowy, cukier puder, deser do kawy', object_type: 'muffin' }
    ]

    if (this.formRef?.current) {
      const currentPrompt = this.formRef.current.getFieldValue('prompt')

      if (currentPrompt) {
        nicePrompts = nicePrompts.filter(x => x.prompt !== currentPrompt)
      }

      const randNum =  Math.floor(Math.random() * nicePrompts.length);
      const randomizedPrompt = nicePrompts[randNum]

      this.formRef.current.setFieldsValue({ generation_type: this.props.generationType, prompt: randomizedPrompt.prompt, object_type: randomizedPrompt.object_type })
    }
  }

  componentDidMount () {
    this.randomizePrompt()

    if (!getDeviceData('inspiration_desc_showed')) {
      this.setState({ descBoxVisible: true })
      saveDeviceData('inspiration_desc_showed', true)
    }
  }

  generateSimilar = (inspiration) => {
    this.formRef.current.setFieldsValue({ prompt: inspiration.prompt, generation_type: 'text_image', object_type: inspiration.object_type  })

    this.props.setGenerateType('text_image')

    this.props.useInspiration(inspiration.id).then(() => {
      this.props.getUploadedFilePreview()
      this.props.generateInspiration({ prompt: inspiration.prompt, generation_type: 'text_image', object_type: inspiration.object_type })
    })
    window.scrollTo(0, 0)

    ReactGA.event({ category: 'Generate Inspiration', action: 'Generate Similar ' + inspiration.id })
  }

  setDescBoxVisible = (flag, device) => {
    this.setState({ descBoxVisible: flag })
    this.mobileDropdownRef?.current?.close()

    flag && ReactGA.event({ category: 'Generate Inspiration', action: 'Description Box Opened ' + device })
  }

  render () {
    const { redirect, inspirations, isMobile } = this.props

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='desc'
          onClick={() => this.setDescBoxVisible(true, 'Mobile')}
        >
            Jak wygenerować poprawną inspirację?
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='desc'
        onClick={() => this.setDescBoxVisible(true, 'Desktop')}
      >
        Jak wygenerować poprawną inspirację?
      </Button>
    ]) || [actionsMenuMobile]

    const calcDesc = (
      <>
        <p>Poprawnie wygenerowana inspiracja zależy od dobrze zdefiniowanej prośby, co ma być przedmiotem tej inspiracji. Poniżej możesz zapoznać się jaka powinna być konstrukcja
        Twojego opisu aby uzyskać jak najlepsze efekty. Nie bój się eksperymentować, najwyżej otrzymasz przedziwną inspirację ale być może trafisz na tą idealną ;-) Jeśli wygenerowana inspiracja dla danego opisu jest nie zadowalająca, spróbuj wygenerować inspirację jeszcze raz nie zmieniając opisu. Jeśli po kilku próbach dalej efekt nie jest odpowiedni, spróbuj zmodyfikować opis i wygeneruj inspirację ponownie. Pamiętaj także, że nie musisz próbować stworzyć wygenerowanego wypieku jeden do jednego, w końcu to ma Cię zainspirować :-D</p>

        <h2>Inspiracje na tort lub babeczki</h2>
        <p>W polu "Co wygenerować?" wybierz tort lub babeczki. Opisz na jakiej tematyki ma dotyczyć przedmiot inspiracji - jak ma wyglądać, na jaką jest okazję. Możesz także dodać trochę kontekstu np. na które urodziny jest tort, czy to wypiek dla kobiety, mężczyzny a może dziecka. Poszczególne informacje możesz oddzielić przecinkami.
        Spróbuj zacząć swój opis w stylu np. <Text keyboard>motyw dinozaura</Text>. Po przecinku podaj więcej szczegółów (takie jak kolor, elementy dodatkowe), np: <Text keyboard>motyw dinozaura, dżungla</Text>.
        </p>

        <h4><b>Przykładowe poprawne zapytania</b></h4>
        <p><Text keyboard>motyw jednorożca, tęczowe kolory</Text></p>
        <p><Text keyboard>motyw dinozaura, dżungla</Text></p>
        <p><Text keyboard>misio, kolory niebieski i beżowy</Text></p>
        <p><Text keyboard>40 rocznica ślubu</Text></p>

        <h2>Inspiracje na dowolną słodycz</h2>
        <p>W polu "Co wygenerować?" wybierz <Text keyboard>dowolnie</Text>. W tym przypadku musisz dokładnie określić na co inspiracji poszukujesz - czy to ciasto, makaroniki, beza a może coś jeszcze innego? Następnie opisz jak to ma wyglądać, na jaką jest okazję i dla kogo podobnie jak w przypadku tortu czy babeczek.</p>

        <h4><b>Przykładowe poprawne zapytania</b></h4>
        <p><Text keyboard>ciasto z owocami: maliny, truskawki, borówki</Text></p>
        <p><Text keyboard>deser z owocami leśnymi</Text></p>

        <h2>Generowania inspiracji ze zdjęcia</h2>
        <p>Jeśli korzystasz już z istniejącego zdjęcia, opis może być bardziej ogólny, generator będzie wiedział co chcesz wygenerować na podstawie przesłanego zdjęcia.</p>

        <h4><b>Przykładowe poprawne zapytania</b></h4>
        <p>Dodaj zdjęcie tortu (jakiegokolwiek, który podoba Ci się wizualnie) i napisz pomysł np: <Text keyboard>motyw jednorożca, tęczowe kolory</Text></p>
      </>
    )

    return (
      <>
        <PageHeader
          title='Generuj inspirację AI'
          onBack={() => redirect(this.props.authenticated ? '/panel' : '/')}
          extra={actions}
        />
        {isMobile
          ? (
            <Popup
              visible={this.state.descBoxVisible}
              onMaskClick={() => {
                this.setDescBoxVisible(false)
              }}
              bodyStyle={{ height: '50vh' }}
            >
              <div className='calculator-help-popup'>
                <Button type='text' className='calculator-help-popup-close' onClick={() => { this.setDescBoxVisible(false) }}><CloseOutlined /></Button>
                <h2>Jak wygenerować poprawną inspirację?</h2>

                {calcDesc}
              </div>
            </Popup>)
          : (
            <Drawer
              title='Jak wygenerować poprawną inspirację?'
              placement='right'
              width={450}
              onClose={() => {
                this.setDescBoxVisible(false)
              }}
              visible={this.state.descBoxVisible}
            >
              <div className='calculator-help-drawer'>
                {calcDesc}
              </div>
            </Drawer>
          )}

          <InspirationForm
            formRef={this.formRef}
            isMobile={isMobile}
            loading={this.props.loading}
            generateInspiration={this.props.generateInspiration}
            handleGenerationTypeSegmented={this.props.handleGenerationTypeSegmented}
            handleGenerationTypeSelector={this.props.handleGenerationTypeSelector}
            handleObjectTypeSegmented={this.props.handleObjectTypeSegmented}
            handleObjectTypeSelector={this.props.handleObjectTypeSelector}
            generationType={this.props.generationType}
            objectType={this.props.objectType}
            getUploadedFilePreview={this.props.getUploadedFilePreview}
            preview={this.props.preview}
            removeFile={this.props.removeFile}
            randomizePrompt={this.randomizePrompt}
          />

        <Space direction='vertical' style={{ marginTop: '20px' }}>
        {inspirations && inspirations.length > 0 && inspirations.map((inspiration, index) => (
          <div className={this.props.loading ? 'inspiration-loading' : ''}>
            <InspirationImage
              getInspirationImage={this.props.getInspirationImage}
              inspiration={inspiration}
              useIdea={this.useIdea}
              generateSimilar={this.generateSimilar}
              mark={index === 0 && this.props.wasGenerated}
            />
          </div>
        ))}
        </Space>
      </>
    )
  }
}

export default StyledInspirationIndex
