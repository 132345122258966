import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

function ContentLeft(props) {
  const { ...tagProps } = props;
  const { image, title, content, isMobile, className, imageXs, imageMd, textXs, textMd } = tagProps;

  const dataSource = {
    wrapper: { className: 'home-page-wrapper content2-wrapper ' + (className || '') },
    OverPack: { className: 'home-page content2', playScale: 0.3 },
    imgWrapper: { className: 'content2-img', md: imageMd || 12, xs: imageXs || 24 },
    img: {
      children: image
    },
    textWrapper: { className: 'content2-text', md: textMd || 12, xs: textXs || 24 },
    title: { className: 'content2-title', children: title },
    content: {
      className: 'content2-content',
      children:
        content
    }
  }

  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'left',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '+=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  }

  const img = (
    <TweenOne
      key="img"
      animation={animType.one}
      resetStyle
      {...dataSource.imgWrapper}
      component={Col}
      componentProps={{
        md: dataSource.imgWrapper.md,
        xs: dataSource.imgWrapper.xs,
      }}
    >
      <span {...dataSource.img}>
        <img src={dataSource.img.children} width="100%" alt="img" />
      </span>
    </TweenOne>
  )

  return (
    <div isMobile={isMobile} className={className} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <QueueAnim
          type={animType.queue}
          key="text"
          leaveReverse
          ease={['easeOutCubic', 'easeInCubic']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
        </QueueAnim>
        {img}
      </OverPack>
    </div>
  );
}

export default ContentLeft;
