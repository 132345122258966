/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import PantryForm from '../../styledComponents/calcPro/pantryForm'
import { addPantry } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'

import { Redirect } from 'react-router'

class AddPantry extends React.Component {
  state = {
    formData: {}
  }

  formRef = React.createRef()

  addPantry = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addPantry(data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie dodano składnik!')
        _this.makeRedirection()
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/pantry' />
      ) : (
        <>
          <PageHeader
            title='Dodaj składnik'
            onBack={() => this.makeRedirection()}
          />
          <PantryForm
            formAction={this.addPantry}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            isNew
          />
        </>
      )
    )
  }
}

export default withTranslation()(AddPantry)
