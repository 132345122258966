/* eslint no-unused-expressions: "off" */
import React from 'react'
import { PageHeader, Row, Col } from 'antd'
import RecipeShowItem from './recipeShowItem'
import { getMainRecipe, getUserProfile, addMyRecipeFromMainRecipe } from '../../utils/actions'
import { connect } from 'react-redux'
import { openNotification, subName } from '../../utils/helpers'
import { setCtaModalVisible } from '../../store/actions'
import CtaModal from '../../styledComponents/common/ctaModal'

class MainRecipeShow extends React.Component {
  state = {
    recipe: undefined,
    id: undefined,
    loaded: false,
    ctaBoxType: 'training'
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  getRecipe = (slug) => {
    getMainRecipe(slug).then((json) => {
      if (this._isMounted) {
        this.setState({ recipe: json, loaded: true })
      }
    })
  }

  addMyRecipeFromMainRecipe = (id) => {
    addMyRecipeFromMainRecipe(id).then((json) => {
      if (json.limit_exceeded) {
        this.setState({ limitExceeded: true }, () => this.props.setCtaModalVisible(true))
      } else {
        this.setState({ justAddedToMyBase: true })
        openNotification('Sukces', 'Pomyślnie dodano przepis!')
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    const { slug } = this.props.match.params

    this.setState({ slug })
    this.getRecipe(slug)

    if (this.props.authenticated) {
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { recipe, user } = this.state

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText

    if (authenticated && this.state.limitExceeded) {
      if (user?.sub_type === 'free' && !user?.trial_used) {
        text = 'Ups! Wyczerpał się limit w Twojej bazie przepisów, wybierz któryś z pakietów Strefowych, wypróbuj konto testowe przez 7 dni'
        toRoute = '/pakiety'
        redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
        requiredSubs = ['sub1', 'sub2', 'sub3']
        buttonText = 'Wybierz pakiet testowy aby dodać ten przepis'
      } else if (user?.sub_type === 'free' && user?.trial_used) {
        text =  'Ups! Wyczerpał się limit w Twojej bazie przepisów, wybierz któryś z pakietów Strefowych aby dodać przepis do swojej bazy przepisów'
        toRoute = '/pakiety'
        redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
        requiredSubs = ['sub1', 'sub2', 'sub3']
        buttonText = 'Wybierz pakiet aby dodać ten przepis'
      } else if (user?.sub_type !== 'free' && user?.trial) {
        text =  'Ups! Wyczerpał się limit w Twojej bazie przepisów, wybierz któryś z wyższych pakietów Strefowych aby dodać przepis do swojej bazy przepisów'
        toRoute = '/pakiety'
        redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
        requiredSubs = ['sub1', 'sub2', 'sub3']
        buttonText = 'Wybierz pakiet aby dodać ten przepis'
      } else if (user?.sub_type !== 'free' && !user?.trial) {
        text =  'Ups! Wyczerpał się limit w Twojej bazie przepisów, wybierz któryś z wyższych pakietów Strefowych aby dodać przepis do swojej bazy przepisów'
        toRoute = '/zmien-pakiet'
        redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
        requiredSubs = ['sub1', 'sub2', 'sub3']
        buttonText = 'Zmień pakiet aby dodać ten przepis'
      }
    } else if (!authenticated && recipe?.type === 'free') {
      text = 'Dodawanie tego przepisu jest dostępne dla zarejestrowanych użytkowników'
      toRoute='/register'
      redirect=[this.props.redirectRoute || '/przepisy/' + recipe?.slug]
      buttonText='Dołącz do Strefy Cukiernika'
    } else if (recipe?.type === 'sub' && !authenticated) {
      text = <>Dodawanie tego przepisu jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>
      toRoute = '/register'
      redirect = ['/pakiety', this.props.redirectRoute || '/przepisy/' + recipe?.slug]
      requiredSubs = ['sub2', 'sub3']
      buttonText = 'Dołącz do Strefy Cukiernika'
    } else if (recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && !user?.trial_used) {
      text = <>Dodawanie tego przepisu jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>
      toRoute = '/pakiety'
      redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
      requiredSubs = ['sub2', 'sub3']
      buttonText = 'Wybierz pakiet testowy i uzyskaj dostęp do tego przepisu'
    } else if (recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && user?.trial_used) {
      text = <>Dodawanie tego przepisu jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}</>
      toRoute = '/pakiety'
      redirect = [this.props.redirectRoute || '/przepisy/' + recipe?.slug]
      requiredSubs = ['sub2', 'sub3']
      buttonText = 'Wybierz pakiet i uzyskaj dostęp do tego przepisu'
    }

    return (
      <>
        <PageHeader
          title={recipe?.name}
          onBack={() => this.redirect('/przepisy')}
        />
        <CtaModal
          text={text}
          toRoute={toRoute}
          redirect={redirect}
          requiredSubs={requiredSubs}
          buttonText={buttonText}
        />

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} lg={12}>
            <RecipeShowItem
              recipe={recipe}
              loaded={this.state.loaded}
              isMobile={isMobile}
              authenticated={authenticated}
              user={this.state.user}
              redirect={this.redirect}
              moduleName={'Main Recipe'}
              justAddedToMyBase={this.state.justAddedToMyBase}
              setCtaModalVisible={this.props.setCtaModalVisible}
              addMyRecipeFromMainRecipe={this.addMyRecipeFromMainRecipe}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  ctaModalVisible: state.appRedux.ctaModalVisible
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const MainRecipeShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainRecipeShow)

export default MainRecipeShowContainer
