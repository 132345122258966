import React from 'react'
import {
  Button, Menu, PageHeader, Empty, Skeleton, Switch, Modal, Space,
  Row, Col, Card
} from 'antd'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import StyledMyRecipesMobileMenu from './menuMobile'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import OrderHelp from './help'
import { saveDeviceData, getDeviceData } from '../../utils/helpers'

class StyledRecipeIndex extends React.Component {
  state = {
    orders: [],
    orders_by_date: [],
    helpVisible: false,
    costCalc: false,
    welcomeModalVisible: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  mobileDropdownRef = React.createRef()

  handleCostCalc = (costCalc) => {
    this.props.setCostCalc(costCalc)
    this.props.saveMyRecipesConfig(costCalc)

    this.props.isMobile && this.mobileDropdownRef?.current?.close()
  }

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()

    helpVisible && ReactGA.event({ category: 'MyRecipes Recipe', action: 'Help Opened Mobile' })
  }

  handleWelcomeModalVisible = (welcomeModalVisible) => {
    this.setState({ welcomeModalVisible })

    !welcomeModalVisible && ReactGA.event({ category: 'MyRecipes RecipeOrders', action: 'Welcome Modal Closed' })
  }

  notShowWelcomeModalAgain = () => {
    saveDeviceData('my_recipes_disable_welcome_modal', 'true')
    this.handleWelcomeModalVisible(false)

    ReactGA.event({ category: 'MyRecipes Recipe', action: 'Welcome Modal Not Show Again Clicked' })
  }

  openHelpAndNotShowAgain = () => {
    this.notShowWelcomeModalAgain()
    this.handleHelpVisible(true)

    ReactGA.event({ category: 'MyRecipes Recipe', action: 'Welcome Modal Show Help Clicked' })
  }

  componentDidMount () {
    const welcomeModal = getDeviceData('my_recipes_disable_welcome_modal')

    if (!welcomeModal && this.props.from_route === 'panel_index') {
      this.handleWelcomeModalVisible(true)
    }
  }

  render () {
    const { recipes, makeRedirection, isMobile, loaded } = this.props

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='add-recipe'
          onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/myrecipes/add')}
        >
          Dodaj przepis
        </Menu.Item>
        <Menu.Item
          key='pantry'
        >
          <Link to='/myrecipes/pantry'>Lista składników</Link>
        </Menu.Item>
        <Menu.Item
          key='cost-calc'
          onClick={() => this.handleCostCalc(!this.props.costCalc)}
        >
          {(this.props.costCalc && 'Wyłącz liczenie kosztów') || 'Włącz liczenie kosztów'}
        </Menu.Item>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='add-recipe'
        type='primary'
        onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/myrecipes/add')}
      >
        Dodaj przepis
      </Button>,
      <Button
        key='pantry'
      >
        <Link to='/myrecipes/pantry'>Lista składników</Link>
      </Button>,
      <span key='cost-calc'><Switch checked={this.props.costCalc} onChange={this.handleCostCalc} /> Włącz liczenie kosztów</span>,
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      // {
      //   title: 'Ilość porcji',
      //   dataIndex: 'portions',
      //   key: 'portions',
      //   render: (x, record) => (
      //     <span>{record.use_portions ? x : '-'}</span>
      //   ),
      //   sorter: (a, b) => { return a.portions - b.portions },
      // },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={{
              pathname: '/myrecipes/' + record.id,
              state: { from: 'recipe' }
            }}
            >
              pokaż
            </Link>
          </span>
        )
      }
    ]

    if (this.props.costCalc) {
      columns.splice(1, 0, {
        title: 'Koszt składników',
        dataIndex: 'cost',
        key: 'cost',
        render: (x) => (
          <span>{x} zł</span>
        ),
        sorter: (a, b) => { return a.cost - b.cost },
      })
    }

    return (
      <>
        <PageHeader
          title='Moje przepisy'
          onBack={() => makeRedirection()}
          extra={actions}
        />
        <OrderHelp
          visible={this.state.helpVisible}
          setVisible={this.handleHelpVisible}
          isMobile={isMobile}
        />
        <Modal
          title='Witaj w Twojej liście przepisów!'
          visible={this.state.welcomeModalVisible}
          className={isMobile ? 'welcome-modal' : ''}
          onCancel={() => this.handleWelcomeModalVisible(false)}
          footer={isMobile ? [
            <Space direction='vertical' style={{ textAlign: 'center' }}>
              <Button type='primary' key='show-help' onClick={this.openHelpAndNotShowAgain}>Pokaż pomoc</Button>
              <Button key='not-show-again' onClick={this.notShowWelcomeModalAgain}>Nie pokazuj ponownie</Button>
              <Button key='close' onClick={() => this.handleWelcomeModalVisible(false)}>Zamknij</Button>
            </Space>
          ] : [
            <Button type='primary' key='show-help' onClick={this.openHelpAndNotShowAgain}>Pokaż pomoc</Button>,
            <Button key='not-show-again' onClick={this.notShowWelcomeModalAgain}>Nie pokazuj ponownie</Button>,
            <Button key='close' onClick={() => this.handleWelcomeModalVisible(false)}>Zamknij</Button>
          ]}
        >
          W tym miejscu możesz dodawać swoje przepisy - tak aby wszystkie mieć w jednym miejscu i żeby żaden Ci nie zaginął ;-). Obecna wersja aplikacji pozwala na dodanie do 10 przepisów. Jeśli chcesz dowiedzieć się więcej jak działają Moje przepisy, kliknij przycisk 'Pokaż Pomoc'.
        </Modal>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>

        <div style={{ marginLeft: '20px', marginRight: '20px', display: loaded ? 'block' : 'none' }} className='myrecipes-list'>
          <Row gutter={[10, 10]}>
            {recipes?.map(item => {
              return <Col xs={24} sm={12} md={8} lg={5} key={item.id}>
                <Card
                  hoverable
                  className='myrecipes-list-item'
                  cover={<Link to={'/myrecipes/' + item.id}><img width={196} src={'/images/recipes.png'} alt='przepis' /></Link>}
                >
                  <Link className='myrecipes-list-title' to={'/myrecipes/' + item.id}>{item.name}</Link>
                </Card>
              </Col>
            })}
          </Row>
        </div>

        {recipes?.length === 0 && <Empty description='Brak przepisów'><Button type="primary" onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/myrecipes/add')}>Dodaj teraz!</Button></Empty>}

        {isMobile && <Button className={'mobile-add-btn' + (this.props.screenSize === 'xs' ? ' with-menu' : '')} type="primary" shape="circle" size="large" onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/myrecipes/add')}>+</Button>}
        {/*<div style={{ display: loaded ? 'block' : 'none' }}>
          {isMobile &&
            <List
              itemLayout='horizontal'
              pagination
              className='mobile-list'
              dataSource={recipes}
              locale={{ emptyText: <Empty description='Brak przepisów' /> }}
              renderItem={(item) => (
                <List.Item
                  actions={[<Link key='edit' to={'/myrecipes/' + item.id}>pokaż</Link>]}
                >
                  <List.Item.Meta
                    onClick={() => this.props.redirect('/myrecipes/' + item.id)}
                    title={<Link key='edit' to={'/myrecipes/' + item.id}>{item.name}</Link>}
                    description={this.props.costCalc && <><DollarCircleOutlined /> {item.cost} zł</>}
                  />
                </List.Item>
              )}
            />}
          {!isMobile && (
            <Table
              className={`settings-table ${recipes && recipes.length ? 'fade-in' : ''}`}
              {...this.settings}
              columns={columns}
              dataSource={recipes}
              rowKey='id'
              locale={{
                emptyText: <Empty description='Brak przepisów' />,
                triggerDesc: 'Sortuj malejąco',
                triggerAsc: 'Sortuj rosnąco',
                cancelSort: 'Kliknij aby anulować sortowanie' }}
            />)}
        </div>*/}
        {this.props.screenSize === 'xs' && <StyledMyRecipesMobileMenu />}
      </>
    )
  }
}

export default menuTypeHook(StyledRecipeIndex)
