import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'

function Content(props) {
  const { ...tagProps } = props;
  const { title, content, className } = tagProps

  const dataSource = {
    wrapper: { className: (className || '') },
    OverPack: {
      className: 'home-page-wrapper content13-wrapper',
      playScale: 0.3
    },
    titleWrapper: {
      className: 'title-wrapper'
    },
    title: { className: 'content13-title', children: title },
    content: {
      className: 'content13-content',
      children:
        content
    }
  }

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type='bottom'
          leaveReverse
          key='page'
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          <h2 key='h1' {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>

        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content;





// class IndexExplain extends React.PureComponent {
//   render () {
//     const { ...props } = this.props
//     const { dataSource } = props
//     delete props.dataSource
//     delete props.isMobile
//     const button = { className: 'content13-button', children: <Link to='/register'>Zarejestruj się!</Link> }
//
//     return (
//       <OverPack {...props} {...dataSource.OverPack}>
//         <QueueAnim
//           type='bottom'
//           leaveReverse
//           key='page'
//           delay={[0, 100]}
//           {...dataSource.titleWrapper}
//         >
//           {dataSource.titleWrapper.children.map(getChildrenToRender)}
//           {<Button ghost key='button' {...button}>
//             {button.children}
//           </Button>}
//         </QueueAnim>
//       </OverPack>
//     )
//   }
// }
//
// export default IndexExplain
