import React from 'react'
import { PageHeader, Space, Alert } from 'antd'
import EnlargeImageForm from './form'
import EnlargeImagePreview from './preview'

class StyledEnlargeImageIndex extends React.Component {
  formRef = React.createRef()

  render () {
    const { redirect, isMobile, limitExceeded } = this.props

    return (
      <>
        <PageHeader
          title='Powiększ grafikę'
          onBack={() => redirect(this.props.authenticated ? '/panel' : '/')}
        />

        {limitExceeded && <div className='alert-wrapper'>
          <Alert
            className='resize-alert'
            closable
            style={{ marginTop: '10px' }}
            message={
              <>
                Wykorzystany już został limit powiększeń grafik (5). Jeśli potrzebujesz powiększać kolejne grafiki to odezwij się na <a href="mailto:kontakt@strefacukiernika.pl">kontakt@strefacukiernika.pl</a> i zapytaj o dostępne możliwości :)
              </>
            }
            type='info'
          />
        </div>}

        <EnlargeImageForm
          formRef={this.formRef}
          isMobile={isMobile}
          loading={this.props.loading}
          enlargeImage={this.props.enlargeImage}
          getUploadedFilePreview={this.props.getUploadedFilePreview}
          preview={this.props.preview}
          removeFile={this.props.removeFile}
          limitExceeded={limitExceeded}
        />

        {typeof this.props.enlargedPreview === 'string' && <Space direction='vertical' style={{ marginTop: '20px' }}>
          <EnlargeImagePreview
            downloadEnlargedImage={this.props.downloadEnlargedImage}
            preview={this.props.enlargedPreview}
            wasGenerated={this.props.wasGenerated}
            downloading={this.props.downloading}
            visible={!this.props.preview && this.props.enlargedPreview}
          />
        </Space>}
      </>
    )
  }
}

export default StyledEnlargeImageIndex
