import React from 'react'
import StyledCalcIndex from '../../styledComponents/calc'
import { Redirect } from 'react-router'
import { saveCalcPricing, getCalcPricing, saveSubmitCalcPricing, calculateCalcPricing } from '../../utils/actions'
import { saveDeviceData, getDeviceData } from '../../utils/helpers'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import ContentLeft from '../common/contentLeft'

class CalcIndex extends React.Component {
  state = {
    makeRedirection: false,
    calcData: undefined,
    showSaveAlert: undefined
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  visibilityChangeListener = () => {
    if (document.visibilityState === 'visible' && this.props.authenticated) {
      this.getData()
    }
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getData()
    }

    const showSaveAlert = getDeviceData('pricing_calc_save_alert') !== 'true'

    this.setState({ showSaveAlert })

    window.addEventListener('visibilitychange', this.visibilityChangeListener)
  }

  componentWillUnmount () {
    this._isMounted = false

    window.removeEventListener('visibilitychange', this.visibilityChangeListener)
  }

  saveData = (data) => {
    saveCalcPricing(data)
  }

  saveSubmitData = (data) => {
    saveSubmitCalcPricing(data)
  }

  getData = () => {
    getCalcPricing().then((json) => {
      if (this._isMounted) {
        this.setState({ calcData: json })
      }
    })
  }

  saveAlertClose = () => {
    saveDeviceData('pricing_calc_save_alert', 'true')
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, calcData, showSaveAlert } = this.state

    return (
      makeRedirection ? (
        <Redirect to={this.props.authenticated ? '/panel' : '/'} />
      ) : (
        this.props.authenticated ? <StyledCalcIndex
          makeRedirection={this.makeRedirection}
          calculateAction={calculateCalcPricing}
          isMobile={isMobile}
          saveData={this.saveData}
          saveSubmitData={this.saveSubmitData}
          calcData={calcData}
          showSaveAlert={showSaveAlert}
          saveAlertClose={this.saveAlertClose}
        /> : <ModuleNotLoggedContent
          title='Kalkulator do wycen'
          contentTitle='Policz ile dokładnie zarabiasz na torcie, cieście, monoporcji!'
          showCtaBottom
          content={
            <ContentLeft
              isMobile={isMobile}
              image='/images/calc_screen.png'
              title='Podstawowy kalkulator do wycen'
              content='Od wyceniania Twoich wypieków aż boli Cię głowa? Już nie będzie! Oddajemy w Twoje ręce prosty ale odpowiedni na początek drogi z wypiekami kalkulator, w którym uwzględnisz koszt zakupu składników, ile ich faktycznie zużyjesz a także wycenisz czas swojej pracy i inne niezbędne koszty aby rzetelnie przygotować wycenę dla klienta. Dowiesz się też ile faktycznie zarabiasz po odjęciu wszystkich kosztów :-)'
            />
          }
          redirect={this.redirect}
          redirectRoute={this.props.location?.pathname}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const CalcIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalcIndex)

export default CalcIndexContainer
