import React from 'react'
// import React, { useState } from 'react'

import '../../assets/scss/floatLabel.scss'

const FloatLabel = props => {
  // const [focus, setFocus] = useState(false)
  // const { children, label, value } = props
  const { children, label } = props

  const labelClass = 'label label-float'
  // const labelClass =
  //   focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div
      className='float-label'
      // onBlur={() => setFocus(false)}
      // onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  )
}

export default FloatLabel
