import React from 'react'
import StyledPortionsCalcIndex from '../../styledComponents/calcPortions'
import { Redirect } from 'react-router'
import { calculateCalcPortions } from '../../utils/actions'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import { Menu, Button } from 'antd'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined, YoutubeOutlined, LikeOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import CtaModal from '../../styledComponents/common/ctaModal'
import { getUserProfile } from '../../utils/actions'
import { sendUpvote } from '../../utils/actions'
import { setCtaModalVisible } from '../../store/actions'
import ContentRight from '../common/contentRight'

class PortionsCalcIndex extends React.Component {
  state = {
    makeRedirection: false
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { makeRedirection, user } = this.state

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='desc'
        >
          <a
            href='https://www.youtube.com/watch?v=ofRS711nCfk'
            target='_blank'
            rel='noopener noreferrer'
            onClick={ReactGA.event({ category: 'Portions Calculator', action: 'YT Video Opened Mobile' })}
          >
            <YoutubeOutlined /> Obejrzyj jak korzystać z kalkulatora
          </a>
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='desc'
      >
        <a
          href='https://www.youtube.com/watch?v=ofRS711nCfk'
          target='_blank'
          rel='noopener noreferrer'
          onClick={ReactGA.event({ category: 'Portions Calculator', action: 'YT Video Opened Desktop' })}
        >
          <YoutubeOutlined /> Obejrzyj jak korzystać z kalkulatora
        </a>
      </Button>,
    ]) || [actionsMenuMobile]

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText
    let buttonTextSize
    let buttonType
    let buttonCallback

    text = <>Hej! Ta funkcjonalność jeszcze nie jest gotowa... Jeśli chcesz przyspieszyć prace nad liczeniem porcji tortu piętrowego, kliknij kciuk w górę :D</>
    requiredSubs = ['sub1', 'sub2', 'sub3']
    buttonText = <LikeOutlined />
    buttonTextSize = 50
    buttonType = 'text'
    buttonCallback = () => { sendUpvote('calculator_portions_multitier') }

    // if (authenticated && user?.sub_type === 'free' && !user?.trial_used) {
    //   text = <>Hej! Liczenie porcji tortów piętrowych jest dostępne w Strefowych pakietach, możesz wybrać jeden z nich i testować za darmo przez 7 dni :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/calculator-portions']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet testowy i oblicz porcje tortu piętrowego'
    // } else if (authenticated && user?.sub_type === 'free' && user?.trial_used) {
    //   text = <>Hej! Liczenie porcji tortów piętrowych jest dostępne w Strefowych pakietach, możesz wybrać jeden z nich aby z niego skorzystać :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/calculator-portions']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i oblicz porcje tortu piętrowego'
    // }

    return (
      makeRedirection ? (
        <Redirect to={this.props.authenticated ? '/panel' : '/'} />
      ) : (
        authenticated ? <>
        <CtaModal
          text={text}
          toRoute={toRoute}
          redirect={redirect}
          requiredSubs={requiredSubs}
          buttonText={buttonText}
          buttonTextSize={buttonTextSize}
          buttonType={buttonType}
          buttonCallback={buttonCallback}
        />
        <StyledPortionsCalcIndex
          makeRedirection={this.makeRedirection}
          calculateAction={calculateCalcPortions}
          isMobile={isMobile}
          setCtaModalVisible={this.props.setCtaModalVisible}
          user={user}
        /></> : <ModuleNotLoggedContent
          title='Kalkulator porcji'
          extra={actions}
          showCtaBottom
          contentTitle='Ile porcji wyjdzie z tortu o danej średnicy?'
          content={
            <ContentRight
              isMobile={isMobile}
              image='/images/calculator-portions.png'
              title='Oblicz za pomocą kalkulatora porcji'
              content='Przydatny kalkulator do obliczenia ile porcji otrzymasz z określonej wielkości tortu (zarówno okrągłego, kwadratowego i prostokątnego)'
            />
          }
          redirect={this.redirect}
          redirectRoute={this.props.location?.pathname}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const PortionsCalcIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortionsCalcIndex)

export default PortionsCalcIndexContainer
