import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

class CtaBox extends React.Component {
  render () {
    const { text, toRoute, redirect, requiredSubs, buttonText, buttonTextSize, buttonType, callAfterClick, buttonCallback, noMargin, additionalText, priceBefore/*, additionalInfoAfter*/ } = this.props

    const button = (<Button className='cta-box-button' style={{ fontSize: buttonTextSize || undefined }} type={buttonType || 'default'} onClick={callAfterClick}>{buttonText}</Button>)

    return (
      <div className={'cta-box' + (noMargin ? ' cta-box-nomargin' : '')}>
        <div className='cta-box-inside'>
          <p>{text}</p>

          {priceBefore && <p className='price-before'>
            {priceBefore} zł miesięcznie
          </p>}

          {additionalText && <p>
            {additionalText}
          </p>}

          {buttonCallback && button}
          {!buttonCallback && toRoute && toRoute[0] === '/' && <Link to={{
            pathname: toRoute,
            state: { redirect, requiredSubs }
          }}>
            {button}
          </Link>}
          {!buttonCallback && toRoute && toRoute[0] !== '/' && <a href={toRoute}>
            {button}
          </a>}
          {/*additionalInfoAfter && <p style={{ marginTop: '20px' }}>{additionalInfoAfter}</p>*/}
        </div>
      </div>
    )
  }
}

export default CtaBox
