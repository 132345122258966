/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import ReactGA from 'react-ga4'
import StyledPrintoutShow from '../../styledComponents/printout/printoutShow'
import { getPrintout, downloadPrintout } from '../../utils/actions'
import { slugify } from '../../utils/helpers'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

const FileDownload = require('js-file-download')

class PrintoutShow extends React.Component {
  state = {
    id: undefined,
    printout: undefined,
    downloading: false
  }

  getPrintout = (id) => {
    getPrintout(id).then((json) => {
      if (this._isMounted) {
        this.setState({ printout: json })
      }
    })
  }

  download = () => {
    this.setState({ downloading: true })
    downloadPrintout(this.state.id).then((json) => {
      if (this._isMounted) {
        this.setState({ downloading: false })
        FileDownload(json.data, slugify(this.state.printout.name) + '-strefacukiernika.png')
      }
    })

    ReactGA.event({ category: 'Printout Projects', action: 'Downloaded Printout: ' + this.state.id })
  }

  componentDidMount () {
    const { id } = this.props.match.params
    this._isMounted = true

    this.setState({ id })
    this.getPrintout(id)

    ReactGA.event({ category: 'Printout Projects', action: 'Printout Showed: ' + id })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { makeRedirection, printout, downloading } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/grafiki-do-wydrukow' />
      ) : (
        <>
          <PageHeader
            title={printout ? printout?.name : 'Projekt printouta'}
            onBack={() => this.makeRedirection()}
          />
          <StyledPrintoutShow
            printout={printout}
            download={this.download}
            isMobile={isMobile}
            redirect={this.redirect}
            downloading={downloading}
            authenticated={authenticated}
          />
        </>
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const PrintoutShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintoutShow)

export default PrintoutShowContainer
