import React from 'react'
import StyledUserPurchaseIndex from '../../styledComponents/userPurchase'
import { getUserPurchases } from '../../utils/actions'

class CalcIndex extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getUserPurchases()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getUserPurchases = () => {
    getUserPurchases().then((json) => {
      if (this._isMounted) {
        this.setState({ purchases: json.purchases, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { purchases, loaded } = this.state

    return (
      <StyledUserPurchaseIndex
        isMobile={isMobile}
        purchases={purchases}
        loaded={loaded}
        redirect={this.redirect}
        from_route={this.props.location.state?.from}
      />
    )
  }
}

export default CalcIndex
