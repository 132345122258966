import React from 'react'
import { setCookie } from '../../utils/helpers'
import { withTranslation } from 'react-i18next'
import { browserName } from 'react-device-detect'
import { Dialog } from 'antd-mobile'

class PwaPrompt extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defferedPrompt: undefined
    }
  }

  prompt = () => {
    if (
      this.props.isMobile &&
      this.props.isAuthenticated &&
      !window.matchMedia('(display-mode: standalone)').matches &&
      !this.props.installPromptShowed
    ) {
      if (this.state.defferedPrompt !== undefined) {
        Dialog.confirm({
          content: this.props.t('Do you want install this application on your\'s device?'),
          onClose: () => {
            setCookie('install_prompt_showed', 'true', 30)
            this.props.setInstallPromptShowed(true)
          },
          cancelText: this.props.t('Cancel'),
          confirmText: this.props.t('Install'),
          onConfirm: () => {
            setCookie('install_prompt_showed', 'true', 30)
            this.props.setInstallPromptShowed(true)

            if (this.state.defferedPrompt !== undefined) {
              this.state.defferedPrompt.prompt()

              this.state.defferedPrompt.userChoice.then(choice => {
                this.setState({ defferedPrompt: null })
              })
            }
          }
        })
      } else if (browserName === 'Safari') {
        Dialog.alert({
          content: (
            <>
              <p>{this.props.t('Do you want install this application on your\'s device?')}</p>
              <p>{this.props.t('Step 1: Tap Share icon')}<img src='/images/ios-share-icon.jpg' className='pwa-prompt-ios-icon' alt='ios share icon' /></p>
              <p>{this.props.t('Step 2: Tap "Add to Home screen"')}</p>
            </>),
          confirmText: this.props.t('Close'),
          onConfirm: () => {
            setCookie('install_prompt_showed', 'true', 30)
          }
        })
      }
    }
  }

  componentDidMount () {
    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault()
      this.setState({ defferedPrompt: event })
    })

    this.prompt()
  }

  componentDidUpdate () {
    this.prompt()
  }

  render () {
    return ''
  }
}

export default withTranslation()(PwaPrompt)
