/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Select, Row, Col, Card, InputNumber,
  Result, Popconfirm, Skeleton, Menu, Drawer, Dropdown, Divider,
  Collapse, Empty, Segmented
} from 'antd'
import { Dropdown as DropdownMobile, Popup, Selector } from 'antd-mobile'
import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, MoreOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import HelpBox from '../common/helpBox'

import FloatLabel from '../common/floatLabel'

const { Panel } = Collapse

const formItemLayout = {
  labelCol: {
    xs: 14,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 6,
    md: 3,
    lg: 4
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

const resultsItemsDivider = {
  orientationMargin: '40px',
  style: { margin: '4px 0 4px 0' }
}

const { Option } = Select

const unitMap = {
  kg: ['g', 'kg', 'szklanka'],
  g: ['g', 'łyżeczka', 'łyżka', 'szklanka'],
  ml: ['ml', 'g', 'kropla', 'łyżka', 'łyżeczka', 'szklanka'],
  szt: ['szt'],
  'opak.': ['szt'],
  l: ['ml', 'l', 'łyżka', 'łyżeczka', 'g', 'szklanka']
}

const oneGlassGram = 200
const oneLittleSpoonGram = 5
const oneSpoonGram = 15
const oneGlassMl = 200
const oneLittleSpoonMl = 5
const oneSpoonMl = 15
const oneDropMl = 0.2

class CalculatorBasicForm extends React.Component {
  state = {
    purchasedIngredients: [],
    purchasedIngredientsCounter: 0,
    sumOfCosts: 0,
    priceToClient: 0,
    initialValues: undefined,
    testChanged: false,
    earnings: 0,
    editing: false,
    loaded: false,
    visibleHelpPurchasedIngredients: false,
    visibleHelpUsedIngredients: false,
    visibleHelpCapturedTime: false,
    visibleHelpParameters: false,
    visibleHelpFixedCosts: false,
    descBoxVisible: false,
    loading_calculation: false,
    calcType: 'overhead'
  }

  formRef = React.createRef()
  xref = React.createRef()
  resultRef = React.createRef()
  mobileDropdownRef = React.createRef()

  handleCalcTypeSegmented = (calcType) => {
    this.setState({ calcType })
  }

  handleCalcTypeSelector = (calcTypes) => {
    this.setState({ calcType: calcTypes[0] })
  }

  setVisibleHelpPurchasedIngredients = (flag) => {
    this.setState({ visibleHelpPurchasedIngredients: flag })

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'VisibleHelpPurchasedIngredients Help Opened Mobile' })
  }

  setVisibleHelpUsedIngredients = (flag) => {
    this.setState({ visibleHelpUsedIngredients: flag })

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'VisibleHelpUsedIngredients Help Opened Mobile' })
  }

  setVisibleHelpCapturedTime = (flag) => {
    this.setState({ visibleHelpCapturedTime: flag })

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'VisibleHelpCapturedTime Help Opened Mobile' })
  }

  setVisibleHelpParameters = (flag) => {
    this.setState({ visibleHelpParameters: flag })

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'VisibleHelpParameters Help Opened Mobile' })
  }

  setVisibleHelpFixedCosts = (flag) => {
    this.setState({ visibleHelpFixedCosts: flag })

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'VisibleHelpFixedCosts Help Opened Mobile' })
  }

  setDescBoxVisible = (flag, device) => {
    this.setState({ descBoxVisible: flag })
    this.mobileDropdownRef?.current?.close()

    flag && ReactGA.event({ category: 'Pricing Calculator', action: 'Description Box Opened ' + device })
  }

  handleSubmit = (x) => {
    this.setState({ loading_calculation: true })

    this.props.calculateAction({...this.formRef.current?.getFieldsValue(), calc_type: this.state.calcType}).then((json) => {
      this.setState({
        sumOfCosts: json.sum_of_costs,
        priceToClient: json.price_to_client,
        earnings: json.earnings,
        calcDetails: json.calc_details,
        showCalculation: true,
        purchasedIngredients: x.purchased_ingredients
      })

      ReactGA.event({ category: 'Pricing Calculator', action: 'Price Calculated' })

      this.props.saveSubmitData({ data: { sum_of_costs: json.sum_of_costs, price_to_client: json.price_to_client, earnings: json.earnings, ...this.formRef.current?.getFieldsValue(), calc_type: this.state.calcType } })
      this.setState({ loading_calculation: false })
    }).catch(error => {
      this.setState({ loading_calculation: false })
    })

    // const sumOfFixedCosts = x.fixed_costs && x.fixed_costs.length && x.fixed_costs.map(item => item.value).reduce((prev, next) => prev + next)
    // const costPerCake = (sumOfFixedCosts && parseFloat(sumOfFixedCosts / x.cakes_per_month)) || 0
    // let costOfIngredients = 0
    // let costOfTime = 0
    //
    // x.used_ingredients?.forEach((item, i) => {
    //   const purchasedProduct = x.purchased_ingredients.find(x => x.id === item.ingId)
    //
    //   if (purchasedProduct) {
    //     let ingredientCost = 0
    //
    //     if (purchasedProduct.unit === 'kg') {
    //       const price1kg = purchasedProduct.price / purchasedProduct.quantity
    //       const price1g = price1kg / 1000
    //
    //       if (item.unit === 'g') {
    //         ingredientCost = price1g * item.quantity
    //       } else if (item.unit === 'kg') {
    //         ingredientCost = price1kg * item.quantity
    //       } else if (item.unit === 'szklanka') {
    //         ingredientCost = price1g * oneGlassGram * item.quantity
    //       }
    //     } else if (purchasedProduct.unit === 'g') {
    //       const price1g = purchasedProduct.price / purchasedProduct.quantity
    //
    //       if (item.unit === 'g') {
    //         ingredientCost = price1g * item.quantity
    //       } else if (item.unit === 'łyżeczka') {
    //         ingredientCost = oneLittleSpoonGram * price1g * item.quantity
    //       } else if (item.unit === 'łyżka') {
    //         ingredientCost = oneSpoonGram * price1g * item.quantity
    //       } else if (item.unit === 'szklanka') {
    //         ingredientCost = price1g * oneGlassGram * item.quantity
    //       }
    //     } else if (purchasedProduct.unit === 'ml') {
    //       const price1ml = purchasedProduct.price / purchasedProduct.quantity
    //
    //       if (item.unit === 'kropla') {
    //         ingredientCost = oneDropMl * price1ml * item.quantity
    //       } else if (item.unit === 'g' || item.unit === 'ml') {
    //         ingredientCost = price1ml * item.quantity
    //       } else if (item.unit === 'łyżeczka') {
    //         ingredientCost = oneLittleSpoonMl * price1ml * item.quantity
    //       } else if (item.unit === 'łyżka') {
    //         ingredientCost = oneSpoonMl * price1ml * item.quantity
    //       } else if (item.unit === 'szklanka') {
    //         ingredientCost = price1ml * oneGlassMl * item.quantity
    //       }
    //     } else if (purchasedProduct.unit === 'l') {
    //       const price1l = purchasedProduct.price / purchasedProduct.quantity
    //       const price1ml = price1l / 1000
    //
    //       if (item.unit === 'l') {
    //         ingredientCost = price1l * item.quantity
    //       } else if (item.unit === 'g' || item.unit === 'ml') {
    //         ingredientCost = price1ml * item.quantity
    //       } else if (item.unit === 'łyżeczka') {
    //         ingredientCost = oneLittleSpoonMl * price1ml * item.quantity
    //       } else if (item.unit === 'łyżka') {
    //         ingredientCost = oneSpoonMl * price1ml * item.quantity
    //       } else if (item.unit === 'szklanka') {
    //         ingredientCost = price1ml * oneGlassMl * item.quantity
    //       }
    //     } else if (purchasedProduct.unit === 'szt') {
    //       ingredientCost = purchasedProduct.price * item.quantity
    //     } else if (purchasedProduct.unit === 'opak.') {
    //       const onPieceCost = purchasedProduct.price / purchasedProduct.quantity
    //       ingredientCost = onPieceCost * item.quantity
    //     }
    //
    //     costOfIngredients += ingredientCost
    //   }
    // })
    //
    // x.captured_time?.forEach((item, i) => {
    //   let timeCost = 0
    //
    //   if (item.unit === 'minuta') {
    //     timeCost = (x.h_price / 60) * item.quantity
    //   } else {
    //     timeCost = x.h_price * item.quantity
    //   }
    //
    //   if (!isNaN(timeCost)) {
    //     costOfTime += timeCost
    //   }
    // })
    //
    // const summaryCostWitTime = costPerCake + costOfIngredients + costOfTime
    // const summaryCost = (costPerCake + costOfIngredients).toFixed(2)
    // const margin = summaryCostWitTime / ((100 - x.margin) / 100)
    // const priceToClient = margin.toFixed(2)
    // const earnings = (costOfTime + (priceToClient - summaryCostWitTime)).toFixed(2)
    //
    // const calcDetails = {
    //   costOfTime: costOfTime.toFixed(2),
    //   costOfIngredients: costOfIngredients.toFixed(2),
    //   margin: (priceToClient - summaryCostWitTime).toFixed(2),
    //   costPerCake: costPerCake.toFixed(2)
    // }
    //
    // // console.log('submit summaryCostWitTime', summaryCostWitTime)
    // // console.log('submit summaryCost', summaryCost)
    // // console.log('submit margin', margin)
    // // console.log('submit priceToClient', priceToClient)
    // // console.log('submit earnings', earnings)
    //
    // this.setState({ purchasedIngredients: x.purchased_ingredients, sumOfCosts: summaryCost, priceToClient, earnings, calcDetails })
    //
    // this.resultRef?.current?.scrollIntoView({ behavior: 'smooth' })
    //
    // ReactGA.event({ category: 'Pricing Calculator', action: 'Price Calculated' })
    //
    // this.props.saveSubmitData({ data: { sum_of_costs: summaryCost, price_to_client: priceToClient, earnings: earnings, ...this.formRef.current?.getFieldsValue() } })
  }

  handleLoadExample = () => {
    const initialValues = {
      purchased_ingredients: [{ id: 2, name: 'mąka tortowa', unit: 'kg', price: 3.69, quantity: 1 }, { id: 3, name: 'jajka', unit: 'opak.', price: 10.99, quantity: 10 }, { id: 4, name: 'proszek do pieczenia', unit: 'g', price: 0.7, quantity: 15 }, { id: 5, name: 'cukier', unit: 'kg', price: 3.89, quantity: 1 }, { id: 6, name: 'aromat śmietankowy', unit: 'ml', price: 1.58, quantity: 9 }, { id: 7, name: 'aromat waniliowy', unit: 'ml', price: 1.58, quantity: 9 }, { id: 13, name: 'truskawki', unit: 'kg', price: 14, quantity: 1 }, { id: 14, name: 'śmietanka kremówka 30%', unit: 'ml', price: 8.09, quantity: 500 }, { id: 8, name: 'ser mascarpone', unit: 'g', price: 7.29, quantity: 250 }, { id: 9, name: 'żelatyna', unit: 'g', price: 2.75, quantity: 20 }, { id: 10, name: 'cukier waniliowy', unit: 'g', price: 0.39, quantity: 16 }, { id: 11, name: 'cukier puder', unit: 'g', price: 2.25, quantity: 400 }, { id: 12, name: 'galaretka truskawkowa w proszku', unit: 'g', price: 1.58, quantity: 77 }],
      used_ingredients: [{ unit: 'szt', ingId: 3, quantity: 6 }, { unit: 'g', ingId: 2, quantity: 170 }, { unit: 'łyżeczka', ingId: 4, quantity: 1 }, { unit: 'g', ingId: 5, quantity: 170 }, { unit: 'kropla', ingId: 6, quantity: 9 }, { unit: 'kropla', ingId: 7, quantity: 3 }, { unit: 'g', ingId: 13, quantity: 150 }, { unit: 'g', ingId: 14, quantity: 400 }, { unit: 'g', ingId: 8, quantity: 250 }, { unit: 'g', ingId: 13, quantity: 700 }, { unit: 'łyżeczka', ingId: 9, quantity: 6 }, { unit: 'kropla', ingId: 6, quantity: 3 }, { unit: 'łyżeczka', ingId: 10, quantity: 1 }, { unit: 'łyżka', ingId: 11, quantity: 6 }, { unit: 'g', ingId: 14, quantity: 250 }, { unit: 'g', ingId: 8, quantity: 250 }, { unit: 'g', ingId: 13, quantity: 700 }, { unit: 'łyżka', ingId: 11, quantity: 2 }, { unit: 'łyżeczka', ingId: 10, quantity: 2 }, { unit: 'g', ingId: 12, quantity: 40 }],
      captured_time: [{ name: 'pieczenie', unit: 'godzina', quantity: 1 }, { name: 'przygotowanie kremu', unit: 'godzina', quantity: 0.5 }, { name: 'składanie', unit: 'minuta', quantity: 45 }, { name: 'dekorowanie', unit: 'godzina', quantity: 1 }],
      fixed_costs: [{ name: 'wynajem lokalu', value: 1000 }, { name: 'media', value: 300 }],
      cakes_per_month: 35,
      h_price: 20,
      margin: 10,
      calc_type: 'margin'
    }

    this.formRef?.current?.setFieldsValue(initialValues)
    const purchasedIngredientsCounter = Math.max(...initialValues.purchased_ingredients.map(o => o.id)) + 1

    this.setState({
      purchasedIngredients: initialValues.purchased_ingredients,
      purchasedIngredientsCounter,
      calcType: initialValues.calc_type
    })
    this.formRef.current?.submit()
    this.saveFormData()
    this.mobileDropdownRef?.current?.close()

    ReactGA.event({ category: 'Pricing Calculator', action: 'Example 1 Loaded' })
  }

  saveFormData = () => {
    if (this.state.timer) return

    const _this = this
    const timer = setTimeout(() => {
      _this.props.saveData({ data: { ...this.formRef.current?.getFieldsValue(), calc_type: this.state.calcType } })
      _this.setState({ timer: null })
    }, 2000)

    this.setState({ timer })
  }

  handleClearForm = () => {
    const initialValues = {
      purchased_ingredients: [],
      used_ingredients: [],
      captured_time: [],
      fixed_costs: [],
      cakes_per_month: 0,
      h_price: 0,
      calc_type: 'overhead',
      margin: 0,
      overhead: 0
    }

    this.formRef?.current?.setFieldsValue(initialValues)

    this.setState({
      purchasedIngredients: initialValues.purchased_ingredients,
      sumOfCosts: 0,
      priceToClient: 0,
      calcType: 'overhead'
    })

    this.saveFormData()
    this.mobileDropdownRef?.current?.close()

    ReactGA.event({ category: 'Pricing Calculator', action: 'Form Cleared' })
  }

  removePurchasedIngredient = (name, remove, x, y) => {
    const ingToRemove = this.formRef.current?.getFieldValue('purchased_ingredients')[name]?.id
    remove(name)
    const fieldV = this.formRef.current?.getFieldValue('used_ingredients')

    const removedPurchasedIngredient = fieldV?.filter(object => {
      return object?.ingId !== ingToRemove
    })

    this.setState({ purchasedIngredients: this.formRef.current?.getFieldValue('purchased_ingredients') })

    this.formRef.current?.setFieldsValue({ used_ingredients: removedPurchasedIngredient })

    this.saveFormData()
  }

  handleUpdatePurchasedIngredients = () => {
    this.setState({ purchasedIngredients: this.formRef.current?.getFieldValue('purchased_ingredients') })
  }

  handleUpdatePurchasedIngredientUnit = (name) => {
    const ingId = this.state.purchasedIngredients[name]?.id
    const fieldV = this.formRef.current?.getFieldValue('used_ingredients')

    const updatedUsedIngredient = fieldV?.map(object => {
      if (object?.ingId === ingId) {
        object.unit = undefined
      }

      return object
    })

    this.formRef.current?.setFieldsValue({ used_ingredients: updatedUsedIngredient })
    this.formRef.current?.validateFields()
  }

  addPurchasedIngredient = (add, name) => {
    const id = this.state.purchasedIngredientsCounter
    add({ id: id })
    this.setState({ purchasedIngredientsCounter: id + 1, purchasedIngredients: this.formRef.current?.getFieldValue('purchased_ingredients') })
    this.handlePurchasedIngredientsFocus(name)
  }

  handleFormChange = () => {
    if (this.state.sumOfCosts !== 0 || this.state.priceToClient !== 0) {
      this.setState({ sumOfCosts: 0, priceToClient: 0 })
    }
    this.saveFormData()
  }

  // onBlurTest = (flag) => {
  //   console.log('onBlurTest')
  //   this.setState({ editing: flag })
  // }

  getIngUnit = (name) => {
    return this.formRef.current?.getFieldValue('purchased_ingredients')[name]?.unit
  }

  handlePurchasedIngredientsFocus = (name) => {
    this.setState({ purchasedIngredientsFocus: name })
  }

  handleUsedIngredientsFocus = (name) => {
    this.setState({ usedIngredientsFocus: name })
  }

  handleCapturedTimeFocus = (name) => {
    this.setState({ capturedTimeFocus: name })
  }

  handleFixedCostsFocus = (name) => {
    this.setState({ fixedCostsFocus: name })
  }

  getCapturedTimeUnit = (name) => {
    const unit = this.formRef.current?.getFieldValue('captured_time')[name]?.unit

    if (unit === 'godzina') {
      return ' godzin'
    } else if (unit === 'minuta') {
      return ' minut'
    }

    return ''
  }

  getIngUnits = (name) => {
    const ingId = this.formRef.current?.getFieldValue('used_ingredients')[name]?.ingId
    const purchasedProduct = this.formRef.current?.getFieldValue('purchased_ingredients').find(x => x.id === ingId)

    if (unitMap[purchasedProduct?.unit]) {
      return unitMap[purchasedProduct?.unit]
    }

    return []
  }

  handleCalcDetailsChange = (data) => {
    !!data?.length && ReactGA.event({ category: 'Pricing Calculator', action: 'Pricing Details Opened' })
  }

  componentDidMount () {
    const { calcData } = this.props
    if (calcData) {
      if (Object.keys(calcData).length > 0) {
        this.formRef.current.setFieldsValue(calcData)
        const purchasedIngredientsCounter = calcData.purchased_ingredients ? Math.max(...calcData.purchased_ingredients.map(o => o.id)) + 1 : 0
        this.setState({
          purchasedIngredients: calcData.purchased_ingredients,
          purchasedIngredientsCounter: purchasedIngredientsCounter,
          loaded: true,
          calcType: calcData.calc_type
        })
      }
    } else {
      this.setState({
        loaded: true
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { calcData } = this.props

    if (calcData && prevProps.calcData !== calcData) {
      if (Object.keys(calcData).length > 0) {
        this.formRef.current.setFieldsValue(calcData)
        const purchasedIngredientsCounter = calcData.purchased_ingredients ? Math.max(...calcData.purchased_ingredients.map(o => o.id)) + 1 : 0

        this.setState({
          purchasedIngredients: calcData.purchased_ingredients,
          purchasedIngredientsCounter: purchasedIngredientsCounter,
          loaded: true,
          calcType: calcData.calc_type
        })
      } else {
        this.setState({
          loaded: true
        })
      }
    }
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    // const formHelpTooltipOptions = {
    //   placement: 'right',
    //   trigger: 'click'
    // }

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='desc'
          onClick={() => this.setDescBoxVisible(true, 'Mobile')}
        >
            Jak wyceniać wypieki?
        </Menu.Item>
        <Popconfirm
          title='Czy na pewno chcesz załadować przykładową kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
          onConfirm={this.handleLoadExample}
          {...popconfirmProps}
        >
          <Menu.Item key='load-example'>
              Załaduj przykładową kalkulację
          </Menu.Item>
        </Popconfirm>
        <Popconfirm

          title='Czy na pewno chcesz wyczyścić obecną kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
          onConfirm={this.handleClearForm}
          {...popconfirmProps}
        >
          <Menu.Item key='clear-form'>
              Wyczyść formularz
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='desc'
        onClick={() => this.setDescBoxVisible(true, 'Desktop')}
      >
        Jak wyceniać wypieki?
      </Button>,
      <Popconfirm
        key='load-example'
        title='Czy na pewno chcesz załadować przykładową kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
        onConfirm={this.handleLoadExample}
        {...popconfirmProps}
      >
        <Button>Załaduj przykładową kalkulację</Button>
      </Popconfirm>,
      <Dropdown
        key='clear-form'
        ref={this.mobileDropdownRef}
        overlay={
          <Menu selectable={false} className='calculator-actions-menu'>
            <Popconfirm

              title='Czy na pewno chcesz wyczyścić obecną kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
              onConfirm={this.handleClearForm}
              {...popconfirmProps}
            >
              <Menu.Item key='clear-form'>
                  Wyczyść formularz
              </Menu.Item>
            </Popconfirm>
          </Menu>
        }
      >
        <MoreOutlined style={{ fontSize: 20 }} />
      </Dropdown>
    ]) || [actionsMenuMobile]

    if (this.props.headerActions.length !== actions.length) {
      this.props.setHeaderActions(actions)
    }

    const calcDesc = (
      <>
        <h3>Koszt składników</h3>
        <p>Na każdy wypiek składają się przede wszystkim zużyte składniki jak np. mąka, jajka jak również akcesoria i np. podkłady. Składniki będą się różnić od konkretnej wielkości wypieku i wymagań klienta odnośnie dekoracji. W kalkulatorze definiujemy je w dwóch sekcjach. Pierwsza z nich to "Cena zakupu składników". W tej sekcji wprowadzamy rodzaj, ilość i cenę zakupu składników, które w odpowiednich proporcjach w kroku następnym posłużą do przygotowania wypieku. Kolejna sekcja "Użyte składniki" służy do określenia jaka konkretnie ilość (także ile sztuk jeśli chodzi o dekoracje) została zużyta do bieżącego wypieku.</p>

        <h3>Czas</h3>
        <p>Każdy wypiek wymaga określonej pracy aby z zakupionych składników takich jak mąka czy mleko wyczarować cudny wypiek. Czas poświęcony na zakup składników, wyrabianie ciasta, kremów czy dekorowanie należy skrupulatnie wypunktować - za tę pracę należy Ci się wynagrodzenie, które powinno mieć odzwierciedlenie w cenie produktu końcowego.</p>

        <h3>Koszty stałe</h3>
        <p>Wypieki potrzebują miejsca na przygotowanie takich jak lokal gastronomiczny lub po prostu Twoja kuchnia, potrzebują też piekarnika, lodówki. Bez prądu, kawałka blatu żaden z Twoich pięknych wypieków by nie powstał. W kosztach stałych należy uwzględnić wszystkie stałe (w skali miesiąca) obciążenia takie jak wynajem lokalu, spłatę sprzętu, wynagrodzenie dla pracownika czy opłatę za media.</p>

        <h3>Marża i narzut</h3>
        <p>Tworząc wypiek należy również wziąć pod uwagę taki element jak marża lub narzut. Jest to procentowa wartość, która jest doliczana do ceny końcowej produktu wyliczonej na podstawie sumy użytych składników, poświęconego czasu i kosztów stałych. Marża lub narzut jest to zysk dla Twojej pracowni z wytworzonego wypieku. Z zarobionych w ten sposób pieniędzy możesz finansować swój rozwój (i Twojego zespołu jeśli go posiadasz) jak np. szkolenia ale także rozwój Twojej pracowni o nowe sprzęty i akcesoria mogące przyspieszyć i podnieść jakość Twoje pracy.</p>

        <h3>Marża i narzut - podobieństwa i różnice</h3>
        <p>Marża procentowa jest to stosunek zysku ze sprzedaży do ceny sprzedaży, natomiast narzut procentowy jest stosunkiem zysku ze sprzedaży do ceny zakupu. Z racji różnic w sposobie wyliczania marża nie może być większa niż 100% (nie możemy zarobić na transakcji 105zł jeśli sprzedaliśmy produkt za 100zł), narzut natomiast możemy dodać w dowolnej wysokości.</p>
      </>
    )

    const purchasedIngredientsHelp = (
      <>
        Tutaj definiujesz zakupione produkty w całych opakowaniach jak np. mąką, jajka czy akcesoria do dekoracji. Konkretną zużytą ilość składników zdefiniujesz w sekcji "Użyte składniki".<br /><br />
        Typowe pozycje, które należy uwzględnić na tej liście:<br />
        <ul className='calculator-help-list'>
          <li><b>składniki spożywcze</b></li>
          <li><b>podkłady</b></li>
          <li><b>toppery</b></li>
          <li><b>figurki (lub składniki na nie jeśli wykonujesz samodzielnie)</b></li>
        </ul>
      </>
    )

    const usedIngredientsHelp = (
      <>
        W tej sekcji definiujesz np. ile kg mąki zostało zużyte, ile jajek albo ile sztuk akcesoriów (wprowadzonych w sekcji "Cena zakupu składników"). Na tej podstawie kalkulator wyliczy jaki był faktyczny koszt składników zużytych na bieżące zamówienie.<br /><br />
        Przyjęte przeliczniki jednostek:<br /><br />
        <b>1 szklanka</b> = {oneGlassGram}g = {oneGlassMl}ml<br />
        <b>1 łyżeczka</b> = {oneLittleSpoonGram}g = {oneLittleSpoonMl}ml<br />
        <b>1 łyżka</b> = {oneSpoonGram}g = {oneSpoonMl}ml<br />
        <b>1 kropla</b> = {oneDropMl}ml<br />
      </>
    )

    const capturedTimeHelp = (
      <>
        Tutaj definiujesz ile czasu zostało poświęcone na poszczególne etapy przygotowania wypieku.<br /><br />
        Typowe pozycje, które należy uwzględnić na tej liście:<br />
        <ul className='calculator-help-list'>
          <li><b>zakupy</b></li>
          <li><b>pieczenie</b></li>
          <li><b>przygotowanie kremu, tynku</b></li>
          <li><b>składanie</b></li>
          <li><b>tynkowanie</b></li>
          <li><b>przygotowanie ozdób</b></li>
          <li><b>dekorowanie</b></li>
        </ul>
      </>
    )

    const parametersHelp = (
      <>
        W tej sekcji określasz parametry niezbędne do wyliczenia ceny końcowej takie jak:<br />
        <ul className='calculator-help-list'>
          <li><b>ile wypieków wytwarzasz w ciągu miesiąca</b> - na tej podstawie kalkulator obliczy średni koszt jaki trzeba doliczyć do każdego wypieku.</li>
          <li><b>ile kosztuje godzina Twoje pracy</b> - na pewno nie mniej niż 19,70 zł brutto (13,91 zł netto) - tyle wynosi minimalne wynagrodzenie na godzinę w Polsce w 2022 roku</li>
          <li><b>dolicz do ceny narzut lub marżę</b> - określ jaki współczynnik chcesz doliczyć do ceny końcowej - jest to zysk dla Twojej pracowni z wytworzonego wypieku. Z zarobionych w ten sposób pieniędzy możesz finansować swój rozwój (i Twojego zespołu jeśli go posiadasz) jak np. szkolenia ale także rozwój Twojej pracowni o nowe sprzęty i akcesoria mogące przyspieszyć i podnieść jakość Twoje pracy.</li>
          <li><b>jaka jest wysokość Twojej marży</b> - tutaj w zasadzie dowolnie, może to być zarówno 10% - 30% i więcej (max 99.9%)</li>
          <li><b>jaka jest wysokość Twojego narzutu</b> - tutaj w zasadzie dowolnie, może to być zarówno 10% - 30% i więcej</li>
        </ul>
        <br/>

        <b>Czym są marża i narzut, jakie są podobieństwa i różnice?</b><br/>
        Marża procentowa jest to stosunek zysku ze sprzedaży do ceny sprzedaży, natomiast narzut procentowy jest stosunkiem zysku ze sprzedaży do ceny zakupu. Zarówno marża jak i narzut doliczony do ceny wyjściowej w określonej wysokości jest to nasz zysk. Z racji różnic w sposobie wyliczania marża nie może być większa niż 100% (nie możemy zarobić na transakcji 105zł jeśli sprzedaliśmy produkt za 100zł), narzut natomiast możemy dodać w dowolnej wysokości.
      </>
    )

    const fixedCostsHelp = (
      <>
        Tutaj definiujesz miesięczne koszty funkcjonowania Twojej pracowni.<br /><br />
        Typowe pozycje, które należy uwzględnić na tej liście:<br />
        <ul className='calculator-help-list'>
          <li><b>wynajem lokalu</b></li>
          <li><b>pensja pracownika</b></li>
          <li><b>leasing/kredyt auta</b></li>
          <li><b>leasing/kredyt urządzeń</b></li>
          <li><b>media</b></li>
          <li><b>podatek od nieruchomości/lokalu (jeśli jest Twój)</b></li>
          <li><b>ubezpieczenie</b></li>
          <li><b>księgowość</b></li>
          <li><b>koszt strony internetowej/domeny/hostingu</b></li>
          <li><b>abonament na telefon (służbowy ;)</b></li>
        </ul>
      </>
    )

    const selectOptions = [{ label: 'Narzut', value: 'overhead', key: 'overhead' }, { label: 'Marżę', value: 'margin', key: 'margin' }]

    return (
      <>
        {isMobile
          ? (
            <Popup
              visible={this.state.descBoxVisible}
              onMaskClick={() => {
                this.setDescBoxVisible(false)
              }}
              bodyStyle={{ height: '50vh' }}
            >
              <div className='calculator-help-popup'>
                <Button type='text' className='calculator-help-popup-close' onClick={() => { this.setDescBoxVisible(false) }}><CloseOutlined /></Button>
                <h2>Jak wyceniać wypieki?</h2>

                {calcDesc}
              </div>
            </Popup>)
          : (
            <Drawer
              title='Jak wyceniać wypieki?'
              placement='right'
              width={450}
              onClose={() => {
                this.setDescBoxVisible(false)
              }}
              visible={this.state.descBoxVisible}
            >
              <div className='calculator-help-drawer'>
                {calcDesc}
              </div>
            </Drawer>
          )}
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ cakes_per_month: 0, h_price: 0, margin: 0, overhead: 0, calc_type: 'overhead' }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  <Form.List
                    name='purchased_ingredients'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj zakupione składniki'))
                          }

                          if (names) {
                            var valueArr = names.map(function (item) { return item?.name })
                            var isDuplicate = valueArr.some(function (item, idx) {
                              return valueArr.indexOf(item) !== idx
                            })

                            if (isDuplicate) {
                              return Promise.reject(new Error('Elementy o tej samej nazwie nie mogą się powtarzać na liście'))
                            }
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Card
                          title='Cena zakupu składników'
                          className={isMobile ? 'on-mobile' : ''}
                          extra={<HelpBox title='Cena zakupu składników' category='Pricing Calculator' content={purchasedIngredientsHelp} visible={this.state.visibleHelpPurchasedIngredients} setVisible={this.setVisibleHelpPurchasedIngredients} isMobile={isMobile} />}
                        >
                          {fields.map(({ key, name, ...restField }) => (
                            <React.Fragment key={key}>
                              <Form.Item
                                {...restField}
                                name={[name, 'id']}
                                hidden
                              >
                                <Input />
                              </Form.Item>

                              <Row gutter={[3, 0]}>
                                <Col xs={24} md={10} lg={10}>
                                  <FloatLabel label={this.state.purchasedIngredientsFocus === name && 'Nazwa składnika'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj nazwę produktu' }]}
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <Input
                                        placeholder={this.state.purchasedIngredientsFocus !== name ? 'Nazwa składnika' : ''}
                                        className={this.state.purchasedIngredientsFocus === name ? 'field-float-input' : ''}
                                        onBlur={() => { this.handleUpdatePurchasedIngredients(); this.handlePurchasedIngredientsFocus(null) }}
                                        onFocus={() => this.handlePurchasedIngredientsFocus(name)}
                                        autoComplete='off'
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={7} md={4} lg={4}>
                                  <FloatLabel label={this.state.purchasedIngredientsFocus === name && 'Jednostka'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'unit']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      style={{ minWidth: '70px' }}
                                      rules={[{ required: true, message: 'Wybierz jednostkę miary' }]}
                                    >
                                      <Select
                                        placeholder={this.state.purchasedIngredientsFocus !== name ? 'Jednostka' : ''}
                                        className={this.state.purchasedIngredientsFocus === name ? 'field-float-select' : ''}
                                        onBlur={() => { this.handleUpdatePurchasedIngredients(); this.handlePurchasedIngredientsFocus(null) }}
                                        onChange={() => this.handleUpdatePurchasedIngredientUnit(name)}
                                        onFocus={() => this.handlePurchasedIngredientsFocus(name)}
                                      >
                                        {
                                          ['kg', 'g', 'opak.', 'szt', 'l', 'ml'].map((ing, n) =>
                                            <Option key={n} value={ing}>{ing}</Option>
                                          )
                                        }
                                      </Select>
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>

                                <Col xs={7} md={4} lg={4}>
                                  <FloatLabel label={this.state.purchasedIngredientsFocus === name && (this.getIngUnit(name) === 'opak.' ? 'Ile szt w opak.' : 'Waga opak.')}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'quantity']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: (this.getIngUnit(name) === 'opak.' ? 'Podaj ilość sztuk w opakowaniu' : 'Podaj wagę opakowania') }]}
                                    >
                                      <InputNumber
                                        placeholder={this.state.purchasedIngredientsFocus !== name ? (this.getIngUnit(name) === 'opak.' ? 'Ilość szt w opak.' : 'Waga opak.') : ''}
                                        className={this.state.purchasedIngredientsFocus === name ? 'field-float-number' : ''}
                                        min={0}
                                        max={10000}
                                        style={{ width: '100%' }}
                                        onBlur={() => { this.handlePurchasedIngredientsFocus(null) }}
                                        onFocus={() => this.handlePurchasedIngredientsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>

                                <Col xs={7} md={4} lg={4}>
                                  <FloatLabel label={this.state.purchasedIngredientsFocus === name && 'Cena (zł brutto)'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'price']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj cenę zakupu' }]}
                                    >
                                      <InputNumber
                                        className={this.state.purchasedIngredientsFocus === name ? 'field-float-number' : ''}
                                        placeholder={this.state.purchasedIngredientsFocus !== name ? 'Cena (zł brutto)' : ''}
                                        min={0}
                                        max={10000}
                                        style={{ width: '100%' }}
                                        onBlur={() => { this.handlePurchasedIngredientsFocus(null) }}
                                        onFocus={() => this.handlePurchasedIngredientsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={3} md={2} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => this.removePurchasedIngredient(name, remove, key, { ...restField })}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                              {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                            </React.Fragment>
                            // : (
                            //  <div onClick={() => this.onBlurTest(name)}>
                            //  {this.formRef.current?.getFieldValue('purchased_ingredients')[name]?.name} ({this.getIngUnit(name)}) {this.formRef.current?.getFieldValue('purchased_ingredients')[name]?.price} zł
                            //  </div>)
                          ))}

                          <Form.ErrorList errors={errors} />

                          <Button
                            type='secondary'
                            onClick={() => this.addPurchasedIngredient(add, fields?.length)}
                            icon={<PlusOutlined />}
                          >
                            Dodaj kupiony składnik
                          </Button>
                        </Card>
                      </>
                    )}
                  </Form.List>

                  <Form.List
                    name='used_ingredients'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj użyte składniki'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Użyte składniki'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<HelpBox title='Użyte składniki' category='Pricing Calculator' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={10} lg={10}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Użyty składnik'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'ingId']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Wybierz z listy zakupionych składników' }]}
                                  >
                                    <Select
                                      className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Wybierz składnik' : ''}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      notFoundContent={<Empty description='Brak składników' />}
                                    >
                                      {
                                        this.state.purchasedIngredients.map((ing, n) =>
                                          ing.name && <Option key={n} value={ing.id}>{ing.name}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>

                              <Col xs={12} md={4} lg={4}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Jednostka'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unit']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    style={{ minWidth: '70px' }}
                                    rules={[{ required: true, message: 'Wybierz jednostkę miary' }]}
                                  >
                                    <Select
                                      className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Jednostka' : ''}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                    >
                                      {
                                        this.getIngUnits(name).map((ing, n) =>
                                          <Option key={n} value={ing}>{ing}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>

                              <Col xs={9} md={4} lg={4}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Ilość'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj ilość' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-number' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Ilość' : ''}
                                      min={0}
                                      max={10000}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                <Popconfirm
                                  title='Czy na pewno chcesz usunąć ten element?'
                                  onConfirm={() => remove(name)}
                                  {...popconfirmItemDeleteProps}
                                >
                                  <MinusCircleOutlined
                                    className='dynamic-delete-button'
                                    title='Usuń'
                                  />
                                </Popconfirm>
                              </Col>

                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                        <Button
                          type='secondary'
                          onClick={() => { add(); this.handleUsedIngredientsFocus(fields?.length) }}
                          icon={<PlusOutlined />}
                        >
                          Dodaj użyty składnik
                        </Button>
                      </Card>
                    )}
                  </Form.List>

                  <Form.List
                    name='captured_time'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj poświęcony czas'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Poświęcony czas'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<HelpBox title='Poświęcony czas' category='Pricing Calculator' content={capturedTimeHelp} visible={this.state.visibleHelpCapturedTime} setVisible={this.setVisibleHelpCapturedTime} isMobile={isMobile} />}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={10} lg={10}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Nazwa czynności'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj nazwę czynności' }]}
                                  >
                                    <Input
                                      className={this.state.capturedTimeFocus === name ? 'field-float-input' : ''}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Nazwa czynności' : ''}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={12} md={4} lg={4}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Jednostka'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unit']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    style={{ minWidth: '70px' }}
                                    rules={[{ required: true, message: 'Wybierz jednostkę czasu' }]}
                                  >
                                    <Select
                                      className={this.state.capturedTimeFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Jednostka' : ''}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                    >
                                      {
                                        ['godzina', 'minuta'].map((ing, n) =>
                                          <Option key={n} value={ing}>{ing}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={9} md={4} lg={4}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Ilość' + this.getCapturedTimeUnit(name)}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj poświęconą ilość czasu' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.capturedTimeFocus === name ? ' field-float-number' : '')}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Ilość' + this.getCapturedTimeUnit(name) : ''}
                                      min={0}
                                      max={10000}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                <Popconfirm
                                  title='Czy na pewno chcesz usunąć ten element?'
                                  onConfirm={() => remove(name)}
                                  {...popconfirmItemDeleteProps}
                                >
                                  <MinusCircleOutlined
                                    className='dynamic-delete-button'
                                    title='Usuń'
                                  />
                                </Popconfirm>
                              </Col>
                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                        <Button
                          type='secondary'
                          onClick={() => { add(); this.handleCapturedTimeFocus(fields?.length) }}
                          icon={<PlusOutlined />}
                        >
                          Dodaj czas
                        </Button>
                      </Card>
                    )}
                  </Form.List>

                  <Card
                    title='Parametry'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    extra={<HelpBox title='Parametry' category='Pricing Calculator' content={parametersHelp} visible={this.state.visibleHelpParameters} setVisible={this.setVisibleHelpParameters} isMobile={isMobile} />}
                  >
                    <Form.Item
                      name='cakes_per_month'
                      label={this.props.t('Ilość wypieków w miesiącu')}
                      {...formItemLayout}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='h_price'
                      label={this.props.t('Twoja stawka za godzinę pracy (zł brutto)')}
                      rules={[
                        { required: true, message: 'Podaj Twoją stawkę za godzinę' },
                        () => ({
                          validator (_, value) {
                            if (value === 0) {
                              return Promise.reject(new Error('Podaj Twoją stawkę za godzinę'))
                            }
                            return Promise.resolve()
                          }
                        })]}
                      {...formItemLayout}
                      // tooltip={{ title: 'treść', ...formHelpTooltipOptions }}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='calc_type'
                      label={this.props.t('Dolicz do ceny')}
                      rules={[{ required: true, message: 'Wybierz co doliczyć do ceny' }]}
                      {...formItemLayout}
                    >
                      {isMobile
                        ? (
                          <Selector
                            options={selectOptions}
                            onChange={this.handleCalcTypeSelector}
                          />
                        ) : (
                          <Segmented
                            onChange={this.handleCalcTypeSegmented}
                            options={selectOptions}
                          />)}
                    </Form.Item>
                    {this.state.calcType === 'margin' &&
                      <Form.Item
                        name='margin'
                        label={this.props.t('Marża (%)')}
                        {...formItemLayout}
                      >
                        <InputNumber min={0} max={99.9} className='input-100p' />
                      </Form.Item>}
                    {this.state.calcType === 'overhead' &&
                      <Form.Item
                        name='overhead'
                        label={this.props.t('Narzut (%)')}
                        {...formItemLayout}
                      >
                        <InputNumber min={0} max={10000} className='input-100p' />
                      </Form.Item>}
                  </Card>

                  <Form.List
                    name='fixed_costs'
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Card
                          title='Koszty stałe (w skali miesiąca)'
                          className={isMobile ? 'on-mobile' : ''}
                          extra={<HelpBox title='Koszty stałe (w skali miesiąca)' category='Pricing Calculator' content={fixedCostsHelp} visible={this.state.visibleHelpFixedCosts} setVisible={this.setVisibleHelpFixedCosts} isMobile={isMobile} />}
                        >
                          {fields.map(({ key, name, ...restField }) => (
                            <React.Fragment key={key}>
                              <Row gutter={[3, 0]}>
                                <Col xs={24} md={10} lg={10}>
                                  <FloatLabel label={this.state.fixedCostsFocus === name && 'Nazwa kosztu'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj nazwę kosztu' }]}
                                    >
                                      <Input
                                        className={this.state.fixedCostsFocus === name ? ' field-float-input' : ''}
                                        placeholder={this.state.fixedCostsFocus !== name ? 'Nazwa kosztu' : ''}
                                        onBlur={() => this.handleFixedCostsFocus(null)}
                                        onFocus={() => this.handleFixedCostsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={10} md={4} lg={4}>
                                  <FloatLabel label={this.state.fixedCostsFocus === name && 'Kwota (zł brutto)'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'value']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj kwotę (zł brutto)' }]}
                                    >
                                      <InputNumber
                                        className={'input-100p' + (this.state.fixedCostsFocus === name ? ' field-float-number' : '')}
                                        placeholder={this.state.fixedCostsFocus !== name ? 'Kwota (zł brutto)' : ''}
                                        min={0}
                                        max={10000}
                                        onBlur={() => this.handleFixedCostsFocus(null)}
                                        onFocus={() => this.handleFixedCostsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => remove(name)}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                              {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                            </React.Fragment>
                          ))}

                          <Form.ErrorList errors={errors} />

                          <Button
                            type='secondary'
                            onClick={() => { add(); this.handleFixedCostsFocus(fields?.length) }}
                            icon={<PlusOutlined />}
                          >
                            Dodaj koszt
                          </Button>
                        </Card>
                      </>
                    )}
                  </Form.List>
                </Space>
              </Col>

              {/* <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                </Space>
              </Col> */}
            </Row>

            {this.state.priceToClient > 0
              ? (
                <Result
                  status='success'
                  title={[
                    <Row key='price-to-client'>
                      <Col xs={24} sm={12} className='calculator-results-header-left'>
                        Cena dla klienta:
                      </Col>
                      <Col xs={24} sm={12} className='calculator-results-header-right'>
                        <b>{this.state.priceToClient}</b> zł brutto
                      </Col>
                    </Row>,
                    <Row key='earnigs'>
                      <Col xs={24} sm={12} className='calculator-results-header-left'>
                        Twój zysk:
                      </Col>
                      <Col xs={24} sm={12} className='calculator-results-header-right'>
                        <b>{this.state.earnings}</b> zł brutto
                      </Col>
                    </Row>
                  ]}
                  subTitle={
                    <Collapse className='calculator-results-collapse' onChange={this.handleCalcDetailsChange}>
                      <Panel header='Pokaż szczegóły obliczeń'>
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Koszt samych składników:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costOfIngredients}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Suma wynagrodzenia za czas Twojej pracy:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costOfTime}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Suma kosztów (koszt składników + koszty stałe):
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.sumOfCosts}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Koszty stałe w przeliczeniu na 1 wypiek:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costPerCake}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            {this.state.calcType === 'margin' ? 'Marża zawarta w cenie:' : 'Narzut dodany do ceny:'}
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.addedToPrice}</b> zł brutto
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  }
                />)
              : ''}
            <Button ref={this.resultRef} htmlType='submit' type='primary' size='large' disabled={this.state.loading_calculation}>
              {this.props.t('Oblicz')}{this.state.loading_calculation && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default withTranslation()(CalculatorBasicForm)
