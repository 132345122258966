import React from 'react'
import { PageHeader } from 'antd'
import QueueAnim from 'rc-queue-anim'
import StyledUserResetPasswordChange from '../../styledComponents/user/resetPasswordChangeForm'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

class ResetPasswordChange extends React.PureComponent {
  state = {
    makeRedirection: false,
    token: undefined
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    const { token } = this.props.match.params

    this.setState({ token })
  }

  render () {
    const { makeRedirection, token } = this.state
    const { authenticated, ...currentProps } = this.props

    return (
      makeRedirection || authenticated ? (
        <Redirect to='/login' />
      ) : (
        <div {...currentProps} className='banner1'>
          <PageHeader
            className='back-to-hompage'
            onBack={!window.matchMedia('(display-mode: standalone)').matches ? this.makeRedirection : null}
            title={this.props.t('Log in')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner1-text-wrapper'
          >
            <div key='content' className='banner1-content'>
              <StyledUserResetPasswordChange
                token={token}
                makeRedirection={this.makeRedirection}
              />
            </div>
          </QueueAnim>
        </div>)
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const ResetPasswordChangeContainer = connect(
  mapStateToProps
)(ResetPasswordChange)

export default withTranslation()(ResetPasswordChangeContainer)
