import React from 'react'
import StyledInspirationIndex from '../../styledComponents/inspiration'
import {
  getInspirations, generateInspiration, getInspirationImage,
  getInspirationUploadedFilePreview, useInspiration, getUserProfile,
  sendUpvote
} from '../../utils/actions'
import { connect } from 'react-redux'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import CtaModal from '../../styledComponents/common/ctaModal'
import { setCtaModalVisible } from '../../store/actions'
import { LikeOutlined } from '@ant-design/icons'
import ContentRight from '../common/contentRight'

class InspirationIndex extends React.Component {
  state = {
    makeRedirection: false,
    inspirations: [],
    generationType: 'text',
    objectType: 'cake',
    loading: false,
    preview: undefined,
    wasGenerated: false
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  setGenerateType = (generationType) => {
    this.setState({ generationType })
  }

  handleGenerationTypeSegmented = (generationType) => {
    this.setState({ generationType })
  }

  handleGenerationTypeSelector = (generationType) => {
    this.setState({ generationType: generationType[0] })
  }

  handleObjectTypeSegmented = (objectType) => {
    this.setState({ objectType })
  }

  handleObjectTypeSelector = (objectType) => {
    this.setState({ objectType: objectType[0] })
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getInspirations()
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  removeFile = () => {
    this.setState({ preview: undefined })
  }

  getInspirations = (wasGenerated) => {
    this.setState({ inspirations: []})

    getInspirations().then((json) => {
      if (this._isMounted) {
        this.setState({ inspirations: json.inspirations, loading: false, wasGenerated })
      }
    })
  }

  getUploadedFilePreview = (data) => {
    getInspirationUploadedFilePreview(data).then((preview) => {
      this.setState({ preview })
    }).catch(err => {
    })
  }

  generateInspiration = (data) => {
    this.setState({ loading: true, wasGenerated: false })

    generateInspiration(data).then((json) => {
      if (json?.limit_exceeded) {
        this.props.setCtaModalVisible(true)
        this.setState({ loading: false})
      } else {
        this.getInspirations(true)
      }
    }).catch(err => {
      this.setState({ loading: false})
    })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { inspirations } = this.state

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText
    let buttonTextSize
    let buttonType
    let buttonCallback

    text = <>Hej! Na ten moment wyczerpany został limit generowania inspiracji AI... Jeśli chcesz przyspieszyć prace nad generowaniem większej ilości inspiracji, kliknij kciuk w górę :D</>
    requiredSubs = ['sub1', 'sub2', 'sub3']
    buttonText = <LikeOutlined />
    buttonTextSize = 50
    buttonType = 'text'
    buttonCallback = () => { sendUpvote('inspirations_limit') }

    // if (authenticated && user?.sub_type === 'free' && !user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit generowania inspiracji. Możesz wygenerować więcej jeśli skorzystasz z któregoś ze Strefowych pakietów, wypróbuj konto testowe przez 7 dni bez opłat :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet testowy i generuj kolejne inspiracje'
    // } else if (authenticated && user?.sub_type === 'free' && user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit generowania inspiracji. Możesz wygenerować więcej jeśli skorzystasz z któregoś ze Strefowych pakietów :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i generuj kolejne inspiracje'
    // } else if (authenticated && user?.sub_type !== 'free' && user?.trial) {
    //   text = <>Ups! Wyczerpany został limit generowania inspiracji. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub wybrać wyższy pakiet gdzie jest ich więcej</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i generuj kolejne inspiracje'
    // } else if (authenticated && user?.sub_type !== 'free' && !user?.trial) {
    //   text = <>Ups! Wyczerpany został limit generowania inspiracji. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub zmienić pakiet na wyższy gdzie jest ich więcej</>
    //   toRoute = '/zmien-pakiet'
    //   redirect = [this.props.redirectRoute || '/inspiracje-ai']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Zmień pakiet i generuj kolejne inspiracje'
    // }

    return (
      authenticated ? <>
      <CtaModal
        text={text}
        toRoute={toRoute}
        redirect={redirect}
        requiredSubs={requiredSubs}
        buttonText={buttonText}
        buttonTextSize={buttonTextSize}
        buttonType={buttonType}
        buttonCallback={buttonCallback}
      />
      <StyledInspirationIndex
        authenticated={authenticated}
        redirect={this.redirect}
        inspirations={inspirations}
        loading={this.state.loading}
        generateInspiration={this.generateInspiration}
        handleGenerationTypeSegmented={this.handleGenerationTypeSegmented}
        handleGenerationTypeSelector={this.handleGenerationTypeSelector}
        handleObjectTypeSegmented={this.handleObjectTypeSegmented}
        handleObjectTypeSelector={this.handleObjectTypeSelector}
        generationType={this.state.generationType}
        objectType={this.state.objectType}
        getInspirationImage={getInspirationImage}
        getUploadedFilePreview={this.getUploadedFilePreview}
        preview={this.state.preview}
        useInspiration={useInspiration}
        setGenerateType={this.setGenerateType}
        wasGenerated={this.state.wasGenerated}
        removeFile={this.removeFile}
        isMobile={isMobile}
      /></> : <ModuleNotLoggedContent
        title='Generuj inspirację AI'
        contentTitle='Poszukujesz inspiracji na wypieki? Żaden problem!'
        showCtaBottom
        content={
          <ContentRight
            isMobile={isMobile}
            image='/images/inspirations.png'
            title='Wygeneruj inspirację na dowolny wypiek korzystając z mocy AI!'
            content='Brak pomysłów na tort? Babeczki? Jakie kolory? Jak udekorować? Napisz prośbę do naszej sztucznej inteligencji o wygenerowanie inspiracji na wypiek, której potrzebujesz :) :)'
          />
        }
        redirect={this.redirect}
        redirectRoute={this.props.location?.pathname}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  ctaModalVisible: state.appRedux.ctaModalVisible
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const InspirationIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InspirationIndex)

export default InspirationIndexContainer
