/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import ReactGA from 'react-ga4'
import StyledTopperShow from '../../styledComponents/topper/topperShow'
import { getTopper, downloadTopper } from '../../utils/actions'
import { slugify } from '../../utils/helpers'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

const FileDownload = require('js-file-download')

class TopperShow extends React.Component {
  state = {
    id: undefined,
    topper: undefined,
    downloading: false
  }

  getTopper = (id) => {
    getTopper(id).then((json) => {
      if (this._isMounted) {
        this.setState({ topper: json })
      }
    })
  }

  download = () => {
    this.setState({ downloading: true })
    downloadTopper(this.state.id).then((json) => {
      if (this._isMounted) {
        this.setState({ downloading: false })
        FileDownload(json.data, slugify(this.state.topper.name) + '-strefacukiernika.pl.zip')
      }
    })

    ReactGA.event({ category: 'Topper Projects', action: 'Downloaded Topper: ' + this.state.id })
  }

  componentDidMount () {
    const { id } = this.props.match.params
    this._isMounted = true

    this.setState({ id })
    this.getTopper(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, topper, downloading } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/topper' />
      ) : (
        <>
          <PageHeader
            title={topper ? topper?.name : 'Projekt toppera'}
            onBack={() => this.makeRedirection()}
          />
          <StyledTopperShow
            topper={topper}
            download={this.download}
            isMobile={isMobile}
            redirect={this.redirect}
            downloading={downloading}
            authenticated={this.props.authenticated}
          />
        </>
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const TopperShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopperShow)

export default TopperShowContainer
