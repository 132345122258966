import React from 'react'
import {
   PageHeader, Row, Col, Card, Skeleton
} from 'antd'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledPrintoutIndex extends React.Component {
  render () {
    const { printouts, makeRedirection, isMobile, loaded } = this.props

    return (
      <>
        <PageHeader
          title='Grafiki do wydruków'
          onBack={() => makeRedirection()}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ marginLeft: '20px', marginRight: '20px', display: loaded ? 'block' : 'none' }} className='list'>
          <Row gutter={[10, 10]}>
            {printouts?.map(item => {
              return <Col xs={24} md={12} lg={6} key={item.id}>
                <Card
                  hoverable
                  className='printout-item'
                  cover={<Link to={'/grafiki-do-wydrukow/' + item.id}><img width={196} src={'/images/printouts/' + item.id + '_min.png'} alt='printout' /></Link>}
                >
                  <Link to={'/grafiki-do-wydrukow/' + item.id}>{item.name}</Link>
                  <p>{item.tags.map(tag => <><Link to={'/grafiki-do-wydrukow/tag/' + tag}>#{tag}</Link>{' '}</>)}</p>
                </Card>
              </Col>
            })}
          </Row>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledPrintoutIndex))
