/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Button, Descriptions
} from 'antd'

class StyledTrainingAdminShow extends React.Component {
  state = {
  }

  render () {
    const { makeRedirection, singleData, isMobile, authenticated, user, liveStatus, justUploaded, isModerator, chatConnected } = this.props
    const { playClicked, view, capturing, processingImage, newMessages, liveQuality } = this.state

    return (
      <>
        <PageHeader
          title={singleData.title}
          className='training-title'
          onBack={() => this.props.redirect('/a/training')}
          extra={singleData.type === 'live' ? [<Button onClick={singleData.live_status === 'started' ? this.props.stopLive : this.props.startLive}>{singleData.live_status === 'started' ? 'Zatrzymaj transmisję' : 'Uruchom transmisję'}</Button>] : []}
        />

        <Descriptions title='Informacje o szkoleniu' bordered column={1} layout={isMobile ? 'vertical' : undefined} style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Descriptions.Item label='Typ'>{singleData.type}</Descriptions.Item>
          {singleData.type === 'live' && <Descriptions.Item label='Status live'>{singleData.live_status}</Descriptions.Item>}
        </Descriptions>
      </>
    )
  }
}

export default StyledTrainingAdminShow
