/* eslint no-unused-expressions: "off" */
import React from 'react'
import { PageHeader, Image, Row, Col, Typography, Divider } from 'antd'
import ReactGA from 'react-ga4'
import ReactMarkdown from 'react-markdown'
import { InstagramOutlined, FacebookOutlined, LinkOutlined } from '@ant-design/icons'

const { Paragraph } = Typography

class StyledTrainingAuthorShow extends React.Component {
  componentDidMount() {
    const { singleData } = this.props

    ReactGA.event({ category: 'Training', action: 'Training Author Viewed: ' + singleData.id })
  }

  render () {
    const { makeRedirection, singleData, isMobile } = this.props

    return (
      <>
        <PageHeader
          title='Trenerka Strefy Cukiernika'
          onBack={() => makeRedirection()}
        />
        <Row className='training-author'>
          <Col xs={24} md={{ span: 7, offset: 4 }} lg={{ span: 6, offset: 5 }}>
            <div>
              <Image
                alt={singleData.name + '-logo'}
                src={'/dimages/trainings/authors/' + singleData.id + '.png'}
                preview={false}
                onClick={() => isMobile ? this.setVisible(true, 0) : null}
                style={{ marginBottom: '20px', maxHeight: '300px' }}
              />
            </div>
          </Col>

          {isMobile ? <Divider /> : ''}

          <Col xs={24} md={10} lg={8}>
            <Typography style={{ padding: '0 20px 20px 20px' }}>
              <Paragraph style={{ textAlign: 'left' }}>
                <h3>{singleData.name}</h3>

                <div className='training-author-link' style={{ marginBottom: '40px' }}>
                  {singleData.link &&
                    <p><a
                      href={singleData.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <LinkOutlined /> {singleData.link_name}
                    </a></p>}
                  {singleData.fb &&
                    <p><a
                      href={singleData.fb}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FacebookOutlined /> {singleData.fb_name}
                    </a></p>}
                  {singleData.ig &&
                    <p><a
                      href={singleData.ig}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <InstagramOutlined /> {singleData.ig_name}
                    </a></p>}
                </div>

                <ReactMarkdown>
                  {singleData.description}
                </ReactMarkdown>
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    )
  }
}

export default StyledTrainingAuthorShow
