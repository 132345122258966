import React from 'react'
import {
  Badge, PageHeader, Row, Col, Card, Skeleton
} from 'antd'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledTopperIndex extends React.Component {
  render () {
    const { toppers, makeRedirection, isMobile, loaded } = this.props

    return (
      <>
        <PageHeader
          title='Projekty topperów'
          onBack={() => makeRedirection()}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ marginLeft: '20px', marginRight: '20px', display: loaded ? 'block' : 'none' }} className='list'>
          <Row gutter={[10, 10]}>
            {toppers?.map(item => {
              return <Col xs={24} md={12} lg={6} key={item.id}>
                <Card
                  hoverable
                  cover={<Badge.Ribbon text={item.category}><Link to={'/topper/' + item.id}><img width={196} src={'/images/toppers/' + item.id + '_min.png'} alt='topper preview' /></Link></Badge.Ribbon>}
                >
                  <Link to={'/topper/' + item.id}>{item.name}</Link>
                </Card>
              </Col>
            })}
          </Row>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledTopperIndex))
