import React from 'react'
import { PageHeader, Col, Row } from 'antd'
import TrainingAuthorItem from './item'

class StyledTrainingAuthorIndex extends React.Component {
  render () {
    const { makeRedirection, data, redirect } = this.props

    return (
      <>
        <PageHeader
          title='Trenerki Strefy Cukiernika'
          onBack={() => makeRedirection()}
        />
        <Row gutter={[16, 16]} className='training-list'>
          {data?.map(item =>
            <Col xs={24} md={12} lg={6}>
              <TrainingAuthorItem item={item} redirect={redirect} />
            </Col>
          )}
        </Row>
        {/*<List
          itemLayout='vertical'
          size='large'
          className='promo-list'
          dataSource={data}
          renderItem={item => (
            <List.Item
              key={item.title}
              extra={
                <Link to={'/training/' + item.id}>
                  <img
                    width={272}
                    alt={item.title + ' logo'}
                    src={'/images/trainings/' + item.id + '.png'}
                    style={{ borderRadius: '50%' }}
                  />
                </Link>
              }
            >
              <List.Item.Meta
                title={
                  <>
                    <Link to={'/training/' + item.id}>{item.title}</Link>
                    <Tag style={{ position: 'absolute', right: '0' }}>{item.durationNice}</Tag>
                  </>}
                description={<>Poziom: <img className='training-level' src={'/images/babeczka' + item.level + '.png'} alt={trainingLevel(item.level)} title={trainingLevel(item.level)} /></>}
              />
              <Paragraph>
                Autor:{' '}
                <Link to={'/training/author/' + item.author.id}>
                  <Avatar size="small" icon={<img alt={item.author.name + '-img'} src={'/images/trainings/authors/' + item.author.id + '_min.png'} />} />
                </Link>{' '}
                <Link to={'/training/author/' + item.author.id}>
                  {item.author.name}
                </Link>
              </Paragraph>
              <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ maxWidth: '500px', margin: '20px auto' }}>
                {item.short_description}
              </Paragraph>
              <Button><Link to={'/training/' + item.id}>Pokaż więcej</Link></Button>
            </List.Item>
          )}
        />*/}
      </>
    )
  }
}

export default StyledTrainingAuthorIndex
