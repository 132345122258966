import React from 'react'
import { PageHeader } from 'antd'
import QueueAnim from 'rc-queue-anim'
import StyledUserResetPassword from '../../styledComponents/user/resetPasswordForm'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

class ResetPassword extends React.PureComponent {
  state = {
    makeRedirection: false,
    requestSent: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  requestSent = () => {
    this.setState({ requestSent: true })
  }

  render () {
    const { makeRedirection, requestSent } = this.state
    const { authenticated, ...currentProps } = this.props

    return (
      makeRedirection || authenticated ? (
        <Redirect to='/login' />
      ) : (
        <div {...currentProps} className='banner1'>
          <PageHeader
            className='back-to-hompage'
            onBack={!window.matchMedia('(display-mode: standalone)').matches ? this.makeRedirection : null}
            title={this.props.t('Log in')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner1-text-wrapper'
          >
            <div key='content' className='banner1-content'>
            {requestSent ?
              <div className='login-form reset-password-form'>
                <div className='confirm-password-change'>
                  Wysłaliśmy na podany przez Ciebie email link do formularza resetowania hasła. Otwórz email i postępuj zgodnie z instrukcją w nim zawartą.
                </div>
              </div> :
              <StyledUserResetPassword
                requestSent={this.requestSent}
              />}
            </div>
          </QueueAnim>
        </div>)
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const ResetPasswordContainer = connect(
  mapStateToProps
)(ResetPassword)

export default withTranslation()(ResetPasswordContainer)
