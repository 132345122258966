/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Row, Col, Card, InputNumber,
  Popconfirm, Skeleton, Divider, Segmented
} from 'antd'
import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import HelpBox from '../common/helpBox'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import StyledOrderMobileMenu from './menuMobile'
import { Selector } from 'antd-mobile'

import FloatLabel from '../common/floatLabel'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 12,
    lg: 12
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

class OrderConfigForm extends React.Component {
  state = {
    loaded: false,
    visibleHelpParameters: false,
    visibleHelpFixedCosts: false,
    visibleHelpAddToPrice: false,
    loading: false,
    calcType: 'overhead',
    blockParamChange: false
  }

  formRef = React.createRef()
  resultRef = React.createRef()

  handleCalcTypeSegmented = (calcType) => {
    this.setState({ calcType })
  }

  handleCalcTypeSelector = (calcTypes) => {
    this.setState({ calcType: calcTypes[0] })
  }

  setVisibleHelpParameters = (flag) => {
    this.setState({ visibleHelpParameters: flag })

    flag && ReactGA.event({ category: 'Orders Config', action: 'VisibleHelpParameters Help Opened Mobile' })
  }

  setVisibleHelpAddToPrice = (flag) => {
    this.setState({ visibleHelpAddToPrice: flag })

    flag && ReactGA.event({ category: 'Orders Config', action: 'visibleHelpAddToPrice Help Opened Mobile' })
  }

  setVisibleHelpFixedCosts = (flag) => {
    this.setState({ visibleHelpFixedCosts: flag })

    flag && ReactGA.event({ category: 'Orders Config', action: 'VisibleHelpFixedCosts Help Opened Mobile' })
  }

  handleSubmit = (x) => {
    this.setState({ loading: true })

    this.props.formAction({ ...this.formRef.current?.getFieldsValue(), calc_type: this.state.calcType }).then((json) => {
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
    })

    ReactGA.event({ category: 'Orders Config', action: 'Config Saved' })
  }

  handleFixedCostsFocus = (name) => {
    this.setState({ fixedCostsFocus: name })
  }

  componentDidMount () {
    const { formData } = this.props

    if (formData) {
      if (Object.keys(formData).length > 1) {
        this.formRef.current.setFieldsValue(formData)
        this.setState({
          loaded: true,
          calcType: formData.calc_type !== undefined ? formData.calc_type : 'overhead',
          blockParamChange: formData.has_new_orders
        })
      } else {
        this.setState({
          loaded: true
        })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (formData && prevProps.formData !== formData) {
      if (Object.keys(formData).length > 1) {
        this.formRef.current.setFieldsValue(formData)
        this.setState({
          loaded: true,
          calcType: formData.calc_type !== undefined ? formData.calc_type : 'overhead',
          blockParamChange: formData.has_new_orders
        })
      } else {
        this.setState({
          loaded: true
        })
      }
    }
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    const parametersHelp = (
      <>
        W tej sekcji określasz parametry niezbędne do wyliczenia ceny końcowej zamówienia takie jak:<br />
        <ul className='calculator-help-list'>
          <li><b>ile wypieków wytwarzasz w ciągu miesiąca</b> - na tej podstawie obliczony zostanie średni koszt jaki trzeba doliczyć do każdego zamówienia.</li>
          <li><b>ile kosztuje godzina Twoje pracy</b> - na pewno nie mniej niż 19,70 zł brutto (13,91 zł netto) - tyle wynosi minimalne wynagrodzenie na godzinę w Polsce w 2022 roku</li>
          <li><b>dolicz do ceny narzut lub marżę</b> - określ jaki współczynnik chcesz doliczyć do ceny końcowej - jest to zysk dla Twojej pracowni z wytworzonego wypieku. Z zarobionych w ten sposób pieniędzy możesz finansować swój rozwój (i Twojego zespołu jeśli go posiadasz) jak np. szkolenia ale także rozwój Twojej pracowni o nowe sprzęty i akcesoria mogące przyspieszyć i podnieść jakość Twoje pracy.</li>
          <li><b>jaka jest wysokość Twojej marży</b> - tutaj w zasadzie dowolnie, może to być zarówno 10% - 30% i więcej (max 99.9%)</li>
          <li><b>jaka jest wysokość Twojego narzutu</b> - tutaj w zasadzie dowolnie, może to być zarówno 10% - 30% i więcej</li>
        </ul>
      </>
    )

    const addedToPriceHelp = (
      <>
        Nie możesz zmienić co ma zostać doliczone do ceny jeśli masz dodane nowe zamówienia. Oznacz je jako zaakceptowane lub je anuluj aby móc zmienić tę opcję.
      </>
    )

    const fixedCostsHelp = (
      <>
        Tutaj definiujesz miesięczne koszty funkcjonowania Twojej pracowni.<br /><br />
        Typowe pozycje, które należy uwzględnić na tej liście:<br />
        <ul className='calculator-help-list'>
          <li><b>wynajem lokalu</b></li>
          <li><b>pensja pracownika</b></li>
          <li><b>leasing/kredyt auta</b></li>
          <li><b>leasing/kredyt urządzeń</b></li>
          <li><b>media</b></li>
          <li><b>podatek od nieruchomości/lokalu (jeśli jest Twój)</b></li>
          <li><b>ubezpieczenie</b></li>
          <li><b>księgowość</b></li>
          <li><b>koszt strony internetowej/domeny/hostingu</b></li>
          <li><b>abonament na telefon (służbowy ;)</b></li>
        </ul>
      </>
    )

    const selectOptions = [{ label: 'Narzut', value: 'overhead', key: 'overhead' }, { label: 'Marżę', value: 'margin', key: 'margin' }]

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ cakes_per_month: 0, h_price: 0, margin: 0, overhead: 0, calc_type: 'overhead' }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  <Card
                    title='Parametry'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    extra={<HelpBox title='Parametry' category='Orders Config' content={parametersHelp} visible={this.state.visibleHelpParameters} setVisible={this.setVisibleHelpParameters} isMobile={isMobile} />}
                  >
                    <Form.Item
                      name='cakes_per_month'
                      label={this.props.t('Ilość wypieków w miesiącu')}
                      rules={[
                        { required: true, message: 'Podaj ilość wypieków w miesiącu' },
                        () => ({
                          validator (_, value) {
                            if (value === 0) {
                              return Promise.reject(new Error('Podaj ilość wypieków w miesiącu'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      {...formItemLayout}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='h_price'
                      label={this.props.t('Twoja stawka za godzinę pracy (zł brutto)')}
                      rules={[
                        { required: true, message: 'Podaj Twoją stawkę za godzinę' },
                        () => ({
                          validator (_, value) {
                            if (value === 0) {
                              return Promise.reject(new Error('Podaj Twoją stawkę za godzinę'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      {...formItemLayout}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='calc_type'
                      label={<>{this.props.t('Dolicz do ceny')} {this.state.blockParamChange ? <span style={{ marginLeft: '6px' }}><HelpBox title='Dolicz do ceny' category='Orders Config' content={addedToPriceHelp} visible={this.state.visibleHelpAddToPrice} setVisible={this.setVisibleHelpAddToPrice} isMobile={isMobile} /></span> : ''}</>}
                      rules={[{ required: true, message: 'Wybierz co doliczać do ceny' }]}
                      {...formItemLayout}
                    >
                      {isMobile
                        ? (
                          <Selector
                            options={selectOptions}
                            onChange={this.handleCalcTypeSelector}
                            disabled={this.state.blockParamChange}
                          />
                        ) : (
                          <Segmented
                            onChange={this.handleCalcTypeSegmented}
                            options={selectOptions}
                            disabled={this.state.blockParamChange}
                          />
                        )}
                    </Form.Item>
                    {this.state.calcType === 'margin' &&
                      <Form.Item
                        name='margin'
                        label={this.props.t('Marża (%)')}
                        rules={[
                          { required: true, message: 'Podaj marżę' }
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber min={0} max={99.9} className='input-100p' />
                      </Form.Item>}
                    {this.state.calcType === 'overhead' &&
                      <Form.Item
                        name='overhead'
                        label={this.props.t('Narzut (%)')}
                        rules={[
                          { required: true, message: 'Podaj narzut' }
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber min={0} max={10000} className='input-100p' />
                      </Form.Item>}
                  </Card>

                  <Form.List
                    name='fixed_costs'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj koszty stałe'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Card
                          title='Koszty stałe (w skali miesiąca)'
                          className={isMobile ? 'on-mobile' : ''}
                          extra={<HelpBox title='Koszty stałe (w skali miesiąca)' category='Orders Config' content={fixedCostsHelp} visible={this.state.visibleHelpFixedCosts} setVisible={this.setVisibleHelpFixedCosts} isMobile={isMobile} />}
                        >
                          {fields.map(({ key, name, ...restField }) => (
                            <React.Fragment key={key}>
                              <Row gutter={[3, 0]}>
                                <Col xs={24} md={10} lg={10}>
                                  <FloatLabel label={this.state.fixedCostsFocus === name && 'Nazwa kosztu'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj nazwę kosztu' }]}
                                    >
                                      <Input
                                        className={this.state.fixedCostsFocus === name ? ' field-float-input' : ''}
                                        placeholder={this.state.fixedCostsFocus !== name ? 'Nazwa kosztu' : ''}
                                        onBlur={() => this.handleFixedCostsFocus(null)}
                                        onFocus={() => this.handleFixedCostsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={10} md={4} lg={4}>
                                  <FloatLabel label={this.state.fixedCostsFocus === name && 'Kwota (zł brutto)'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'value']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Podaj kwotę (zł brutto)' }]}
                                    >
                                      <InputNumber
                                        className={'input-100p' + (this.state.fixedCostsFocus === name ? ' field-float-number' : '')}
                                        placeholder={this.state.fixedCostsFocus !== name ? 'Kwota (zł brutto)' : ''}
                                        min={0}
                                        max={10000}
                                        onBlur={() => this.handleFixedCostsFocus(null)}
                                        onFocus={() => this.handleFixedCostsFocus(name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>
                                <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => remove(name)}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                              {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                            </React.Fragment>
                          ))}

                          <Form.ErrorList errors={errors} />

                          <Button
                            type='secondary'
                            onClick={() => { add(); this.handleFixedCostsFocus(fields?.length) }}
                            icon={<PlusOutlined />}
                          >
                            Dodaj koszt
                          </Button>
                        </Card>
                      </>
                    )}
                  </Form.List>
                </Space>
              </Col>
            </Row>
          </Space>
          <Space>
            <Button ref={this.resultRef} htmlType='submit' type='primary' size='large' disabled={this.state.loading}>
              {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
        {this.props.screenSize === 'xs' && <StyledOrderMobileMenu />}
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(OrderConfigForm))
