import React from 'react'
import { Link } from 'react-router-dom'
import { Divider, PageHeader } from 'antd'

class InfoDataInfo extends React.Component {
  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <>
        <PageHeader
          onBack={() => this.redirect('/profile')}
          title='Informacje o danych'
        />
        <div className='static-content'>
          <h1 style={{ textAlign: 'center' }}>Informacje o danych</h1>

          <p>Podstawowe dane, które są gromadzone do Twojego konta to: adres email, województwo i miasto. Te dane są na ten moment obligatoryjne i bez nich aplikacja nie jest w stanie działać poprawnie, jeśli nie chcesz udostępniać tych danych, możesz <Link to='/delete-account'>usunąć konto</Link>.</p>
          <p>Gromadzimy także poniższe dane w celu polepszania jakości świadczonych usług oraz identyfikowania tych najbardziej interesujących:</p>

          <ul>
            <li>użycie kalkulatorów</li>
            <li>użycie kodów rabatowym partnerów</li>
            <li>pobranie projektów topperów</li>
            <li>pobranie plików dołączonych do szkoleń</li>
            <li>korzystanie ze szkoleń</li>
            <li>sposób korzystania z interfejsu</li>
          </ul>

          <p>Jeśli chcesz dowiedzieć się więcej o danych zebranych do Twojego konta albo usunąć wybrane lub wszystkie zebrane dane, napisz do nas wiadomość email: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>

          <Divider />

          <p>Strefa Cukiernika sp. z o.o.</p>
          <p>Siedziba: ul. Jarzębinowa 97/2, 05-825 Książenice</p>
          <p>Email: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>
          <p>Tel: +48 782 070 907</p>
        </div>
      </>
    )
  }
}

export default InfoDataInfo
