/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Button, Space, Row, Col, Card, InputNumber,
  Result, Skeleton, Popconfirm, Divider, Collapse,
  Segmented, Switch
} from 'antd'
import { Selector } from 'antd-mobile'
import { withTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { openNotification, niclyFormatPortions } from '../../utils/helpers'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import FloatLabel from '../common/floatLabel'
import { menuTypeHook } from '../../components/common/menuTypeHook'

const { Panel } = Collapse

const rimPortionSizeMap = {
  12: 6.5,
  13: 6,
  14: 5.5,
  15: 5,
  16: 5,
  17: 4,
  18: 4,
  19: 3.5,
  20: 3.5,
  21: 3.5,
  22: 3,
  23: 3,
  24: 3,
  25: 3,
  26: 3,
  27: 2.5,
  28: 2.5,
  29: 2.5,
  30: 2.5,
  31: 2.3,
  32: 2.3,
  33: 2.3,
  34: 2.2,
  35: 2.1,
  36: 2.1,
  37: 2,
  38: 2,
  39: 2,
  40: 2,
  41: 1.9,
  42: 1.9,
  43: 1.9,
  44: 1.8,
  45: 1.8,
  46: 1.7,
  47: 1.7,
  48: 1.7,
  49: 1.7,
  50: 1.6
}

const formItemLayout = {
  labelCol: {
    xs: 14,
    md: 12,
    lg: 12
  },
  wrapperCol: {
    xs: 6,
    md: 10,
    lg: 10
  }
}

const multiplyBy = 8
const multiplyByRect = 6

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

class CalculatorPortionsForm extends React.Component {
  state = {
    loading_calculation: false,
    calcType: 'circle',
    portionsCount: 0,
    showCalculation: false,
    loaded: true,
    tiers: [],
    multiTier: false,
    circlePortionSizeAutomatic: true,
    tier: {}
  }

  formRef = React.createRef()
  canvasRef = React.createRef()
  columnLeftRef = React.createRef()

  handleCalcTypeSegmented = (calcType) => {
    this.setState({ calcType }, () => {
      const fv = this.formRef.current.getFieldsValue()
      const tier = {
        circle_size: fv.circle_size,
        height: 16,
        rectangle_width_x: fv.rectangle_width_x,
        rectangle_width_y: fv.rectangle_width_y,
        square_size: fv.square_size,
        circle_portion_size: fv.circle_portion_size
      }

      this.setState({ tier })
    })

    this.formRef.current?.setFieldsValue({ tiers: this.state.tiers[calcType] })
  }

  handleCalcTypeSelector = (calcTypes) => {
      const calcType = calcTypes[0]

      this.setState({ calcType }, () => {
      const fv = this.formRef.current.getFieldsValue()

      const tier = {
        circle_size: fv.circle_size,
        height: 16,
        rectangle_width_x: fv.rectangle_width_x,
        rectangle_width_y: fv.rectangle_width_y,
        square_size: fv.square_size,
        circle_portion_size: fv.circle_portion_size
      }

      this.setState({ tier })
    })

    this.formRef.current?.setFieldsValue({ tiers: this.state.tiers[calcType] })
  }

  handleSubmit = (x) => {
    if (this.state.multiTier/* && this.props.user?.sub_type === 'free'*/) {
      this.props.setCtaModalVisible(true)
      return
    }

    this.setState({ loading_calculation: true })

    this.props.calculateAction({ ...this.formRef.current?.getFieldsValue(), calc_type: this.state.calcType }).then((json) => {
      this.setState({
        portionsCount: json.portions_count,
        calcResults: json,
        showCalculation: true
      })

      ReactGA.event({ category: 'Portions Calculator', action: 'Portions Calculated' })

      this.setState({ loading_calculation: false })
    }).catch(error => {
      this.setState({ loading_calculation: false })
    })
  }

  drawRect = () => {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    let widthX
    let widthY

    if(!this.state.multiTier) {
      if (this.state.calcType === 'square') {
        widthX = this.formRef.current?.getFieldValue('square_size')
        widthY = this.formRef.current?.getFieldValue('square_size')
      } else {
        widthX = this.formRef.current?.getFieldValue('rectangle_width_x')
        widthY = this.formRef.current?.getFieldValue('rectangle_width_y')
      }
    } else {
      const tier = this.state.tiers[this.state.calcType][this.state.usedIngredientsFocus]
      if (this.state.calcType === 'square') {
        widthX = tier?.square_size
        widthY = tier?.square_size
      } else {
        widthX = tier?.rectangle_width_x
        widthY = tier?.rectangle_width_y
      }
    }

    const portion_size_proportion_x = this.formRef.current?.getFieldValue('square_portion_size_x') / widthX * (widthX * 6)
    const portion_size_proportion_y = this.formRef.current?.getFieldValue('square_portion_size_y') / widthY * (widthY * 6)

    // draw square
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.strokeStyle = '#000000';

    widthX = widthX * 6
    widthY = widthY * 6

    const moveX = (canvas.width - widthY)/2
    const moveY = (canvas.height - widthX)/2

    ctx.rect(moveX, moveY, widthY, widthX);
    ctx.stroke();

    // draw portion
    ctx.strokeStyle = 'red';
    ctx.beginPath();
    ctx.rect(moveX, moveY, portion_size_proportion_x, portion_size_proportion_y);
    ctx.stroke();
  }

  drawCircle = (tier) => {
    const canvas = this.canvasRef.current;
    const centerX = Math.floor(canvas.width / 2)
    const centerY = Math.floor(canvas.height / 2)
    const circleSize = !this.state.multiTier ? this.formRef.current?.getFieldValue('circle_size') : tier?.circle_size
    const circleDrawSize = circleSize * 3

    const circuit = circleSize * Math.PI
    const portion_size_proportion = (!this.state.multiTier ? this.formRef.current?.getFieldValue('circle_portion_size') : tier?.circle_portion_size) / circuit

    const angle = 360 - 360 * portion_size_proportion
    const ctx = canvas.getContext('2d');

    // clear drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw open ended circle
    ctx.strokeStyle = '#000000';
    ctx.beginPath();
    ctx.arc(centerX, centerY, circleDrawSize, this.degreesToRadians(360-angle), 2*Math.PI, false);
    ctx.stroke();

    // draw portion
    ctx.strokeStyle = 'red';
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, circleDrawSize, this.degreesToRadians(0), this.degreesToRadians((-1) * angle), false);
    ctx.closePath();
    ctx.stroke();
  }

  drawCake = (calcType, tier) => {
    if (calcType === 'circle') {
      this.drawCircle(tier)
    } else {
      this.drawRect()
    }
  }

  degreesToRadians(degrees) {
    return (degrees * Math.PI)/180;
  }

  handleFormChange = () => {
    if (this.state.portionsCount > 0) {
      this.setState({ showCalculation: false })
    }

    const tiersRender = !this.state.multiTier && this.formRef?.current?.getFieldsValue() ? [{...this.formRef?.current?.getFieldsValue(), height: 16 }] : this.state.tiers[this.state.calcType][this.state.usedIngredientsFocus]
    const tier = {...this.formRef?.current?.getFieldsValue(), height: 16 }

    if (this.state.circlePortionSizeAutomatic && !this.state.multi_tier) {
      tier.circle_portion_size = rimPortionSizeMap[parseInt(tier.circle_size)] || 3
      this.formRef?.current?.setFieldsValue({ circle_portion_size: tier.circle_portion_size })
    }

    this.setState({ tier })
    this.drawCake(this.formRef.current?.getFieldValue('calc_type'), tiersRender)
  }

  addTier = (add) => {
    const { tiers, calcType } = this.state

    if (tiers[calcType]?.length >= 6) {
      openNotification('Ups!', 'Tort może mieć maksymalnie 6 pięter')
      return
    }

    const firstTier = tiers[calcType]?.length > 0 ? tiers[calcType][0] : {}

    let newTierCircleSize
    let newTierHeight
    let newTierSquareSize
    let newTierRectangleWidthX
    let newTierRectangleWidthY

    if (calcType === 'circle') {
      newTierCircleSize = firstTier && firstTier.circle_size - 4
      newTierHeight = 18 - (tiers[calcType]?.length * 2)

      if (newTierCircleSize < 12) {
        openNotification('Ups!', 'Kolejne piętro byłoby zbyt małe, powiększ ostatnie piętro aby dodać kolejne')

        return
      }
    } else if (calcType === 'square') {
      newTierSquareSize = firstTier && firstTier.square_size - 4

      if (newTierSquareSize < 12) {
        openNotification('Ups!', 'Kolejne piętro byłoby zbyt małe, powiększ ostatnie piętro aby dodać kolejne')

        return
      }
    } else if (calcType === 'rectangle') {
      newTierRectangleWidthX = firstTier && firstTier.rectangle_width_x - 4
      newTierRectangleWidthY = firstTier && firstTier.rectangle_width_y - 4

      if (newTierRectangleWidthX < 12 || newTierRectangleWidthY < 12) {
        openNotification('Ups!', 'Kolejne piętro byłoby zbyt małe, powiększ ostatnie piętro aby dodać kolejne')

        return
      }
    }

    const newTier = {
      circle_size: newTierCircleSize,
      height: newTierHeight,
      square_size: newTierSquareSize,
      rectangle_width_x: newTierRectangleWidthX,
      rectangle_width_y: newTierRectangleWidthY,
      circle_portion_size: rimPortionSizeMap[newTierCircleSize] || 3
    }

    tiers[calcType].unshift(newTier);

    this.setState({ tiers })

    this.formRef.current?.setFieldsValue({ tiers: tiers[calcType] })
  }

  componentDidMount () {
    const tiers = {}
    tiers['circle'] = [
      {
        circle_size: 16,
        height: 12,
        circle_portion_size: rimPortionSizeMap[16]
      },
      {
        circle_size: 24,
        height: 14,
        circle_portion_size: rimPortionSizeMap[24]
      },
      {
        circle_size: 30,
        height: 16,
        circle_portion_size: rimPortionSizeMap[30]
      },
      {
        circle_size: 35,
        height: 18,
        circle_portion_size: rimPortionSizeMap[35]
      }
    ]

    tiers['square'] = [
      {
        square_size: 16,
      },
      {
        square_size: 24,
      },
      {
        square_size: 30,
      },
      {
        square_size: 36,
      }
    ]

    tiers['rectangle'] = [
      {
        rectangle_width_x: 14,
        rectangle_width_y: 18,
      },
      {
        rectangle_width_x: 22,
        rectangle_width_y: 26,
      },
      {
        rectangle_width_x: 28,
        rectangle_width_y: 32,
      },
      {
        rectangle_width_x: 33,
        rectangle_width_y: 37,
      }
    ]

    const tier = {
      circle_size: 25,
      height: 12,
      rectangle_width_x: 28,
      rectangle_width_y: 32,
      square_size: 30,
      circle_portion_size: rimPortionSizeMap[25]
    }

    this.setState({ tiers, tier })
    this.formRef.current?.setFieldsValue({ tiers: tiers })
    this.handleUsedIngredientsFocus(0)

    this.drawCake(this.state.calcType, tiers[this.state.calcType][tiers.length - 1])
  }

  componentDidUpdate (prevProps, prevState) {
    if(prevState.calcType !== this.state.calcType) {
      this.drawCake(this.state.calcType, this.state.tiers[this.state.calcType][this.state.usedIngredientsFocus])
    }
    if(prevState.multiTier !== this.state.multiTier) {
      if (!this.state.multiTier) {
        this.handleUsedIngredientsFocus(0)
        this.drawCake(this.state.calcType, this.state.tiers[this.state.calcType][this.state.tiers[this.state.calcType]?.length - 1])
      } else {
        this.handleUsedIngredientsFocus(this.state.tiers[this.state.calcType]?.length - 1)
        this.drawCake(this.state.calcType, this.state.tiers[this.state.calcType][this.state.tiers[this.state.calcType]?.length - 1])
      }
    }
  }

  tier = (tier, i) => {
    this.handleUsedIngredientsFocus(i)
    this.drawCake(this.formRef.current?.getFieldValue('calc_type'), tier)
  }

  tierUpdated = () => {
    const tiers = this.state.tiers

    tiers[this.state.calcType] = this.formRef.current?.getFieldValue('tiers')

    if (this.state.circlePortionSizeAutomatic && this.state.calcType === 'circle') {
      for (const item of tiers[this.state.calcType]) {
        item.circle_portion_size = rimPortionSizeMap[parseInt(item.circle_size)] || 3
      }
    }

    this.setState({ tiers })
  }

  handleUsedIngredientsFocus = (name) => {
    this.setState({ usedIngredientsFocus: name })
  }

  circleCalcTop = (tiers, x) => {
    let sum = 0
    for(let i = 0; i < x; i++) {
      sum += tiers[i].height*multiplyBy
    }

    return sum
  }

  circleCalcTop2 = (x) => {
    let sum = 0
    for(let i = 0; i <= x; i++) {
      sum += (10+(i)*multiplyBy)*2
    }

    return sum
  }

  render () {
    const { isMobile } = this.props
    const { loaded, /*tiers,*/ calcType, multiTier, tier, circlePortionSizeAutomatic } = this.state
    const selectOptions = [{ label: 'Koło', value: 'circle', key: 'circle' }, { label: 'Kwadrat', value: 'square', key: 'square' }, { label: 'Prostokąt', value: 'rectangle', key: 'rectangle' }]

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }
    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    const tiers = this.state.tiers[calcType] || []
    const tiersRender = !multiTier && tier ? [tier] : tiers
    const mv = this.columnLeftRef?.current?.offsetWidth/2 - (tiersRender[tiersRender.length - 1]?.circle_size*multiplyBy/2)

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : 0, marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 10, offset: 2 }}
          >
            <div style={{
              position: 'relative',
              marginTop: '100px',
              height: (calcType === 'rectangle' && tiersRender?.length === 1 ? 100 : (calcType === 'circle' ? 110 : 80) * tiersRender?.length) + 'px' }}>
              {(calcType === 'rectangle' || calcType === 'square') && <div className='rectangle'>
                {tiersRender.map((tier, i) =>
                {
                  //               /
                  //              / X
                  // -----Y------/

                  let widthX
                  let widthY
                  let leftField

                  if (calcType === 'rectangle') {
                    widthX = tier.rectangle_width_x*multiplyByRect
                    widthY = tier.rectangle_width_y*multiplyByRect
                    leftField = 'rectangle_width_y'
                  } else {
                    widthX = tier.square_size * multiplyByRect
                    widthY = widthX
                    leftField = 'square_size'
                  }
                  return (<div className={"rect-cake-wrapper" + (this.state.usedIngredientsFocus === i ? ' rect-cake-selected' : '')} style={{
                    // width: size + 'px',
                    // height: size + 'px',
                    // zIndex: String(9999-i),
                    // left: 0,
                    // left: (i === tiers.length ? 0 : ((tiers[tiers.length - 1]?.circle_size * 10) - (tier.circle_size * 10))/2) + 'px'
                  }}>
                    <div className="rect-cake" style={{
                      zIndex: String(10-i),
                      // top: i !== tiers.length - 1 ? (i * -5) + 'px' : 0,
                      top: (i * 40) + 'px',
                      left: ((i === tiers.length ? 0 : ((tiers[tiers.length - 1][leftField] * multiplyByRect) - (tier[leftField] * multiplyByRect))/2) + 140) + 'px'
                      // left: ((((tiers[tiers.length - 1][leftField] * multiplyBy) - (tier[leftField] * multiplyBy))/2) + 20) + 'px'
                    }}>
                      <div className="side1" onClick={() => this.tier(tier, i)} style={{ width: widthY + 'px', height: widthX + 'px', transform: 'rotatex(90deg) translateX(0px) translateY(0px) translateZ(' + (widthX / 2) + 'px)' }}></div>
                      <div className="side2" style={{ width: widthX + 'px', transform: 'rotateY(-90deg) translateX(0px) translateY(0px) translateZ(' + (widthX / 2) + 'px)' }}></div>
                      <div className="side3" onClick={() => this.tier(tier, i)} style={{ width: widthY + 'px', transform: 'translateX(0px) translateY(0px) translateZ(' + (widthX / 2) + 'px)' }}></div>
                      <div className="side4" onClick={() => this.tier(tier, i)} style={{ width: widthX + 'px', transform: 'rotateY(90deg) translateX(0px) translateY(0px) translateZ(' + (widthY/2+((widthY-widthX)/2)) + 'px)' }}></div>
                      <div className="side5" style={{ width: widthY + 'px', transform: 'rotateY(180deg) translateX(0px) translateY(0px) translateZ(' + (widthX / 2) + 'px)' }}></div>
                      {/*<div className="side6" style={{ width: widthY + 'px', height: widthX + 'px', transform: 'rotateX(-90deg) translateX(0px) translateY(0px) translateZ(' + ((widthY/2+((widthY-widthX)/2))-319) + 'px)' }}></div>*/}
                      {/*<div className="side6" style={{ width: widthY + 'px', height: widthX + 'px', transform: 'rotateX(-90deg) translateX(0px) translateY(0px) translateZ(-' + (33) + 'px)' }}></div>*/}
                    </div>
                </div>)
              })}
            </div>}

            <div style={{
              // marginLeft: '50%'
              // position: 'relative',
              // left: '0',
              // right: '0',
              // marginLeft: (tiersRender[tiersRender.length - 1]?.circle_size*multiplyBy + 200) + 'px'
            }}>
              {calcType === 'circle' && tiersRender.map((tier, i) =>
                <div
                  className={"cylinder" + (this.state.usedIngredientsFocus === i ? ' cylinder-selected' : '')}
                  onClick={() => this.tier(tier, i)}
                  style={{ '--r': (10+(i+1)*multiplyBy) + 'px', width: (tier.circle_size * multiplyBy) + 'px', height: (tier.height * multiplyBy) + 'px', top: this.circleCalcTop(tiers, i) - this.circleCalcTop2(i) + 'px', zIndex: 10-i, left: (i === tiers.length ? 0 : (((tiers[tiers.length - 1]?.circle_size) - (tier.circle_size * multiplyBy))/2) + (tiersRender[tiersRender.length - 1]?.circle_size*multiplyBy/2)) + mv + 'px' }}
                />
              )}
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 10 }}
          ref={this.columnLeftRef}
        >
          {multiTier && <h4>Podgląd piętra #{tiersRender?.length - this.state.usedIngredientsFocus}</h4>}
          <div>
            <canvas height='300' ref={this.canvasRef}></canvas>
          </div>

          <Form
            onFinish={this.handleSubmit}
            initialValues={{ calc_type: 'circle', circle_portion_size: 3, square_portion_size_x: 3, square_portion_size_y: 5, circle_size: 25, square_size: 25, rectangle_width_x: 30, rectangle_width_y: 24, circle_portion_size_automatic: true }}
            layout='horizontal'
            className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
            ref={this.formRef}
            style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
            onValuesChange={this.handleFormChange}
            scrollToFirstError={{ behavior: 'smooth' }}
            labelWrap
          >
            <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
              <Row gutter={[isMobile ? 0 : 20, 20]}>
                <Col xs={24} lg={24}>
                  <Space direction='vertical' size='middle' style={{ display: 'flex' }}>

                  {multiTier && <Form.List
                    name='tiers'
                  >
                    {(fields, { add, remove, move }, { errors }) => (
                      <Card
                        title='Piętra'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<Button
                          type='secondary'
                          onClick={() => { this.addTier(add); this.handleUsedIngredientsFocus(0); }}
                          icon={<PlusOutlined />}
                        >
                          Dodaj piętro
                        </Button>}
                      >
                        {fields.map(({ key, name, ...restField }, index) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={24} lg={2}>
                                <p>#{fields.length - name}</p>
                              </Col>
                              {calcType === 'circle' && <Col xs={11} md={19} lg={10}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Średnica'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'circle_size']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Średnica' : ''}
                                      min={(tiers.length > 1 && name !== 0 && tiers[name-1].circle_size + 4) || 12}
                                      max={(tiers.length > 1 && name !== tiers.length - 1 && tiers[name+1].circle_size - 4) || 50}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      onChange={this.tierUpdated}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>}

                              {calcType === 'circle' && <Col xs={10} md={19} lg={10}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Rozmiar porcji w cm (wycinek obwodu tortu)'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'circle_portion_size']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Rozmiar porcji w cm (wycinek obwodu tortu)' : ''}
                                      min={1}
                                      max={20}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      onChange={this.tierUpdated}
                                      disabled={circlePortionSizeAutomatic}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>}

                              {calcType === 'square' && <Col xs={9} md={19} lg={9}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Długość boku'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'square_size']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Długość boku' : ''}
                                      min={(tiers.length > 1 && name !== 0 && tiers[name-1].rectangle_width_y + 4) || 12}
                                      max={(tiers.length > 1 && name !== tiers.length - 1 && tiers[name+1].rectangle_width_y - 4) || 50}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      onChange={this.tierUpdated}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>}

                              {calcType === 'rectangle' && <Col xs={10} md={12} lg={6}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Długość'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'rectangle_width_y']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Długość' : ''}
                                      min={(tiers.length > 1 && name !== 0 && tiers[name-1].rectangle_width_y + 4) || 12}
                                      max={(tiers.length > 1 && name !== tiers.length - 1 && tiers[name+1].rectangle_width_y - 4) || 50}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      onChange={this.tierUpdated}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>}

                              {calcType === 'rectangle' && <Col xs={10} md={12} lg={6}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Szerokość'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'rectangle_width_x']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Szerokość' : ''}
                                      min={(tiers.length > 1 && name !== 0 && tiers[name-1].rectangle_width_x + 4) || 12}
                                      max={(tiers.length > 1 && name !== tiers.length - 1 && tiers[name+1].rectangle_width_x - 4) || 50}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                      onChange={this.tierUpdated}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>}

                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                              {name === 0 && tiers?.length > 1 &&
                                <Popconfirm
                                  title='Czy na pewno chcesz usunąć ten element?'
                                  onConfirm={() => {
                                    remove(name)
                                    this.tierUpdated()
                                    this.handleUsedIngredientsFocus(name)
                                  }}
                                  {...popconfirmItemDeleteProps}
                                >
                                  <MinusCircleOutlined
                                    className='dynamic-delete-button'
                                    title='Usuń'
                                  />
                                </Popconfirm>}
                              </Col>

                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                      </Card>
                    )}
                  </Form.List>}

                  <Card
                    title='Parametry'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                  >
                    <Form.Item
                      name='multi_tier'
                      label='Licz tort piętrowy'
                      {...formItemLayout}
                      valuePropName='checked'
                      help={multiTier && this.props.user?.sub_type === 'free' && 'Liczenie porcji tortu piętrowego jeszcze nie jest dostępne'}
                      // help={multiTier && this.props.user?.sub_type === 'free' && 'Liczenie porcji tortu piętrowego wymaga posiadania jednego z pakietów płatnych Strefy Cukiernika'}
                    >
                      <Switch
                        checked={this.state.multiTier}
                        onClick={(enabled) => {
                          if (enabled) {
                            this.props.setCtaModalVisible(true)
                          }
                        }}
                        onChange={(x) => {
                          this.setState({ multiTier: x })
                          this.formRef.current?.setFieldsValue({ tiers: this.state.tiers[calcType] })
                          this.handleUsedIngredientsFocus(tiers.length - 1)
                        }}
                      />
                    </Form.Item>
                    {calcType === 'circle' && <Form.Item
                      name='circle_portion_size_automatic'
                      label='Automatycznie licz rozmiar porcji'
                      {...formItemLayout}
                      valuePropName='checked'
                    >
                      <Switch checked={this.state.circlePortionSizeAutomatic} onChange={(x) => {
                        if (this.state.multiTier) {
                          const tiers = this.state.tiers

                          if (x) {
                            for (const item of tiers.circle) {
                              item.circle_portion_size = rimPortionSizeMap[parseInt(item.circle_size)] || 3
                            }
                          }
                          this.setState({ circlePortionSizeAutomatic: x, tiers })
                        } else {
                          const tier = this.state.tier

                          tier.circle_portion_size = rimPortionSizeMap[parseInt(tier.circle_size)] || 3
                          this.setState({ circlePortionSizeAutomatic: x, tier })
                          this.formRef?.current.setFieldsValue({ circle_portion_size: tier.circle_portion_size })
                        }
                      }} />
                    </Form.Item>}
                  <Form.Item
                    name='calc_type'
                    label={this.props.t('Kształt tortu')}
                    rules={[{ required: true, message: 'Wybierz kształt tortu' }]}
                    {...formItemLayout}
                  >
                    {isMobile
                      ? (
                        <Selector
                          options={selectOptions}
                          onChange={this.handleCalcTypeSelector}
                        />
                      ) : (
                        <Segmented
                          onChange={this.handleCalcTypeSegmented}
                          options={selectOptions}
                        />)}
                  </Form.Item>
                  {!multiTier && calcType === 'circle' &&
                    <Form.Item
                      name='circle_size'
                      label={this.props.t('Średnica tortu')}
                      rules={[{ required: true, message: 'Podaj średnicę tortu' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={12} max={50} className='input-100p' />
                    </Form.Item>}
                  {!multiTier && calcType === 'square' &&
                    <Form.Item
                      name='square_size'
                      label={this.props.t('Wymiar boku tortu w cm')}
                      rules={[{ required: true, message: 'Podaj wymiar boku tortu' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={10} max={50} className='input-100p' />
                    </Form.Item>}
                  {!multiTier && calcType === 'circle' &&
                    <Form.Item
                      name='circle_portion_size'
                      label={this.props.t('Rozmiar porcji w cm (wycinek obwodu tortu)')}
                      rules={[{ required: true, message: 'Podaj rozmiar porcji' }]}
                      {...formItemLayout}
                    >
                      <InputNumber
                        min={1}
                        max={20}
                        className='input-100p'
                        disabled={circlePortionSizeAutomatic}
                      />
                    </Form.Item>}
                  {!multiTier && calcType === 'rectangle' &&
                    <Form.Item
                      name='rectangle_width_x'
                      label={this.props.t('Długość boku tortu w cm')}
                      rules={[{ required: true, message: 'Podaj szerokość tortu' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={10} max={50} className='input-100p' />
                    </Form.Item>}
                  {!multiTier && calcType === 'rectangle' &&
                    <Form.Item
                      name='rectangle_width_y'
                      label={this.props.t('Szerokość boku tortu w cm')}
                      rules={[{ required: true, message: 'Podaj szerokość tortu' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={10} max={50} className='input-100p' />
                    </Form.Item>}
                  {(calcType === 'square' || calcType === 'rectangle') &&
                    <Form.Item
                      name='square_portion_size_x'
                      label={this.props.t('Rozmiar porcji w cm (szerokość)')}
                      rules={[{ required: true, message: 'Podaj rozmiar porcji' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={1} max={10} className='input-100p' />
                    </Form.Item>}
                  {(calcType === 'square' || calcType === 'rectangle') &&
                    <Form.Item
                      name='square_portion_size_y'
                      label={this.props.t('Rozmiar porcji w cm (długość)')}
                      rules={[{ required: true, message: 'Podaj rozmiar porcji' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={1} max={10} className='input-100p' />
                    </Form.Item>}
                </Card>
              </Space>
            </Col>
          </Row>

                {this.state.showCalculation
                  ? (
                    <Result
                      status='success'
                      title={[
                        <Row key='price-to-client'>
                          <Col xs={24} sm={12} className='calculator-results-header-left'>
                          </Col>
                          <Col xs={24} sm={12} className='calculator-results-header-right'>
                            <b>~{niclyFormatPortions(this.state.portionsCount)}</b>
                          </Col>
                        </Row>
                      ]}
                      subTitle={this.state.calcResults?.tiers?.length > 0 &&
                        <Collapse className='calculator-results-collapse' onChange={this.handleCalcDetailsChange}>
                          <Panel header='Pokaż szczegóły obliczeń'>
                            {this.state.calcResults?.tiers?.map(tier => (
                              <Row gutter={6}>

                              <Col span={13} className='calculator-results-left'>
                                Piętro #{tier.tier_nr}
                              </Col>
                              <Col span={11} className='calculator-results-right'>
                                ~{niclyFormatPortions(tier.portions_count)}
                              </Col>
                              </Row>
                            ))}
                          </Panel>
                        </Collapse>}
                    />)
                  : ''}
                <Button htmlType='submit' type='primary' size='large' disabled={this.state.loading_calculation}>
                  {this.props.t('Oblicz')}{this.state.loading_calculation && <LoadingOutlined style={{ fontSize: 14 }} spin />}
                </Button>
              </Space>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(CalculatorPortionsForm))
