import React from 'react'
import {
  Table, List, Tag, Segmented, Dropdown, Menu, Empty, Skeleton
} from 'antd'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import { CalendarOutlined, DollarCircleOutlined, UserOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { saveDeviceData, getDeviceData, getOrderStatusColor } from '../../utils/helpers'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledCalendarShow extends React.Component {
  state = {
    filteredOrders: [],
    status: 'new,accepted',
    loaded: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  handleStatus = (status) => {
    const statusSplited = status.split(',')

    this.setState({
      status,
      filteredOrders: status === 'all' ? this.props.orders : this.props.orders.filter(x => statusSplited.includes(x.status))
    })

    saveDeviceData('orders_status', status)

    ReactGA.event({ category: 'Orders', action: 'Status Filter Changed In Calendar Show: ' + status })
  }

  componentDidMount () {
    const status = getDeviceData('orders_status')

    if (status) {
      this.setState({ status })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.orders !== this.props.orders) {
      const statusSplited = this.state.status.split(',')

      this.setState({
        filteredOrders: this.state.status === 'all' ? this.props.orders : this.props.orders.filter(x => statusSplited.includes(x.status)),
        loaded: true
      })
    }
  }

  render () {
    const { orders, isMobile } = this.props
    const { filteredOrders, loaded } = this.state

    const selectStatusOptions = [{ label: 'Aktywne', value: 'new,accepted' }, { label: 'Zakończone', value: 'finished' }, { label: 'Anulowane', value: 'canceled' }, { label: 'Wszystkie', value: 'all' }]

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (x) => (
          <Tag color={x === 'finished' ? 'orange' : (x === 'canceled' ? 'gray' : 'green')}>{this.props.t(x)}</Tag>
        )
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Nazwa zamówienia',
        dataIndex: 'name',
        key: 'name'
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Klient',
        dataIndex: 'client_name',
        key: 'client_name'
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Wartość zamówienia',
        dataIndex: 'price_to_client',
        key: 'price_to_client',
        render: (x) => (
          <span>{x && x + ' zł'}</span>
        )
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Twój zysk',
        dataIndex: 'earnigs',
        key: 'earnigs',
        render: (x) => (
          <span>{x && x + ' zł'}</span>
        )
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={{
              pathname: '/order/' + record.id,
              state: { from: 'calendar', back_to: this.props.date }
            }}
            >
              edytuj
            </Link>
          </span>
        )
      }
    ]

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          {loaded &&
            <div style={{ textAlign: !isMobile && 'right' }} className='orders-actions'>
              {this.props.screenSize !== 'xs' &&
                <>
                  Status:{' '}
                  <Segmented
                    onChange={this.handleStatus}
                    options={selectStatusOptions}
                    value={this.state.status}
                    style={{ marginBottom: '10px' }}
                  />
                </>}
              {this.props.screenSize === 'xs' &&
                <>
                  Filtruj po statusie: <b style={{ fontWeight: '800' }}>{this.props.t(this.state.status)}</b>{' '}
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu
                        onClick={(x) => this.handleStatus(x.key)}
                        items={[
                          {
                            key: 'new,accepted',
                            label: 'Aktywne'
                          },
                          {
                            key: 'finished',
                            label: 'Zakończone'
                          },
                          {
                            key: 'canceled',
                            label: 'Anulowane'
                          },
                          {
                            key: 'all',
                            label: 'Wszystkie'
                          }
                        ]}
                      />
                    }
                  >
                    <a onClick={e => e.preventDefault()}>
                      zmień
                    </a>
                  </Dropdown>
                </>}
            </div>}
          <div style={{ display: loaded ? 'block' : 'none' }}>
            {isMobile &&
              <List
                itemLayout='horizontal'
                pagination
                className='mobile-list'
                dataSource={filteredOrders}
                locale={{ emptyText: <Empty description='Brak zamówień' /> }}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Link
                        key='edit'
                        to={{
                          pathname: '/order/' + item.id,
                          state: { from: 'calendar', back_to: this.props.date }
                        }}
                      >
                        edytuj
                      </Link>
                    ]}
                  >
                    <List.Item.Meta
                      onClick={() => this.props.redirect({
                        pathname: '/order/' + item.id,
                        state: { from: 'calendar', back_to: this.props.date }
                      })}
                      title={
                        <>
                          <Link
                            to={{
                              pathname: '/order/' + item.id,
                              state: { from: 'calendar', back_to: this.props.date }
                            }}
                          >
                            {item.name}
                          </Link>{' '}
                          <Tag color={getOrderStatusColor(item.status)}>{this.props.t(item.status)}</Tag>
                        </>
                      }
                      description={<><CalendarOutlined /> {item.to_date} {item.to_time}<br /><UserOutlined /> {item.client_name}<br /><DollarCircleOutlined /> wartość: {item.price_to_client} zł zysk: {item.earnigs} zł</>}
                    />
                  </List.Item>
                )}
              />}
            {!isMobile &&
              <Table
                className={`settings-table ${orders && orders.length ? 'fade-in' : ''}`}
                {...this.settings}
                columns={columns}
                dataSource={filteredOrders}
                locale={{ emptyText: <Empty description='Brak zamówień' /> }}
              />}
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCalendarShow))
