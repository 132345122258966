import { Menu } from 'antd'
import React from 'react'
import { UnorderedListOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { Link, withRouter } from 'react-router-dom'

class StyledMyRecipesMobileMenu extends React.Component {
  render () {
    return (
      <div className='mobile-menu-container myrecipes-mobile-menu-container'>
        <Menu mode='horizontal' className='order-menu' disabledOverflow defaultSelectedKeys={[this.props.location.pathname]}>
          <Menu.Item key='/myrecipes' className='menu-item'>
            <Link to='/myrecipes'>
              <div className='icon'><UnorderedListOutlined /></div>
              <div className='title'>Przepisy</div>
            </Link>
          </Menu.Item>
          <Menu.Item key='/myrecipes/pantry' className='menu-item'>
            <Link to='/myrecipes/pantry'>
              <div className='icon'><ShoppingCartOutlined /></div>
              <div className='title'>Składniki</div>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
}

export default withRouter(StyledMyRecipesMobileMenu)
