import React from 'react'
import StyledPrintoutIndex from '../../styledComponents/printout'
import { Redirect } from 'react-router'
import { getPrintouts } from '../../utils/actions'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'

class PrintoutIndex extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getPrintouts()

    ReactGA.event({ category: 'Printout Projects', action: 'Printout List Showed'})
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getPrintouts = () => {
    getPrintouts().then((json) => {
      if (this._isMounted) {
        this.setState({ printouts: json.printouts, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, printouts, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to={this.props.authenticated ? '/panel' : '/'} />
      ) : (
        <StyledPrintoutIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          printouts={printouts}
          redirect={this.redirect}
          from_route={this.props.location.state?.from}
          loaded={loaded}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const PrintoutIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintoutIndex)

export default PrintoutIndexContainer
