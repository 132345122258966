import React from 'react'
import { Link } from 'react-router-dom'

class Regulations extends React.Component {
  render () {
    const appName = 'StrefaCukiernika.pl'
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <div className='static-content'>
        <h1 style={{ textAlign: 'center' }}>Regulamin korzystania z serwisu <span className='colored'>{appName}</span></h1>

        <h2 style={{ textAlign: 'center' }}>Definicje</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            <span className='colored'>Platforma szkoleniowa</span> – strona internetowa Strefa Cukiernika.pl, działająca pod adresem internetowym https://strefacukiernika.pl/;
          </li>
          <li>
            <span className='colored'>Produkt cyfrowy</span> - usługa świadczona drogą elektroniczną, której tworzenie, dystrybucja oraz użytkowanie odbywa się za pomocą technologii cyfrowych oraz treści cyfrowe wytwarzane i dostarczane w postaci cyfrowej dostępne na platformie cyfrowej;
          </li>
          <li>
            <span className='colored'>Szkolenie kulinarne</span> – określony produkt cyfrowy dostępny na platformie szkoleniowej oraz  w aplikacji mobilnej pod nazwą „Strefa Cukiernika”;
          </li>
          <li>
            <span className='colored'>Regulamin</span> – dokument, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,  określający zasady i warunki korzystania z  platformy szkoleniowej, w szczególności dokonywanie zakupu oferowanych produktów cyfrowych, realizacji zamówień, sposobów płatności, reklamacji oraz zasad rejestracji;
          </li>
          <li>
            <span className='colored'>Usługodawca</span> – właściciel platformy szkoleniowej Strefa Cukiernika sp. z o.o., adres siedziby: ul. Jarzębinowa 97/2, 05-825 Książenice, NIP: 529-185-44-35, REGON: 528554570, KRS: 0001103806;
          </li>
          <li>
            <span className='colored'>Użytkownik</span> – osoba korzystająca z produktów cyfrowych dostępnych na platformie szkoleniowej;
          </li>
          <li>
            <span className='colored'>Konto</span> – panel tworzony w systemie informatycznym platformy szkoleniowej, umożliwiający Użytkownikowi korzystanie z jej funkcjonalności, w szczególności nabywanie produktów cyfrowych;
          </li>
          <li>
            <span className='colored'>Umowa</span> – umowa o dostarczanie treści cyfrowej lub usługi cyfrowej  w rozumieniu przepisów Ustawy o prawach konsumenta, na podstawie której Usługodawca zobowiązuje dostarczyć Użytkownikowi produkt cyfrowy, a Użytkownik zobowiązuje się zapłacić Usługodawcy cenę.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Postanowienia ogólne</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Kontakt z Usługodawcą jest możliwy za pomocą:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>poczty elektronicznej – pod adresem: kontakt@strefacukiernika.pl;</li>
              <li>poczty tradycyjnej – pod adresem: ul. Jarzębinowa 97/2, 05-825 Książenice;</li>
              <li>telefonu – pod numerem: 782 070 907.</li>
            </ul>
          </li>
          <li>
            W ramach wykonywanej działalności Usługodawca dostarcza następujące produkty cyfrowe:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>szkolenia kulinarne zamknięte:
                <ul style={{ listStyleType: 'lower-roman' }}>
                  <li>dostępne w ramach abonamentu,</li>
                  <li>jednorazowe;</li>
                </ul>
              </li>
              <li>szkolenia kulinarne otwarte;</li>
              <li>kalkulatory;</li>
              <li>zamówienia;</li>
              <li>projekty Topperów;</li>
              <li>baza własnych przepisów użytkownika.</li>
            </ul>
          </li>
          <li>
            Produkty cyfrowe dostępne są na platformie szkoleniowej użytkowników odpłatnie lub nieodpłatnie.
          </li>
          <li>
            Informacje o produktach cyfrowych dostępnych na platformie szkoleniowej, w szczególności ich opisy oraz ceny, stanowią zaproszenie do zawarcia Umowy dostępu do szkoleń kulinarnych  w rozumieniu art. 71 ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny.
          </li>
          <li>
            Przed rozpoczęciem korzystania ze Platformy szkoleniowej, Użytkownik zobowiązany jest zapoznać się z Regulaminem oraz Polityką prywatności.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Wymagania techniczne</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            W celu prawidłowego korzystania przez Użytkowników z Platformy szkoleniowej, niezbędne jest:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>
                połączenie z siecią Internet;
              </li>
              <li>
                posiadanie urządzeń pozwalających na korzystanie z zasobów sieci Internet;
              </li>
              <li>
                korzystanie z przeglądarki internetowej Google Chrome lub równoważnej;
              </li>
              <li>
                posiadanie aktywnego konta poczty elektronicznej;
              </li>
              <li>
                w przypadkach określonych w opisie produktu cyfrowego wymagana jest rejestracja na platformie szkoleniowej (dalej „utworzenie konta użytkownika”);
              </li>
              <li>
                posiadanie programów komputerowych zapewniających odczytywanie plików w formacie PDF.
              </li>
            </ul>
          </li>
          <li>
            W ramach platformy szkoleniowej zabronione jest korzystanie przez Użytkowników z materiałów zawierających wirusy.
          </li>
          <li>
            Usługodawca informuje, że wykorzystuje ochronę transferu elektronicznego oraz treści cyfrowych przez zastosowanie właściwych środków organizacyjnych i technicznych, w szczególności szyfrowanie komunikacji z wykorzystaniem protokołu SSL.
          </li>
          <li>
            Usługodawca informuje, że pomimo stosowania zabezpieczeń, o których mowa ust. 3 w celu zminimalizowania zagrożeń związanych z korzystaniem z sieci Internet zaleca stosowanie programów antywirusowych lub środków chroniących identyfikację w sieci Internet.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Zasady korzystania z Platformy szkoleniowej</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Użytkownik jest zobowiązany do korzystania z platformy szkoleniowej w sposób zgodny z jej przeznaczeniem, z przepisami prawa powszechnie obowiązującego, postanowieniami Regulaminu, a także z dobrymi obyczajami.
          </li>
          <li>
            Zakazane jest podczas korzystania z Platformy szkoleniowej dostarczanie treści o charakterze bezprawnym, w szczególności poprzez przesyłanie tych treści podczas rejestracji na platformie szkoleniowej oraz używania formularza kontaktowego.
          </li>
          <li>
            Brak korzystania przez użytkownika z nabytego produktu cyfrowego pomimo możliwości korzystania z niego, nie uprawnia użytkownika do żądania od Usługodawcy zwrotu ceny za produkt cyfrowy.
          </li>
        </ul>

        {/*<h1 style={{ textAlign: 'center' }}>Produkty cyfrowe płatne</h1>*/}
        <h2 style={{ textAlign: 'center' }}>Rejestracja Użytkownika</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            W celu złożenia zamówienia na produkt cyfrowy płatny, Użytkownik dokonuje rejestracji na platformie szkoleniowej (tworzy konto).
          </li>
          <li>
            Warunkiem utworzenia konta jest akceptacja niniejszego Regulaminu oraz zapoznanie się z zasadami przetwarzania danych osobowych przez Usługodawcę. Użytkownik potwierdza fakt zapoznania się z dokumentami poprzez zaznaczenie aktywnego okienka checkbox oznaczonego „Akceptuję Regulamin oraz Politykę Prywatności” .
          </li>
          <li>
            Utworzenie konta następuje nie później niż przy złożeniu pierwszego zamówienia na produkt cyfrowy płatny i polega na wypełnieniu właściwego formularza dostępnego na platformie szkoleniowej.
          </li>
          <li>
            Podczas rejestracji Użytkownik udostępnia swoje następujące dane oraz informacje niezbędne do założenia konta, w szczególności:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>imię i nazwisko,</li>
              <li>adres e-mail,</li>
              <li>numer telefonu.</li>
            </ul>
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Składanie zamówień</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Zamówienia mogą składać przez pełnoletnie osoby fizyczne lub osoby prawne.
          </li>
          <li>
            Złożenie zamówienia następuje poprzez kliknięcie przez Użytkownika przycisku finalizującego zamówienie „Kupuję” .
          </li>
          <li>
            Użytkownik wybiera metodę płatności. Formy płatności za zamówienie jednorazowe są prezentowane na platformie szkoleniowej w ramach ścieżki zakupowej. Są to: płatności online, przelew bankowy oraz płatność BLIK. Jeżeli Użytkownik wybrał przy składaniu zamówienia płatność online, po kliknięciu w przycisku finalizującego zamówienie zostanie on przekierowany do bramki płatności obsługiwanej przez zewnętrznego operatora płatności; jeżeli Użytkownik wybrał płatność przelewem bankowym, informacje dotyczące dokonania płatności zostaną podane w e-mailu z podsumowaniem zamówienia;<br />
            w przypadku kupowania subskrypcji przez Użytkownika, możliwe formy płatności to: karta płatnicza.
          </li>
          <li>
            Ceny przedstawione w Serwisie są cenami brutto.
          </li>
          <li>
            Składając zamówienie Użytkownik może skorzystać z kodu rabatowego.
          </li>
          <li>
            W chwili przyjęcia przez Usługodawcę zamówienia dochodzi do zawarcia na odległość umowy o dostarczanie produktu cyfrowego będącego przedmiotem zamówienia. Usługodawca potwierdza przyjęcie zamówienia wiadomością e- mail przesłana do Użytkownika na adres wskazany przy rejestracji.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Realizacja zamówień</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Realizacja zamówienia obejmującego produkty cyfrowe oznacza, że Usługodawca dostarcza Użytkownikowi dane umożliwiające dostęp do tych nich w jeden z następujących sposobów, zależnie od rodzaju produktu cyfrowego:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>poprzez link przesłany kupującemu na wskazany przez niego w zamówieniu adres e-mail;  </li>
              <li>w ramach konta użytkownika, do którego zostaną przypisane produkty cyfrowe; </li>
            </ul>
          </li>
          <li>
            Dostęp do produktów cyfrowych zostanie przekazany niezwłocznie po opłaceniu zamówienia, chyba że inny termin dostarczenia produktów cyfrowych został określonych przez Usługodawcę w opisie produktu (np. w przypadku prowadzenia przedsprzedaży szkoleń kulinarnych organizowanych w określonej dacie w przyszłości).
          </li>
          <li>
            Z chwilą uzyskania dostępu do zamówionego produktu cyfrowego zgodnie z powyższym, przedmiot umowy uważa się za dostarczony w całości.
          </li>
          <li>
            W przypadku braku dostarczenia produktu cyfrowego w terminie wskazanym w ust. 3 powyżej, Użytkownik może wzywać Usługodawcę do jego dostarczenia. Wezwanie może zostać wysłane za pomocą poczty elektronicznej, na adres wskazany Regulaminie. Jeżeli Usługodawca nie dostarczy produktu cyfrowego niezwłocznie po otrzymaniu wezwania albo w dodatkowym, wyraźnie uzgodnionym z Użytkownikiem terminie, Użytkownik może odstąpić od umowy.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Dowód sprzedaży - paragony fiskalne w postaci elektronicznej</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
          Eparagon - jest to dokument fiskalny w postaci elektronicznej, którego zakres reguluje
Ustawa o podatku od towarów i usług z dnia 11 marca 2004 r., Rozporządzenie Ministra
Finansów w sprawie kas rejestrujących oraz Rozporządzenia MPiT w sprawie kryteriów i
warunków technicznych, którym muszą odpowiadać́ kasy rejestrujące.
          </li>
          <li>
          Warunkiem wydania eparagonu jest wyrażenie przez Klienta zgody. Klient wyraża zgodę na
wydanie eparagonu, jak i na sposób jego wydania, akceptując nieniejszy regulamin. W przypadku wycofania zgody, Klient powinien niewzłocznie poinformować o tym Usługodawcę w wiadomości email wysłanej na adres: kontakt@strefacukiernika.pl.
          </li>
          <li>
          Usługodawca wydaje eparagon Klientowi za pośrednictwem systemu i repozytorium
elektronicznych paragonów fiskalnych eparagony.pl
          </li>
          <li>
          Wydanie eparagonu następuje poprzez i w momencie skutecznego zapisu dokumentu w
repozytorium dokumentów systemu eparagony.pl. Klient uzyskuje dostęp do dokumentu oraz
jego czytelnego widoku za pośrednictwem unikatowego hiperłącza przekazanego przez
Usługodawcę na podany w zamówieniu adres e-mail Klienta.
          </li>
          <li>
          W przypadku problemów z dostępem do dokumentu bądź jego czytelnego widoku Klient
powinien sprawdzić folder SPAM oraz skontaktować się niezwłocznie ze Usługodawcą.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Uprawnienia z tytułu niezgodności z umową o dostarczanie produktu cyfrowego</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Usługodawca dokłada wszelkich starań, aby dostarczane produkty cyfrowe były zgodne z umową. W tym celu informuje w sposób jasny i zrozumiały o wszelkich istotnych wymaganiach zapewniających możliwość korzystania z nich zgodnie z umową.
          </li>
          <li>
            Usługodawca ponosi odpowiedzialność za brak zgodności produktu cyfrowego z umową, który istniał w chwili ich dostarczenia i ujawnił się w ciągu dwóch lat od tej chwili.
          </li>
          <li>
            W przypadku niezgodności produktu cyfrowego z umową, Użytkownik może żądać:
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>doprowadzenia do jego zgodności z umową. Usługodawca na swój koszt doprowadza produkt cyfrowy do zgodności z umową w czasie maksymalnie 14 dni. Termin jest liczony od chwili, w której Usługodawca został poinformowany przez Użytkownika o braku zgodności produktu cyfrowego z umową.</li>
              <li>
                złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od umowy, gdy:
                <ul style={{ listStyleType: 'lower-roman' }}>
                  <li>doprowadzenie do jego zgodności z umową jest niemożliwe albo wymaga nadmiernych kosztów;</li>
                  <li>Usługodawca nie doprowadził produktu cyfrowego do zgodności z umową;</li>
                  <li>brak zgodności produktu cyfrowego występuje nadal, mimo że Usługodawca próbował doprowadzić go do zgodności z umową;</li>
                  <li>brak zgodności produktu cyfrowego z umową jest na tyle istotny, że uzasadnia obniżenie ceny albo odstąpienie od umowy bez uprzedniego skorzystania z żądania doprowadzenia do zgodności,</li>
                  <li>z oświadczenia Usługodawcy lub okoliczności wyraźnie wynika, że nie doprowadzi on produktu cyfrowego do zgodności z umową w rozsądnym czasie lub bez nadmiernych niedogodności dla Użytkownika.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Odstąpienie od umowy nie jest możliwe, jeśli produkt cyfrowy jest dostarczany w zamian za zapłatę ceny, a brak jego zgodności z umową jest nieistotny.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za brak zgodności produktu cyfrowego z umową, jeżeli środowisko cyfrowe Użytkownika nie jest kompatybilne z wymaganiami technicznymi, o których Usługodawca informował przed zawarciem umowy za pośrednictwem Regulaminu.
          </li>
          <li>
            Użytkownik może zgłosić przysługujące mu roszczenia w zakresie niezgodności produktów cyfrowych z umową w na adres e-mail wskazany w Regulaminie.
          </li>
          <li>
            Usługodawca ma obowiązek dokonać zwrotu Użytkownikowi ceny należnej wskutek skorzystania z prawa odstąpienia lub obniżenia ceny niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia kupującego o odstąpieniu od umowy.
          </li>
          <li>
            O sposobie rozpatrzenia reklamacji Usługodawca powiadomi Użytkownika drogą mailową na adres przypisany do konta Użytkownika.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Prawo Odstąpienia - zakup jednorazowy</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Użytkownik może odstąpić od umowy, bez podania przyczyny, w terminie 14 dni poprzez złożenie Usługodawcy oświadczenia o odstąpieniu.
          </li>
          <li>
            Do zachowania terminu odstąpienia wystarczające jest wysłanie Usługodawcy oświadczenia o odstąpieniu, co może nastąpić drogą elektroniczną lub pisemnie, na adresy wskazane na wstępie niniejszego Regulaminu.
          </li>
          <li>
            Usługodawca zobowiązany jest niezwłocznie, nie później jednak niż w terminie 14 dni od dnia otrzymania oświadczenia o odstąpieniu od umowy, zwrócić wszystkie dokonane przez Użytkownika płatności.
          </li>
          <li>
            W przypadku odstąpienia od umowy Użytkownik traci prawo dostępu do udostępnionych produktów cyfrowych.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Subskrypcja</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Użytkownik chcący skorzystać z subskrypcji podpina w tym celu za pomocą bramki płatności swoją kartę płatniczą.
          </li>
          <li>
            Podpięcie karty płatniczej przez Użytkownika wiąże się z pobraniem opłaty za pierwszy miesiąc subskrypcji z góry.
          </li>
          <li>
            Za okres rozliczeniowy subskrypcji przyjmuje się jeden miesiąc tj. płatność za subskrypcję pobierana jest automatycznie z konta Użytkownika każdego miesiąca aż do momentu rezygnacji Użytkownika z subskrypcji.
          </li>
          <li>
            W ramach opłacanej subskrypcji Usługodawca zobowiązuje się na dostarczanie miesięcznie minimum jednego szkolenia w formie nagrania lub szkolenia na żywo (szkolenie online w formie LIVE).
          </li>
          <li>
            Tak długo jak Użytkownik opłaca subskrypcję, otrzymuje także dostęp do zamkniętych społeczności prowadzonych przez Usługodawcę oraz możliwość otrzymania certyfikatu w formie elektronicznej do oferowanych szkoleń.
          </li>
          <li>
            Użytkownik uruchamiający subskrypcję w promocyjnej cenie, zachowuje cenę promocyjną przez cały czas trwania subskrypcji. Użytkownik z subskrypcji w promocyjnej cenie może skorzystać tylko raz. W przypadku rezygnacji z subskrypcji może skorzystać z niej ponownie w standardowej cenie.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Prawo Odstąpienia – subskrypcja</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Użytkownik może odstąpić od kontynuowania subskrypcji w dowolnym momencie trwania umowy.
          </li>
          <li>
            Po rezygnacji z kontynuowania umowy, subskrypcja wygasa na koniec okresu rozliczeniowego tj. Użytkownik zachowuje dostęp do zasobów zawartych w subskrypcji do czasu wygaśnięcia okresu rozliczeniowego. Po rezygnacji kolejne płatności nie będą pobierane.
          </li>
          <li>
            W przypadku korzystania z subskrypcji nie ma możliwości zwrotu pobranych płatności.
          </li>
          <li>
            Anulowania subskrypcji można dokonać po zalogowaniu do Serwisu pod adresem: https://strefacukiernika.pl/anuluj-subskrypcje
          </li>
          <li>
            W przypadku odstąpienia od umowy Użytkownik traci prawo dostępu do udostępnionych produktów cyfrowych.
          </li>
          <li>
            W przypadku odstąpienia od umowy i wygaśnięciu bieżącego okresu rozliczeniowego Użytkownik traci prawo dostępu do obecnie udostępnionych produktów cyfrowych oraz kolejnych publikacji produktów cyfrowych oraz do zamkniętych społeczności prowadzonych przez Serwis i możliwości otrzymania certyfikatu do oferowanych szkoleń.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Produkty cyfrowe nieodpłatne</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            <span className='colored'>Szkolenia kulinarne otwarte.</span> Szkolenia dla Użytkowników platformy szkoleniowej. Warunkiem zapisu na szkolenie jest wyrażenie zgody na prowadzenie komunikacji marketingowej związanej z danym szkoleniem oraz podanie adresu e-mail i/lub numeru telefonu.
          </li>
          <li>
            <span className='colored'>Kalkulator.</span> Platforma szkoleniowa udostępnia dla Użytkownika Kalkulator(y), które mogą pomóc w codziennej pracy cukiernika. Użytkownik ma możliwość zapisania bieżących ustawień Kalkulatora do wycen (zapis następuje automatycznie po każdej zmianie w jego formularzu). Każdorazowe użycie Kalkulatora jest zapisywane w celu umożliwiania wygenerowania anonimowej statystyki danych w nim wprowadzonych.
          </li>
          <li>
            <span className='colored'>Zamówienia.</span> Istnieje możliwość dodawania do 10 zamówień na jeden miesiąc. Platforma szkoleniowa udostępnia dla Użytkownika system obsługi zamówień na wypieki.
          </li>
          <li>
            <span className='colored'>Projekty Topperów.</span> Platforma szkoleniowa udostępnia dla Użytkownika Projekty Topperów do pobrania. Pobrany projekt toppera Użytkownik może zrealizować (wyciąć) w dowolnym wybranym przez siebie miejscu, dowolną ilość razy (na potrzeby prywatne ale także na potrzeby zamówień od klientów Użytkownika). Dalsza odsprzedaż udostępnionych projektów oraz wykorzystanie w sposób masowy nie jest dozwolone (np. umieszczenie w sklepie z topperami) bez zgody autora projektu widniejącego na każdej podstronie z podglądem toppera.
          </li>
          <li>
            <span className='colored'>Baza własnych przepisów użytkownika</span> – do 10 przepisów. Platforma szkoleniowa udostępnia dla Użytkownika mechanizmy definiowania w aplikacji własnej bazy przepisów.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Dodatkowe usługi</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            <span className='colored'>Kody Rabatowe.</span> Platforma szkoleniowa udostępnia dla Użytkownika Kody Rabatowe, z których Użytkownik może skorzystać przy zamawianiu produktów i/lub usług u Partnerów Serwisu.
          </li>
          <li>
            <span className='colored'>Newsletter.</span> Usługodawca oferuje usługę wysyłki Newslettera, polegającą na przesyłaniu wiadomości e-mail, za pośrednictwem których informuje o promocjach, nowościach oraz kolejnych edycjach szkoleń. Zapis do Newslettera odbywa się za pośrednictwem odpowiedniego formularza zapisu dostępnego na platformie szkoleniowej po wyrażeniu przez użytkownika zgody na otrzymywanie informacji marketingowych i handlowych. Powyższe zgody są dobrowolne, jednakże konieczne do realizacji wysyłki Newslettera na rzecz Użytkownika. Zgody mogą zostać w każdej chwili wycofane, co spowoduje zaprzestanie wysyłania Newslettera zgodnie z zasadami zawartymi w Polityce Prywatności. System mailingowy wykorzystywany do wysyłki Newslettera odnotowuje aktywność i działania podejmowane przez Użytkownika związane z przesyłanymi do niego e-mailami. Dane te mogą być wykorzystywane przez Usługodawcę dla potrzeb analitycznych, statystycznych lub lepszego dopasowania oferty.
          </li>
          <li>
            <span className='colored'>Certyfikaty.</span> Usługodawca oferuje możliwość uzyskania Certyfikatu potwierdzającego udział w szkoleniach zamkniętych.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Własność Intelektualna</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Wszystkie treści zamieszczone na stronie internetowej pod adresem StrefaCukiernika.pl są własnością StrefaCukiernika.pl lub osób wskazanych każdorazowo przy udostępnionych treściach.
          </li>
          <li>
            Jakiekolwiek wykorzystanie tych treści, bez wyraźnej pisemnej zgody Usługodawcy lub któregokolwiek z elementów składających się na treść oraz zawartość strony StrefaCukiernika.pl stanowi naruszenie prawa autorskiego przysługującego Usługodawcy i skutkuje odpowiedzialnością cywilnoprawną oraz karną.
          </li>
          <li>
            Zakazane jest wprowadzanie jakichkolwiek zmian do produktów cyfrowych, odsprzedaż lub inne formy udostępniania produktów cyfrowych, ich rozpowszechniania i prezentowanie osobom trzecim, zarówno odpłatnie jak i nieodpłatnie, a także utrwalanie, kopiowanie i powielanie na potrzeby inne niż własny użytek.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Ochrona Danych Osobowych</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Administratorem danych osobowych Użytkowników jest Usługodawca.
          </li>
          <li>
            Podanie danych jest dobrowolne, ale niezbędne w celu rejestracji użytkownika, złożenia zamówienia lub korzystania z określonych usług oferowanych przez Usługodawcę.
          </li>
          <li>
            Sprzedający przetwarza dane osobowe na zasadach szczegółowo opisanych w Polityce Prywatności.
          </li>
        </ul>

        <h2 style={{ textAlign: 'center' }}>Postanowienia Końcowe</h2>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Umowy zawierane za pośrednictwem portalu szkoleniowego zawierane są zgodnie z prawem polskim.
          </li>
          <li>
            W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego.
          </li>
          <li>
            Wszelkie spory powstałe pomiędzy Usługodawcą a Użytkownikiem nie będącym konsumentem zostają poddane sądowi właściwemu ze względu na siedzibę Usługodawcy.
          </li>
        </ul>
      </div>
    )
  }
}

export default Regulations
