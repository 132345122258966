/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import PantryForm from '../../styledComponents/myrecipes/pantryForm'
import { addMyRecipesPantry, getMyRecipesConfig } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'

import { Redirect } from 'react-router'

class MyRecipeAddPantry extends React.Component {
  state = {
    formData: {},
    costCalc: false
  }

  formRef = React.createRef()

  addPantry = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addMyRecipesPantry(data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie dodano składnik!')
        _this.makeRedirection()
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  getMyRecipesConfig = () => {
    getMyRecipesConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ costCalc: json.cost_calc })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    this.getMyRecipesConfig()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/myrecipes/pantry' />
      ) : (
        <>
          <PageHeader
            title='Dodaj składnik'
            onBack={() => this.makeRedirection()}
          />
          <PantryForm
            formAction={this.addPantry}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            isNew
          />
        </>
      )
    )
  }
}

export default withTranslation()(MyRecipeAddPantry)
