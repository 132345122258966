/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Popconfirm, Button, Menu, Collapse
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import PantryForm from '../../styledComponents/calcPro/pantryForm'
import { getPantryItem, updatePantryItem, deletePantryItem } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'

import { Redirect } from 'react-router'
const { Panel } = Collapse

class EditPantry extends React.Component {
  state = {
    formData: undefined,
    id: undefined
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  getPantryItem = (id) => {
    getPantryItem(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })
      }
    })
  }

  updatePantryItem = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updatePantryItem(_this.state.id, data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano składnik!')
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  deletePantryItem = (data) => {
    deletePantryItem(this.state.id).then((json) => {
      openNotification(this.props.t('Success'), 'Pomyślnie usunięto składnik z magazynu!')
      this.redirect('/pantry')
    })
  }

  componentDidMount () {
    const { id } = this.props.match.params

    this._isMounted = true

    this.setState({ id })
    this.getPantryItem(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const confirmation = (
      <>
        <p>Czy na pewno usunąć składnik?</p>
        {this.state.formData && this.state.formData.related_orders.length > 0 &&
          <>
            <p>Ten składnik jest używany w przepisach, które są używane w poniższych zamówieniach i zostanie z nich usunięty. Wyceny w zamówieniach zostaną automatycznie przeliczone.</p>
            <Collapse className='calculator-results-collapse'>
              <Panel header='Pokaż zamówienia'>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <ul>
                    {this.state.formData.related_orders.map(x => <li key={x.id}>{x.name}</li>)}
                  </ul>
                </div>
              </Panel>
            </Collapse>
          </>}
      </>)

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Popconfirm
          title={confirmation}
          onConfirm={() => this.deletePantryItem()}
          {...popconfirmProps}
        >
          <Menu.Item key='delete-pantry'>
              Usuń
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Popconfirm
        title={confirmation}
        onConfirm={() => this.deletePantryItem()}
        {...popconfirmProps}
        key='delete-pantry'
      >
        <Button>Usuń</Button>
      </Popconfirm>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to='/pantry' />
      ) : (
        <>
          <PageHeader
            title='Edytuj składnik'
            onBack={() => this.makeRedirection()}
            extra={actions}
          />
          <PantryForm
            formAction={this.updatePantryItem}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
          />
        </>
      )
    )
  }
}

export default withTranslation()(EditPantry)
