/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Button, Upload, Space
} from 'antd'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import settings from '../../config'
import { getDeviceData, openNotification } from '../../utils/helpers'
import ReactGA from 'react-ga4'

const { Dragger } = Upload

const formItemLayout = {
  labelCol: {
    xs: { span: 6, offset: 0 },
    md: { span: 8, offset: 2 },
  },
  wrapperCol: {
    xs: 24,
    md: 8,
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

class EnlargeImageForm extends React.Component {
  state = {
    rating: ''
  }

  handleSubmit = () => {
    this.props.enlargeImage(this.props.formRef.current?.getFieldsValue())

    ReactGA.event({ category: 'Generate EnlargeImage', action: 'EnlargeImage Generated' })
  }

  // countWords = (prompt) => {
  //   const arr = prompt.split(' ');
  //
  //   return arr.filter(word => word !== '').length;
  // }
  //
  // countComma = (prompt) => {
  //   return prompt.split(',').length - 1
  // }

  render () {
    const { isMobile, loading } = this.props

    const headers = {}
    headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='horizontal'
          className={'inspiration' + (isMobile ? ' inspiration-mobile' : '')}
          ref={this.props.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='image'
            label='Grafika do powiększenia'
            className='inspiration-upload'
            {...formItemLayout}
            rules={[
              {
                message: 'Wgraj grafikę do powiększenia',
                validator: (_, value) => {
                  if (this.props.preview) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject('Wgraj zdjęcie do inspiracji')
                  }
                }
              }
           ]}
            extra={
              !this.props.preview && <Dragger
                action={settings.api_url + '/api/enlarge_image/upload'}
                disabled={loading}
                headers={headers}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                  if (!isJpgOrPng) {
                    openNotification('Ups, wystąpił błąd!', 'Możesz wgrać tylko plik JPG lub PNG')
                  }
                  const sizeCheck = file.size / 1024 / 1024 <= 3;
                  if (!sizeCheck) {
                    openNotification('Ups, wystąpił błąd!', 'Obrazek może mieć rozmiar maksymalnie 3MB')
                  }
                  return isJpgOrPng && sizeCheck;
                }}
                onChange={(x) => {
                  if(x.file.status === 'done') {
                    this.props.getUploadedFilePreview()
                  }
                  console.log(x)
                }}
              >
                <p>Kliknij by przesłać plik lub przeciągnij go tutaj</p>
              </Dragger>
            }
          >
            {this.props.preview && <img src={"data:image/png;base64, " + this.props.preview} alt='uploaded preview' height='128' />}
            {this.props.preview && <Button style={{ marginLeft: '10px' }} icon={<DeleteOutlined onClick={this.props.removeFile} />} />}
          </Form.Item>

          <Space>
            <Button ref={this.resultRef} disabled={loading || this.props.limitExceeded} htmlType='submit' type='primary' size='large'>
              Powiększ grafikę!{loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default EnlargeImageForm
