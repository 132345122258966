import { Menu } from 'antd'
import React from 'react'
import { UnorderedListOutlined, SettingOutlined, FileTextOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { Link, withRouter } from 'react-router-dom'

class StyledOrderMobileMenu extends React.Component {
  render () {
    return (
      <div className='mobile-menu-container'>
        <Menu mode='horizontal' className='order-menu' disabledOverflow defaultSelectedKeys={[this.props.location.pathname]}>
          <Menu.Item key='/order' className='menu-item'>
            <Link to='/order'>
              <div className='icon'><UnorderedListOutlined /></div>
              <div className='title'>Zamówienia</div>
            </Link>
          </Menu.Item>
          <Menu.Item key='/recipe' className='menu-item'>
            <Link to='/recipe'>
              <div className='icon'><FileTextOutlined /></div>
              <div className='title'>Przepisy</div>
            </Link>
          </Menu.Item>
          <Menu.Item key='/pantry' className='menu-item'>
            <Link to='/pantry'>
              <div className='icon'><ShoppingCartOutlined /></div>
              <div className='title'>Magazyn</div>
            </Link>
          </Menu.Item>
          <Menu.Item key='/order/config' className='menu-item'>
            <Link to='/order/config'>
              <div className='icon'><SettingOutlined /></div>
              <div className='title'>Ustawienia</div>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
}

export default withRouter(StyledOrderMobileMenu)
