/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button,
  Upload, Segmented, Space
} from 'antd'
import { Selector } from 'antd-mobile'
import { DeleteOutlined, FileTextOutlined, FileImageOutlined, LoadingOutlined } from '@ant-design/icons'
import settings from '../../config'
import { getDeviceData } from '../../utils/helpers'
import ReactGA from 'react-ga4'

const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: { span: 6, offset: 0 },
    md: { span: 8, offset: 2 },
  },
  wrapperCol: {
    xs: 24,
    md: 8,
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const { TextArea } = Input

class InspirationForm extends React.Component {
  state = {
    rating: ''
  }

  handleSubmit = () => {
    this.props.generateInspiration({ ...this.props.formRef.current?.getFieldsValue(), generation_type: this.props.generationType })

    ReactGA.event({ category: 'Generate Inspiration', action: 'Inspiration Generated' })
  }

  countWords = (prompt) => {
    const arr = prompt.split(' ');

    return arr.filter(word => word !== '').length;
  }

  countComma = (prompt) => {
    return prompt.split(',').length - 1
  }

  render () {
    const { isMobile, loading } = this.props
    const selectOptions = [{ label: <><FileTextOutlined /><br/>Tekst</>, value: 'text', key: 'text' }, { label: <><FileTextOutlined /> + <FileImageOutlined /><br/>Tekst i zdjęcie</>, value: 'text_image', key: 'text_image' }]
    const selectOptionsObjectType = [{ label: 'tort', value: 'cake', key: 'cake' }, { label: 'babeczki', value: 'muffin', key: 'muffin' }, { label: 'dowolnie', value: 'any', key: 'any' }]

    const headers = {}
    headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='horizontal'
          className={'inspiration' + (isMobile ? ' inspiration-mobile' : '')}
          ref={this.props.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='generation_type'
            label='Generuj na podstawie'
            {...formItemLayout}
            className='inspiration-form-icons'
          >
          {isMobile
            ? (
              <Selector
                options={selectOptions}
                onChange={this.props.handleGenerationTypeSelector}
                disabled={loading}
              />
            ) : (
              <Segmented
                onChange={this.props.handleGenerationTypeSegmented}
                options={selectOptions}
                disabled={loading}
              />)}
          </Form.Item>
          <Form.Item
            name='object_type'
            label='Co wygenerować?'
            {...formItemLayout}
            className='inspiration-form-icons'
            help={this.props.objectType === 'any' && 'Jeśli generujesz dowolny wypiek, musisz dokładnie opisać na jaką słodycz inspirację chcesz uzyskać i jak ma wyglądać - im lepszy opis tym lepsza inspiracja :)'}
          >
          {isMobile
            ? (
              <Selector
                options={selectOptionsObjectType}
                onChange={this.props.handleObjectTypeSelector}
                disabled={loading}
              />
            ) : (
              <Segmented
                onChange={this.props.handleObjectTypeSegmented}
                options={selectOptionsObjectType}
                disabled={loading}
              />)}
          </Form.Item>
          <Form.Item
            name='prompt'
            label='Opisz jak ma wyglądać, dla kogo, na jaką okazję'
            validateTrigger='onBlur'
            rules={[
              { required: true, message: 'Podaj pomysł na jaki chcesz wygenerować inspirację' },
              {
                message: 'Twój pomysł zawiera zbyt mało informacji. Spróbuj dodać więcej szczegółów oddzielając je przecinkiem lub podaj więcej słów.',
                validator: (_, value) => {
                  if (value === '' || this.countComma(value) >= 2 || this.countWords(value) >= 3) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject('Twój pomysł zawiera zbyt mało informacji. Spróbuj dodać więcej szczegółów oddzielając je przecinkiem lub podaj więcej słów.')
                  }
                }
              }
            ]}
            {...formItemLayout}
          >
            <TextArea disabled={loading} />
          </Form.Item>
          {this.props.generationType === 'text_image' && <Form.Item
            name='image'
            label='Użyj zdjęcia do inspiracji'
            className='inspiration-upload'
            {...formItemLayout}
            rules={[
              {
                message: 'Wgraj zdjęcie do inspiracji',
                validator: (_, value) => {
                  if (this.props.preview) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject('Wgraj zdjęcie do inspiracji')
                  }
                }
              }
           ]}
            extra={
              !this.props.preview && <Dragger
                action={settings.api_url + '/api/inspiration/upload'}
                disabled={loading}
                headers={headers}
                onChange={(x) => {
                  if(x.file.status === 'done') {
                    this.props.getUploadedFilePreview()
                  }
                }}
              >
                <p>Kliknij by przesłać plik lub przeciągnij go tutaj</p>
              </Dragger>
            }
          >
            {this.props.preview && <img src={"data:image/png;base64, " + this.props.preview} alt='uploaded preview' height='128' />}
            {this.props.preview && <Button style={{ marginLeft: '10px' }} icon={<DeleteOutlined onClick={this.props.removeFile} />} />}
          </Form.Item>}

          <Space>
            <Button ref={this.resultRef} disabled={loading} htmlType='submit' type='primary' size='large'>
              Zainspiruj mnie!{loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
            <Button disabled={loading} onClick={this.props.randomizePrompt} type='secondary' size='large'>
              Wylosuj pomysł ;)
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default InspirationForm
