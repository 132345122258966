import React from 'react'
import { Form, Input, Button } from 'antd'
import { setUserResetPassword } from '../../utils/actions'
import { LoadingOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledResetPasswordChangeForm extends React.Component {
  formRef = React.createRef()

  state = {
    loading: false
  }

  handleSubmit = (values) => {
    const { makeRedirection } = this.props

    this.setState({ loading: true })

    setUserResetPassword(this.props.token, values.password, values.password_repeat).then((json) => {
      this.setState({ loading: false })
      makeRedirection()
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  render () {
    return (
      <div className='login-form change-password-form'>
        <div className='info'>
          Ustaw nowe hasło do Twojego konta
        </div>
        <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
          <Form.Item
            name='password'
            label={this.props.t('Password')}
            rules={[{ required: true, message: this.props.t('Password field is required') }]}
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='password_repeat'
            label={this.props.t('Repeat password')}
            rules={[{ required: true, message: this.props.t('Password field is required') }]}
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className='form-footer'>
            <Button htmlType='submit' disabled={this.state.loading}>Ustaw nowe hasło{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(StyledResetPasswordChangeForm)
