import React from 'react'
import { Form, Input, Button } from 'antd'
import { requestUserResetPassword } from '../../utils/actions'
import { LoadingOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
}

class StyledResetPasswordForm extends React.Component {
  formRef = React.createRef()

  state = {
    loading: false
  }

  handleSubmit = (values) => {
    const { requestSent } = this.props

    this.setState({ loading: true })

    requestUserResetPassword(values.login).then((json) => {
      this.setState({ loading: false })
      requestSent()
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  render () {
    return (
      <div className='login-form reset-password-form'>
        <div className='info'>
          Podaj adres email, na który zostało zarejestrowane konto
        </div>
        <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
          <Form.Item
            name='login'
            label={this.props.t('Email')}
            rules={[{ required: true, message: this.props.t('Email field is required') }]}
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item className='form-footer'>
            <Button htmlType='submit' disabled={this.state.loading}>Resetuj hasło{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(StyledResetPasswordForm)
