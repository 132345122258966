import React from 'react'
import StyledCalcProIndex from '../../styledComponents/calcPro'
import { Redirect } from 'react-router'
import { getOrders, getOrdersConfig, getUserProfile, sendUpvote } from '../../utils/actions'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import { setCtaModalVisible } from '../../store/actions'
import CtaModal from '../../styledComponents/common/ctaModal'
import { LikeOutlined } from '@ant-design/icons'
import ContentRight from '../common/contentRight'

class CalcIndex extends React.Component {
  state = {
    makeRedirection: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  getOrdersConfig = () => {
    getOrdersConfig().then((json) => {
      if (this._isMounted) {
        this.setState({
          ordersConfig: json
        })
      }
    })
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getOrders()
      this.getOrdersConfig()
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getOrders = () => {
    getOrders().then((json) => {
      if (this._isMounted) {
        this.setState({ orders: json.orders, ordersByDate: json.orders_by_date })
      }
    })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { makeRedirection, orders, ordersByDate } = this.state

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText
    let buttonTextSize
    let buttonType
    let buttonCallback

    text = <>Hej! Na ten moment wyczerpany został limit w tym miesiącu w Twoich zamówieniach... Jeśli chcesz przyspieszyć prace nad możliwością dodawania większej ilości zamówień, kliknij kciuk w górę :D</>
    requiredSubs = ['sub1', 'sub2', 'sub3']
    buttonText = <LikeOutlined />
    buttonTextSize = 50
    buttonType = 'text'
    buttonCallback = () => { sendUpvote('orders_limit') }

    // if (authenticated && user?.sub_type === 'free' && !user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit zamówień w tym miesiącu. Możesz dodać więcej jeśli skorzystasz z któregoś ze Strefowych pakietów, wypróbuj konto testowe przez 7 dni bez opłat :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/order']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet testowy i dodaj kolejne zamówienia'
    // } else if (authenticated && user?.sub_type === 'free' && user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit zamówień w tym miesiącu. Możesz dodać więcej jeśli skorzystasz z któregoś ze Strefowych pakietów :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/order']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i dodaj kolejne zamówienia'
    // } else if (authenticated && user?.sub_type !== 'free' && user?.trial) {
    //   text = <>Ups! Wyczerpany został limit zamówień w tym miesiącu. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub wybrać wyższy pakiet gdzie można ich dodać więcej.</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/order']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i dodaj kolejne zamówienia'
    // } else if (authenticated && user?.sub_type !== 'free' && !user?.trial) {
    //   text = <>Ups! Wyczerpany został limit zamówień w tym miesiącu. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub zmienić pakiet gdzie można ich dodać więcej.</>
    //   toRoute = '/zmien-pakiet'
    //   redirect = [this.props.redirectRoute || '/order']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Zmień pakiet i dodaj kolejne zamówienia'
    // }

    return (
      makeRedirection ? (
        <Redirect to={authenticated ? '/panel' : '/'} />
      ) : (
        authenticated ? <>
          <CtaModal
            text={text}
            toRoute={toRoute}
            redirect={redirect}
            requiredSubs={requiredSubs}
            buttonText={buttonText}
            buttonTextSize={buttonTextSize}
            buttonType={buttonType}
            buttonCallback={buttonCallback}
          />
          <StyledCalcProIndex
            makeRedirection={this.makeRedirection}
            isMobile={isMobile}
            orders={orders}
            ordersByDate={ordersByDate}
            redirect={this.redirect}
            from_route={this.props.location.state?.from}
            limitExceeded={this.state.ordersConfig?.limit_exceeded}
            setCtaModalVisible={this.props.setCtaModalVisible}
          />
        </> : <ModuleNotLoggedContent
          title='Zamówienia'
          contentTitle='Ułatw sobie pracę dodając je do systemu zamówień!'
          showCtaBottom
          content={
            <ContentRight
              isMobile={isMobile}
              image='/images/orders_screen.png'
              title='System obsługi zamówień'
              content='Masz więcej zamówień? Mamy również coś dla Ciebie! W aplikacji znajdziesz moduł obsługi zamówień, który jest połączony z kalendarzem, bazą przepisów i magazynem składników. Możesz tam definiować swoje własne składniki i przepisy a następnie dodawać je do zamówień. W podsumowaniu otrzymasz informację o całkowitej wielkości sprzedaży i wypracowanego przez Ciebie zysku :)'
            />
          }
          redirect={this.redirect}
          redirectRoute={this.props.location?.pathname}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const CalcIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalcIndex)

export default CalcIndexContainer
