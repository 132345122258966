import React from 'react'
import {
  PageHeader, Skeleton, Row, Col, Card
} from 'antd'
// import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

class StyledMainRecipeIndex extends React.Component {
  render () {
    const { recipes, redirect, isMobile, loaded } = this.props

    return (
      <>
        <PageHeader
          title='Przepisy'
          onBack={() => redirect('/')}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ marginLeft: '20px', marginRight: '20px', display: loaded ? 'block' : 'none' }}>
          <Row gutter={[10, 10]}>
            {recipes?.map(item => {
              return <Col xs={24} md={12} lg={6} key={item.id}>
                <Card
                  hoverable
                  className='printout-item'
                  cover={<Link to={'/przepisy/' + item.slug}><img width={196} src={'/images/' + item.image} alt='przepis' /></Link>}
                >
                  <Link to={'/przepisy/' + item.slug}>{item.name}</Link>
                </Card>
              </Col>
            })}
          </Row>
        </div>
      </>
    )
  }
}

export default StyledMainRecipeIndex
