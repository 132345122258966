import React from 'react'
import {
  Table, Segmented, Menu, PageHeader, Tag, Row, Col,
  Dropdown, Empty, Skeleton, Button
} from 'antd'
import { Link } from 'react-router-dom'
import { saveDeviceData, getDeviceData, getCertificateStatusColor } from '../../utils/helpers'
import 'moment/locale/pl'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledCertificateRequestIndex extends React.Component {
  state = {
    filteredCertificateRequests: [],
    status: 'toreview',
    loaded: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  componentDidMount () {
    const status = getDeviceData('certificate_requests_status')

    if (status) {
      this.setState({ status })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { status } = this.state

    if (prevProps.certificateRequests !== this.props.certificateRequests) {
      const statusSplited = this.state.status.split(',')

      this.setState({
        filteredCertificateRequests: status === 'all' ? this.props.certificateRequests : this.props.certificateRequests.filter(x => statusSplited.includes(x.status)),
        loaded: true
      })
    }
  }

  handleStatus = (status) => {
    const statusSplited = status.split(',')

    this.setState({
      status,
      filteredCertificateRequests: status === 'all' ? this.props.certificateRequests : this.props.certificateRequests.filter(x => statusSplited.includes(x.status)),
    })

    saveDeviceData('certificate_requests_status', status)
  }

  render () {
    const { orders, isMobile } = this.props
    const { filteredCertificateRequests, loaded } = this.state

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (x) => (
          <Tag color={getCertificateStatusColor(x)}>{this.props.t(x)}</Tag>
        ),
        // sorter: (a, b) => { return a.status.localeCompare(b.status) },
      },
      {
        title: 'Szkolenie',
        dataIndex: 'training',
        key: 'training',
        render: (record) => record?.title
        // sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/certificate/' + record.id}>pokaż</Link>
          </span>
        )
      }
    ]

    const selectStatusOptions = [{ label: 'Do akceptacji', value: 'toreview' }, { label: 'Zatwierdzone', value: 'accepted' }, { label: 'Niewypełnione', value: 'new' }, { label: 'Odrzucone', value: 'rejected' }, { label: 'Wszystkie', value: 'all' }]

    return (
      <>
        <PageHeader
          title='Zgłoszenia o certyfikaty'
          onBack={() => this.props.redirect('/')}
        />
        {loaded &&
          <div>
            <Row>
              <Col xs={24} md={24} style={{ textAlign: !isMobile && 'right' }} className='orders-actions'>
                {this.props.screenSize !== 'xs' &&
                  <>
                    Status:{' '}
                    <Segmented
                      onChange={this.handleStatus}
                      options={selectStatusOptions}
                      value={this.state.status}
                      style={{ marginBottom: '10px' }}
                    />
                  </>}
                {this.props.screenSize === 'xs' &&
                  <>
                    Filtruj po statusie: <b style={{ fontWeight: '800' }}>{this.props.t(this.state.status)}</b>{' '}
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Menu
                          onClick={(x) => this.handleStatus(x.key)}
                          items={[
                            {
                              key: 'toreview',
                              label: 'Do akceptacji'
                            },
                            {
                              key: 'accepted',
                              label: 'Zatwierdzone'
                            },
                            {
                              key: 'new',
                              label: 'Niewypełnione'
                            },
                            {
                              key: 'rejected',
                              label: 'Odrzucone'
                            },
                            {
                              key: 'finished',
                              label: 'Zakończone'
                            },
                            {
                              key: 'all',
                              label: 'Wszystkie'
                            }
                          ]}
                        />
                      }
                    >
                      <Button type='info' size='small' onClick={e => e.preventDefault()}>
                        zmień
                      </Button>
                    </Dropdown>
                  </>}
              </Col>
            </Row>
          </div>}
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ display: loaded ? 'block' : 'none' }}>
          <Table
            className={`settings-table ${orders && orders.length ? 'fade-in' : ''}`}
            {...this.settings}
            columns={columns}
            dataSource={filteredCertificateRequests}
            locale={{
              emptyText: <Empty description='Brak zgłoszeń o certyfikaty' />,
              triggerDesc: this.props.t('Sortuj malejąco'),
              triggerAsc: this.props.t('Sortuj rosnąco'),
              cancelSort: this.props.t('Kliknij aby anulować sortowanie') }}
          />
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCertificateRequestIndex))
