/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Select, Row, Col, Card, InputNumber,
  Popconfirm, Skeleton, Divider, Switch
} from 'antd'
import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import HelpBox from '../common/helpBox'
import { unitMap, oneGlassGram, oneLittleSpoonGram, oneSpoonGram, oneGlassMl, oneLittleSpoonMl, oneSpoonMl, oneDropMl } from '../../utils/calcUnits'
import { Link } from 'react-router-dom'

import FloatLabel from '../common/floatLabel'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 12,
    lg: 12
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

const { Option } = Select

class RecipeForm extends React.Component {
  state = {
    visibleHelpUsedIngredients: false,
    visibleHelpGeneral: false,
    loaded: false,
    pantryIngredients: [],
    usePortions: false,
    loading: false
  }

  formRef = React.createRef()
  xref = React.createRef()
  resultRef = React.createRef()

  setVisibleHelpGeneral = (flag) => {
    this.setState({ visibleHelpGeneral: flag })

    flag && ReactGA.event({ category: 'Order Recipe', action: 'VisibleHelpGeneral Help Opened Mobile' })
  }

  setVisibleHelpUsedIngredients = (flag) => {
    this.setState({ visibleHelpUsedIngredients: flag })

    flag && ReactGA.event({ category: 'Order Recipe', action: 'VisibleHelpUsedIngredients Help Opened Mobile' })
  }

  handleSubmit = (x) => {
    this.setState({ loading: true })

    this.props.formAction({ ...this.formRef.current?.getFieldsValue() }).then((json) => {
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
    })
  }

  handleUsedIngredientsFocus = (name) => {
    this.setState({ usedIngredientsFocus: name })
  }

  getIngUnits = (name) => {
    const ingId = this.formRef.current?.getFieldValue('used_ingredients')[name]?.ingId
    const pantryItem = this.props.pantryIngredients?.find(x => x.id === ingId)

    if (unitMap[pantryItem?.unit]) {
      return unitMap[pantryItem?.unit]
    }

    return []
  }

  componentDidMount () {
    const { formData } = this.props

    if (formData) {
      // formData.portions = 0
      this.formRef.current.setFieldsValue(formData)

      this.setState({
        loaded: true,
        usePortions: formData.use_portions
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (formData && prevProps.formData !== formData) {
      this.formRef.current.setFieldsValue(formData)

      this.setState({
        loaded: true,
        usePortions: formData.use_portions
      })
    }
  }

  handleUsePortions = (usePortions) => {
    this.setState({ usePortions })

    if (usePortions) {
      this.formRef?.current?.setFieldsValue({ portions: 10 })
    }
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    const generalHelp = (
      <>
        Tutaj podaj ogólne informacje o przepisie.<br/>
        <ul className='calculator-help-list'>
          <li><b>Przepis jest na ilość porcji</b> - jeśli dany przepis jest na określoną ilość porcji, kliknij to ustawienie i podaj ją, dzięki temu w formularzu zamówienia łatwiej odnajdziesz pasujące przepisy</li>
        </ul>
      </>
    )

    const usedIngredientsHelp = (
      <>
        W tej sekcji definiujesz np. ile kg mąki zostało zużyte, ile jajek albo ile sztuk akcesoriów (wprowadzonych w <Link to='/pantry'>Magazynie składników</Link>). Na tej podstawie wyliczany będzie faktyczny koszt składników zużytych na ten przepis.<br /><br />
        Przyjęte przeliczniki jednostek:<br /><br />
        <b>1 szklanka</b> = {oneGlassGram}g = {oneGlassMl}ml<br />
        <b>1 łyżeczka</b> = {oneLittleSpoonGram}g = {oneLittleSpoonMl}ml<br />
        <b>1 łyżka</b> = {oneSpoonGram}g = {oneSpoonMl}ml<br />
        <b>1 kropla</b> = {oneDropMl}ml<br />
      </>
    )

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ cakes_per_month: 0, h_price: 0, margin: 0 }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  <Card
                    title='Informacje o przepisie'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    extra={<HelpBox title='Informacje o przepisie' category='Recipe Form' content={generalHelp} visible={this.state.visibleHelpGeneral} setVisible={this.setVisibleHelpGeneral} isMobile={isMobile} />}
                  >
                    <Form.Item
                      name='name'
                      label={this.props.t('Nazwa')}
                      rules={[{ required: true, message: 'Podaj nazwę' }]}
                      {...formItemLayout}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name='use_portions'
                      label={this.props.t('Przepis jest na ilość porcji')}
                      {...formItemLayout}
                    >
                      <Switch checked={this.state.usePortions} onChange={this.handleUsePortions} />
                    </Form.Item>

                    {this.state.usePortions &&
                      <Form.Item
                        name='portions'
                        label={this.props.t('Ilość porcji')}
                        rules={[
                          { required: true, message: 'Podaj ilość porcji' },
                          () => ({
                            validator (_, value) {
                              if (value === 0) {
                                return Promise.reject(new Error('Podaj ilość porcji'))
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber />
                      </Form.Item>}
                  </Card>

                  <Form.List
                    name='used_ingredients'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj użyte składniki'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Użyte składniki'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={10} lg={10}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'id']}
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Użyty składnik'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'ingId']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Wybierz z listy zakupionych składników' }]}
                                  >
                                    <Select
                                      className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Wybierz składnik' : ''}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                    >
                                      {
                                        this.props.pantryIngredients?.map((ing, n) =>
                                          ing.name && <Option key={n} value={ing.id}>{ing.name}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>

                              <Col xs={12} md={4} lg={4}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Jednostka'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unit']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    style={{ minWidth: '70px' }}
                                    rules={[{ required: true, message: 'Wybierz jednostkę miary' }]}
                                  >
                                    <Select
                                      className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Jednostka' : ''}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                    >
                                      {
                                        this.getIngUnits(name).map((ing, n) =>
                                          <Option key={n} value={ing}>{ing}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>

                              <Col xs={9} md={4} lg={4}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Ilość'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj ilość' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-number' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Ilość' : ''}
                                      min={0}
                                      max={10000}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus(name)}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                <Popconfirm
                                  title='Czy na pewno chcesz usunąć ten element?'
                                  onConfirm={() => remove(name)}
                                  {...popconfirmItemDeleteProps}
                                >
                                  <MinusCircleOutlined
                                    className='dynamic-delete-button'
                                    title='Usuń'
                                  />
                                </Popconfirm>
                              </Col>

                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                        <Button
                          type='secondary'
                          onClick={() => { add(); this.handleUsedIngredientsFocus(fields?.length) }}
                          icon={<PlusOutlined />}
                        >
                          Dodaj użyty składnik
                        </Button>
                      </Card>
                    )}
                  </Form.List>
                </Space>
              </Col>
            </Row>
            <Button htmlType='submit' type='primary' size='large' disabled={this.state.loading}>
              {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default withTranslation()(RecipeForm)
