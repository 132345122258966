import React from 'react'
import StyledTrainingIndex from '../../styledComponents/training'
import { Redirect } from 'react-router'
import { getTrainings, getTrainingAuthors, getTrainingCategories } from '../../utils/actions'
import { connect } from 'react-redux'

class TrainingIndex extends React.Component {
  state = {
    makeRedirection: false,
    trainings: [],
    filteredTrainings: [],
    authors: [],
    trainingCategories: [],
    loaded: false,
    initialSearch: undefined
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    this._isMounted = true

    this.getTrainings()
    this.getTrainingAuthors()
    this.getTrainingCategories()

    if(this.props.location?.state?.trainingSearch || this.props.location?.state?.trainingFilter) {
      this.setState({ initialSearch: this.props.location?.state?.trainingSearch, initialFilter: this.props.location?.state?.trainingFilter })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.initialSearch !== this.props.initialSearch || prevProps.initialFilter !== this.props.initialFilter) {
      this.setState({ initialSearch: this.props.location?.state?.trainingSearch, initialFilter: this.props.location?.state?.trainingFilter })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getTrainings = () => {
    getTrainings().then((json) => {
      if (this._isMounted) {
        this.setState({ trainings: json.trainings, filteredTrainings: json.trainings, loaded: true })
      }
    })
  }

  getTrainingCategories = () => {
    getTrainingCategories().then((json) => {
      if (this._isMounted) {
        this.setState({ trainingCategories: json.training_categories })
      }
    })
  }

  getTrainingAuthors = () => {
    getTrainingAuthors().then((json) => {
      if (this._isMounted) {
        this.setState({ authors: json.authors })
      }
    })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  filterTrainings = (filter) => {
    const { searchedFor } = this.state

    const trainings = searchedFor ? this.filterTrainingsForSearch(this.state.trainings, searchedFor) : this.state.trainings
    const filteredTrainings = this.filterTrainingsRaw(trainings, filter)

    this.setState({ filteredTrainings, filteredFor: filter })
  }

  filterTrainingsRaw = (trainings, filter) => {
    return trainings.filter(training => {
      let ret = []

      for (const key of Object.keys(filter)) {
        if (training[key] === undefined || filter[key] === undefined || filter[key].length === 0 || filter[key].includes(
          typeof training[key] === 'object' ? training[key].id : String(training[key])
        ) || (Array.isArray(training[key]) && training[key].some(obj => filter[key].includes(obj.id)))
        ) {
          ret.push(true)
        } else {
          ret.push(false)
        }
      }

      return ret.every(element => element === true)
    })
  }

  searchTrainings = (data) => {
    const { filteredFor } = this.state

    const trainings = filteredFor ? this.filterTrainingsRaw(this.state.trainings, filteredFor) : this.state.trainings
    const filteredTrainings = this.filterTrainingsForSearch(trainings, data.search)

    this.setState({ filteredTrainings, searchedFor: data.search })
  }

  filterTrainingsForSearch = (trainings, search) => {
    return trainings.filter(training => new RegExp(search, 'gi').test(training.title))
  }

  render () {
    const { makeRedirection, filteredTrainings } = this.state
    const { authenticated } = this.props

    return (
      makeRedirection ? (
        <Redirect to={authenticated ? '/panel' : '/'} />
      ) : (
        <StyledTrainingIndex
          makeRedirection={this.makeRedirection}
          data={filteredTrainings}
          redirect={this.redirect}
          loaded={this.state.loaded}
          authors={this.state.authors}
          trainingCategories={this.state.trainingCategories}
          filterTrainings={this.filterTrainings}
          searchTrainings={this.searchTrainings}
          initialSearch={this.state.initialSearch}
          initialFilter={this.state.initialFilter}
          searchedFor={this.state.searchedFor}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const TrainingIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingIndex)

export default TrainingIndexContainer
