import { Menu } from 'antd'
import React from 'react'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
// import { Link as LinkScroll } from 'react-scroll'
import { Link, withRouter } from 'react-router-dom'

class StyledMenuMain extends React.Component {
  hideMobileMenu = () => {
    this.props.onHideMobileMenu()
  }

  render () {
    const { isMobile, activeModules, userRoles } = this.props

    return (
      this.props.isAuthenticated ? (
        <Menu mode={!isMobile ? 'horizontal' : 'inline'} onClick={isMobile && this.hideMobileMenu} disabledOverflow>
          <Menu.Item key='desktop'>
            <Link to='/panel'>PULPIT</Link>
          </Menu.Item>

          {activeModules?.includes('trainings') &&
            <Menu.SubMenu key='trainings' title='SZKOLENIA'>
              <Menu.Item key='training'>
                <Link to='/training'>Przeglądaj szkolenia</Link>
              </Menu.Item>
              <Menu.Item key='trainers'>
                <Link to='/training/author'>Nasze trenerki</Link>
              </Menu.Item>
            </Menu.SubMenu>}
          {/*<Menu.Item key='recipes'>
          <Link to='/przepisy'>PRZEPISY</Link>
          </Menu.Item>*/}
          {/*<Menu.Item key='calculator'>
            <Link to='/calculators'>Kalkulatory</Link>
          </Menu.Item>*/}

          <Menu.SubMenu key='calculators' title='KALKULATORY'>
            <Menu.Item key='calc-portions'>
              <Link to='/calculator-portions'>Kalkulator porcji</Link>
            </Menu.Item>
            <Menu.Item key='calc'>
              <Link to='/calculator'>Kalkulator do wycen</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key='tools' title='NARZĘDZIA'>
            <Menu.Item key='myrecipes'>
              <Link to='/myrecipes'>Moje przepisy</Link>
            </Menu.Item>
            <Menu.Item key='ai'>
              <Link to='/inspiracje-ai'>Inspiracje AI</Link>
            </Menu.Item>
            <Menu.Item key='enlarge'>
              <Link to='/powieksz-grafike'>Powiększanie grafik</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key='addons' title='DODATKI'>
            <Menu.Item key='toppers'>
              <Link to='/topper'>Projekty topperów</Link>
            </Menu.Item>
            <Menu.Item key='printouts'>
              <Link to='/grafiki-do-wydrukow'>Grafiki do wydruków</Link>
            </Menu.Item>
            <Menu.Item key='partners'>
              <Link to='/promotions'>Partnerzy i kody rabatowe</Link>
            </Menu.Item>
            <Menu.Item key='order'>
              <Link to='/order'>System zamówień</Link>
            </Menu.Item>
          </Menu.SubMenu>

          {/*<Menu.Item key='toppers'>
            <Link to='/topper'>Projekty topperów</Link>
          </Menu.Item>*/}

          <Menu.SubMenu
            key='profile'
            className='menu-profile'
            title={<><UserOutlined /> MOJE KONTO</>}
            // title={isMobile ? 'Moje konto' : <UserOutlined />}
          >
            <Menu.Item key='edit-profile'>
              <Link to='/profile'>Edytuj profil</Link>
            </Menu.Item>
            {userRoles?.includes('ROLE_TRAINING_AUTHOR') &&
              <Menu.Item key='certificate'>
                <Link to='/certificate'>Zgłoszenia o certyfikaty</Link>
              </Menu.Item>}
            <Menu.Item key='help'>
              <Link to='/help'>Pomoc</Link>
            </Menu.Item>
            <Menu.Item key='community'>
              <Link to='/community'>Społeczności</Link>
            </Menu.Item>
            <Menu.Item key='logout' onClick={this.props.handleLogout} icon={<LogoutOutlined />}>
              Wyloguj
            </Menu.Item>
            {/*!isMobile &&
              <Menu.Item key='regulations'>
                <Link to='/regulations'>Regulamin</Link>
              </Menu.Item>*/}
          </Menu.SubMenu>
          {/*isMobile &&
            <Menu.Item key='regulations'>
              <Link to='/regulations'>Regulamin</Link>
            </Menu.Item>*/}
        </Menu>
      ) : (
        <Menu mode={!isMobile ? 'horizontal' : 'inline'} onClick={isMobile && this.hideMobileMenu} disabledOverflow>
          {!window.matchMedia('(display-mode: standalone)').matches ? (
            <>
              <Menu.Item key='index'>
                <Link to='/'>START</Link>
              </Menu.Item>
              {activeModules?.includes('trainings') &&
                <Menu.SubMenu key='trainings' title='SZKOLENIA'>
                  <Menu.Item key='training'>
                    <Link to='/training'>Przeglądaj szkolenia</Link>
                  </Menu.Item>
                  <Menu.Item key='trainers'>
                    <Link to='/training/author'>Nasze trenerki</Link>
                  </Menu.Item>
                </Menu.SubMenu>}
              {/*<Menu.Item key='recipes'>
                <Link to='/przepisy'>Przepisy</Link>
              </Menu.Item>*/}

              <Menu.SubMenu key='calculators' title='KALKULATORY'>
                <Menu.Item key='calc-portions'>
                  <Link to='/calculator-portions'>Kalkulator porcji</Link>
                </Menu.Item>
                <Menu.Item key='calc'>
                  <Link to='/calculator'>Kalkulator do wycen</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key='tools' title='NARZĘDZIA'>
                <Menu.Item key='myrecipes'>
                  <Link to='/myrecipes'>Moje przepisy</Link>
                </Menu.Item>
                <Menu.Item key='ai'>
                  <Link to='/inspiracje-ai'>Inspiracje AI</Link>
                </Menu.Item>
                <Menu.Item key='enlarge'>
                  <Link to='/powieksz-grafike'>Powiększanie grafik</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key='addons' title='DODATKI'>
                <Menu.Item key='toppers'>
                  <Link to='/topper'>Projekty topperów</Link>
                </Menu.Item>
                <Menu.Item key='printouts'>
                  <Link to='/grafiki-do-wydrukow'>Grafiki do wydruków</Link>
                </Menu.Item>
                <Menu.Item key='partners'>
                  <Link to='/promotions'>Partnerzy i kody rabatowe</Link>
                </Menu.Item>
                <Menu.Item key='order'>
                  <Link to='/order'>System zamówień</Link>
                </Menu.Item>
              </Menu.SubMenu>
              {/*<Menu.Item key='index'>
                {this.props.location.pathname === '/' ? (
                  <LinkScroll to='Banner0_0' spy smooth offset={-70} duration={500}>Strona główna</LinkScroll>
                ) : (
                  <Link to='/panel'>Pulpit</Link>
                )}
              </Menu.Item>*/}
              {/*this.props.location.pathname === '/'
                ? (
                  <>
                    <Menu.Item key='modules'>
                      <LinkScroll to='Content0_0' spy smooth offset={-70} duration={500} onClick={isMobile && this.hideMobileMenu}>Dostępne funkcje</LinkScroll>
                    </Menu.Item>
                    <Menu.Item key='access'>
                      <LinkScroll to='Content1_0' spy smooth offset={-70} duration={500} onClick={isMobile && this.hideMobileMenu}>Jak uzyskać dostęp?</LinkScroll>
                    </Menu.Item>
                  </>
                ) : '' */}
            </>
          ) : ''}
          <Menu.Item key='login'>
            <Link to={{ pathname: '/login', state: { redirect: [this.props.location.pathname]}}}>LOGOWANIE</Link>
          </Menu.Item>
        </Menu>
      )
    )
  }
}

export default withRouter(StyledMenuMain)
