import React from 'react'
import { PageHeader, Result, Button } from 'antd'
import QueueAnim from 'rc-queue-anim'
import StyledUserResetPassword from '../../styledComponents/user/resetPasswordForm'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { joinNewsletter } from '../../utils/actions'
import { LoadingOutlined } from '@ant-design/icons'

class JoinNewsletter extends React.PureComponent {
  state = {
    requestSent: undefined
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    const { hash } = this.props.match.params

    joinNewsletter(hash).then(() => this.requestSent(true)).catch(() => this.requestSent(false))
  }

  requestSent = (requestSent) => {
    this.setState({ requestSent })
  }

  render () {
    const { makeRedirection, requestSent } = this.state
    const { authenticated, ...currentProps } = this.props

    return (
      <div key='content' className='banner1-content'>
      {requestSent ?
        <Result
          status="success"
          title="Udało się! Dziękujemy za dołączenie do newslettera :)"
          extra={[
            <Button key="index" onClick={() => this.redirect('/')}>
              Strona główna &raquo;
            </Button>,
            <Button type="primary" key="training">
              <a href='https://tort.strefacukiernika.pl/live'>Zapisz się na najbliższe darmowe szkolenie</a>
            </Button>
          ]}
        /> :
        (requestSent === false ? <><Result
          status="error"
          title="Ups! Wystąpił błąd"
          style={{ maxWidth: '600px', margin: '0 auto' }}
          subTitle="Niestety nie udało się zapisać automatycznie do newslettera. Jeśli chcesz możesz zapisać się korzystając z formularza poniżej lub zapisać sie tylko na nadchodzące szkolenie live."
          extra={[
            <Button type="primary" key="training">
              <a href='https://tort.strefacukiernika.pl/live'>Zapisz się na najbliższe darmowe szkolenie</a>
            </Button>
          ]}
        />
        <iframe width="100%" height="400" frameBorder="0" src="https://cdn.forms-content.sg-form.com/f2e771cd-9ac7-11ef-aee3-7adbd6f7a55e"/>

        </> : <LoadingOutlined spin={true} style={{ fontSize: '40px', marginTop: '50px' }} />)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const JoinNewsletterContainer = connect(
  mapStateToProps
)(JoinNewsletter)

export default withTranslation()(JoinNewsletterContainer)
