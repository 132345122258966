import React from 'react'
import StyledTrainingAdminIndex from '../../../styledComponents/admin/training'
import { getTrainings } from '../../../utils/actions'
import { connect } from 'react-redux'

class TrainingAdminIndex extends React.Component {
  state = {
    trainings: [],
    filteredTrainings: [],
    loaded: false
  }

  componentDidMount () {
    this._isMounted = true

    this.getTrainings()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getTrainings = () => {
    getTrainings().then((json) => {
      if (this._isMounted) {
        this.setState({ trainings: json.trainings, filteredTrainings: json.trainings, loaded: true })
      }
    })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  // filterTrainings = (filter) => {
  //   const { searchedFor } = this.state
  //
  //   const trainings = searchedFor ? this.filterTrainingsForSearch(this.state.trainings, searchedFor) : this.state.trainings
  //   const filteredTrainings = this.filterTrainingsRaw(trainings, filter)
  //
  //   this.setState({ filteredTrainings, filteredFor: filter })
  // }
  //
  // filterTrainingsRaw = (trainings, filter) => {
  //   return trainings.filter(training => {
  //     let ret = []
  //
  //     for (const key of Object.keys(filter)) {
  //       if (training[key] === undefined || filter[key] === undefined || filter[key].length === 0 || filter[key].includes(
  //         typeof training[key] === 'object' ? training[key].id : String(training[key])
  //       ) || (Array.isArray(training[key]) && training[key].some(obj => filter[key].includes(obj.id)))
  //       ) {
  //         ret.push(true)
  //       } else {
  //         ret.push(false)
  //       }
  //     }
  //
  //     return ret.every(element => element === true)
  //   })
  // }
  //
  // searchTrainings = (data) => {
  //   const { filteredFor } = this.state
  //
  //   const trainings = filteredFor ? this.filterTrainingsRaw(this.state.trainings, filteredFor) : this.state.trainings
  //   const filteredTrainings = this.filterTrainingsForSearch(trainings, data.search)
  //
  //   this.setState({ filteredTrainings, searchedFor: data.search })
  // }

  // filterTrainingsForSearch = (trainings, search) => {
  //   return trainings.filter(training => new RegExp(search, 'gi').test(training.title))
  // }

  render () {
    const { filteredTrainings } = this.state
    const { authenticated } = this.props

    return (
      <StyledTrainingAdminIndex
        makeRedirection={this.makeRedirection}
        data={filteredTrainings}
        redirect={this.redirect}
        loaded={this.state.loaded}
        authors={this.state.authors}
        trainingCategories={this.state.trainingCategories}
        filterTrainings={this.filterTrainings}
        searchTrainings={this.searchTrainings}
        initialSearch={this.state.initialSearch}
        initialFilter={this.state.initialFilter}
        searchedFor={this.state.searchedFor}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const TrainingAdminIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingAdminIndex)

export default TrainingAdminIndexContainer
