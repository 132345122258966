import React from 'react'
import StyledTrainingShow from '../../styledComponents/training/show'
import { Redirect } from 'react-router'
import {
  getTraining, updateTrainingPlay, downloadTrainingFile, downloadCertificate, getUserProfile, getChatMessagesForTraining,
  certificateSubmitRequest, updateUserProfile
} from '../../utils/actions'
import TrainingOpinionModal from './opinionModal'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import ModuleNotLogged from '../main/notLogged'
import ModuleNoAccess from '../main/noAccess'
import { /*saveDeviceData, getDeviceData,*/ openNotification, getUrlParameter } from '../../utils/helpers'
import CtaModalMany from '../../styledComponents/common/ctaModalMany'
import { setCtaModalVisible } from '../../store/actions'
import settings from '../../config'
import { Link } from 'react-router-dom'

import { io } from 'socket.io-client'

const URL = process.env.NODE_ENV === 'production' ? '/' : (settings.websocket_dev_url || 'http://localhost:3001');

let socket

const FileDownload = require('js-file-download')

class TrainingShow extends React.Component {
  state = {
    makeRedirection: false,
    id: undefined,
    training: undefined,
    opinionModalVisible: false,
    opinionNotNow: false,
    downloadingFile: false,
    liveStatus: null,
    messages: [],
    username: '',
    socket: null,
    reply: null,
    upsellConfirmation: false,
    justUploaded: undefined
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  updateUserProfile = (data) => {
    this.setState({ formSaving: true })

    updateUserProfile(data).then((json) => {
      this.getUserProfile(this.state.scrollToBottom)
    }).catch(() => {
    })
  }

  getUserProfile = (callAfter) => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json }, callAfter || undefined)
      }
    })
  }

  getChatMessagesForTraining = (id) => {
    getChatMessagesForTraining(id).then((json) => {
      if (this._isMounted) {
        this.setState({ messages: json.messages }, () => {
          this.state.scrollToBottom && this.state.scrollToBottom(true)
        })
      }
    })
  }

  setOpinionModalVisible = (opinionModalVisible) => {
    if (!this.state.training.trial) {
      this.setState({ opinionModalVisible })

      opinionModalVisible && ReactGA.event({ category: 'Training', action: 'Opinion Modal Opened For Training: ' + this.state.id })
    }
  }

  setOpinionNotNow = (opinionNotNow) => {
    this.setState({ opinionNotNow })

    opinionNotNow && ReactGA.event({ category: 'Training', action: 'Opinion Closed Not Now: ' + this.state.id })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  setJustUploaded = (justUploaded) => {
    this.setState({ justUploaded })
  }

  handleVisibilityChange = () => {
    if(document.visibilityState === 'visible' && this.state.training?.type === 'live' && this.props.authenticated) {
      this.getChatMessagesForTraining(this.state.training?.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.getUserProfile()
    }

    const { id } = this.props.match.params

    if(prevState.training && this.state.id !== id) {
      this.getData()
    }

    if (prevState.training === undefined && this.state.training && this.props.authenticated) {
      this.getUserProfile()
      this.getChatMessagesForTraining(this.state.training?.id)
      if(this.state.training.type === 'live' && this.state.training.live_status !== 'finished' && (!socket || !socket.connected)) {
        this.prepareSocket()
      }
    }
  }

  prepareSocket = () => {
    socket = io(URL, { transports: ['websocket'] }/*, {'reconnection': true,'reconnectionDelay': 500,'maxReconnectionAttempts':Infinity}*/)

    this.setState({ socket })

    socket.on('connect', (socket) => {
      console.log('connected')
      this.setState({ websocketConnected: true })

      if (!this.state.websocketInitialConnected) {
        this.setState({ websocketInitialConnected: true })
      } else {
        this.getChatMessagesForTraining(this.state.training?.id)
      }
    })

    socket.on('disconnect', (socket) => {
      console.log('disconnected', socket)
      this.setState({ websocketConnected: false })
    })

    socket.on('message', (statusUpdate) => {
      if (parseInt(statusUpdate?.training_id) === parseInt(this.state.training?.id)) {
        this.setState({ liveStatus: statusUpdate?.status }, (statusUpdate?.status === 'started' && this.state.scrollToBottom) || undefined)
      }
    })

    socket.on('chat message reaction', (trainingId, msgId, userId, reaction) => {
      const messages = this.state.messages
      const message = messages.find(x => x.id === msgId)

      if(message.reactions) {
        if(message.reactions[reaction]) {
          message.reactions[reaction] = message.reactions[reaction] + 1
        } else {
          message.reactions[reaction] = 1
        }
      } else {
        message.reactions = {}
        message.reactions[reaction] = 1
      }

      this.setState({ messages })
    })

    socket.on('chat message', (msgId, msg, username, date, trainingId, userId, reply, messageType, imageFilename) => {
      const messages = this.state.messages

      messages.push({ id: msgId, text: msg, username, date, training_id: trainingId, user_id: userId, reply, message_type: messageType, image_filename: imageFilename })

      this.setState({ messages }, () => {
        this.state.scrollToBottom && this.state.scrollToBottom(userId === this.state.user?.id)
      })
    })
  }

  getData = () => {
    const { id } = this.props.match.params

    this.setState({ id })
    this.getTraining(id)
  }

  componentDidMount () {
    this._isMounted = true

    const upsell = getUrlParameter('upsell')
    const onlyChat = getUrlParameter('onlyChat')

    if (upsell) {
      this.setState({ upsellConfirmation: true })
    }
    if (onlyChat) {
      this.setState({ onlyChat: true })
    }

    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    this.getData()
    // if (this.props.authenticated) {
    //   this.getUserProfile()
    //   if (!socket || !socket.connected) {
    //     this.prepareSocket()
    //   }
    // }
  }

  sendMessage = (values) => {
    const { justUploaded, reply } = this.state

    if (reply?.message?.props?.children?.length && typeof(reply.message?.props?.children[0]) === 'object') {
      reply.message = 'zdjęcie'
    } else if (reply?.message?.props?.children?.length && typeof(reply.message?.props?.children[0]) === 'string') {
      reply.message = reply.message?.props?.children[0]
    }

    if (values.message || justUploaded) {
      socket.emit('chat message', values.message, this.state.user?.nick, new Date(), this.state.training?.id, this.state.user?.id, this.state.reply, (justUploaded && 'image') || 'text', justUploaded)
      this.formRef?.current?.setFieldsValue({ message: '' })
      this.setState({ justUploaded: undefined }, () => this.setReply(undefined) )
    }
  }

  sendReaction = (msgId, reaction) => {
    socket.emit('chat message reaction', this.state.training?.id, msgId, this.state.user?.id, reaction)
  }

  removeFile = () => {
    this.setState({ justUploaded: undefined })
  }

  setUsername = (values, skipSaveToLocalStorage) => {
    this.updateUserProfile({ nick: values.username })
  }

  setReply = (reply) => {
    this.setState({ reply })
  }

  setScrollToBottom = (scrollToBottom) => {
    this.setState({ scrollToBottom })
  }

  componentWillUnmount () {
    this._isMounted = false

    this.state.socket?.disconnect()
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  setAddedOpinion = (addedOpinion) => {
    this.setState({ addedOpinion })
  }

  getTraining = (id) => {
    getTraining(id).then((json) => {
      if (this._isMounted) {
        this.setState({ training: json, liveStatus: json.live_status })

        if (this.state.onlyChat && !this.props.authenticated) {
          this.redirect({
            pathname: '/login',
            state: { redirect: ['/training/' + json?.slug + '?onlyChat=1'] }
          })
        }
      }
    })
  }

  updateTrainingPlay = (playTime, finished, playedSeconds) => {

    let tmpKey = this.state.tmpKey

    if (!this.props.authenticated) {
      if (!tmpKey) {
        tmpKey = Math.random().toString(36).slice(2, 7);
        this.setState({ tmpKey })
      }
    }

    updateTrainingPlay(this.state.training.id, playTime, finished, playedSeconds, tmpKey).then((json) => {})
  }

  downloadFile = (file) => {
    const { authenticated } = this.props
    const { training, user } = this.state

    if(!authenticated ||
    (authenticated && ((training?.type === 'sub' && user?.sub_type === 'free' && !user?.trial_used && !training?.has_private_perms) ||
    (training?.type === 'sub' && user?.sub_type === 'free' && user?.trial_used && !training?.has_private_perms) //||
     // ||
    // (singleData.type === 'sub' && authenticated && user?.sub_type === 'sub1')
    ))) {
      this.props.setCtaModalVisible(true)
    } else {
      this.setState({ downloadingFile: true })

      downloadTrainingFile(file.id).then((json) => {
        if (this._isMounted) {
          this.setState({ downloadingFile: false })

          FileDownload(json.data, file.name + '.' + file.file_type)
        }
      })
    }
  }

  submitCertificateRequest = (data, callback) => {
    certificateSubmitRequest(this.state.training?.id, data).then((json) => {
      if (this._isMounted) {
        this.getTraining(this.state.training?.id)
        callback && callback()
        openNotification('Sukces', 'Pomyślnie przesłano zgłoszenie o certyfikat')
      }
    })
  }

  downloadCertificate = (type, text) => {
    this.setState({ downloadingFile: true })
    downloadCertificate(this.state.training.id, type, text).then((json) => {
      if (this._isMounted) {
        this.setState({ downloadingFile: false })

        const niceType = type === 'printinghouse' ? 'druk-w-drukarni' : 'druk-samodzielny'

        FileDownload(json.data, 'certyfikat-' + niceType + '.pdf')
      }
    }).catch((err) => {
      this.setState({ downloadingFile: false })
    })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { makeRedirection, training, user } = this.state
    const isApple = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)

    const hasPro = !(!authenticated ||
    (authenticated && ((training?.type === 'sub' && user?.sub_type === 'free' && !user?.trial_used && !training?.has_private_perms) ||
    (training?.type === 'sub' && user?.sub_type === 'free' && user?.trial_used && !training?.has_private_perms) //||
    )))

    const content = []
    // let text
    // let toRoute
    // let redirect
    // let requiredSubs
    // let buttonText
    // let buttonTextSize
    // let buttonType
    // let buttonCallback

    let notLogged

    if (['sub'].includes(training?.type) && (!user || user?.sub_type === 'free')) {
      notLogged = !authenticated && (<div style={{ textAlign: 'center' }}>{training?.purchase_url ? 'Jeśli już kupiłaś to szkolenie lub masz subskrypcję' : 'Jeśli już masz subskrypcję'}, <Link to={{
          pathname: '/login',
          state: { redirect: ['/training/' + training?.slug] }
        }}>zaloguj się</Link></div>)

      content.push({
        key: 'sub',
        text: <>To szkolenie jest dostępne bez dodatkowych opłat dla subskrybentów Strefy Cukiernika.<br/><span style={{ fontWeight: 700 }}>Uzyskaj dostęp do tego i kolejnych szkoleń za jedyne {settings.subscription_price_gross / 100} zł miesięcznie.</span></>,
        toRoute: settings.subscription_purchase_url,
        buttonText: 'Kupuję subskrypcję'
      })

      if(training?.purchase_url) {
        content.push({
          key: 'onetime',
          text: <span style={{ fontWeight: 700 }}>Kup to jedno szkolenie za {training?.purchase_price_gross / 100} zł</span>,
          toRoute: training?.purchase_url,
          buttonText: 'Kupuję to szkolenie'
        })
      }
    }

    if (['live'].includes(training?.type) && (!user || user?.sub_type === 'free')) {
      notLogged = !authenticated && (<div style={{ textAlign: 'center' }}>{training?.purchase_url ? 'Jeśli już kupiłaś to szkolenie lub masz subskrypcję' : 'Jeśli już masz subskrypcję'}, <Link to={{
          pathname: '/login',
          state: { redirect: ['/training/' + training?.slug] }
        }}>zaloguj się</Link></div>)

      content.push({
        key: 'sub',
        text: 'To szkolenie LIVE jest dostępne bez dodatkowych opłat dla subskrybentów Strefy Cukiernika lub osób, które do niego dołączyły.',
        toRoute: training?.purchase_url,
        buttonText: 'Dołączam do szkolenia'
      })
    }

    if (training?.type === 'free' && !authenticated) {
      content.push({
        key: 'join',
        text: 'To szkolenie jest za darmo dla zalogowanych użytkowników',
        toRoute: '/register',
        buttonText: 'Dołącz do Strefy Cukiernika',
        redirect: ['/training/' + training?.slug]
      })
    }

    return (
      makeRedirection ? (
        <Redirect to='/training' />
      ) : (training && (
        training.status === 'is_private' ? (!this.props.authenticated ? <ModuleNotLogged
          title={'Zaloguj się aby uzyskać dostęp do szkolenia ' + training.title}
          redirect={this.redirect}
          redirectRoute={this.props.location?.pathname}
        /> :
          <ModuleNoAccess
            title={'Nie masz dostępu do szkolenia: ' + training.title}
            content={
              <>
                <p>Jeśli to błąd i powinieneś/aś mieć dostęp do tego szkolenia - napisz do nas na <a href='mailto:kontakt@strefacukiernika.pl'>kontakt@strefacukiernika.pl</a></p>
              </>
            }
            redirect={this.redirect}
          />
         ) : <>
         <CtaModalMany
          content={content}
          before={notLogged}
           // text={text}
           // toRoute={toRoute}
           // redirect={redirect}
           // requiredSubs={requiredSubs}
           // buttonText={buttonText}
           // buttonTextSize={buttonTextSize}
           // buttonType={buttonType}
           // buttonCallback={buttonCallback}
         />
          <TrainingOpinionModal
            visible={this.state.opinionModalVisible}
            setModalVisibility={this.setOpinionModalVisible}
            setOpinionNotNow={this.setOpinionNotNow}
            isMobile={isMobile}
            trainingId={this.state.training.id}
            setAddedOpinion={this.setAddedOpinion}
          />
          <StyledTrainingShow
            makeRedirection={this.makeRedirection}
            redirect={this.redirect}
            setModalVisibility={this.setOpinionModalVisible}
            singleData={training}
            isMobile={isMobile}
            updateTrainingPlay={this.updateTrainingPlay}
            downloadFile={this.downloadFile}
            downloadCertificate={this.downloadCertificate}
            opinionNotNow={this.state.opinionNotNow}
            downloadingFile={this.state.downloadingFile}
            authenticated={this.props.authenticated}
            user={this.state.user}
            addedOpinion={this.state.addedOpinion}
            liveStatus={this.state.liveStatus}
            handleSubmit={this.sendMessage}
            sendReaction={this.sendReaction}
            messages={this.state.messages}
            reply={this.state.reply}
            setUsername={this.setUsername}
            setReply={this.setReply}
            username={this.state.username}
            setScrollToBottom={this.setScrollToBottom}
            formRef={this.formRef}
            submitCertificateRequest={this.submitCertificateRequest}
            upsellConfirmation={this.state.upsellConfirmation}
            setCtaModalVisible={this.props.setCtaModalVisible}
            setJustUploaded={this.setJustUploaded}
            justUploaded={this.state.justUploaded}
            removeFile={this.removeFile}
            chatConnected={this.state.websocketConnected}
            isModerator={this.props.userRoles?.includes('ROLE_ADMIN')}
            isApple={isApple}
            hasPro={hasPro}
            onlyChat={this.state.onlyChat}
          />
        </>
      )) || null
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  userRoles: state.appRedux.userRoles
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const TrainingShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingShow)

export default TrainingShowContainer
