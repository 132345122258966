import React from 'react'
import { PageHeader, List, Typography, Button, Skeleton } from 'antd'
import { Link } from 'react-router-dom'

const { Paragraph } = Typography

class StyledPromoIndex extends React.Component {
  render () {
    const { makeRedirection, partners, loaded, isMobile } = this.props

    return (
      <>
        <PageHeader
          title='Zaufane sklepy i twórcy'
          onBack={() => makeRedirection()}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <List
          itemLayout='vertical'
          size='large'
          className='promo-list list'
          dataSource={partners}
          style={{ display: loaded ? 'block' : 'none' }}
          renderItem={item => (
            <List.Item
              key={item.title}
              extra={
                <Link to={'/promotions/' + item.id}>
                  <img
                    width={272}
                    alt={item.title + ' logo'}
                    src={'/images/partners/' + item.logo}
                  />
                </Link>
              }
            >
              <List.Item.Meta
                title={<Link to={'/promotions/' + item.id}>{item.title}</Link>}
              />
              <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ maxWidth: '500px', margin: '20px auto' }}>
                {item.short_description || item.description}
              </Paragraph>
              <Button><Link to={'/promotions/' + item.id}>Pokaż więcej</Link></Button>
            </List.Item>
          )}
        />
      </>
    )
  }
}

export default StyledPromoIndex
