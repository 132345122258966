import React from 'react'
import StyledPromoShow from '../../styledComponents/promo/show'
import { Redirect } from 'react-router'
import { savePromoUse, getPartner, getPartnerCodes } from '../../utils/actions'
import { connect } from 'react-redux'

class PromoShow extends React.Component {
  state = {
    makeRedirection: false,
    singleData: undefined,
    loaded: false,
    codes: []
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    this._isMounted = true
    const id = this.props.match.params.id

    this.getPartner(id)
  }

  getPartner = (id) => {
    getPartner(id).then((json) => {
      if (this._isMounted) {
        this.setState({ singleData: json, loaded: true })
      }
    })
  }

  getPartnerCodes = (id, after) => {
    getPartnerCodes(id).then((json) => {
      if (this._isMounted) {
        this.setState({ codes: json.codes }, after)
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, singleData, loaded, codes } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/partnerzy' />
      ) : (
        <StyledPromoShow
          makeRedirection={this.makeRedirection}
          singleData={singleData}
          savePromoUse={savePromoUse}
          isMobile={isMobile}
          loaded={loaded}
          getPartnerCodes={this.getPartnerCodes}
          codes={codes}
          authenticated={this.props.authenticated}
        />
      ) || null
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const PromoShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoShow)

export default PromoShowContainer
