import React from 'react'
import { connect } from 'react-redux'

class Help extends React.Component {
  render () {
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <>
        <div className='static-content'>
          <h2 style={{ textAlign: 'center' }}>Potrzebujesz pomocy z aplikacją? Napisz do nas!</h2>

          <p>
            Skontaktuj się z nami przez naszą grupę FB:{' '}
            <a
              href='https://www.facebook.com/groups/1719093458165351'
              target='_blank'
              rel='noopener noreferrer'
            >
              Torty i wypieki - Inspiracje Cukiernicze
            </a>
          </p>
          <ul style={{ marginLeft: '20px' }}>
            <li>
              Napisz post z pytaniem i oznacz tagiem #StrefaCukiernika, wtedy my i inni użytkownicy będziemy mogli Ci pomóc
            </li>
            <li>
              Napisz bezpośrednio do administratorów/moderatorów grupy
            </li>
          </ul>
          <p>lub napisz na email: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>
        </div>
        {this.props.authenticated && <div>Wersja aplikacji: {this.props.version}</div>}
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
})

const mapDispatchToProps = {
}

const HelpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Help)

export default HelpContainer
