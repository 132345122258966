/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Select, Row, Col, Card, InputNumber,
  Result, Popconfirm, Skeleton, Divider, TimePicker,
  Collapse, DatePicker, Table, Switch, Empty, Modal, Alert
} from 'antd'
import { DatePicker as DatePickerMobile, Picker } from 'antd-mobile'

import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { niclyFormatPortions } from '../../utils/helpers'
import HelpBox from '../common/helpBox'
import moment from 'moment'
import 'moment/locale/pl'
import locale from 'antd/es/date-picker/locale/pl_PL'
import { Link } from 'react-router-dom'

import FloatLabel from '../common/floatLabel'

const { Panel } = Collapse
const { warning } = Modal

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 12,
    lg: 12
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

const resultsItemsDivider = {
  orientationMargin: '40px',
  style: { margin: '4px 0 4px 0' }
}

const { Option, OptGroup } = Select

class OrderForm extends React.Component {
  state = {
    sumOfCosts: 0,
    priceToClient: 0,
    initialValues: undefined,
    earnings: 0,
    loaded: false,
    visibleHelpUsedRecipes: false,
    visibleHelpCapturedTime: false,
    visibleHelpParameters: false,
    descBoxVisible: false,
    showCalculation: false,
    useDefaultHPrice: true,
    useDefaultMargin: true,
    useDefaultOverhead: true,
    error: undefined,
    calcDetails: {},
    usePortions: false,
    useDeposit: false,
    recipesMatching: [],
    recipesNotMatching: [],
    toDatePickerVisible: false,
    toTimePickerVisible: false,
    loading_calculation: false,
    loading: false,
    calcType: 'overhead',
    formDisabled: false,
    partialFormDisabled: false
  }

  formRef = React.createRef()
  resultRef = React.createRef()
  // mobileDropdownRef = React.createRef()

  showUpgradeModal = () => {
    ReactGA.event({ category: 'Orders Form', action: 'Upgrade Modal Opened' })

    warning({
      title: 'Wyczerpany został limit zamówień',
      content: 'W miesiącu, na który dodajesz zamówienie wyczerpany został limit zamówień (10). Potrzebujesz dodać więcej zamówień? Napisz do nas na kontakt@strefacukiernika.pl i zapytaj jakie są możliwości.',
      onOk () {
        ReactGA.event({ category: 'Orders Form', action: 'Upgrade Modal Closed' })
        // console.log('OK');
      }
    })
  }

  setVisibleHelpUsedRecipes = (flag) => {
    this.setState({ visibleHelpUsedIngredients: flag })

    flag && ReactGA.event({ category: 'Orders Form', action: 'VisibleHelpUsedRecipes Help Opened Mobile' })
  }

  setVisibleHelpCapturedTime = (flag) => {
    this.setState({ visibleHelpCapturedTime: flag })

    flag && ReactGA.event({ category: 'Orders Form', action: 'VisibleHelpCapturedTime Help Opened Mobile' })
  }

  setVisibleHelpParameters = (flag) => {
    this.setState({ visibleHelpParameters: flag })

    flag && ReactGA.event({ category: 'Orders Form', action: 'VisibleHelpParameters Help Opened Mobile' })
  }

  // setDescBoxVisible = (flag, device) => {
  //   this.setState({ descBoxVisible: flag })
  //   this.mobileDropdownRef?.current?.close()
  //
  //   flag && ReactGA.event({ category: 'Pricing Calculator', action: 'Description Box Opened ' + device })
  // }

  // doCalc = (x) => {
  //   const { ordersConfig } = this.props
  //   const sumOfFixedCosts = ordersConfig && ordersConfig.fixed_costs && ordersConfig.fixed_costs.length && ordersConfig.fixed_costs.map(item => item.value).reduce((prev, next) => prev + next)
  //   const cakesPerMonth = ordersConfig.cakes_per_month || 0
  //
  //   if (sumOfFixedCosts === 0 || cakesPerMonth === 0) {
  //     this.setState({ error: <p>Ustawienia zamówień nie zostały wypełnione, jest to niezbędny krok aby formularz poprawnie obliczał ceny wypieków.<br /><Link to='/order/config'>Przejdź do ustawień</Link></p> })
  //     return -1
  //   }
  //
  //   const costPerCake = (sumOfFixedCosts && parseFloat(sumOfFixedCosts / cakesPerMonth)) || 0
  //   let costOfIngredients = 0
  //   let costOfTime = 0
  //
  //   x.used_recipes?.forEach((item, i) => {
  //     const recipe = this.props.recipes.find(x => x.id === item.ingId)
  //     costOfIngredients += recipe?.cost
  //   })
  //
  //   const hPrice = (x.useDefaultHPrice === false && x.h_price) || ordersConfig.h_price
  //
  //   x.captured_time?.forEach((item, i) => {
  //     let timeCost = 0
  //
  //     if (item.unit === 'minuta') {
  //       timeCost = (hPrice / 60) * item.quantity
  //     } else {
  //       timeCost = hPrice * item.quantity
  //     }
  //
  //     if (!isNaN(timeCost)) {
  //       costOfTime += timeCost
  //     }
  //   })
  //
  //   const summaryCostWitTime = costPerCake + costOfIngredients + costOfTime
  //   const summaryCost = parseFloat((costPerCake + costOfIngredients).toFixed(2))
  //   const configMargin = (!x.useDefaultMargin && x.margin) || ordersConfig.margin
  //   const margin = summaryCostWitTime / ((100 - configMargin) / 100)
  //   const priceToClient = parseFloat(margin.toFixed(2))
  //   const earnings = parseFloat((costOfTime + (priceToClient - summaryCostWitTime)).toFixed(2))
  //
  //   const calcDetails = {
  //     costOfTime: parseFloat(costOfTime.toFixed(2)),
  //     costOfIngredients: parseFloat(costOfIngredients.toFixed(2)),
  //     margin: parseFloat((priceToClient - summaryCostWitTime).toFixed(2)),
  //     costPerCake: parseFloat(costPerCake.toFixed(2))
  //   }
  //
  //   // console.log('submit summaryCostWitTime', summaryCostWitTime)
  //   // console.log('submit summaryCost', summaryCost)
  //   // console.log('submit margin', margin)
  //   // console.log('submit priceToClient', priceToClient)
  //   // console.log('submit earnings', earnings)
  //
  //   this.setState({ sumOfCosts: summaryCost, priceToClient, earnings, calcDetails })
  // }

  handleCalculate = () => {
    this.formRef.current?.validateFields(['used_recipes'])
      .then(x => {
        this.formRef.current?.validateFields(['captured_time'])
          .then(x => {
            // this.formRef.current?.validateFields(['fixed_costs'])
            //   .then(x => {
            //     this.formRef.current?.validateFields(['h_price'])
            //       .then(x => {
            //         this.formRef.current?.validateFields(['cakes_per_month'])
            //           .then(x => {
            //             this.formRef.current?.validateFields(['margin'])
            //               .then(y => {
            // const x = this.formRef.current?.getFieldsValue()

            this.setState({ loading_calculation: true })

            ReactGA.event({ category: 'Orders Form', action: 'Calculation Made' })

            this.props.calculateAction(this.formRef.current?.getFieldsValue()).then((json) => {
              if (json.error) {
                ReactGA.event({ category: 'Orders Form', action: 'Config Not Set Error Showed On Calculate' })

                this.setState({ error: <p>Ustawienia zamówień nie zostały wypełnione, jest to niezbędny krok aby formularz poprawnie obliczał ceny wypieków.<br /><Link to='/order/config'>Przejdź do ustawień</Link></p> })
              } else {
                this.setState({
                  sumOfCosts: json.sum_of_costs,
                  priceToClient: json.price_to_client,
                  earnings: json.earnings,
                  calcDetails: json.calc_details,
                  showCalculation: true
                })
                this.resultRef?.current?.scrollIntoView({ behavior: 'smooth' })
              }
              this.setState({ loading_calculation: false })
            }).catch(error => {
              this.setState({ loading_calculation: false })
            })
          }).catch((x) => {
            this.formRef.current?.scrollToField('price_h')
          })
      })
      .catch(errorInfo => {
      })
  }

  handleSubmit = (x) => {
    this.setState({ loading: true })
    // ReactGA.event({ category: 'Pricing Calculator', action: 'Price Calculated' })

    this.props.formAction(this.formRef.current?.getFieldsValue()).then((json) => {
      if (json.error) {
        if (json.error === 'orderConfigNotFound') {
          this.setState({ error: <p>Ustawienia zamówień nie zostały wypełnione, jest to niezbędny krok aby formularz poprawnie obliczał ceny wypieków.<br /><Link to='/order/config'>Przejdź do ustawień</Link></p> })
          ReactGA.event({ category: 'Orders Form', action: 'Config Not Set Error Showed On Save' })
        } else if (json.error === 'tooManyOrdersUpgradeAccount') {
          this.showUpgradeModal()
        }
      } else {
        this.setState({ sumOfCosts: json.sum_of_costs, priceToClient: json.price_to_client, earnings: json.earnings, calcDetails: json.calc_details })

        this.setState({ showCalculation: true })
      }
      this.resultRef?.current?.scrollIntoView({ behavior: 'smooth' })
      this.setState({ loading: false })
      this.props.setFormUnsaved && this.props.setFormUnsaved(false)
    }).catch(error => {
      this.setState({ loading: false })
    })
  }

  // handleLoadExample = () => {
  //   const initialValues = {
  //     purchased_ingredients: [{ id: 2, name: 'mąka tortowa', unit: 'kg', price: 3.69, quantity: 1 }, { id: 3, name: 'jajka', unit: 'opak.', price: 10.99, quantity: 10 }, { id: 4, name: 'proszek do pieczenia', unit: 'g', price: 0.7, quantity: 15 }, { id: 5, name: 'cukier', unit: 'kg', price: 3.89, quantity: 1 }, { id: 6, name: 'aromat śmietankowy', unit: 'ml', price: 1.58, quantity: 9 }, { id: 7, name: 'aromat waniliowy', unit: 'ml', price: 1.58, quantity: 9 }, { id: 13, name: 'truskawki', unit: 'kg', price: 14, quantity: 1 }, { id: 14, name: 'śmietanka kremówka 30%', unit: 'ml', price: 8.09, quantity: 500 }, { id: 8, name: 'ser mascarpone', unit: 'g', price: 7.29, quantity: 250 }, { id: 9, name: 'żelatyna', unit: 'g', price: 2.75, quantity: 20 }, { id: 10, name: 'cukier waniliowy', unit: 'g', price: 0.39, quantity: 16 }, { id: 11, name: 'cukier puder', unit: 'g', price: 2.25, quantity: 400 }, { id: 12, name: 'galaretka truskawkowa w proszku', unit: 'g', price: 1.58, quantity: 77 }],
  //     used_ingredients: [{ unit: 'szt', ingId: 3, quantity: 6 }, { unit: 'g', ingId: 2, quantity: 170 }, { unit: 'łyżeczka', ingId: 4, quantity: 1 }, { unit: 'g', ingId: 5, quantity: 170 }, { unit: 'kropla', ingId: 6, quantity: 9 }, { unit: 'kropla', ingId: 7, quantity: 3 }, { unit: 'g', ingId: 13, quantity: 150 }, { unit: 'g', ingId: 14, quantity: 400 }, { unit: 'g', ingId: 8, quantity: 250 }, { unit: 'g', ingId: 13, quantity: 700 }, { unit: 'łyżeczka', ingId: 9, quantity: 6 }, { unit: 'kropla', ingId: 6, quantity: 3 }, { unit: 'łyżeczka', ingId: 10, quantity: 1 }, { unit: 'łyżka', ingId: 11, quantity: 6 }, { unit: 'g', ingId: 14, quantity: 250 }, { unit: 'g', ingId: 8, quantity: 250 }, { unit: 'g', ingId: 13, quantity: 700 }, { unit: 'łyżka', ingId: 11, quantity: 2 }, { unit: 'łyżeczka', ingId: 10, quantity: 2 }, { unit: 'g', ingId: 12, quantity: 40 }],
  //     captured_time: [{ name: 'pieczenie', unit: 'godzina', quantity: 1 }, { name: 'przygotowanie kremu', unit: 'godzina', quantity: 0.5 }, { name: 'składanie', unit: 'minuta', quantity: 45 }, { name: 'dekorowanie', unit: 'godzina', quantity: 1 }],
  //     fixed_costs: [{ name: 'wynajem lokalu', value: 1000 }, { name: 'media', value: 300 }],
  //     cakes_per_month: 35,
  //     h_price: 20,
  //     margin: 10
  //   }
  //
  //   this.formRef?.current?.setFieldsValue(initialValues)
  //   const purchasedIngredientsCounter = Math.max(...initialValues.purchased_ingredients.map(o => o.id)) + 1
  //
  //   this.setState({ purchasedIngredients: initialValues.purchased_ingredients, purchasedIngredientsCounter })
  //   this.formRef.current?.submit()
  //   // this.saveFormData()
  //   // this.mobileDropdownRef?.current?.close()
  //
  //   ReactGA.event({ category: 'Pricing Calculator', action: 'Example 1 Loaded' })
  // }

  // saveFormData = () => {
  //   if (this.state.timer) {
  //     return
  //   }
  // }

  handleClearForm = () => {
    const initialValues = {
      purchased_ingredients: [],
      used_ingredients: [],
      captured_time: [],
      fixed_costs: [],
      cakes_per_month: 0,
      h_price: 0,
      margin: 0,
      overhead: 0
    }

    this.formRef?.current?.setFieldsValue(initialValues)

    this.setState({
      purchasedIngredients: initialValues.purchased_ingredients,
      sumOfCosts: 0,
      priceToClient: 0
    })

    // this.saveFormData()
    // this.mobileDropdownRef?.current?.close()

    ReactGA.event({ category: 'Orders Form', action: 'Form Cleared' })
  }

  handleFormChange = () => {
    if (this.state.sumOfCosts !== 0 || this.state.priceToClient !== 0) {
      this.setState({ sumOfCosts: 0, priceToClient: 0, showCalculation: false })
      this.props.setFormUnsaved && this.props.setFormUnsaved(true)
    }
  }

  handleUsedRecipesFocus = (name) => {
    this.setState({ usedIngredientsFocus: name })
  }

  handleCapturedTimeFocus = (name) => {
    this.setState({ capturedTimeFocus: name })
  }

  getCapturedTimeUnit = (name) => {
    const unit = this.formRef.current?.getFieldValue('captured_time')[name]?.unit

    if (unit === 'godzina') {
      return ' godzin'
    } else if (unit === 'minuta') {
      return ' minut'
    }

    return ''
  }

  handleCalcDetailsChange = (data) => {
    !!data?.length && ReactGA.event({ category: 'Orders Form', action: 'Pricing Details Opened' })
  }

  prepareData = () => {
    const { formData, isNew, ordersConfig } = this.props

    if (formData) {
      if (!isNew) {
        formData.to_date = moment(formData.to_date, 'YYYY-MM-DD')
        formData.to_time = moment(formData.to_time, 'HH:mm:ss')
        formData.use_default_h_price = formData.use_default_h_price !== undefined ? formData.use_default_h_price : true
        formData.use_default_margin = formData.use_default_margin !== undefined ? formData.use_default_margin : true

        if (formData.use_default_h_price === true) {
          formData.h_price = ordersConfig.h_price
        }
        if (formData.use_default_margin === true) {
          formData.margin = ordersConfig.margin
        }

        if (!this.props.isNew) {
          this.setState({
            calcDetails: formData.calc_details,
            priceToClient: formData.price_to_client,
            earnings: formData.earnings,
            sumOfCosts: formData.sum_of_costs,
            showCalculation: true
          })
        }

        if (formData.use_deposit) {
          this.setState({ useDeposit: true })
        }

        if (formData.use_portions) {
          const recipesMatching = this.props.recipes.filter(x => x.use_portions && x.portions >= formData.portions && x.portions <= formData.portions + 2)
          const recipesNotMatching = this.props.recipes.filter(object1 => {
            return !recipesMatching.some(object2 => {
              return object1.id === object2.id
            })
          })

          this.setState({ recipesMatching, recipesNotMatching, usePortions: true, portions: formData.portions })
        }
      }

      const formDisabled = formData?.status === 'finished' || formData?.status === 'canceled'
      const partialFormDisabled = formData?.status === 'accepted'

      this.setState({
        loaded: true,
        useDefaultHPrice: !formData.use_default_h_price,
        useDefaultMargin: !formData.use_default_margin,
        useDefaultOverhead: !formData.use_default_overhead,
        calcType: (formDisabled || partialFormDisabled) ? formData.frozen?.used_params?.calc_type : ordersConfig.calc_type,
        formDisabled,
        partialFormDisabled
      })
      this.formRef.current.setFieldsValue(formData)
    }
  }

  componentDidMount () {
    this.prepareData()
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (prevProps.formData !== formData || prevProps.recipes !== this.props.recipes) {
      this.prepareData()
    }
  }

  handleUsePortions = (usePortions) => {
    if (usePortions) {
      this.formRef?.current?.setFieldsValue({ portions: 10 })
      this.setState({ portions: 10 })
    }

    this.setState({ usePortions })
  }

  handleUseDeposit = (useDeposit) => {
    this.setState({ useDeposit })
  }

  handlePortionsChange = (portions) => {
    this.setState(
      { recipesMatching: [], recipesNotMatching: [] },
      () => {
        const recipesMatching = this.props.recipes.filter(x => x.use_portions && x.portions >= portions && x.portions <= portions + 2)
        const recipesNotMatching = this.props.recipes.filter(object1 => {
          return !recipesMatching.some(object2 => {
            return object1.id === object2.id
          })
        })

        this.setState({ recipesMatching })

        this.setState({
          recipesMatching,
          recipesNotMatching,
          portions
        })
      })
  }

  handleToDatePickerVisible = (flag) => {
    this.setState({ toDatePickerVisible: flag })
  }

  handleToTimePickerVisible = (flag) => {
    this.setState({ toTimePickerVisible: flag })
  }

  handleToDatePicker = (e) => {
    this.formRef?.current?.setFieldsValue({ to_date: moment(e) })
    this.setState({ toDatePickerVisible: false })
  }

  handleToTimePicker = (e) => {
    this.formRef?.current?.setFieldsValue({ to_time: moment(e[0] + ':' + e[1], 'HH:mm') })
    this.setState({ toTimePickerVisible: false })
  }

  render () {
    const { isMobile, formData, ordersConfig, isNew } = this.props
    const { loaded, error, formDisabled, partialFormDisabled } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    // const menu = (
    //   <Menu selectable={false} className='calculator-actions-menu'>
    //     <Menu.Item
    //       key='desc'
    //       onClick={() => this.setDescBoxVisible(true, 'Mobile')}
    //     >
    //         Jak wyceniać wypieki?
    //     </Menu.Item>
    //     <Popconfirm
    //       title='Czy na pewno chcesz załadować przykładową kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
    //       onConfirm={this.handleLoadExample}
    //       {...popconfirmProps}
    //     >
    //       <Menu.Item key='load-example'>
    //           Załaduj przykładową kalkulację
    //       </Menu.Item>
    //     </Popconfirm>
    //     <Popconfirm
    //
    //       title='Czy na pewno chcesz wyczyścić obecną kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
    //       onConfirm={this.handleClearForm}
    //       {...popconfirmProps}
    //     >
    //       <Menu.Item key='clear-form'>
    //           Wyczyść formularz
    //       </Menu.Item>
    //     </Popconfirm>
    //   </Menu>
    // )
    //
    // const actionsMenuMobile = (
    //   <DropdownMobile ref={this.mobileDropdownRef}>
    //     <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
    //       {menu}
    //     </DropdownMobile.Item>
    //   </DropdownMobile>)
    //
    // const actions = (!isMobile && [
    //   <Button
    //     key='desc'
    //     onClick={() => this.setDescBoxVisible(true, 'Desktop')}
    //   >
    //     Jak wyceniać wypieki?
    //   </Button>,
    //   <Popconfirm
    //     key='load-example'
    //     title='Czy na pewno chcesz załadować przykładową kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
    //     onConfirm={this.handleLoadExample}
    //     {...popconfirmProps}
    //   >
    //     <Button>Załaduj przykładową kalkulację</Button>
    //   </Popconfirm>,
    //   <Dropdown
    //     key='clear-form'
    //     ref={this.mobileDropdownRef}
    //     overlay={
    //       <Menu selectable={false} className='calculator-actions-menu'>
    //         <Popconfirm
    //
    //           title='Czy na pewno chcesz wyczyścić obecną kalkulację? Bieżące informacje z kalkulatora zostaną usunięte.'
    //           onConfirm={this.handleClearForm}
    //           {...popconfirmProps}
    //         >
    //           <Menu.Item key='clear-form'>
    //               Wyczyść formularz
    //           </Menu.Item>
    //         </Popconfirm>
    //       </Menu>
    //     }
    //   >
    //     <MoreOutlined style={{ fontSize: 20 }} />
    //   </Dropdown>
    // ]) || [actionsMenuMobile]

    // if (this.props.headerActions.length !== actions.length) {
    //   this.props.setHeaderActions(actions)
    // }

    // const calcDesc = (
    //   <>
    //     <h3>Koszt składników</h3>
    //     <p>Na każdy wypiek składają się przede wszystkim zużyte składniki jak np. mąka, jajka jak również akcesoria i np. podkłady. Składniki będą się różnić od konkretnej wielkości wypieku i wymagań klienta odnośnie dekoracji. W kalkulatorze definiujemy je w dwóch sekcjach. Pierwsza z nich to "Cena zakupu składników". W tej sekcji wprowadzamy rodzaj, ilość i cenę zakupu składników, które w odpowiednich proporcjach w kroku następnym posłużą do przygotowania wypieku. Kolejna sekcja "Użyte składniki" służy do określenia jaka konkretnie ilość (także ile sztuk jeśli chodzi o dekoracje) została zużyta do bieżącego wypieku.</p>
    //
    //     <h3>Czas</h3>
    //     <p>Każdy wypiek wymaga określonej pracy aby z zakupionych składników takich jak mąka czy mleko wyczarować cudny wypiek. Czas poświęcony na zakup składników, wyrabianie ciasta, kremów czy dekorowanie należy skrupulatnie wypunktować - za tę pracę należy Ci się wynagrodzenie, które powinno mieć odzwierciedlenie w cenie produktu końcowego.</p>
    //
    //     <h3>Koszty stałe</h3>
    //     <p>Wypieki potrzebują miejsca na przygotowanie takich jak lokal gastronomiczny lub po prostu Twoja kuchnia, potrzebują też piekarnika, lodówki. Bez prądu, kawałka blatu żaden z Twoich pięknych wypieków by nie powstał. W kosztach stałych należy uwzględnić wszystkie stałe (w skali miesiąca) obciążenia takie jak wynajem lokalu, spłatę sprzętu, wynagrodzenie dla pracownika czy opłatę za media.</p>
    //
    //     <h3>Marża</h3>
    //     <p>Tworząc wypiek należy również wziąć pod uwagę taki element jak marża. Jest to procentowa wartość, która jest doliczana do ceny końcowej produktu wyliczonej na podstawie sumy użytych składników, poświęconego czasu i kosztów stałych. Marża jest to zysk dla Twojej pracowni z wytworzonego wypieku. Z zarobionych w ten sposób pieniędzy możesz finansować swój rozwój (i Twojego zespołu jeśli go posiadasz) jak np. szkolenia ale także rozwój Twojej pracowni o nowe sprzęty i akcesoria mogące przyspieszyć i podnieść jakość Twoje pracy.</p>
    //   </>
    // )

    const usedRecipesHelp = (
      <>
        W tej sekcji dodaj niezbędne przepisy zdefiniowane w zakładce <Link to='/recipe'>Przepisy</Link>. Na tej podstawie zostanie obliczony faktyczny koszt składników potrzebnych na bieżące zamówienie.<br /><br />
      </>
    )

    const capturedTimeHelp = (
      <>
        Tutaj definiujesz ile czasu będą wymagały poszczególne etapy przygotowania zamówienia.<br /><br />
        Typowe pozycje, które należy uwzględnić na tej liście:<br />
        <ul className='calculator-help-list'>
          <li><b>zakupy</b></li>
          <li><b>pieczenie</b></li>
          <li><b>przygotowanie kremu, tynku</b></li>
          <li><b>składanie</b></li>
          <li><b>tynkowanie</b></li>
          <li><b>przygotowanie ozdób</b></li>
          <li><b>dekorowanie</b></li>
        </ul>
      </>
    )

    const parametersHelp = (
      <>
        W tej sekcji określasz ogólne informacje o zamówieniu.<br />

        <ul className='calculator-help-list'>
          <li><b>Użyj domyślnej stawki za godzinę</b> - w tym polu domyślnie jest używana stawka godzinowa z <Link to='/order/config'>Ustawień</Link>, klikając w tę opcję możesz ustawić inną stawkę tylko dla tego zamówienia</li>
          <li><b>Użyj domyślnej marży lub narzutu</b> - w tym polu domyślnie jest używana wartość marży lub narzutu z <Link to='/order/config'>Ustawień</Link>, klikając w tę opcję możesz ustawić inną wartość tylko dla tego zamówienia. W ustawieniach ustawisz także czy chcesz korzystać z marży czy narzutu dla wszystkich zamówień.</li>
          <li><b>Zamówienie na ilość porcji</b> - jeśli przyjmujesz zamówienie na ilość porcji, zaznacz tę opcję i podaj ilość porcji, wtedy formularz będzie podpowiadał najbardziej pasujące przepisy</li>
          <li><b>Dolicz kaucję do zamówienia</b> - gdy użyczasz klientowi np. swoje półmiski, talerze - warto doliczyć do zamówienia zwrotną kaucję w ramach zabezpieczenia zwrotu Twoich przedmiotów w całości</li>
        </ul>
      </>
    )

    return (
      <>
        {/* isMobile
          ? (
            <Popup
              visible={this.state.descBoxVisible}
              onMaskClick={() => {
                this.setDescBoxVisible(false)
              }}
              bodyStyle={{ height: '50vh' }}
            >
              <div className='calculator-help-popup'>
                <Button type='text' className='calculator-help-popup-close' onClick={() => { this.setDescBoxVisible(false) }}><CloseOutlined /></Button>
                <h2>Jak wyceniać wypieki?</h2>

                {calcDesc}
              </div>
            </Popup>)
          : (
            <Drawer
              title='Jak wyceniać wypieki?'
              placement='right'
              width={450}
              onClose={() => {
                this.setDescBoxVisible(false)
              }}
              visible={this.state.descBoxVisible}
            >
              <div className='calculator-help-drawer'>
                {calcDesc}
              </div>
            </Drawer>
          ) */}
        {loaded && Object.keys(ordersConfig).length <= 1 &&
            <div className='alert-wrapper'>
              <Alert
                className={'calculator-alert' + (isMobile ? ' on-mobile' : '')}
                closable
                message={
                  <>
                    Zamówienia do poprawnego działa wymagają uzupełnienia <Link to='/order/config'>Ustawień</Link>. Najpierw przejdź do konfiguracji, później wróć i dodaj zamówienie.
                  </>
                }
                type='warning'
              />
            </div>}
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ portions: 1 }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
          disabled={formDisabled}
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  <Card
                    title='Informacje ogólne'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    extra={<HelpBox title='Parametry' category='Orders Form' content={parametersHelp} visible={this.state.visibleHelpParameters} setVisible={this.setVisibleHelpParameters} isMobile={isMobile} />}
                  >
                    <Form.Item
                      name='name'
                      label={this.props.t('Nazwa zamówienia')}
                      rules={[{ required: true, message: 'Podaj nazwę zamówienia' }]}
                      {...formItemLayout}
                    >
                      <Input maxLength={255} />
                    </Form.Item>
                    <Form.Item
                      name='client_name'
                      label={this.props.t('Nazwa klienta')}
                      rules={[{ required: true, message: 'Podaj nazwę klienta' }]}
                      {...formItemLayout}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name='client_phone'
                      label={this.props.t('Nr tel klienta')}
                      rules={[{ required: true, message: 'Podaj numer telefonu klienta' }]}
                      {...formItemLayout}
                    >
                      <Input />
                    </Form.Item>
                    {this.props.screenSize !== 'xs' &&
                      <Form.Item
                        name='to_date'
                        label={this.props.t('Data realizacji')}
                        rules={[{ required: true, message: 'Podaj datę realizacji' }]}
                        {...formItemLayout}
                      >
                        <DatePicker format='YYYY-MM-DD' locale={locale} />
                      </Form.Item>}
                    {this.props.screenSize === 'xs' &&
                      <Form.Item
                        name='to_date'
                        label={this.props.t('Data realizacji')}
                        rules={[{ required: true, message: 'Podaj datę realizacji' }]}
                        {...formItemLayout}
                      >
                        {this.formRef?.current?.getFieldValue('to_date')?.format('YYYY-MM-DD')}
                        <DatePickerMobile
                          cancelText='Anuluj'
                          confirmText='Ok'
                          value={this.formRef?.current?.getFieldValue('to_date') && this.formRef?.current?.getFieldValue('to_date').toDate()}
                          visible={this.state.toDatePickerVisible}
                          onCancel={() => this.handleToDatePickerVisible(false)}
                          onConfirm={this.handleToDatePicker}
                        />{' '}
                        {!formDisabled && <Button type='link' onClick={() => this.handleToDatePickerVisible(true)}>zmień</Button>}
                      </Form.Item>}
                    {this.props.screenSize !== 'xs' &&
                      <Form.Item
                        name='to_time'
                        label={this.props.t('Godzina odbioru')}
                        rules={[{ required: true, message: 'Podaj godzinę odbioru' }]}
                        {...formItemLayout}
                      >
                        <TimePicker format='HH:mm' locale={locale} />
                      </Form.Item>}
                    {this.props.screenSize === 'xs' &&
                      <Form.Item
                        name='to_time'
                        label={this.props.t('Godzina odbioru')}
                        rules={[{ required: true, message: 'Podaj godzinę odbioru' }]}
                        {...formItemLayout}
                      >
                        {this.formRef?.current?.getFieldValue('to_time')?.format('HH:mm')}
                        <Picker
                          cancelText='Anuluj'
                          confirmText='Ok'
                          columns={[[
                            { label: '00', value: '0' },
                            { label: '01', value: '1' },
                            { label: '02', value: '2' },
                            { label: '03', value: '3' },
                            { label: '04', value: '4' },
                            { label: '05', value: '5' },
                            { label: '06', value: '6' },
                            { label: '07', value: '7' },
                            { label: '08', value: '8' },
                            { label: '09', value: '9' },
                            { label: '10', value: '10' },
                            { label: '11', value: '11' },
                            { label: '12', value: '12' },
                            { label: '13', value: '13' },
                            { label: '14', value: '14' },
                            { label: '15', value: '15' },
                            { label: '16', value: '16' },
                            { label: '17', value: '17' },
                            { label: '18', value: '18' },
                            { label: '19', value: '19' },
                            { label: '20', value: '20' },
                            { label: '21', value: '21' },
                            { label: '22', value: '22' },
                            { label: '23', value: '23' }
                          ],
                          [
                            { label: '00', value: '0' },
                            { label: '05', value: '5' },
                            { label: '10', value: '10' },
                            { label: '15', value: '15' },
                            { label: '20', value: '20' },
                            { label: '25', value: '25' },
                            { label: '30', value: '30' },
                            { label: '35', value: '35' },
                            { label: '40', value: '40' },
                            { label: '45', value: '45' },
                            { label: '50', value: '50' },
                            { label: '55', value: '55' }
                          ]]}
                          value={this.formRef?.current?.getFieldValue('to_time') && [this.formRef?.current?.getFieldValue('to_time').format('H'), this.formRef?.current?.getFieldValue('to_time').format('m')]}
                          visible={this.state.toTimePickerVisible}
                          onCancel={() => this.handleToTimePickerVisible(false)}
                          onConfirm={this.handleToTimePicker}
                        />{' '}
                        {!formDisabled && <Button type='link' onClick={() => this.handleToTimePickerVisible(true)}>zmień</Button>}
                      </Form.Item>}
                    {!formDisabled && !partialFormDisabled &&
                      <Form.Item
                        name='use_default_h_price'
                        label={this.props.t('Użyj domyślnej stawki za godzinę (' + (ordersConfig && ordersConfig.h_price >= 0 ? ordersConfig.h_price : '-') + ' zł)')}
                        {...formItemLayout}
                        valuePropName='checked'
                      >
                        <Switch checked={this.state.useDefaultHPrice} onChange={(x) => this.setState({ useDefaultHPrice: !x })} />
                      </Form.Item>}
                    {!formDisabled && !partialFormDisabled && this.state.useDefaultHPrice &&
                      <Form.Item
                        name='h_price'
                        label={this.props.t('Twoja stawka za godzinę pracy (zł brutto)')}
                        rules={[
                          { required: this.state.useDefaultHPrice, message: 'Podaj Twoją stawkę za godzinę' },
                          () => ({
                            validator (_, value) {
                              if (value === 0) {
                                return Promise.reject(new Error('Podaj Twoją stawkę za godzinę'))
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber min={0} max={1000} className='input-100p' />
                      </Form.Item>}
                    {this.state.calcType === 'margin' && !formDisabled && !partialFormDisabled &&
                      <Form.Item
                        name='use_default_margin'
                        label={this.props.t('Użyj domyślnej marży (' + (ordersConfig && ordersConfig.margin >= 0 ? ordersConfig.margin : '-') + ' %)')}
                        {...formItemLayout}
                        valuePropName='checked'
                      >
                        <Switch checked={this.state.useDefaultMargin} onChange={(x) => this.setState({ useDefaultMargin: !x })} />
                      </Form.Item>}
                    {this.state.calcType === 'margin' && !formDisabled && !partialFormDisabled && this.state.useDefaultMargin &&
                      <Form.Item
                        name='margin'
                        label={this.props.t('Marża (%)')}
                        {...formItemLayout}
                        rules={[
                          { required: this.state.useDefaultMargin, message: 'Podaj marżę' }
                        ]}
                      >
                        <InputNumber min={0} max={99.9} className='input-100p' />
                      </Form.Item>}


                      {this.state.calcType === 'overhead' && !formDisabled && !partialFormDisabled &&
                        <Form.Item
                          name='use_default_overhead'
                          label={this.props.t('Użyj domyślnego narzutu (' + (ordersConfig && ordersConfig.overhead ? ordersConfig.overhead : '-') + ' %)')}
                          {...formItemLayout}
                          valuePropName='checked'
                        >
                          <Switch checked={this.state.useDefaultOverhead} onChange={(x) => this.setState({ useDefaultOverhead: !x })} />
                        </Form.Item>}
                      {this.state.calcType === 'overhead' && !formDisabled && !partialFormDisabled && this.state.useDefaultOverhead &&
                        <Form.Item
                          name='overhead'
                          label={this.props.t('Narzut (%)')}
                          {...formItemLayout}
                          rules={[
                            { required: this.state.useDefaultOverhead, message: 'Podaj narzut' }
                          ]}
                        >
                          <InputNumber min={0} max={1000} className='input-100p' />
                        </Form.Item>}


                    {!formDisabled && !partialFormDisabled &&
                      <Form.Item
                        name='use_portions'
                        label={this.props.t('Zamówienie na ilość porcji')}
                        {...formItemLayout}
                      >
                        <Switch checked={this.state.usePortions} onChange={this.handleUsePortions} />
                      </Form.Item>}
                    {this.state.usePortions &&
                      <Form.Item
                        name='portions'
                        label={this.props.t('Ilość porcji')}
                        rules={[
                          { required: true, message: 'Podaj ilość porcji' },
                          () => ({
                            validator (_, value) {
                              if (value === 0) {
                                return Promise.reject(new Error('Podaj ilość porcji'))
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber
                          onChange={this.handlePortionsChange}
                          disabled={partialFormDisabled}
                        />
                      </Form.Item>}

                    {!formDisabled && !partialFormDisabled &&
                      <Form.Item
                        name='use_deposit'
                        label={this.props.t('Dolicz kaucję do zamówienia')}
                        {...formItemLayout}
                        valuePropName='checked'
                      >
                        <Switch checked={this.state.useDeposit} onChange={this.handleUseDeposit} />
                      </Form.Item>}
                    {this.state.useDeposit &&
                      <Form.Item
                        name='deposit'
                        label={this.props.t('Wysokość kaucji zwrotnej (zł)')}
                        rules={[
                          { required: this.state.useDeposit, message: 'Podaj wysokość kaucji' },
                          () => ({
                            validator (_, value) {
                              if (value === 0) {
                                return Promise.reject(new Error('Podaj wysokość kaucji'))
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber
                          min={0}
                          max={1000}
                          className='input-100p'
                          disabled={partialFormDisabled}
                        />
                      </Form.Item>}

                    {(formDisabled || partialFormDisabled) &&
                      <Form.Item
                        label={this.props.t('Twoja stawka za godzinę pracy')}
                        {...formItemLayout}
                      >
                        {formData.frozen?.used_params?.h_price} zł
                      </Form.Item>}
                    {this.state.calcType === 'margin' && (formDisabled || partialFormDisabled) &&
                      <Form.Item
                        label={this.props.t('Marża')}
                        {...formItemLayout}
                      >
                        {formData.frozen?.used_params?.margin} %
                      </Form.Item>}
                    {this.state.calcType === 'overhead' && (formDisabled || partialFormDisabled) &&
                      <Form.Item
                        label={this.props.t('Narzut')}
                        {...formItemLayout}
                      >
                        {formData.frozen?.used_params?.overhead} %
                      </Form.Item>}
                    {(formDisabled || partialFormDisabled) &&
                      <Form.Item
                        label={this.props.t('Ilość wypieków w miesiącu')}
                        {...formItemLayout}
                      >
                        {formData.frozen?.used_params?.cakes_per_month}
                      </Form.Item>}
                    {(formDisabled || partialFormDisabled) &&
                      <Form.Item
                        label={this.props.t('Przyjęte koszty stałe')}
                        {...formItemLayout}
                      >
                        <Table
                          columns={[{
                            title: 'Nazwa',
                            dataIndex: 'name',
                            key: 'name'
                          },
                          {
                            title: 'Kwota',
                            dataIndex: 'value',
                            key: 'value',
                            render: (x) => (
                              <span>{x} zł</span>
                            )
                          }]}
                          dataSource={formData.frozen?.fixed_costs}
                          size='small'
                        />

                      </Form.Item>}
                  </Card>

                  <Form.List
                    name='used_recipes'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj użyte przepisy'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Użyte przepisy'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<HelpBox title='Użyte składniki' category='Orders Form' content={usedRecipesHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedRecipes} isMobile={isMobile} />}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={10} lg={10}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Użyty przepis'}>
                                  {this.state.usePortions && this.state.recipesMatching.length !== 0 &&
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'ingId']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Wybierz użyty przepis' }]}
                                    >
                                      <Select
                                        key='with-portion'
                                        notFoundContent={<center><Empty description='Brak przepisów' /><Link to='/recipe/add'>Dodaj nowy</Link></center>}
                                        locale={{ emptyText: <Empty description='Brak zamówień' /> }}
                                        className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                        placeholder={this.state.usedIngredientsFocus !== name ? 'Wybierz przepis' : ''}
                                        onBlur={() => this.handleUsedRecipesFocus(null)}
                                        onFocus={() => this.handleUsedRecipesFocus(name)}
                                      >
                                        <OptGroup label='Pasujące do ilości porcji'>
                                          {
                                            (this.state.recipesMatching.map((ing, n) =>
                                              ing.name && <Option key={n} value={ing.id}>{ing.use_portions ? ing.name + ' (' + niclyFormatPortions(ing.portions) + ')' : ing.name}</Option>
                                            ))
                                          }
                                        </OptGroup>
                                        {this.state.recipesNotMatching.length > 0 &&
                                          <OptGroup label='Pozostałe'>
                                            {
                                              (this.state.recipesNotMatching.map((ing, n) =>
                                                ing.name && <Option key={n} value={ing.id}>{ing.use_portions ? ing.name + ' (' + niclyFormatPortions(ing.portions) + ')' : ing.name}</Option>
                                              ))
                                            }
                                          </OptGroup>}
                                      </Select>
                                    </Form.Item>}
                                  {(!this.state.usePortions || this.state.recipesMatching.length === 0) &&
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'ingId']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Wybierz użyty przepis' }]}
                                    >
                                      <Select
                                        key='without-portion'
                                        locale={{ emptyText: <Empty description='Brak zamówień' /> }}
                                        notFoundContent={<center><Empty description='Brak przepisów' /><Link to='/recipe/add'>Dodaj nowy</Link></center>}
                                        defaultValue={this.formRef?.current?.getFieldValue('used_recipes')[name]?.ingId}
                                        className={this.state.usedIngredientsFocus === name ? 'field-float-select' : ''}
                                        placeholder={this.state.usedIngredientsFocus !== name ? 'Wybierz przepis' : ''}
                                        onBlur={() => this.handleUsedRecipesFocus(null)}
                                        onFocus={() => this.handleUsedRecipesFocus(name)}
                                        disabled={partialFormDisabled}
                                      >
                                        {(!formDisabled && !partialFormDisabled && this.props.recipes.map((ing, n) =>
                                          ing.name && <Option key={n} value={ing.id}>{ing.use_portions ? ing.name + ' (' + niclyFormatPortions(ing.portions) + ')' : ing.name}</Option>
                                        )) || formData?.frozen?.used_recipes.map((ing, n) =>
                                          ing.name && <Option key={n} value={ing.ingId}>{ing.name}</Option>
                                        )}
                                      </Select>
                                    </Form.Item>}
                                </FloatLabel>
                              </Col>

                              <Col xs={9} md={4} lg={4}>
                                <FloatLabel label={this.state.usedIngredientsFocus === name && 'Ilość'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj ilość' }]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.usedIngredientsFocus === name ? ' field-float-number' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== name ? 'Ilość' : ''}
                                      min={1}
                                      max={10000}
                                      onBlur={() => this.handleUsedRecipesFocus(null)}
                                      onFocus={() => this.handleUsedRecipesFocus(name)}
                                      disabled={partialFormDisabled}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                {!formDisabled && !partialFormDisabled &&
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => remove(name)}
                                    {...popconfirmItemDeleteProps}

                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>}
                              </Col>

                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                        <Button
                          type='secondary'
                          onClick={() => { add({ quantity: 1 }); this.handleUsedRecipesFocus(fields?.length) }}
                          icon={<PlusOutlined />}
                          disabled={partialFormDisabled}
                        >
                          Dodaj użyty przepis
                        </Button>
                      </Card>
                    )}
                  </Form.List>

                  <Form.List
                    name='captured_time'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj poświęcony czas'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Poświęcony czas'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={<HelpBox title='Poświęcony czas' category='Orders Form' content={capturedTimeHelp} visible={this.state.visibleHelpCapturedTime} setVisible={this.setVisibleHelpCapturedTime} isMobile={isMobile} />}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={24} md={10} lg={10}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Nazwa czynności'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Podaj nazwę czynności' }]}
                                  >
                                    <Input
                                      className={this.state.capturedTimeFocus === name ? 'field-float-input' : ''}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Nazwa czynności' : ''}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                      disabled={partialFormDisabled}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={12} md={4} lg={4}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Jednostka'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'unit']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    style={{ minWidth: '70px' }}
                                    rules={[{ required: true, message: 'Wybierz jednostkę czasu' }]}
                                  >
                                    <Select
                                      className={this.state.capturedTimeFocus === name ? 'field-float-select' : ''}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Jednostka' : ''}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                      disabled={partialFormDisabled}
                                    >
                                      {
                                        ['godzina', 'minuta'].map((ing, n) =>
                                          <Option key={n} value={ing}>{ing}</Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={9} md={4} lg={4}>
                                <FloatLabel label={this.state.capturedTimeFocus === name && 'Ilość' + this.getCapturedTimeUnit(name)}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      { required: true, message: 'Podaj poświęconą ilość czasu' },
                                      () => ({
                                        validator (_, value) {
                                          if (value === 0) {
                                            return Promise.reject(new Error('Podaj poświęconą ilość czasu'))
                                          }
                                          return Promise.resolve()
                                        }
                                      })
                                    ]}
                                  >
                                    <InputNumber
                                      className={'input-100p' + (this.state.capturedTimeFocus === name ? ' field-float-number' : '')}
                                      placeholder={this.state.capturedTimeFocus !== name ? 'Ilość' + this.getCapturedTimeUnit(name) : ''}
                                      min={0}
                                      max={10000}
                                      onBlur={() => this.handleCapturedTimeFocus(null)}
                                      onFocus={() => this.handleCapturedTimeFocus(name)}
                                      disabled={partialFormDisabled}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>
                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                {!formDisabled && !partialFormDisabled &&
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => remove(name)}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>}
                              </Col>
                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                        <Button
                          type='secondary'
                          onClick={() => { add({ unit: 'godzina', quantity: 1 }); this.handleCapturedTimeFocus(fields?.length) }}
                          icon={<PlusOutlined />}
                          disabled={partialFormDisabled}
                        >
                          Dodaj czas
                        </Button>
                      </Card>
                    )}
                  </Form.List>
                </Space>
              </Col>
            </Row>

            {error !== undefined
              ? (
                <Result
                  status='error'
                  title='Formularz zawiera błędy'
                  subTitle={error}
                />)
              : ''}

            {(this.state.showCalculation && error === undefined) || formDisabled || partialFormDisabled
              ? (
                <Result
                  status='success'
                  title={[
                    this.state.useDeposit
                      ? (
                        <Row key='price-to-client-deposit'>
                          <Col xs={24} sm={12} className='calculator-results-header-left'>
                            Cena dla klienta z kaucją:
                          </Col>
                          <Col xs={24} sm={12} className='calculator-results-header-right'>
                            <b>{(this.state.priceToClient + this.formRef?.current?.getFieldValue('deposit')).toFixed(2)}</b> zł brutto
                          </Col>
                        </Row>) : null,
                    <Row key='price-to-client'>
                      <Col xs={24} sm={12} className='calculator-results-header-left'>
                        Cena dla klienta:
                      </Col>
                      <Col xs={24} sm={12} className='calculator-results-header-right'>
                        <b>{this.state.priceToClient}</b> zł brutto
                      </Col>
                    </Row>,
                    <Row key='earnigs'>
                      <Col xs={24} sm={12} className='calculator-results-header-left'>
                        Twój zysk:
                      </Col>
                      <Col xs={24} sm={12} className='calculator-results-header-right'>
                        <b>{this.state.earnings}</b> zł brutto
                      </Col>
                    </Row>
                  ]}
                  subTitle={
                    <Collapse className='calculator-results-collapse' onChange={this.handleCalcDetailsChange}>
                      <Panel header='Pokaż szczegóły obliczeń'>
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Koszt samych składników:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costOfIngredients}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Suma wynagrodzenia za czas Twojej pracy:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costOfTime}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Suma kosztów (koszt składników + koszty stałe):
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.sumOfCosts}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            Koszty stałe w przeliczeniu na 1 wypiek:
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.costPerCake}</b> zł brutto
                          </Col>
                        </Row>
                        {isMobile && <div className='calculator-results-divider-wrapper'><Divider {...resultsItemsDivider} /></div>}
                        <Row gutter={6}>
                          <Col span={13} className='calculator-results-left'>
                            {this.state.calcType === 'margin' ? 'Marża zawarta w cenie:' : 'Narzut dodany do ceny:'}
                          </Col>
                          <Col span={11} className='calculator-results-right'>
                            <b>{this.state.calcDetails.addedToPrice}</b> zł brutto
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  }
                />)
              : ''}
          </Space>
          <Space>
            {isNew &&
              <Button onClick={this.handleCalculate} type='primary' size='large' disabled={this.state.loading || this.state.loading_calculation}>
                {this.props.t('Wyceń bez zapisywania')}{this.state.loading_calculation && <LoadingOutlined style={{ fontSize: 14 }} spin />}
              </Button>}
            <Button ref={this.resultRef} htmlType='submit' type='primary' size='large' disabled={this.state.loading || this.state.loading_calculation}>
              {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default withTranslation()(OrderForm)
