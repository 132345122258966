/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Button, Input
} from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 24,
    // sm: 10,
    md: 24,
    lg: 24
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

class TrainingSearchForm extends React.Component {
  state = {
    searchUsed: false,
    initialSearchUsed: false
  }

  formRef = React.createRef()

  handleSubmit = (values) => {
    this.props.formAction(values)

    this.setState({ searchUsed: values.search?.length > 0})
  }

  componentDidUpdate () {
      if (this.props.loaded && this.formRef?.current && this.props.initialSearch && !this.state.initialSearchUsed && this.formRef?.current.getFieldValue('search') !== this.props.initialSearch) {
        this.formRef?.current.setFieldsValue({ search: this.props.initialSearch })

        this.props.formAction({ search: this.props.initialSearch })
        this.setState({ initialSearchUsed: true, searchUsed: true })
      }
  }

  resetForm = () => {
    this.formRef.current?.setFieldsValue({ search: '' })
    this.props.formAction({ search: '' })
    this.setState({ searchUsed: false })
  }

  render () {
    // const { isMobile } = this.props

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='inline'
          className={'training-search'}
          ref={this.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='search'
            {...formItemLayout}
          >
            <Input placeholder='Szukaj...' className='training-search-input' />
          </Form.Item>

          <Form.Item className='form-footer'>
            <Button htmlType='submit' type='primary' className='training-search-button'><ArrowRightOutlined /></Button>
            {this.state.searchUsed && <Button type='link' onClick={this.resetForm}>Resetuj</Button>}
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(TrainingSearchForm)
