/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'
import { connect } from 'react-redux'
import { Button, Space } from 'antd'
import { Link as LinkScroll } from 'react-scroll'
// import { Alert } from 'antd'
import { Link } from 'react-router-dom'
import { getTrainings, getStats, getTrainingAuthors, getPartners } from '../../utils/actions'

import TrainingItem from '../../styledComponents/training/item'
import TrainingAuthorItem from '../../styledComponents/trainingAuthor/item'

// import IndexBanner from './indexBanner'
// import IndexModules from './indexModules'
// import IndexAccess from './indexAccess'
// import IndexExplain from './indexExplain'
// import IndexExplainLeft from './indexExplainLeft'
// import IndexPartners from './indexPartners'
// import IndexDevices from './indexDevices'
// import IndexExplainRight from './indexExplainRight'
import ContentItems from '../common/contentItems'
import ContentSlider from '../common/contentSlider'
import TrainingSearchForm from '../../styledComponents/training/searchForm'
import ContentLeft from '../common/contentLeft'
import Content from '../common/content'

const { location = {} } = typeof window !== 'undefined' ? window : {}

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port
    }
  }

  redirect = (pathname, state) => {
    this.props.history.push({ pathname, state })
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render () {
    const { isMobile } = this.state

    const children = [
      // <IndexBanner
      //   id='Banner0_0'
      //   key='Banner0_0'
      //   dataSource={IndexBannerDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <ContentLeft
        isMobile={isMobile}
        image='/images/cake.png'
        title='Nowoczesne szkolenia online dla cukierników'
        className='welcome'
        content={
          <>
            <p>Tworzenie słodkości to Twoja praca? A może to Twoja pasja? Nie ważne z jakich powodów interesujesz się słodką twórczością - my mamy pasję do dzielenia się wiedzą!</p>
            <p>Dołącz do nas - z przyjemnością nauczymy i Ciebie :)</p>
            <Space wrap>
              <Button className='sc-button'><Link to='/register'>Zarejestruj się za darmo</Link></Button>
              <Button className='sc-button sc-button-reverse'><LinkScroll to='Content13_0' spy smooth offset={-70} duration={500}>Dowiedz się więcej</LinkScroll></Button>
            </Space>
          </>
        }
      />,
      <Content
        id='Content13_0'
        isMobile={isMobile}
        title='Czym jest Strefa Cukiernika?'
        className='explain'
        content={
          <Space direction='vertical' size='large'>
            <p>Strefa Cukiernika to miejsce (oraz społeczność ;) dedykowana osobom zajmującymi się wypiekami.</p>
            <p>Znajdziesz tutaj przydatne narzędzia, wiedzę i dodatki, które ułatwią Ci codzienną pracę w słodkim świecie!</p>
            <Button className='sc-button register'><Link to='/register'>Dołącz do Strefy Cukiernika</Link></Button>
          </Space>
        }
      />,
      <ContentItems
        getData={() =>
          new Promise(function (resolve, reject) {
            getTrainings().then((json) => {
              resolve(json.trainings.slice(0, 6))
            }).catch((error) => reject(error))
          })
        }
        renderItem={(item) => {
          return <TrainingItem item={item} redirect={this.redirect} />
        }}
        title={
          <>
            <span>Nasze szkolenia</span>
            <TrainingSearchForm formAction={(data) => this.redirect('/training', { trainingSearch: data?.search })} />
          </>}
        isMobile={this.state.isMobile}
        more={{
          title: 'POKAŻ WIĘCEJ',
          route: '/training'
        }}
      />,
      <ContentItems
        items={[
          {
            route: '/calculator',
            title: 'Kalkulator wycen',
            img: 'calculator.png?v2',
          },
          {
            route: '/calculator-portions',
            title: 'Kalkulator porcji',
            img: 'calculator-portions.png?v2',
          },
          {
            route: '/order',
            title: 'Obsługa zamówień',
            img: 'orders.png?v2',
          },
          {
            route: '/myrecipes',
            title: 'Moje przepisy',
            img: 'recipes.png?v2',
          }
        ]}
        itemXs={24}
        itemSm={12}
        itemMd={12}
        itemLg={6}
        renderItem={(item) => {
          return <div class='item'><Link to={item.route}><img src={'/images/' + item.img} alt='item' /></Link><p><Link className='text-link' to={item.route}><span>{item.title}</span><span className='line'></span></Link></p></div>
        }}
        className='tools'
        title='Przydatne narzędzia'
        isMobile={this.state.isMobile}
      />,
      <ContentItems
        getData={() =>
          new Promise(function (resolve, reject) {
            // setTimeout(resolve, 3000)
            getStats().then((json) => {
              resolve(json.stats)
            }).catch((error) => reject(error))
          })
        }
        loadingItems={[{ text: '-', data: '-' }, { text: '-', data: '-' }, { text: '-', data: '-' }]}
        className='stats'
        itemXs={24}
        itemSm={24}
        itemMd={6}
        itemLg={6}
        renderItem={(item) => {
          return <div><p className='stats-item-data'>{item.data}</p><p className='stats-item-desc'>{item.text}</p></div>
        }}
        isMobile={this.state.isMobile}
      />,
      <ContentItems
        getData={() =>
          new Promise(function (resolve, reject) {
            getTrainingAuthors().then((json) => {
              resolve(json.authors)
            }).catch((error) => reject(error))
          })
        }
        renderItem={(item) => {
          return <TrainingAuthorItem item={item} redirect={this.redirect} />
        }}
        itemXs={24}
        itemSm={12}
        itemMd={8}
        itemLg={6}
        title='Poznaj nasze Trenerki'
        isMobile={this.state.isMobile}
        more={{
          title: 'POKAŻ WIĘCEJ',
          route: '/training/author'
        }}
      />,

      // <div key='tmp' style={{ marginTop: '100px' }} />
      // <IndexModules
      //   id='Content0_0'
      //   key='Content0_0'
      //   dataSource={IndexModulesDataSource}
      //   isMobile={this.state.isMobile}
      //   userRoles={[]}
      //   activeModules={[]}
      // />,
      // <IndexAccess
      //   id='Content1_0'
      //   key='Content1_0'
      //   dataSource={IndexAccessDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <ContentSlider
        getData={() =>
          new Promise(function (resolve, reject) {
            getPartners().then((json) => {
              resolve(json.partners)
            }).catch((error) => reject(error))
          })
        }
        renderItem={(item) => {
          return <div className='partners-item' style={{ margin: '0 auto' }}>
            <Link to={'/partnerzy/' + item.id}>
              <img src={'/images/partners/' + item.logo.replace('logo', 'logo-mini')} alt={item.name} className='slick-image' />
            </Link>
          </div>
        }}
        title='Partnerzy'
        className='partners'
        isMobile={this.state.isMobile}
      />,
      // <IndexPartners
      //   id='Content12_0'
      //   key='Content12_0'
      //   dataSource={PartnersDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexDevices
      //   id='Feature5_0'
      //   key='Feature5_0'
      //   dataSource={DevicesDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexExplainLeft
      //   id='Content6_0'
      //   key='Content6_0'
      //   dataSource={CalcDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexExplainRight
      //   id='Content8_0'
      //   key='Content8_0'
      //   dataSource={OrdersDataSource}
      //   isMobile={this.state.isMobile}
      // />
    ]

    return (
      <div
        className='templates-wrapper'
        ref={(d) => {
          this.dom = d
        }}
      >
        {/*<Alert
          className='registration-alert'
          closable
          style={{ marginTop: '10px' }}
          message={
            <>
              {'Obecnie aplikacja jest w fazie testów, rejestracja jeszcze nie jest dostępna dla użytkowników. Zapraszamy wkrótce!'}
            </>
          }
          type='info'
        />*/}
        {this.state.show && children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const HomeContainer = connect(
  mapStateToProps
)(Home)

export default HomeContainer

export const IndexBannerDataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    isImg: true,
    // children: '/images/napis.png'
  },
  content: {
    className: 'banner0-content',
    children: 'Wszystko dla Cukierników!'
  },

  button: { className: 'banner0-button', children: <LinkScroll to='Content13_0' spy smooth offset={-70} duration={500}>Dowiedz się więcej</LinkScroll> }
}

export const IndexExplainDataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image'
      },
      { name: 'title', children: 'Czym jest Strefa Cukiernika?', className: 'title-h1' },
      {
        name: 'content',
        children: 'Jest to portal dedykowany osobom zajmującymi się wypiekami.',
        className: 'title-content'
      },
      {
        name: 'content2',
        children: 'Znajdziesz tutaj przydatne narzędzia, wiedzę i dodatki, które ułatwią Ci codzienną pracę!',
        className: 'title-content'
      }
    ]
  }
}

export const IndexModulesDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Dostępne funkcjonalności' }]
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block00',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/trainings.png' alt='Szkolenia' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Szkolenia'
            },
            { name: 'content', children: 'Chcesz poszerzyć swoją wiedzę cukierniczą? Nie ma problemu, zapraszamy do szkoleń przygotowanych specjalnie dla Ciebie przez naszych Trenerów!' }
          ]
        }
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/partners.png' alt='partners' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Zaufane sklepy i twórcy'
            },
            { name: 'content', children: 'Uzyskaj dostęp do sprawdzonych sklepów i twórców w branży cukierniczej oraz atrakcyjne kody rabatowe' }
          ]
        }
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/any-device.png' alt='any device' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Wersja mobilna'
            },
            {
              name: 'content',
              children: 'Korzystaj ze Strefy na każdym urządzeniu, również na telefonie i tablecie!'
            }
          ]
        }
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/calculator.png' alt='calculator' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Kalkulator do wycen'
            },
            {
              name: 'content',
              children: 'Wyceniaj swoje wypieki z pomocą kalkulatora aby nie zapomnieć o żadnym koszcie i dowiedzieć się ile faktycznie zarabiasz :)'
            }
          ]
        }
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/calculator.png' alt='calculator' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Zapis ustawień kalkulatora'
            },
            {
              name: 'content',
              children: 'Ustawienia kalkulatora zapisują się automatycznie po każdej zmianie - możesz powrócić do wyceny w dowolnym czasie na dowolnym urządzeniu'
            }
          ]
        }
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/orders.png' alt='orders' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'System obsługi zamówień'
            },
            {
              name: 'content',
              children: 'Jeśli masz więcej zamówień na wypieki niż kilka w miesiącu przygotowaliśmy dla Ciebie System obsługi zamówień wraz z kalendarzem by o niczym nie zapomnieć :)'
            }
          ]
        }
      },
      {
        name: 'block5',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/quick-access.png' alt='quick-access' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Szybki dostęp'
            },
            {
              name: 'content',
              children: 'Nie musisz logować się za każdym razem aby skorzystać z aplikacji, wystarczy jedno logowanie na każdym urządzeniu'
            }
          ]
        }
      },
      {
        name: 'block6',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/calculator-portions.png' alt='calculator' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Kalkulator porcji'
            },
            {
              name: 'content',
              children: 'Przydatny kalkulator do obliczenia ile porcji otrzymasz z określonej wielkości tortu (zarówno okrągłego jak i kwadratowego)'
            }
          ]
        }
      },
      {
        name: 'block7',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/toppers.png' alt='toppers' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Projekty topperów'
            },
            {
              name: 'content',
              children: 'Poszukujesz projektów pięknych topperów? Znajdziesz je w aplikacji, do pobrania za darmo!'
            }
          ]
        }
      },
      {
        name: 'block8',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <img src='/images/recipes.png' alt='my-recipes' />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Moje przepisy'
            },
            {
              name: 'content',
              children: 'Stwórz swoją własną bazę przepisów, aby wszystkie mieć w jednym miejscu i żadnego nie zgubić ;-)'
            }
          ]
        }
      },
    ]
  }
}

export const IndexAccessDataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '/images/for-free.png'
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Dostęp za darmo!' },
  content: {
    className: 'content1-content',
    children: ''
  }
}

export const PartnersDataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: 'Partnerzy Strefy Cukiernika', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/tortdecor/logo-mini.jpg',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/panipanitorcik/logo-mini.jpg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/rgs/logo-mini.png',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/lps/logo-mini.png',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/kochamlukier/logo-mini.png',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/drranty/logo-mini.jpg',
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/kiccake/logo-mini.jpg',
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/recpak/logo-mini.png',
          },
        },
      },
      {
        name: 'block8',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/exposweet/logo-mini.jpg',
          },
        },
      },
      {
        name: 'block9',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              '/images/partners/miniowe/logo-mini.png',
          },
        },
      }
    ],
  },
};

export const DevicesDataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Dowolne urządzenie',
        className: 'title-h1',
      },
      { name: 'content', children: 'Strefa Cukiernika działa na dowolnym urządzeniu' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: 'Telefon', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>Telefon</h3>
                <p>
                  Aplikacja działa na każdym małym ekranie.
                </p>
                <br />
                <p>
                  Istnieje możliwość dodania aplikacji do ekranu domowego (aplikacja będzie wyglądać i działać jak normalna aplikacja mobilna)
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              '/images/mobile.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: { className: 'content7-tag-name', children: 'Tablet' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 6,
            xs: 24,
            children: (
              <span>
                <h3>Tablet</h3>
                <p>
                  Aplikacja automatycznie dostosuje się do ekranu tabletu, zarówno w orientacji poziomej jak i pionowej.
                </p>
                <br />
                <p>
                  Dla tabletów również istnieje możliwość dodania aplikacji do ekranu domowego i będzie wyglądać i działać jak aplikacja mobilna.
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 18,
            xs: 24,
            children:
              '/images/tablet.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: { children: 'Komputer', className: 'content7-tag-name' },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 6,
            xs: 24,
            children: (
              <span>
                <h3>Komputer</h3>
                <p>
                  Na komputerach aplikacja oczywiście również działa bez problemów:)
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 18,
            xs: 24,
            children:
              '/images/desktop.png',
          },
        },
      },
    ],
  },
};

export const CalcDataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: '/images/calc_screen.png'
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Podstawowy kalkulator do wycen' },
  content: {
    className: 'content2-content',
    children:
      'Od wyceniania Twoich wypieków aż boli Cię głowa? Już nie będzie! Oddajemy w Twoje ręce prosty ale odpowiedni na początek drogi z wypiekami kalkulator, w którym uwzględnisz koszt zakupu składników, ile ich faktycznie zużyjesz a także wycenisz czas swojej pracy i inne niezbędne koszty aby rzetelnie przygotować wycenę dla klienta. Dowiesz się też ile faktycznie zarabiasz po odjęciu wszystkich kosztów :-)',
  },
};

export const OrdersDataSource = {
  wrapper: { className: 'home-page-wrapper content8-wrapper' },
  OverPack: { className: 'home-page content8', playScale: 0.3 },
  imgWrapper: { className: 'content8-img', md: 10, xs: 24 },
  img: {
    children: '/images/orders_screen.png'
  },
  textWrapper: { className: 'content8-text', md: 14, xs: 24 },
  title: { className: 'content8-title', children: 'System obsługi zamówień' },
  content: {
    className: 'content8-content',
    children: 'Masz więcej zamówień? Mamy również coś dla Ciebie! W aplikacji znajdziesz moduł obsługi zamówień, który jest połączony z kalendarzem, bazą przepisów i magazynem składników. Możesz tam definiować swoje własne składniki i przepisy a następnie dodawać je do zamówień. W podsumowaniu otrzymasz informację o całkowitej wielkości sprzedaży i wypracowanego przez Ciebie zysku :)'
  }
}
