/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Select, Segmented
} from 'antd'
import { withTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'

import { Selector } from 'antd-mobile'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 12,
    lg: 12
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const { Option } = Select

class RecalcRecipeForm extends React.Component {
  state = {
    visibleHelpUsedIngredients: false,
    visibleHelpGeneral: false,
    loaded: false,
    pantryIngredients: [],
    usePortions: false,
    loading: false,
    addPantryModalVisible: false,
    selectOptions: []
  }

  formRef = React.createRef()
  xref = React.createRef()
  resultRef = React.createRef()

  handleCalcTypeSegmented = (calcType) => {
    this.setState({ calcType })
  }

  handleCalcTypeSelector = (calcTypes) => {
    this.setState({ calcType: calcTypes[0] })
  }

  handleSubmit = (x) => {
    const { calcType } = this.state

    ReactGA.event({ category: 'Main Recipe', action: 'Recalc Recipe Used For Type:' + calcType })

    const recipe = JSON.parse(JSON.stringify(this.props.recipe))
    let quantity
    let proportion

    if (calcType === 'rim' && x.calc_for_rim) {
        const quantityWeight = recipe.quantities.find(x => x.type === 'weight')
        const quantityRim = recipe.quantities.find(x => x.type === 'rim')

        quantity = quantityRim.quantity
        proportion = x.calc_for_rim > quantity ? 1 + ((x.calc_for_rim - quantity) / quantity) : 1 - ((quantity - x.calc_for_rim) / quantity)

        if (quantityWeight) {
          quantityWeight.quantity = Math.round(quantityWeight.quantity * proportion)
        }

        if (quantityRim) {
          quantityRim.quantity = x.calc_for_rim
        }
    } else if (calcType === 'simple' && x.calc_for_simple) {
      const quantityWeight = recipe.quantities.find(x => x.type === 'weight')
      const quantityPortion = recipe.quantities.find(x => x.type === 'portion')

      proportion = x.calc_for_simple

      if (quantityWeight) {
        quantityWeight.quantity = Math.round(quantityWeight.quantity * proportion)
        recipe.quantities = [quantityWeight]
      }
      if (quantityPortion) {
        quantityPortion.quantity = Math.round(quantityPortion.quantity * proportion)
        recipe.quantities = [quantityPortion]
      }
    } else if (calcType === 'weight' && x.calc_for_g) {
      const quantityWeight = recipe.quantities.find(x => x.type === 'weight')

      quantity = quantityWeight.quantity
      proportion = x.calc_for_g > quantity ? 1 + ((x.calc_for_g - quantity) / quantity) : 1 - ((quantity - x.calc_for_g) / quantity)

      if (quantityWeight) {
        quantityWeight.quantity = Math.round(quantityWeight.quantity * proportion)
        recipe.quantities = [quantityWeight]
      }
    } else if (calcType === 'rim_layer' && (x.calc_for_rim_layer || x.calc_for_rim)) {
        const quantityWeight = recipe.quantities.find(x => x.type === 'weight')
        const quantityRimLayer = recipe.quantities.find(x => x.type === 'rim_layer')

        quantity = quantityRimLayer.rim_size
        const proportionToUse = x.calc_for_rim > quantity ? 1 + ((x.calc_for_rim - quantity) / quantity) : 1 - ((quantity - x.calc_for_rim) / quantity)

        if (x.calc_for_rim_layer && x.calc_for_rim) {
          proportion = x.calc_for_rim_layer + proportionToUse
        } else if (x.calc_for_rim_layer) {
          proportion = x.calc_for_rim_layer
        } else if (x.calc_for_rim) {
          proportion = proportionToUse
        }

        if (quantityWeight) {
          quantityWeight.quantity = Math.round(quantityWeight.quantity * proportion)
        }

        if (quantityRimLayer) {
          if (x.calc_for_rim_layer) {
            quantityRimLayer.quantity = x.calc_for_rim_layer
          }

          if (x.calc_for_rim) {
            quantityRimLayer.rim_size = x.calc_for_rim
          }
        }
    }

    if (proportion) {
      recipe.sections.map(section => section.ingredients.map(ing => {
        if (['łyżka', 'łyżeczka', 'szklanka'].includes(ing.unit)) {
          ing.quantity = this.formatToQuarter(ing.quantity * proportion)
        } else if (['kg'].includes(ing.unit)) {
          ing.quantity = Math.round(ing.quantity * proportion * 10) / 10
        } else if (ing.indivisible) {
          ing.quantity = Math.round(ing.quantity * proportion)
        } else {
          const newQuantity = ing.quantity * proportion
          if (newQuantity < 10) {
            ing.quantity = Math.round(newQuantity * 10) / 10
          } else {
            ing.quantity = Math.round(newQuantity)
          }
        }

        return ing
      }))

      this.props.updateRecipe(recipe)
      this.props.setRecalcUsed(true)
    }

    this.props.setModalVisibility(false)
  }

  componentDidMount () {
    this.prepare()
  }

  prepare = () => {
    const { recipe } = this.props
    let calcType

    const selectOptions = []

    if(recipe?.quantities.find(x => x.type === 'rim')) {
      selectOptions.push({ label: 'Na inny rant', value: 'rim', key: 'rim' })
    }
    if(recipe?.quantities.find(x => x.type === 'rim_layer')) {
      selectOptions.push({ label: 'Na inną ilość przełożeń', value: 'rim_layer', key: 'rim_layer' })
    }
    if(recipe?.quantities.find(x => x.type === 'weight')) {
      selectOptions.push({ label: 'Na inną wagę', value: 'weight', key: 'weight' })
    }

    selectOptions.push({ label: 'Na inną ilość', value: 'simple', key: 'simple' })

    calcType = selectOptions[0]?.value

    this.setState({ selectOptions, calcType })

    this.formRef.current.setFieldsValue({ calc_for_rim: undefined, calc_for_simple: undefined, calc_for_g: undefined, calc_for_rim_layer: 1, calc_type: calcType })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.selectOptions.length === 0) {
      this.prepare()
    }
  }

  formatToQuarter = (number) => {
    const rounted = (Math.round(number * 4) / 4).toFixed(2)
    const splited = rounted.split('.')
    return splited[1] === '00' ? parseInt(rounted) : parseFloat(rounted)
  }

  render () {
    const { isMobile, recipe } = this.props
    const { calcType, selectOptions } = this.state

    let usedRimSize

    if (calcType === 'rim') {
      usedRimSize = recipe?.quantities.find(x => x.type === 'rim')?.quantity
    } else if (calcType === 'rim_layer') {
      usedRimSize = recipe?.quantities.find(x => x.type === 'rim_layer')?.rim_size
    }

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ cakes_per_month: 0, h_price: 0, margin: 0 }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' } : { paddingBottom: '20px' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='calc_type'
            wrapperCol={{
              xs: 24
            }}
          >
            {isMobile
              ? (
                <Selector
                  options={selectOptions}
                  onChange={this.handleCalcTypeSelector}
                />
              ) : (
                <Segmented
                  onChange={this.handleCalcTypeSegmented}
                  options={selectOptions}
                />)}
          </Form.Item>

          {(calcType === 'rim' || calcType === 'rim_layer') &&
            <Form.Item
              name='calc_for_rim'
              label={this.props.t('Przelicz na inny rant')}
              {...formItemLayout}
            >
              <Select>
              {Array.from({length: 31}, (_, i) => i + 12).filter(x => x !== usedRimSize).map((x, i) => <Option key={i} value={x}>{x} cm</Option>)}
              </Select>
            </Form.Item>}

          {calcType === 'rim_layer' &&
            <Form.Item
              name='calc_for_rim_layer'
              label={this.props.t('Przelicz na ilość przełożeń')}
              {...formItemLayout}
            >
              <Select>
              {Array.from({ length: 5 }, (_, i) => i + 1).map((x, i) => <Option key={i} value={x}>{x} szt</Option>)}
              </Select>
            </Form.Item>}

          {calcType === 'weight' &&
            <Form.Item
              name='calc_for_g'
              label={this.props.t('Przelicz na wagę (gramy)')}
              {...formItemLayout}
            >
              <Input />
            </Form.Item>}

            {calcType === 'simple' &&
            <Form.Item
              name='calc_for_simple'
              label={this.props.t('Przelicz na ilość')}
              {...formItemLayout}
            >
              <Select>
                <Option value={0.5}>2x mniej</Option>
                <Option value={2}>2x więcej</Option>
                <Option value={3}>3x więcej</Option>
                <Option value={4}>4x więcej</Option>
              </Select>
            </Form.Item>}

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button htmlType='submit' type='primary'>
              {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

export default withTranslation()(RecalcRecipeForm)
