/* eslint no-unused-expressions: "off" */
import React from 'react'
import { PageHeader } from 'antd'
import { withTranslation } from 'react-i18next'
import CertificateShow from '../../styledComponents/certificates/show'
import { updateCertificateRequest, getCertificateRequest } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import { menuTypeHook } from '../common/menuTypeHook'

class EditOrder extends React.Component {
  state = {
    formData: undefined,
    id: undefined
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  getCertificateRequest = (id) => {
    getCertificateRequest(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })
      }
    })
  }

  updateCertificateRequest = (action, message) => {
    const _this = this
    this.setState({ downloadingFile: true })
    return new Promise(function (resolve, reject) {
      updateCertificateRequest(_this.state.id, { action, message }).then((json) => {
        _this.redirect('/certificate')
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano certyfikat!')
        _this.setState({ downloadingFile: false })
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  componentDidMount () {
    const { id } = this.props.match.params

    this._isMounted = true

    this.setState({ id })
    this.getCertificateRequest(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile } = this.props
    const { formData } = this.state

    return (
      <>
        <PageHeader
          title='Zgłoszenie o certyfikat'
          onBack={() => this.redirect('/certificate')}
        />
        <CertificateShow
          isMobile={isMobile}
          formData={formData}
          sendCertificateResponse={this.updateCertificateRequest}
          downloadingFile={this.state.downloadingFile}
        />
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(EditOrder))
