import React from 'react'
import {
  Button, List, Skeleton, Row, Col, Image, Typography, Avatar, Space
} from 'antd'
import ReactMarkdown from 'react-markdown'
import { subName, quantityRimLayers, /*quantityUnits,*/ niclyFormatPortions, nl2br } from '../../utils/helpers'
import CtaBox from '../common/ctaBox'
import TrainingItem from '../training/item'
import RecalcRecipeModal from '../../components/recipe/recalcModal'
import Unsleep from '../common/unsleep'
import { Link } from 'react-router-dom'
import { CheckOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

class StyledMainRecipeShow extends React.Component {
  state = {
    showed: false
  }

  isFloat = (value) => {
    return typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
  }

  formatIngUnit = (quantity, unit) => {
    const mapUnit1 = {
      'łyżeczka': 'łyżeczka',
      'łyżka': 'łyżka',
      'szklanka': 'szklanka',
    }

    const mapUnit234 = {
      'łyżeczka': 'łyżeczki',
      'łyżka': 'łyżki',
      'szklanka': 'szklanki',
    }

    const mapUnit5 = {
      'łyżeczka': 'łyżeczek',
      'łyżka': 'łyżek',
      'szklanka': 'szklanek',
    }

    if (mapUnit1[unit] && quantity === 1) {
      return quantity + ' ' + mapUnit1[unit]
    } else if (mapUnit234[unit] && [2, 3, 4].includes(quantity)) {
      return quantity + ' ' + mapUnit234[unit]
    } else if (mapUnit234[unit] && this.isFloat(quantity)) {
      const splited = String(quantity).split('.')
      const mainNumber = splited[0]
      const afterComma = splited[1]

      const digMap = {
        '25': '1/4',
        '5': '1/2',
        '75': '3/4'
      }

      if (mainNumber === '0') {
        return digMap[afterComma] + ' ' + mapUnit234[unit]
      }

      return mainNumber + ' i ' + digMap[afterComma] + ' ' + mapUnit234[unit]
    } else if (mapUnit5[unit] && quantity > 5) {
      return quantity + ' ' + mapUnit5[unit]
    }

    return quantity + ' ' + unit
  }

  render () {
    const { recipe, isMobile, loaded, recalcUsed, authenticated, user, collapse } = this.props
    const { showed } = this.state

    return (
      <>
        <RecalcRecipeModal
          visible={this.props.modalVisible}
          setModalVisibility={this.props.setRecalcRecipeModalVisibility}
          isMobile={isMobile}
          recipe={recipe}
          updateRecipe={this.props.updateRecipe}
          setRecalcUsed={this.props.setRecalcUsed}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          <div style={{ display: loaded ? 'block' : 'none' }}>
            {/*<Image
              preview={false}
              style={{ width: '100%', maxWidth: (this.props.imageMaxSize || 400) + 'px' }}
              src={'/images/' + recipe?.image}
            />*/}

            {recipe?.orig_note && <div>
              <Space direction='horizontal'>
                {recipe?.recipe_author_id && <Avatar icon={<img alt='logo autora przepisu' src='/images/recipe_authors/1.png' />} />}
                <ReactMarkdown className='partner-description'>
                  {recipe?.orig_note}
                </ReactMarkdown>
              </Space>
            </div>}

            <div style={{ marginTop: 20 }}>
              <Space wrap style={{ justifyContent: 'center' }}>
                {!this.props.disableAddToMyBase && <>
                  {(recipe?.user_has_saved || this.props.justAddedToMyBase) && <span><CheckOutlined/> Dodany do Twojej bazy przepisów</span>}
                  {!recipe?.user_has_saved && !this.props.justAddedToMyBase &&
                    <Button type="primary" style={{ marginRight: 10 }} onClick={() =>
                        (!authenticated && recipe?.type === 'free') ||
                        (recipe?.type === 'sub' && !authenticated) ||
                        (recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && !user?.trial_used) ||
                        (recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && user?.trial_used)
                       ? this.props.setCtaModalVisible(true) : this.props.addMyRecipeFromMainRecipe(recipe?.id)}>
                      Dodaj przepis do mojej bazy przepisów
                    </Button>}
                </>}
                {!recalcUsed && <Button style={{ marginRight: 10 }} className='sc-button' onClick={() => this.props.setRecalcRecipeModalVisibility(true)}>
                    Przelicz składniki
                </Button>}
                {recalcUsed && <Button danger style={{ marginRight: 10 }} onClick={() => this.props.cancelRecalc()}>
                    Cofnij przeliczenie
                </Button>}
                {!this.props.disableUnsleep && <Unsleep moduleName={this.props.moduleName} />}
              </Space>
            </div>

            <Typography style={{ marginTop: '30px' }}>
              {recipe?.quantities && recipe?.quantities.length > 0 && <Paragraph strong>
                Przepis na{' '}

                {recipe?.quantities.map((item, i) => (<>
                  {i !== 0 && ' / '}
                  {item.type === 'piece' && <>{item.quantity} {recipe?.suffix || 'sztuk'}</>}
                  {item.type === 'rim' && <>rant {item.quantity} cm</>}
                  {item.type === 'rim_layer' && <>{quantityRimLayers[item.quantity]} rantu {item.rim_size} cm {item.suffix}</>}
                  {item.type === 'weight' && <>{item.quantity} g</>}
                  {item.type === 'portion' && <>
                    {/*item.quantity}{' '*/}
                    {/*Array.from(Array(50).keys()).map(x => <p>{niclyFormatPortions(x)}</p>)*/}
                    {niclyFormatPortions(item.quantity)}
                    {/*(item.quantity === 1 && quantityUnits.portion_1) ||
                    ([2, 3, 4].includes(item.quantity % 10) && quantityUnits.portion_2) ||
                    quantityUnits.portion_3*/}
                  </>}
                </>))}

                {recipe?.cost_sum !== undefined && <Paragraph>Koszt przygotowania: {recipe?.cost_sum} zł brutto</Paragraph>}
              </Paragraph>}

              {collapse && !showed && <Paragraph>
                {recipe?.short_description}{' '}
                <Button onClick={() => this.setState({ showed: true })}>Rozwiń przepis</Button>
              </Paragraph>}

              {(!collapse || showed) && <Paragraph>
                <ReactMarkdown className='partner-description'>
                  {recipe?.description}
                  </ReactMarkdown>
              </Paragraph>}
            </Typography>

            {recipe?.trainings?.length > 0 &&
              <div>
                <Title level={4}>Ten przepis jest wykorzystywany w szkoleniach:</Title>

                <Row gutter={[16, 16]} className='training-list'>
                  {recipe?.trainings?.map(item =>
                    <Col xs={24} md={12} lg={6}>
                      <TrainingItem item={item} redirect={this.props.redirect} />
                    </Col>
                  )}
                </Row>
              </div>}

            {(!collapse || showed) && <Typography style={{ marginTop: '30px' }}>
                {recipe?.tools?.length > 0 &&
                  <>
                    <Title level={4}>Potrzebne narzędzia:</Title>

                    <ul>
                      {recipe?.tools?.map(tool => <li>{tool.content}</li>)}
                    </ul>
                  </>}

                {recipe?.sections?.map((section, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        {recipe?.sections?.length !== 1 && <Title level={3}>{section.name}</Title>}
                        {recipe?.cost_sum !== undefined && <Paragraph>Koszt przygotowania: {section.cost} zł brutto</Paragraph>}

                        <Title level={4}>Składniki:</Title>
                        <List
                          className='recipe-ingredients'
                          dataSource={section.ingredients}
                          renderItem={item => <List.Item>{item.name} {this.formatIngUnit(item.quantity, item.unit)}</List.Item>}
                        />

                        {section.preparings?.length > 0 && <><Title level={4}>Sposób przygotowania:</Title>

                        <List
                          className='recipe-preparings'
                          dataSource={section?.preparings}
                          renderItem={preparing => <List.Item style={!preparing.content ? { justifyContent: 'left', fontStyle: 'italic' } : {}}>{preparing.content ? nl2br(preparing.content) : 'Brak opisu'}
                            {preparing.image && <img src={preparing.image} style={{ maxWidth: '80%' }} alt='prep image' />}
                          </List.Item>}
                        /></>}
                    </div>
                ))}

                {recipe?.preparings?.length > 0 && <><Title level={4}>Ogólne kroki przygotowania:</Title>
                  <Paragraph>
                    <List
                      className='recipe-preparings'
                      dataSource={recipe?.preparings}
                      renderItem={preparing => <List.Item style={!preparing.content ? { justifyContent: 'left', fontStyle: 'italic' } : {}}>{preparing.content ? nl2br(preparing.content) : 'Brak opisu'}
                        {preparing.image && <img src={preparing.image} style={{ maxWidth: '80%' }} alt='prep image' />}
                      </List.Item>}
                    />
                  </Paragraph>
                </>}
            </Typography>}

            {(!collapse || showed) && <>
              {!authenticated && recipe?.type === 'free' &&
                <CtaBox
                  text='Dalsza część przepisu jest dostępna za darmo dla zarejestrowanych użytkowników'
                  toRoute='/register'
                  redirect={[this.props.redirectRoute || '/przepisy/' + recipe?.slug]}
                  buttonText='Dołącz do Strefy Cukiernika'
                />}

              {recipe?.type === 'sub' && !authenticated &&
                <CtaBox
                  text={<>Dalsza część przepisu jest dostępna w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
                  toRoute='/register'
                  redirect={['/pakiety', this.props.redirectRoute || '/przepisy/' + recipe?.slug]}
                  requiredSubs={['sub2', 'sub3']}
                  buttonText='Dołącz do Strefy Cukiernika'
                />}

              {recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && !user?.trial_used &&
                <CtaBox
                  text={<>Dalsza część przepisu jest dostępna w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
                  toRoute='/pakiety'
                  redirect={[this.props.redirectRoute || '/przepisy/' + recipe?.slug]}
                  requiredSubs={['sub2', 'sub3']}
                  buttonText='Wybierz pakiet testowy i uzyskaj dostęp do tego przepisu'
                />}

              {recipe?.type === 'sub' && authenticated && user?.sub_type === 'free' && user?.trial_used &&
                <CtaBox
                  text={<>Dalsza część przepisu jest dostępna w pakietach {subName('sub2')} i {subName('sub3')}</>}
                  toRoute='/pakiety'
                  redirect={[this.props.redirectRoute || '/przepisy/' + recipe?.slug]}
                  requiredSubs={['sub2', 'sub3']}
                  buttonText='Wybierz pakiet i uzyskaj dostęp do tego przepisu'
                />}
              </>}
            {this.props.recipeLink && <div style={{ textAlign: 'center', margin: '20px 0' }}><Button><Link to={this.props.recipeLink}>Zobacz cały przepis</Link></Button></div>}
          </div>
        </div>
      </>
    )
  }
}

export default StyledMainRecipeShow
