import React from 'react'
import {
  Modal, Button, Result
} from 'antd'

class ConfirmModal extends React.Component {
  render () {
    const { reason } = this.props

    return (
      <Modal
        visible={this.props.visible}
        onCancel={() => this.props.setModalVisibility(undefined)}
        mask={false}
        footer={false}
      >
        {reason === 'trial' &&
          <Result
          status="success"
          title="Włączyliśmy okres testowy 7 dni dla Ciebie!"
          subTitle="Już teraz możesz w pełni korzystać z wybranego przez Ciebie pakietu :-)"
          extra={[
            <Button type="primary" key="redirect" onClick={() => this.props.setModalVisibility(undefined)}>
            Ok, pokaż co tam masz!
            </Button>
          ]}
          />}
        {reason === 'success' &&
          <Result
          status="success"
          title="Płatność przebiegła pomyślnie"
          subTitle="Już teraz możesz w pełni korzystać z wybranego przez Ciebie pakietu :-)"
          extra={[
            <Button type="primary" key="redirect" onClick={() => this.props.setModalVisibility(undefined)}>
            Ok, pokaż co tam masz!
            </Button>
          ]}
          />}
      </Modal>
    )
  }
}

export default ConfirmModal
