/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Popconfirm, Button, Menu, Collapse
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import RecipeForm from '../../styledComponents/calcPro/recipeForm'
import { updateRecipe, getRecipe, getPantry, deleteRecipe } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'

import { Redirect } from 'react-router'
const { Panel } = Collapse

class EditRecipe extends React.Component {
  state = {
    formData: undefined,
    id: undefined,
    pantryIngredients: []
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  getPantry = () => {
    getPantry().then((json) => {
      if (this._isMounted) {
        this.setState({ pantryIngredients: json })
      }
    })
  }

  getRecipe = (id) => {
    getRecipe(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })
      }
    })
  }

  updateRecipe = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateRecipe(_this.state.id, data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano przepis!')
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  deleteRecipe = (data) => {
    deleteRecipe(this.state.id).then((json) => {
      openNotification(this.props.t('Success'), 'Pomyślnie usunięto przepis!')
      this.redirect('/recipe')
    })
  }

  componentDidMount () {
    this._isMounted = true

    const { id } = this.props.match.params

    this.setState({ id })
    this.getRecipe(id)
    this.getPantry()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection, pantryIngredients } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const confirmation = (
      <>
        <p>Czy na pewno usunąć przepis?</p>
        {this.state.formData && this.state.formData.related_orders.length > 0 &&
          <>
            <p>Przepis jest używany w poniższych zamówieniach i zostanie z nich usunięty. Wyceny w zamówieniach zostaną automatycznie przeliczone.</p>
            <Collapse className='calculator-results-collapse'>
              <Panel header='Pokaż zamówienia'>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <ul>
                    {this.state.formData.related_orders.map(x => <li key={x.id}>{x.name}</li>)}
                  </ul>
                </div>
              </Panel>
            </Collapse>
          </>}
      </>)

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Popconfirm
          title={confirmation}
          onConfirm={() => this.deleteRecipe()}
          {...popconfirmProps}
        >
          <Menu.Item key='delete-recipe'>
              Usuń
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Popconfirm
        // title='Czy na pewno usunąć przepis?'
        title={confirmation}
        onConfirm={() => this.deleteRecipe()}
        {...popconfirmProps}
        key='delete-recipe'
      >
        <Button>Usuń</Button>
      </Popconfirm>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to='/recipe' />
      ) : (
        <>
          <PageHeader
            title='Edytuj przepis'
            onBack={() => this.makeRedirection()}
            extra={this.state.formData && actions}
          />
          <RecipeForm
            formAction={this.updateRecipe}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            pantryIngredients={pantryIngredients}
          />
        </>
      )
    )
  }
}

export default withTranslation()(EditRecipe)
