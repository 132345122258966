import React from 'react'
import { PageHeader, Col, Row, Empty, Skeleton, Tag, Table } from 'antd'
import { Link } from 'react-router-dom'

class StyledTrainingAdminIndex extends React.Component {
  render () {
    const { data, redirect, isMobile, loaded } = this.props

    const columns = [
      {
        title: 'Szkolenie',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Typ',
        dataIndex: 'type',
        key: 'type',
        render: (x) => (
          <Tag>{x}</Tag>
        )
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/a/training/' + record.id}>pokaż</Link>
          </span>
        )
      }
    ]

    return (
      <>
        <PageHeader
          title='Szkolenia'
          onBack={() => this.props.redirect('/')}
        />
        {loaded &&
          <>
            <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
              <Skeleton active loading={!loaded} />
            </div>
            <div style={{ display: loaded ? 'block' : 'none', maxWidth: '1200px', margin: '0 auto' }}>
              <Table
                className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
                {...this.settings}
                columns={columns}
                dataSource={data}
                locale={{
                  emptyText: <Empty description='Brak zgłoszeń o certyfikaty' />,
                  triggerDesc: 'Sortuj malejąco',
                  triggerAsc: 'Sortuj rosnąco',
                  cancelSort: 'Kliknij aby anulować sortowanie' }}
              />
            </div>
          </>}
      </>

    )
  }
}

    // return (
    //   <>
    //     <PageHeader
    //       title='Szkolenia'
    //       onBack={() => makeRedirection()}
    //       extra={<TrainingSearchForm formAction={this.props.searchTrainings} initialSearch={this.props.initialSearch} loaded={this.props.loaded} />}
    //     />
    //
    //     {searchedFor && <p>Wyniki wyszukiwania dla: {searchedFor}</p>}
    //     <Row gutter={[16, 16]} className='training-list'>
    //       <Col xs={24} md={18} lg={20}>
    //         <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '95%' }}>
    //           <Skeleton active loading={!this.props.loaded} />
    //         </div>
    //         {data.length > 0 && <Row gutter={[16, 16]} className='training-list'>
    //           {data?.map(item =>
    //             <Col xs={24} md={12} lg={6}>
    //               <TrainingItem item={item} redirect={redirect} />
    //             </Col>
    //           )}
    //         </Row>}
    //         {data.length === 0 && this.props.loaded && <Empty description='Brak szkoleń spełniających kryteria' />}
    //       </Col>
    //       <Col xs={24} md={6} lg={4}>
    //         <TrainingFilterForm authors={this.props.authors} trainingCategories={this.props.trainingCategories} loaded={this.props.loaded} initialFilter={this.props.initialFilter} formAction={this.props.filterTrainings} />
    //       </Col>
    //     </Row>
    //     {/*<List
    //       itemLayout='vertical'
    //       size='large'
    //       className='promo-list'
    //       dataSource={data}
    //       renderItem={item => (
    //         <List.Item
    //           key={item.title}
    //           extra={
    //             <Link to={'/training/' + item.id}>
    //               <img
    //                 width={272}
    //                 alt={item.title + ' logo'}
    //                 src={'/images/trainings/' + item.id + '.png'}
    //                 style={{ borderRadius: '50%' }}
    //               />
    //             </Link>
    //           }
    //         >
    //           <List.Item.Meta
    //             title={
    //               <>
    //                 <Link to={'/training/' + item.id}>{item.title}</Link>
    //                 <Tag style={{ position: 'absolute', right: '0' }}>{item.durationNice}</Tag>
    //               </>}
    //             description={<>Poziom: <img className='training-level' src={'/images/babeczka' + item.level + '.png'} alt={trainingLevel(item.level)} title={trainingLevel(item.level)} /></>}
    //           />
    //           <Paragraph>
    //             Autor:{' '}
    //             <Link to={'/training/author/' + item.author.id}>
    //               <Avatar size="small" icon={<img alt={item.author.name + '-img'} src={'/images/trainings/authors/' + item.author.id + '_min.png'} />} />
    //             </Link>{' '}
    //             <Link to={'/training/author/' + item.author.id}>
    //               {item.author.name}
    //             </Link>
    //           </Paragraph>
    //           <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ maxWidth: '500px', margin: '20px auto' }}>
    //             {item.short_description}
    //           </Paragraph>
    //           <Button><Link to={'/training/' + item.id}>Pokaż więcej</Link></Button>
    //         </List.Item>
    //       )}
    //     />*/}
    //   </>

/*<div>
  <Row>
    <Col xs={24} md={24} style={{ textAlign: !isMobile && 'right' }} className='orders-actions'>
      {this.props.screenSize !== 'xs' &&
        <>
          Status:{' '}
          <Segmented
            onChange={this.handleStatus}
            options={selectStatusOptions}
            value={this.state.status}
            style={{ marginBottom: '10px' }}
          />
        </>}
      {this.props.screenSize === 'xs' &&
        <>
          Filtruj po statusie: <b style={{ fontWeight: '800' }}>{this.props.t(this.state.status)}</b>{' '}
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                onClick={(x) => this.handleStatus(x.key)}
                items={[
                  {
                    key: 'toreview',
                    label: 'Do akceptacji'
                  },
                  {
                    key: 'accepted',
                    label: 'Zatwierdzone'
                  },
                  {
                    key: 'new',
                    label: 'Niewypełnione'
                  },
                  {
                    key: 'rejected',
                    label: 'Odrzucone'
                  },
                  {
                    key: 'finished',
                    label: 'Zakończone'
                  },
                  {
                    key: 'all',
                    label: 'Wszystkie'
                  }
                ]}
              />
            }
          >
            <Button type='info' size='small' onClick={e => e.preventDefault()}>
              zmień
            </Button>
          </Dropdown>
        </>
    </Col>
  </Row>
</div>*/

export default StyledTrainingAdminIndex
