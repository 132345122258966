import React from 'react'

class Contact extends React.Component {
  render () {
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <div className='static-content'>
        <h1 style={{ textAlign: 'center' }}>Kontakt z nami</h1>

        <p>Strefa Cukiernika sp. z o.o.</p>
        <p>Siedziba: ul. Jarzębinowa 97/2, 05-825 Książenice</p>
        <p>Email: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>
        <p>Tel: +48 782 070 907</p>
      </div>
    )
  }
}

export default Contact
