/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import OrderForm from '../../styledComponents/calcPro/orderForm'
import { addOrder, getRecipes, getOrdersConfig, getOrderQuotation } from '../../utils/actions'
import moment from 'moment'
import { openNotification } from '../../utils/helpers'
import { menuTypeHook } from '../common/menuTypeHook'

class AddOrder extends React.Component {
  state = {
    formData: {
      to_date: moment(),
      to_time: moment(),
      use_default_h_price: true,
      use_default_margin: true,
      use_default_overhead: true,
    },
    recipes: [],
    date: undefined,
    ordersConfig: {}
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  determineRedirect = (route) => {
    if (this.state.date) {
      this.redirect('/calendar/' + this.state.date.replace(/-/gi, '/'))
    } else {
      this.redirect('/order')
    }
  }

  addOrder = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addOrder(data).then((json) => {
        if (json.error) {
          openNotification('Ups!', json.error)
        } else {
          openNotification(_this.props.t('Success'), 'Pomyślnie dodano zamówienie!')
          _this.determineRedirect()
        }
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  getRecipes = () => {
    getRecipes().then((json) => {
      if (this._isMounted) {
        this.setState({ recipes: json })
      }
    })
  }

  getOrdersConfig = () => {
    getOrdersConfig().then((json) => {
      if (this._isMounted) {
        this.setState({
          ordersConfig: json,
          formData: {
            use_default_h_price: true,
            use_default_margin: true,
            use_default_overhead: true,
            h_price: json.h_price,
            margin: json.margin,
            ...this.state.formData
          }
        })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getRecipes()
    this.getOrdersConfig()

    const { date } = this.props.match.params
    const { formData } = this.state

    if (date) {
      formData.to_date = moment(date, 'YYYY-M-D')
      formData.to_time = moment('08:00', 'HH:mm')
      this.setState({ date, formData })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile } = this.props
    const { formData, recipes, ordersConfig } = this.state

    return (
      <>
        <PageHeader
          title='Dodaj zamówienie'
          onBack={() => this.determineRedirect()}
        />
        <OrderForm
          recipes={recipes}
          formAction={this.addOrder}
          calculateAction={getOrderQuotation}
          formRef={this.formRef}
          isMobile={isMobile}
          formData={formData}
          isNew
          ordersConfig={ordersConfig}
          screenSize={this.props.screenSize}
        />
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(AddOrder))
