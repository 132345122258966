import React from 'react'
import { Result, Button, PageHeader } from 'antd'
import { Link } from 'react-router-dom'

class NotLogged extends React.Component {
  render () {
    const fromRoute = this.props.location?.state?.from

    return (
      <>
        <PageHeader
          // className='back-to-hompage'
          onBack={() => this.props.redirect(fromRoute ? fromRoute : '/')}
          title={this.props.title || 'Dostęp wymaga logowania'}
        />
        <Result
          status={403}
          title={this.props.title || 'Hej! Dostęp do ' + this.props.moduleName + ' wymaga zalogowania'}
          extra={[
            <Button key='signin'>
              <Link to={{ pathname: '/login', state: { redirect: [(this.props.location?.pathname || this.props.redirectRoute)]}}}>Zaloguj się</Link>
            </Button>,
            <Button type='primary' key='register'>
              <Link to={{
                pathname: '/register',
                state: { redirect: [this.props.location?.pathname || this.props.redirectRoute] }
              }}>
                Darmowa rejestracja
              </Link>
            </Button>
          ]}
        >
          {this.props.content || <div>
            <Link to='/'>Dowiedz się więcej o Strefie Cukiernika</Link>
          </div>}
        </Result>
      </>
    )
  }
}

export default NotLogged
