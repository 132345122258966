import React from 'react'
import { Typography, Card } from 'antd'
import { Link } from 'react-router-dom'

const { Paragraph } = Typography
const { Meta } = Card

class StyledTrainingAuthorItem extends React.Component {
  render () {
    const { redirect, item } = this.props

    return (
      <Card
        className='trainer-card'
        style={{ maxWidth: 250 }}
        bordered={false}
        cover={<img alt={item.name + ' logo'} src={'/dimages/trainings/authors/' + item.id + '.png'} onClick={() => redirect('/training/author/' + item.id)} />}
      >
        <Meta description={
          <div className='trainer-list'>
            <Link className='trainer-list-title' to={'/training/author/' + item.id}>{item.name}</Link>

            <Paragraph className='trainer-list-text' ellipsis={{ rows: 2, expandable: false }}>
              {item.description}
            </Paragraph>
            <div className='button-wrapper'>
              {/*<Button size='text' style={{ backgroundColor: item.main_image_color }}><Link to={'/training/author/' + item.id}>Więcej o mnie</Link></Button>*/}
              <Link to={'/training/author/' + item.id}>Więcej o mnie</Link>
            </div>

            {/*<Link to={{ pathname: '/training', state: { trainingFilter: { author: [item.id] } }}}>Moje szkolenia</Link>*/}
          </div>
        } />
      </Card>
    )
  }
}

export default StyledTrainingAuthorItem
