import React from 'react'
import StyledPromoIndex from '../../styledComponents/promo'
import { Redirect } from 'react-router'
import { getPartners } from '../../utils/actions'
import { connect } from 'react-redux'

class PromoIndex extends React.Component {
  state = {
    makeRedirection: false,
    partners: [],
    loaded: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    this._isMounted = true
    this.getPartners()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getPartners = () => {
    getPartners().then((json) => {
      if (this._isMounted) {
        this.setState({ partners: json.partners, loaded: true })
      }
    })
  }

  render () {
    const { makeRedirection, partners, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to={this.props.authenticated ? '/panel' : '/'} />
      ) : (
        <StyledPromoIndex
          makeRedirection={this.makeRedirection}
          partners={partners}
          loaded={loaded}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const PromoIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoIndex)

export default PromoIndexContainer
