import React from 'react'
import { PageHeader } from 'antd'

class InfoDataInfo extends React.Component {
  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <>
        <PageHeader
          onBack={() => this.redirect('/profile')}
          title='Usuń konto'
        />
        <div className='static-content'>


          <h1 style={{ textAlign: 'center' }}>Usuń konto</h1>

          <p>Jeśli chcesz usunąć Twoje konto i wszystkie powiązane z nim dane, napisz do nas wiadomość email: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>

          <p style={{ color: 'red' }}>Pamiętaj, że usunięcie konta jest nieodwracalne i wszystkie wprowadzone przez Ciebie dane zostaną bezpowrotnie usunięte.</p>
        </div>
      </>
    )
  }
}

export default InfoDataInfo
