import React from 'react'
import { Card, Button } from 'antd'

class InspirationPreview extends React.Component {
  state = {
    image: undefined
  }

  componentDidMount () {
    this.props.getInspirationImage(this.props.inspiration.id).then(image => {
      this.setState({ image })
    })
  }

  render () {
    const { image } = this.state
    const { inspiration } = this.props

    const objectTypesNames = {
      cake: 'tort',
      muffin: 'babeczki',
      any: 'dowolnie'
    }

    const objectTypeNice = objectTypesNames[inspiration.object_type]

    return (
      (image && (
        <Card
         style={{
           width: 300
         }}
         className={'inspiration-preview' + (this.props.mark ? ' inspiration-generated' : '')}
         cover={
           <img src={"data:image/png;base64, " + image} alt='generated inspiration' />
         }
         actions={[
           <Button key='use' onClick={() => { this.props.useIdea(inspiration) }}>Użyj pomysłu</Button>,
           <Button key='similar' onClick={() => { this.props.generateSimilar(inspiration) }}>Generuj podobne</Button>
         ]}
       >
         {inspiration.prompt}<br/>
         ({objectTypeNice})
       </Card>)
     ) || ''
    )
  }
}

export default InspirationPreview
