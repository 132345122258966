import React from 'react'
import StyledTrainingAdminShow from '../../../styledComponents/admin/training/show'
import {
  getTraining, startLiveTraining, stopLiveTraining
} from '../../../utils/actions'
import { connect } from 'react-redux'

class TrainingAdminShow extends React.Component {
  state = {
    id: undefined,
    training: undefined,
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  getData = () => {
    const { id } = this.props.match.params

    this.setState({ id })
    this.getTraining(id)
  }

  componentDidMount () {
    this._isMounted = true
    this.getData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  startLive = () => {
    startLiveTraining(this.state.id).then((json) => {
      if (this._isMounted) {
        this.getData()
      }
    })
  }

  stopLive = () => {
    stopLiveTraining(this.state.id).then((json) => {
      if (this._isMounted) {
        this.getData()
      }
    })
  }

  getTraining = (id) => {
    getTraining(id).then((json) => {
      if (this._isMounted) {
        this.setState({ training: json, liveStatus: json.live_status })
      }
    })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { training } = this.state

    return (
      training && <StyledTrainingAdminShow
        redirect={this.redirect}
        singleData={training}
        isMobile={isMobile}
        authenticated={this.props.authenticated}
        startLive={this.startLive}
        stopLive={this.stopLive}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  userRoles: state.appRedux.userRoles
})

const mapDispatchToProps = {
}

const TrainingAdminShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingAdminShow)

export default TrainingAdminShowContainer
