import React from 'react'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender } from '../../utils/helpers'

class IndexModules extends React.PureComponent {
  render () {
    const { dataSource, userRoles, activeModules, isMobile, ...props } = this.props
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper
    } = dataSource

    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          {titleWrapper && (
            <div {...titleWrapper}>
              {titleWrapper.children.map(getChildrenToRender)}
            </div>)}
          <OverPack {...overPackData}>
            <QueueAnim
              type='bottom'
              key='block'
              leaveReverse
              component={Row}
              componentProps={childWrapper}
            >
              {childWrapper.children.filter(x => (typeof userRoles === 'object' && userRoles?.includes(x.requireRole)) || !x.requireRole || (typeof activeModules === 'object' && activeModules?.includes(x.moduleName))).map((block, i) => {
                const { children: item, ...blockProps } = block
                return (
                  <Col key={i.toString()} {...blockProps} onClick={() => this.props.redirect(item.url?.pathname, item.url?.state?.from)}>
                    <div {...item}>
                      {item.children.map(getChildrenToRender)}
                    </div>
                  </Col>
                )
              })}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    )
  }
}

export default IndexModules
