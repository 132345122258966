/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Menu, Button
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import OrderConfigForm from '../../styledComponents/calcPro/orderConfigForm'
import { saveOrdersConfig, getOrdersConfig } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import OrderHelp from '../../styledComponents/calcPro/help'
import { MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'

import { Redirect } from 'react-router'

class EditOrderConfig extends React.Component {
  state = {
    config: undefined
  }

  formRef = React.createRef()
  mobileDropdownRef = React.createRef()

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()
  }

  getOrdersSettings = () => {
    getOrdersConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ config: json })
      }
    })
  }

  saveOrdersConfig = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      saveOrdersConfig(data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano ustawienia!')
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  componentDidMount () {
    this._isMounted = true

    this.getOrdersSettings()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, config } = this.state

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to='/order' />
      ) : (
        <>
          <PageHeader
            title='Ustawienia zamówień'
            onBack={() => this.makeRedirection()}
            extra={actions}
          />
          <OrderHelp
            visible={this.state.helpVisible}
            setVisible={this.handleHelpVisible}
            isMobile={isMobile}
          />
          <OrderConfigForm
            formAction={this.saveOrdersConfig}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={config}
            makeRedirection={this.makeRedirection}
          />
        </>
      )
    )
  }
}

export default withTranslation()(EditOrderConfig)
