/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Modal, Button
} from 'antd'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import TrainingOpinionForm from '../../styledComponents/training/opinionForm'
import { addTrainingOpinion } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import { LoadingOutlined } from '@ant-design/icons'

class OpinionModal extends React.Component {
  state = {
    loading: false
  }

  formRef = React.createRef()

  addTrainingOpinion = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addTrainingOpinion(_this.props.trainingId, data).then((json) => {
        ReactGA.event({ category: 'Training', action: 'Opinion Added For Training: ' + _this.props.trainingId })

        _this.props.setAddedOpinion(true)

        openNotification(_this.props.t('Success'), 'Pomyślnie dodano Twoją opinię! Po zatwierdzeniu przez moderatora Twoja opinia wkrótce pojawi się w tym szkoleniu :)', 5)
        _this.props.setModalVisibility(false)
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  setLoading = (loading) => {
    this.setState({ loading })
  }

  render () {
    const { isMobile } = this.props

    return (
      <Modal
        title='Dodaj swoją opinię'
        visible={this.props.visible}
        onCancel={() => this.props.setModalVisibility(false)}
        footer={[
          <Button
            type='primary'
            key='add-opinion'
            onClick={() => this.formRef.current.submit()}
            disabled={this.state.loading}
          >
            Dodaj opinię{this.state.loading && <> <LoadingOutlined style={{ fontSize: 14 }} spin /></>}
          </Button>,
          <Button key='not-now' onClick={() => {
            this.props.setModalVisibility(false)
            this.props.setOpinionNotNow(true)
            ReactGA.event({ category: 'Training', action: 'Opinion Not Now Clicked For Training: ' + this.props.trainingId })
          }}>Nie teraz</Button>,
        ]}
      >
        <p>Mamy nadzieję, że szkolenie było dla Ciebie przydatne i poszerzyło Twoją wiedzę :)</p>
        <p>Jeśli masz dosłownie odrobinę czasu - mamy małą prośbę do Ciebie - oceń to szkolenie abyśmy wiedzieli jeśli jeszcze coś możemy usprawnić oraz by pomóc innym użytkownikom w podjęciu decyzji, które szkolenia są warte uwagi :) Z góry wielkie dzięki!</p>
        <TrainingOpinionForm
          formAction={this.addTrainingOpinion}
          formRef={this.formRef}
          isMobile={isMobile}
          setLoading={this.setLoading}
        />
      </Modal>
    )
  }
}

export default withTranslation()(OpinionModal)
