import React from 'react'
import { Card, Button } from 'antd'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

class EnlargedImagePreview extends React.Component {
  componentDidMount () {
    if(this.props.wasGenerated) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }

  render () {
    const { preview, downloading } = this.props

    return (
        <Card
          title={!this.props.wasGenerated && 'Ostatnio powiększana grafika'}
          style={!this.props.visible ? { visibility: 'hidden', width: 300 } : { width: 300 }}
          className={'enlarged-image-preview' + (this.props.wasGenerated ? ' enlarged-image-generated' : '')}
          cover={
           <img src={"data:image/png;base64, " + preview} alt='Powiększona grafika' />
          }
       >
         <Button type='primary' size='large' onClick={this.props.downloadEnlargedImage} icon={downloading ? <LoadingOutlined style={{ fontSize: 14 }} spin /> : <DownloadOutlined />} disabled={downloading}>
           Pobierz grafikę
         </Button>
       </Card>
    )
  }
}

export default EnlargedImagePreview
