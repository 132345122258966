/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Modal
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import PantryForm from '../../styledComponents/myrecipes/pantryForm'
import { addMyRecipesPantry } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'

class MyRecipeAddPantry extends React.Component {
  state = {
    formData: {},
    item: undefined
  }

  formRef = React.createRef()

  addPantry = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addMyRecipesPantry(data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie dodano składnik!')
        _this.props.afterSave({ id: json.id, ..._this.state.item})
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  setItem = (item) => {
    this.setState({ item })
  }

  render () {
    const { isMobile } = this.props
    const { formData } = this.state

    return (
      <Modal
        title='Dodaj składnik'
        visible={this.props.visible}
        onCancel={() => this.props.setModalVisibility(false)}
        footer={null}
      >
        <PantryForm
          formAction={this.addPantry}
          formRef={this.formRef}
          isMobile={isMobile}
          formData={formData}
          makeRedirection={this.makeRedirection}
          setItem={this.setItem}
          costCalc={this.props.costCalc}
          isNew
        />
      </Modal>
    )
  }
}

export default withTranslation()(MyRecipeAddPantry)
