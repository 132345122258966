/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import RecipeForm from '../../styledComponents/myrecipes/recipeForm'
import { getMyRecipesPantry, addMyRecipesRecipe, getMyRecipesConfig, getUserProfile } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'

import { Redirect } from 'react-router'

class MyRecipeAddRecipe extends React.Component {
  state = {
    formData: {},
    pantryIngredients: [],
    pantryModalVisible: false,
    costCalc: false
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  formRef = React.createRef()

  getPantry = () => {
    getMyRecipesPantry().then((json) => {
      if (this._isMounted) {
        this.setState({ pantryIngredients: json })
      }
    })
  }

  addRecipe = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addMyRecipesRecipe(data).then((json) => {
        if (json.limit_exceeded) {
          this.props.setCtaModalVisible(true)
        } else if (json.error) {
          openNotification('Ups!', json.error)
        } else {
          openNotification(_this.props.t('Success'), 'Pomyślnie dodano przepis!')
          _this.redirect('/myrecipes/' + json.id)
        }

        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  getMyRecipesConfig = () => {
    getMyRecipesConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ costCalc: json.cost_calc })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getPantry()
    this.getMyRecipesConfig()
    this.getUserProfile()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection, pantryIngredients } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/myrecipes' />
      ) : (
        <>
          <PageHeader
            title='Dodaj przepis'
            onBack={() => this.makeRedirection()}
          />
          <RecipeForm
            formAction={this.addRecipe}
            refreshPantry={this.getPantry}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            pantryIngredients={pantryIngredients}
            costCalc={this.state.costCalc}
          />
        </>
      )
    )
  }
}

export default withTranslation()(MyRecipeAddRecipe)
