import React from 'react'
import { Modal } from 'antd'
import CtaBox from './ctaBox'
import { connect } from 'react-redux'
import { setCtaModalVisible } from '../../store/actions'

class CtaModalMany extends React.Component {
  componentWillUnmount() {
    this.props.setCtaModalVisible(undefined)
  }
  
  render () {
    return (
      <Modal
        visible={this.props.ctaModalVisible}
        onCancel={() => this.props.setCtaModalVisible(undefined)}
        mask={false}
        footer={false}
      >
        {this.props.before || ''}
        {this.props.content?.map(box =>
          <CtaBox
            text={box.text}
            toRoute={box.toRoute}
            redirect={box.redirect}
            buttonText={box.buttonText}
            requiredSubs={box.requiredSubs}
            buttonTextSize={box.buttonTextSize}
            buttonType={box.buttonType}
            buttonCallback={box.buttonCallback}
            callAfterClick={() => {
              box.buttonCallback && box.buttonCallback()
              this.props.setCtaModalVisible(undefined)
            }}
          />)}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ctaModalVisible: state.appRedux.ctaModalVisible
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const CtaModalManyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CtaModalMany)

export default CtaModalManyContainer
