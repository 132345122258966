import React from 'react'
import StyledMainRecipeIndex from '../../styledComponents/recipe/recipeIndex'
import { getMainRecipes } from '../../utils/actions'
// import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'

class MainRecipeIndex extends React.Component {
  state = {
    makeRedirection: false,
    recipes: [],
    loaded: false,
    costCalc: false
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true

    this.getRecipes()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getRecipes = () => {
    getMainRecipes().then((json) => {
      if (this._isMounted) {
        this.setState({ recipes: json, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { recipes, loaded } = this.state

    return (
      <StyledMainRecipeIndex
        isMobile={isMobile}
        recipes={recipes}
        redirect={this.redirect}
        loaded={loaded}
        from_route={this.props.location.state?.from}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const MainRecipeIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainRecipeIndex)

export default MainRecipeIndexContainer
