import React from 'react'
import StyledCertificateRequestIndex from '../../styledComponents/certificates'
import { getCertificateRequests } from '../../utils/actions'
import { connect } from 'react-redux'

class CertificateRequestIndex extends React.Component {
  state = {
    certificateRequests: []
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true

    this.getCertificateRequests()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getCertificateRequests = () => {
    getCertificateRequests().then((json) => {
      if (this._isMounted) {
        this.setState({ certificateRequests: json.certificate_requests })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { certificateRequests } = this.state

    return (
      this.props.authenticated ? <>
        <StyledCertificateRequestIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          certificateRequests={certificateRequests}
          redirect={this.redirect}
        />
      </> : '')
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const CertificateRequestIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateRequestIndex)

export default CertificateRequestIndexContainer
