import React from 'react'
import {
  Button, Menu, Table, List, PageHeader, Empty, Skeleton, Segmented
} from 'antd'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined, DollarCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import StyledMyRecipesMobileMenu from './menuMobile'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import OrderHelp from './help'
import { saveDeviceData, getDeviceData } from '../../utils/helpers'

class StyledPantryIndex extends React.Component {
  state = {
    helpVisible: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  mobileDropdownRef = React.createRef()

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()

    helpVisible && ReactGA.event({ category: 'MyRecipes Pantry', action: 'Help Opened Mobile' })
  }

  handleViewType = (viewType) => {
    this.setState({ viewType })
    saveDeviceData('myrecipes_ingredients_view_type', viewType)
  }

  componentDidMount() {
    const viewType = getDeviceData('myrecipes_ingredients_view_type') === 'onlyused' ? 'onlyused' : 'all'

    this.setState({ viewType })
    this.props.getPantry(viewType === 'onlyused')
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewType } = this.state

    if (prevState.viewType !== viewType) {
      this.props.getPantry(viewType === 'onlyused')
    }
  }

  render () {
    const { pantry, makeRedirection, isMobile, loaded, costCalc } = this.props

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='add-order'
        >
          <Link to='/myrecipes/pantry/add'>Dodaj składnik</Link>
        </Menu.Item>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='add-pantry'
        type='primary'
      >
        <Link to='/myrecipes/pantry/add'>Dodaj składnik</Link>
      </Button>,
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Jednostka',
        dataIndex: 'unit',
        key: 'unit',
        sorter: (a, b) => { return a.unit.localeCompare(b.unit) },
      },
      {
        title: 'Ilość w opakowaniu',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: (a, b) => { return a.quantity - b.quantity },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/myrecipes/pantry/' + record.id}>edytuj</Link>
          </span>
        )
      }
    ]

    if (costCalc) {
      columns.splice(3, 0, {
        title: 'Cena brutto',
        dataIndex: 'price_gross',
        key: 'price_gross',
        render: (x) => (
          x ? <span>{x} zł</span> : '-'
        ),
        sorter: (a, b) => { return a.price_gross - b.price_gross },
      },)
    }

    const selectViewOptions = [{ label: 'wszystkie', value: 'all' }, { label: 'tylko użyte w moich przepisach', value: 'onlyused' }]

    return (
      <>
        <PageHeader
          title='Składniki'
          onBack={() => makeRedirection()}
          extra={actions}
        />
        <OrderHelp
          visible={this.state.helpVisible}
          setVisible={this.handleHelpVisible}
          isMobile={isMobile}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        {isMobile && <Link to='/myrecipes/pantry/add' className={'mobile-add-btn' + (this.props.screenSize === 'xs' ? ' with-menu' : '')}><Button type="primary" shape="circle" size="large">+</Button></Link>}
        {loaded &&
          <div style={{ textAlign: !isMobile && 'right' }} className='orders-actions'>
            Filtruj składniki:{' '}
            <Segmented
              onChange={this.handleViewType}
              options={selectViewOptions}
              value={this.state.viewType}
              style={{ marginBottom: '10px' }}
            />
          </div>}
        <div style={{ display: loaded ? 'block' : 'none' }} className='list'>
          {isMobile &&
            <List
              itemLayout='horizontal'
              pagination
              className='mobile-list'
              dataSource={pantry}
              locale={{ emptyText: <Empty description='Brak składników' /> }}
              renderItem={(item) => (
                <List.Item
                  actions={[<Link key='edit' to={'/myrecipes/pantry/' + item.id}>edytuj</Link>]}
                >
                  <List.Item.Meta
                    onClick={() => this.props.redirect('/myrecipes/pantry/' + item.id)}
                    title={<Link key='edit' to={'/myrecipes/pantry/' + item.id}>{item.name}</Link>}
                    description={<>{item.unit === 'opak.' ? 'opak. (' + item.quantity + ' szt)' : item.quantity + ' ' + item.unit} {costCalc && <><DollarCircleOutlined /> {item.price_gross === 0 || item.price_gross > 0 ? item.price_gross : '-'} zł</>}</>}
                  />
                </List.Item>
              )}
            />}
          {!isMobile && (
            <Table
              className={`settings-table ${pantry && pantry.length ? 'fade-in' : ''}`}
              {...this.settings}
              columns={columns}
              dataSource={pantry}
              rowKey='id'
              locale={{
                emptyText: <Empty description='Brak składników' />,
                triggerDesc: 'Sortuj malejąco',
                triggerAsc: 'Sortuj rosnąco',
                cancelSort: 'Kliknij aby anulować sortowanie' }}
            />)}
        </div>
        {this.props.screenSize === 'xs' && <StyledMyRecipesMobileMenu />}
      </>
    )
  }
}

export default menuTypeHook(StyledPantryIndex)
