export const unitMap = {
  kg: ['g', 'kg', 'szklanka'],
  g: ['g', 'łyżeczka', 'łyżka', 'szklanka'],
  ml: ['ml', 'g', 'kropla', 'łyżka', 'łyżeczka', 'szklanka'],
  szt: ['szt'],
  'opak.': ['szt'],
  l: ['ml', 'l', 'łyżka', 'łyżeczka', 'g', 'szklanka']
}

export const oneGlassGram = 200
export const oneLittleSpoonGram = 5
export const oneSpoonGram = 15
export const oneGlassMl = 200
export const oneLittleSpoonMl = 5
export const oneSpoonMl = 15
export const oneDropMl = 0.2
