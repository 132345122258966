import React from 'react'
import StyledPrintoutTagShow from '../../styledComponents/printout/tagShow'
import { Redirect } from 'react-router'
import { getTagPrintouts } from '../../utils/actions'
import ReactGA from 'react-ga4'

class PrintoutTagShow extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false,
    tag: undefined
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    const { tag } = this.props.match.params
    this._isMounted = true

    this.setState({ tag })
    this.getTagPrintouts(tag)
  }

  componentDidUpdate (prevProps, prevState) {
    const { tag } = this.props.match.params

    if (this.state.tag !== tag) {
      this.setState({ tag })
      this.getTagPrintouts(tag)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getTagPrintouts = (tag) => {
    ReactGA.event({ category: 'Printout Projects', action: 'Printout List Showed By Tag:' + tag})

    getTagPrintouts(tag).then((json) => {
      if (this._isMounted) {
        this.setState({ printouts: json.printouts, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, printouts, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/grafiki-do-wydrukow' />
      ) : (
        <StyledPrintoutTagShow
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          printouts={printouts}
          redirect={this.redirect}
          from_route={this.props.location.state?.from}
          loaded={loaded}
          tag={this.state.tag}
        />
      )
    )
  }
}

export default PrintoutTagShow
