import React from 'react'
import { PageHeader, Menu, Button, Drawer } from 'antd'
import { Dropdown as DropdownMobile, Popup } from 'antd-mobile'
import CalculatorPortionsForm from './calculator'
import { MoreOutlined, CloseOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'

class StyledCalcPortionsIndex extends React.Component {
  state = {
  }

  setDescBoxVisible = (flag, device) => {
    this.setState({ descBoxVisible: flag })
    this.mobileDropdownRef?.current?.close()

    flag && ReactGA.event({ category: 'Calculator Portions', action: 'Description Box Opened ' + device })
  }

  render () {
    const { makeRedirection, formSaving, isMobile } = this.props

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='desc'
          onClick={() => this.setDescBoxVisible(true, 'Mobile')}
        >
          Jak obliczać porcje tortu?
        </Menu.Item>
        {/*<Menu.Item
          key='desc'
        >
          <a
            href='https://www.youtube.com/watch?v=ofRS711nCfk'
            target='_blank'
            rel='noopener noreferrer'
            onClick={ReactGA.event({ category: 'Portions Calculator', action: 'YT Video Opened Mobile' })}
          >
            <YoutubeOutlined /> Obejrzyj jak korzystać z kalkulatora
          </a>
        </Menu.Item>*/}
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='desc'
        onClick={() => this.setDescBoxVisible(true, 'Desktop')}
      >
        Jak obliczać porcje tortu?
      </Button>
      // <Button
      //   key='desc'
      // >
      //   <a
      //     href='https://www.youtube.com/watch?v=ofRS711nCfk'
      //     target='_blank'
      //     rel='noopener noreferrer'
      //     onClick={ReactGA.event({ category: 'Portions Calculator', action: 'YT Video Opened Desktop' })}
      //   >
      //     <YoutubeOutlined /> Obejrzyj jak korzystać z kalkulatora
      //   </a>
      // </Button>,
    ]) || [actionsMenuMobile]

    const calcDesc = (
      <>
        <h2>Torty okrągłe</h2>
        <p>Obliczanie porcji tortów okrągłych odbywa się za pomocą wyliczenia wycinka o określonej wielkości z całego tortu, tak aby jedna wykrojona porcja miała ok 100-150g wagi. Podany podgląd wielkości kawałka tortu jest czysto orientacyjny, dla większych średnic oczywiście nie jest konieczne wycinać porcji w sposób tradycyjny tzn. jako trójkąt. Jednakże obliczanie ilości porcji w ten sposób daje pewność, że krojąc innymi sposobami również tych porcji nie zabraknie.</p>

        <h2>Torty kwadratowe i prostokątne</h2>
        <p>Obliczanie porcji tortów prostokątnych odbywa się poprzez próbę "zmieszczenia" porcji o zdefiniowanej w kalkulatorze wielkości w powierzchni tortu.</p>

        <h2>Dodatkowe opcje</h2>
        <p><b style={{ fontWeight: '700' }}>Licz tort piętrowy</b>: kalkulator umożliwia definiowanie wielu pięter podczas pracy z kalkulatorem i obliczanie ilości porcji dla całego tortu (opcja dostępna w pakietach płatnych Strefy Cukiernika)</p>
        <p><b style={{ fontWeight: '700' }}>Automatycznie licz rozmiar porcji</b>: dla tortów okrągłych kalkulator sam podpowie Ci jakieś wielkości powinien być wycinek określający wielkość porcji aby uzyskać typowe wielkości porcji</p>
      </>
    )

    return (
      <>
        <PageHeader
          title='Kalkulator porcji'
          onBack={() => makeRedirection()}
          extra={actions}
        />
        {isMobile
          ? (
            <Popup
              visible={this.state.descBoxVisible}
              onMaskClick={() => {
                this.setDescBoxVisible(false)
              }}
              bodyStyle={{ height: '50vh' }}
            >
              <div className='calculator-help-popup'>
                <Button type='text' className='calculator-help-popup-close' onClick={() => { this.setDescBoxVisible(false) }}><CloseOutlined /></Button>
                <h2>Obliczanie porcji - ważne informacje</h2>

                {calcDesc}
              </div>
            </Popup>)
          : (
            <Drawer
              title='Obliczanie porcji - ważne informacje'
              placement='right'
              width={450}
              onClose={() => {
                this.setDescBoxVisible(false)
              }}
              visible={this.state.descBoxVisible}
            >
              <div className='calculator-help-drawer'>
                {calcDesc}
              </div>
            </Drawer>
          )}

        <div>
          <CalculatorPortionsForm
            formSaving={formSaving}
            calculateAction={this.props.calculateAction}
            isMobile={isMobile}
            setCtaModalVisible={this.props.setCtaModalVisible}
            user={this.props.user}
          />
        </div>
      </>
    )
  }
}

export default StyledCalcPortionsIndex
