import React from 'react'
import {
  Skeleton, Descriptions, Row, Col, Image, Avatar, Button
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import HelpBox from '../common/helpBox'
import CtaBox from '../common/ctaBox'

class StyledCalendarShow extends React.Component {
  state = {
    loaded: false,
    visibleLicenseInfo: false
  }

  setVisibleLicenseInfo = (flag) => {
    this.setState({ visibleLicenseInfo: flag })

    flag && ReactGA.event({ category: 'Topper Projects', action: 'License Info Opened Mobile' })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.topper !== this.props.topper) {
      this.setState({
        loaded: true
      })
    }
  }

  render () {
    const { topper, isMobile, downloading, authenticated } = this.props
    const { loaded } = this.state

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          <div style={{ display: loaded ? 'block' : 'none' }}>
            <Row>
              <Col xs={24} md={12}>
                <Image
                  hoverable='true'
                  style={{ width: '100%', maxWidth: '400px' }}
                  src={'/images/toppers/' + topper?.id + '_mid.png'}
                  preview={{
                    src: '/images/toppers/' + topper?.id + '.png',
                  }}
                />
              </Col>
              <Col xs={24} md={12} style={ isMobile ? { paddingLeft: '20px', paddingRight: '20px' } : {} }>
                <Descriptions title='Informacje o projekcie' bordered column={1} layout={isMobile ? 'vertical' : undefined}>
                  <Descriptions.Item label='Format'>{topper?.format}</Descriptions.Item>
                  <Descriptions.Item label='Autor'>
                    <Avatar size="small" icon={<img src='/images/toppers/monika.jpg' alt='Monika' />} /> {topper?.author}<br/>
                    kontakt: <a href="mailto:monika@strefacukiernika.pl">monika@strefacukiernika.pl</a>
                  </Descriptions.Item>
                  <Descriptions.Item label='Na jaką uroczystość?'>{topper?.category}</Descriptions.Item>
                  <Descriptions.Item label='Opis'>{topper?.description}</Descriptions.Item>
                  <Descriptions.Item label='Gdzie można zamówić wykonanie toppera?'>Pobrany projekt możesz zrealizować u jednego z naszych partnerów: <Link to='/promotions/534'>Laser Project Studio</Link> lub <Link to='/promotions/533'>Tortdecor</Link></Descriptions.Item>
                  <Descriptions.Item label='Licencja'>
                    <HelpBox
                      title='Informacje o licencji'
                      component='button'
                      buttonLabel='Zapoznaj się z licencją'
                      category='Topper Project Show'
                      content='Możesz wykorzystać ten projekt nieodpłatnie na potrzebny realizacji swoich prywatnych wypieków jak i na potrzeby zamówień Twoich klientów (bez ograniczeń ilościowych). Zabroniona jest dalsza odsprzedaż projektu oraz masowa produkcja topperów według tego projektu (np. umieszczenie w sklepie z topperami) bez zgody autora.'
                      visible={this.state.visibleLicenseInfo}
                      setVisible={this.setVisibleLicenseInfo}
                      isMobile={isMobile} />
                  </Descriptions.Item>

                </Descriptions>
                <Button type='primary' style={{ marginTop: '30px' }} onClick={this.props.download} disabled={downloading || !authenticated}>
                  {downloading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
                  Pobierz projekt
                </Button>

                {!authenticated &&
                  <CtaBox
                    text='Ten projekt jest dostępny za darmo dla zarejestrowanych użytkowników'
                    toRoute='/register'
                    redirect={['/topper/' + topper?.id]}
                    buttonText='Dołącz do Strefy Cukiernika'
                    noMargin
                  />}

                {topper?.video && (
                  <div className='topper-video'>
                    <Col xs={24} md={24}>
                      Film z wyciętego toppera u naszego partnera <Link to='/promotions/534'>Laser Project Studio</Link>:
                    </Col>
                    <Col xs={24} md={24}>
                      <iframe
                        src={topper?.video}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        title='video'
                        className={isMobile ? 'promo-video-iframe-mobile' : 'promo-video-iframe'}
                      />
                    </Col>
                  </div>)}
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCalendarShow))
