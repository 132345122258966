import React from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Result } from 'antd'

class StyledUserPurchase extends React.Component {
  render () {
    const { reason, redirectRoute } = this.props

    return (
      <div>
        {reason === 'trial' &&
          <Result
          status="success"
          title="Włączyliśmy okres testowy 7 dni dla Ciebie!"
          subTitle="Już teraz możesz w pełni korzystać z wybranego przez Ciebie pakietu :-)"
          extra={[
            <Button type="primary" key="redirect" onClick={() => this.props.redirect(redirectRoute || '/training')}>
            Ok, pokaż co tam masz!
            </Button>
          ]}
          />}
        {reason === 'success' &&
          <Result
          status="success"
          title="Płatność przebiegła pomyślnie"
          subTitle="Już teraz możesz w pełni korzystać z wybranego przez Ciebie pakietu :-)"
          extra={[
            <Button type="primary" key="redirect" onClick={() => this.props.redirect(redirectRoute || '/training')}>
            Ok, pokaż co tam masz!
            </Button>
          ]}
          />}
        {reason === 'canceled' &&
          <Result
          status="error"
          title="Płatność została anulowana"
          subTitle="Jeśli chcesz wybrać pakiet ponownie, przejdź jeszcze raz do wyboru pakietów"
          extra={[
            <Button type="primary" key="sub_again" onClick={() => this.props.redirect('/pakiety')}>
            Wybierz pakiet ponownie
            </Button>,
            <Button key="home" onClick={() => this.props.redirect('/')}>
            Nie, dzięki - przejdź do strony głównej
            </Button>,
          ]}
          />}
      </div>
    )
  }
}

export default withTranslation()(StyledUserPurchase)
