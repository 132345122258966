/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { Alert } from 'antd'
import { enquireScreen } from 'enquire-js'
import IndexModules from './indexModules'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { saveDeviceData, getDeviceData } from '../../utils/helpers'

export const PanelModulesDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  // titleWrapper: {
  //   className: 'title-wrapper',
  //   children: []
  //   // children: [{ name: 'title', children: 'Twoje moduły' }]
  // },
  // <Badge.Ribbon text='Nowe'>
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block7',
        moduleName: 'trainings',
        requireRole: 'ROLE_TESTER',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/training',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/training',
                state: { from: 'panel_index' }
              }}
              >
                <img src='/images/trainings.png' alt='trainings' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/training',
                state: { from: 'panel_index' }
              }}
              >
                Szkolenia
              </Link>
            }
          ]
        }
      },
      {
        name: 'block6',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/myrecipes',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/myrecipes',
                state: { from: 'panel_index' }
              }}
              ><img src='/images/recipes.png' alt='myrecipes' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/myrecipes',
                state: { from: 'panel_index' }
              }}
              >
                Moje przepisy
              </Link>
            }
          ]
        }
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/calculator'
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to='/calculator'><img src='/images/calculator.png' alt='calculator' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to='/calculator'>Kalkulator do wycen</Link>
            }
            // {
            //   name: 'content',
            //   children: 'Wyceniaj swoje wypieki z pomocą kalkulatora aby nie zapomnieć o żadnym koszcie i dowiedzieć się ile faktycznie zarabiasz :)'
            // }
          ]
        }
      },
      {
        name: 'block5',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/calculator-portions'
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to='/calculator-portions'><img src='/images/calculator-portions.png' alt='calculator portions' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to='/calculator-portions'>Kalkulator porcji</Link>
            }
            // {
            //   name: 'content',
            //   children: 'Wyceniaj swoje wypieki z pomocą kalkulatora aby nie zapomnieć o żadnym koszcie i dowiedzieć się ile faktycznie zarabiasz :)'
            // }
          ]
        }
      },
      {
        name: 'block9',
        moduleName: 'community',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/community',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/community',
                state: { from: 'panel_index' }
              }}
              >
                <img src='/images/community.png' alt='społeczność' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/community',
                state: { from: 'panel_index' }
              }}
              >
                Społeczności
              </Link>
            }
          ]
        }
      },
      {
        name: 'block8',
        moduleName: 'inspirations',
        requireRole: 'ROLE_TESTER',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/inspiracje-ai',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/inspiracje-ai',
                state: { from: 'panel_index' }
              }}
              >
                <img src='/images/inspirations.png' alt='inspiracje ai' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/inspiracje-ai',
                state: { from: 'panel_index' }
              }}
              >
                Generuj inspirację AI
              </Link>
            }
          ]
        }
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/partnerzy'
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to='/partnerzy'><img src='/images/partners.png' alt='partners' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to='/partnerzy'>Zaufane sklepy i twórcy</Link>
            }
            // { name: 'content', children: 'Sprawdzone sklepy i twórcy w branży cukierniczej oraz atrakcyjne kody rabatowe' }
          ]
        }
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/order',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/order',
                state: { from: 'panel_index' }
              }}
              ><img src='/images/orders.png' alt='orders' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/order',
                state: { from: 'panel_index' }
              }}
              >
                System zamówień
              </Link>
            }
            // {
            //   name: 'content',
            //   children: 'Wyceniaj swoje wypieki z pomocą kalkulatora aby nie zapomnieć o żadnym koszcie i dowiedzieć się ile faktycznie zarabiasz :)'
            // }
          ]
        }
      },
      {
        name: 'block9',
        moduleName: 'printouts',
        requireRole: 'ROLE_TESTER',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/grafiki-do-wydrukow',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/grafiki-do-wydrukow',
                state: { from: 'panel_index' }
              }}
              >
                <img src='/images/graphics.png' alt='grafiki do wydruków' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/grafiki-do-wydrukow',
                state: { from: 'panel_index' }
              }}
              >
                Grafiki do wydruków
              </Link>
            }
          ]
        }
      },
      {
        name: 'block10',
        moduleName: 'enlarge_images',
        requireRole: 'ROLE_TESTER',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/powieksz-grafike',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/powieksz-grafike',
                state: { from: 'panel_index' }
              }}
              >
                <img src='/images/enlarge.png' alt='powiekszanie grafik' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/powieksz-grafike',
                state: { from: 'panel_index' }
              }}
              >
                Powiększanie grafik
              </Link>
            }
          ]
        }
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 8,
        xs: 24,
        sm: 12,
        lg: 6,
        children: {
          className: 'content0-block-item',
          url: {
            pathname: '/topper',
            state: { from: 'panel_index' }
          },
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to={{
                pathname: '/topper',
                state: { from: 'panel_index' }
              }}
              ><img src='/images/toppers.png' alt='toppers' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to={{
                pathname: '/topper',
                state: { from: 'panel_index' }
              }}
              >
                Projekty topperów
              </Link>
            }
          ]
        }
      }
    ]
  }
}

const { location = {} } = typeof window !== 'undefined' ? window : {}

class PanelIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port,
      dummyDone: false
    }
  }

  redirect = (pathname, from) => {
    this.props.history.push({ pathname, state: { from } })
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }

    const showSaveAlert = getDeviceData('panel_welcome_alert') !== 'true'

    this.setState({ showSaveAlert })
  }

  handleSaveAlertClose = () => {
    saveDeviceData('panel_welcome_alert', 'true')
  }

  render () {
    const children = [
      <IndexModules
        id='Content0_0'
        key='Content0_0'
        dataSource={PanelModulesDataSource}
        isMobile={this.state.isMobile}
        redirect={this.redirect}
        userRoles={this.props.userRoles || []}
        activeModules={this.props.activeModules || []}
      />
    ]

    return (
      <div
        className='templates-wrapper panel'
        ref={(d) => {
          this.dom = d
        }}
      >
        {/*this.state.showSaveAlert ? (
          <div className='alert-wrapper'>
            <Alert
              className={'panel-alert' + (this.state.isMobile ? ' on-mobile' : '')}
              closable
              onClose={this.handleSaveAlertClose}
              message={
                <>
                  Witaj w Strefie Cukiernika! Do Twojej dyspozycji oddajemy na początek listę naszych zaufanych partnerów oraz kilka narzędzi do pracy z wypiekami. W razie problemów, uwag lub pomysłów <a href='mailto:kontakt@strefacukiernika.pl'>napisz do nas</a>. Wkrótce pojawią się tutaj kolejne moduły więc zachęcamy do odwiedzin. Miłego dnia :))
                </>
              }
              type='info'
            />
          </div>) : ''*/}
        {this.state.show && children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userRoles: state.appRedux.userRoles,
  activeModules: state.appRedux.activeModules
})

const PanelIndexContainer = connect(
  mapStateToProps
)(PanelIndex)

export default PanelIndexContainer
