import React from 'react'
import StyledConfirmModal from '../../styledComponents/userPurchase/confirmModal'
import { setConfirmModalVisible } from '../../store/actions'
import { connect } from 'react-redux'

class ConfirmModal extends React.Component {
  state = {
    formSaving: false,
    successfull: undefined
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.confirmModalVisible === undefined && this.props.confirmModalVisible === 'trial') {
      this.doAnimation()
    }
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  doAnimation = () => {
    let W = window.innerWidth;
    let H = document.getElementById('confetti').clientHeight;
    const canvas = document.getElementById('confetti');
    const context = canvas.getContext("2d");
    const maxConfettis = 30;
    const particles = [];
    let frame = 0;
    const maxFramses = 300;

    const possibleColors = [
      "#ff7336",
      "#f9e038",
      "#02cca4",
      "#383082",
      "#fed3f5",
      "#b1245a",
      "#f2733f"
    ];

    function randomFromTo(from, to) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    }

    function confettiParticle() {
      this.x = Math.random() * W; // x
      this.y = Math.random() * H - H; // y
      this.r = randomFromTo(11, 33); // radius
      this.d = Math.random() * maxConfettis + 11;
      this.color =
        possibleColors[Math.floor(Math.random() * possibleColors.length)];
      this.tilt = Math.floor(Math.random() * 33) - 11;
      this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
      this.tiltAngle = 0;

      this.draw = function() {
        context.beginPath();
        context.lineWidth = this.r / 2;
        context.strokeStyle = this.color;
        context.moveTo(this.x + this.tilt + this.r / 3, this.y);
        context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        return context.stroke();
      };
    }

    function Draw() {
      const results = [];

      if (frame < maxFramses) {
        requestAnimationFrame(Draw);
        frame += 1
      }

      context.clearRect(0, 0, W, window.innerHeight);

      for (let i = 0; i < maxConfettis; i++) {
        results.push(particles[i].draw());
      }

      let particle = {};
      for (let i = 0; i < maxConfettis; i++) {
        particle = particles[i];

        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;
      }

      return results;
    }

    // Push new confetti objects to `particles[]`
    for (var i = 0; i < maxConfettis; i++) {
      particles.push(new confettiParticle());
    }

    // Initialize
    canvas.width = W;
    canvas.height = H;
    Draw();
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    return (
      <div>
        {this.props.confirmModalVisible !== undefined && <canvas id="confetti"></canvas>}

        <StyledConfirmModal
          reason={this.props.confirmModalVisible}
          redirect={this.redirect}
          visible={this.props.confirmModalVisible !== undefined}
          setModalVisibility={this.props.setConfirmModalVisible}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  confirmModalVisible: state.appRedux.confirmModalVisible
})

const mapDispatchToProps = {
  setConfirmModalVisible
}

const ConfirmModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmModal)

export default ConfirmModalContainer
