import React from 'react'
import { Result, PageHeader } from 'antd'
import { Link } from 'react-router-dom'

class NoAccess extends React.Component {
  render () {
    const fromRoute = this.props.location?.state?.from

    return (
      <>
        <PageHeader
          // className='back-to-hompage'
          onBack={() => this.props.redirect(fromRoute ? fromRoute : '/')}
          title={this.props.title || 'Brak dostępu'}
        />
        <Result
          status={403}
          title={this.props.title || 'Hej! Dostęp do ' + this.props.moduleName + ' wymaga zalogowania'}
        >
          {this.props.content || <div>
            <Link to='/'>Dowiedz się więcej o Strefie Cukiernika</Link>
          </div>}
        </Result>
      </>
    )
  }
}

export default NoAccess
