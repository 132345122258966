import React from 'react'
import {
  Skeleton, Descriptions, Tag
} from 'antd'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import { getPaymentStatusColor, getPaymentErrorCodeInfo, getPaymentDecelineCodeInfo } from '../../utils/helpers'

class StyledPaymentShow extends React.Component {
  state = {
    loaded: false,
    visibleLicenseInfo: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.payment !== this.props.payment) {
      this.setState({
        loaded: true
      })
    }
  }

  render () {
    const { payment, isMobile } = this.props
    const { loaded } = this.state

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          <div style={{ display: loaded ? 'block' : 'none', maxWidth: '1000px', margin: '0 auto' }}>
              <Descriptions title='Informacje o płatności' bordered column={1} layout={isMobile ? 'vertical' : undefined}>
              <Descriptions.Item label='Produkt'>{payment?.item_title}</Descriptions.Item>
              {/*<Descriptions.Item label='Rodzaj'>{payment?.type === 'sub' ? 'subskrypcja' : 'płatność jednorazowa'}</Descriptions.Item>*/}
              <Descriptions.Item label='Status'><Tag color={getPaymentStatusColor(payment?.status)}>{this.props.t(payment?.status)}</Tag></Descriptions.Item>
              <Descriptions.Item label='Kwota'>{payment?.amount} zł</Descriptions.Item>
              <Descriptions.Item label='Data operacji'>{payment?.created_at}</Descriptions.Item>
              {payment?.status === 'error' && <Descriptions.Item label='Szczegóły operacji'>
                <b style={{ fontWeight: 600 }}>{getPaymentErrorCodeInfo(payment?.details?.code)}{payment?.details?.code === 'card_declined' && ': ' + getPaymentDecelineCodeInfo(payment?.details?.decline_code)}</b>
              </Descriptions.Item>}
            </Descriptions>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledPaymentShow))
