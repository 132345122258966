/* eslint no-unused-expressions: "off" */
import React from 'react'
import ReactGA from 'react-ga4'
import StyledMainRecipeShow from '../../styledComponents/recipe/recipeShow'

class MainRecipeShowItem extends React.Component {
  state = {
    recipe: undefined,
    id: undefined,
    loaded: false
  }

  updateRecipe = (recipe) => {
    this.setState({ recipe })
  }

  componentDidUpdate (prevProps, prevState) {
    if(!prevProps.recipe && this.props.recipe) {
      this.setState({ recipe: this.props.recipe,
        origRecipe: { ...this.props.recipe }
      })
    }
  }

  componentDidMount () {
    this.setState({ recipe: this.props.recipe,
      origRecipe: { ...this.props.recipe }
    })
  }

  setRecalcModalVisible = (recalcModalVisible) => {
    this.setState({ recalcModalVisible })

    recalcModalVisible && ReactGA.event({ category: 'Main Recipe', action: 'Recalc Modal Opened For Recipe: ' + this.state.slug })
  }

  setRecalcUsed = (recalcUsed) => {
    this.setState({ recalcUsed })
  }

  cancelRecalc = () => {
    this.setState({ recipe: this.state.origRecipe, recalcUsed: false })

    ReactGA.event({ category: 'Main Recipe', action: 'Recalc Cancel Clicked For Recipe: ' + this.state.slug })
  }

  render () {
    const { isMobile, authenticated } = this.props
    const { recipe } = this.state

    return (
      <StyledMainRecipeShow
        recipe={recipe}
        loaded={this.props.loaded}
        setRecalcRecipeModalVisibility={this.setRecalcModalVisible}
        modalVisible={this.state.recalcModalVisible}
        isMobile={isMobile}
        updateRecipe={this.updateRecipe}
        setRecalcUsed={this.setRecalcUsed}
        recalcUsed={this.state.recalcUsed}
        cancelRecalc={this.cancelRecalc}
        authenticated={authenticated}
        user={this.state.user}
        redirect={this.redirect}
        imageMaxSize={this.props.imageMaxSize}
        recipeLink={this.props.recipeLink}
        collapse={this.props.collapse}
        disableUnsleep={this.props.disableUnsleep}
        disableAddToMyBase={this.props.disableAddToMyBase}
        moduleName={this.props.moduleName}
        redirectRoute={this.props.redirectRoute}
        justAddedToMyBase={this.props.justAddedToMyBase}
        setCtaModalVisible={this.props.setCtaModalVisible}
        addMyRecipeFromMainRecipe={this.props.addMyRecipeFromMainRecipe}
      />
    )
  }
}

export default MainRecipeShowItem
