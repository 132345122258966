import React from 'react'
import { PageHeader } from 'antd'
import QueueAnim from 'rc-queue-anim'
import StyledUserLogin from '../../styledComponents/user/loginForm'
import { withTranslation } from 'react-i18next'
import { setAuth, setUserRoles, setActiveModules, setSubType } from '../../store/actions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

class UserLogin extends React.PureComponent {
  redirect = (pathname, redirect) => {
    this.props.history.push({ pathname, state: { redirect } })
  }

  render () {
    const { authenticated, setAuth, setUserRoles, setActiveModules, setSubType, ...currentProps } = this.props
    const redirectRoute = this.props.location.state?.redirect
    const requiredSubs = this.props.location.state?.requiredSubs

    return (
      authenticated ? (
        <Redirect to='/panel' />
      ) : (
        <div {...currentProps} className='banner1'>
          <PageHeader
            className='back-to-hompage'
            onBack={!window.matchMedia('(display-mode: standalone)').matches ? () => this.redirect((redirectRoute?.length && redirectRoute.slice(-1)) || '/', redirectRoute?.length > 1 && redirectRoute.slice(1)) : null}
            title={this.props.t('Log in')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner1-text-wrapper'
          >
            <div key='content' className='banner1-content'>
              <StyledUserLogin
                setAuth={setAuth}
                setUserRoles={setUserRoles}
                setActiveModules={setActiveModules}
                setSubType={setSubType}
                redirect={this.redirect}
                redirectRoute={redirectRoute}
                requiredSubs={requiredSubs}
              />
            </div>
          </QueueAnim>
        </div>)
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth,
  setUserRoles,
  setActiveModules,
  setSubType
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogin)

export default withTranslation()(RoutesContainer)
