import axios from 'axios'
import { openNotification, getDeviceData, saveDeviceData, removeDeviceData } from './helpers'
import settings from '../config'
import { setAuth } from '../store/actions'
import store from '../store'

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500
}

// intercept request to add authorization token
axios.interceptors.request.use(config => {
  const token = getDeviceData('auth')
  if (token) {
    config.headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + token
  }
  config.headers.common['X-LOCALE'] = 'pl_PL'
  return config
}, error => {
  Promise.reject(error)
})

// intercept response to catch http:401 to call refresh token or to logout user when refresh token is dead
axios.interceptors.response.use((response) => {
  if (response) {
    const originalRequest = response.config

    if (response.status === 401 && ['/api/token/refresh', '/api/api/token/refresh'].includes(new URL(originalRequest.url).pathname)) {
      // logout user
      console.log('Session expired (refresh token expired)')
      store.dispatch(setAuth(false))
      removeDeviceData('auth')
      removeDeviceData('refresh_token')
      throw new axios.Cancel('Session expired')
    }

    if (response.status === 401 && new URL(originalRequest.url).pathname !== '/api/login_check' && new URL(originalRequest.url).pathname !== '/api/api/login_check') {
      return axios.post(`${settings.api_url}/api/token/refresh`, { refresh_token: getDeviceData('refresh_token') })
        .then(res => {
          if (res.status === 200) {
            saveDeviceData('auth', res.data.token)
            saveDeviceData('refresh_token', res.data.refresh_token)
            return axios(originalRequest)
          }
        })
    }
  }

  return response
}, (error) => {
  return Promise.reject(error)
})

// export function getServers () {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'GET',
//       url: `${settings.api_url}/api/server/`
//     }).then(function (response) {
//       if (response.status === 200) {
//         resolve(response.data)
//       } else {
//         openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
//         reject(response.data)
//       }
//     }).catch(function (error) {
//       openNotification('Ups, wystąpił błąd!', error.message)
//       reject(error)
//     })
//   })
// }
//
// export function getServer (id) {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'GET',
//       url: `${settings.api_url}/api/server/${id}`
//     }).then(function (response) {
//       if (response.status === 200) {
//         resolve(response.data)
//       } else {
//         openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
//         reject(response.data)
//       }
//     }).catch(function (error) {
//       openNotification('Ups, wystąpił błąd!', error.message)
//       reject(error)
//     })
//   })
// }

// export function updateServer (id, data) {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'POST',
//       url: `${settings.api_url}/api/server/${id}`,
//       data: data
//     }).then(function (response) {
//       if (response.status === 200) {
//         resolve(response.data)
//       } else {
//         openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
//         reject(response.data)
//       }
//     }).catch(function (error) {
//       openNotification('Ups, wystąpił błąd!', error.message)
//       reject(error)
//     })
//   })
// }
//
// export function addServer (data) {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'POST',
//       url: `${settings.api_url}/api/server/`,
//       data: data
//     }).then(function (response) {
//       if (response.status === 200) {
//         resolve(response.data)
//       } else {
//         openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
//         reject(response.data)
//       }
//     }).catch(function (error) {
//       openNotification('Ups, wystąpił błąd!', error.message)
//       reject(error)
//     })
//   })
// }

export function authenticateUser (username, password) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/login_check`,
      data: {
        username: username,
        password: password
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function requestUserResetPassword (email) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/forgotten-password/request`,
      data: {
        email
      }
    }).then(function (response) {
      if (response.status === 200) {
        openNotification('Sukces!', (response.data && response.data.message))
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function joinNewsletter (hash) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/newsletter/join`,
      data: {
        hash
      }
    }).then(function (response) {
      if (response.status === 200) {
        // openNotification('Sukces!', (response.data && response.data.message))
        resolve(response.data)
      } else {
        // openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function setUserResetPassword (token, password, passwordRepeat) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/forgotten-password/change/${token}`,
      data: {
        password,
        password_repeat: passwordRepeat
      }
    }).then(function (response) {
      if (response.status === 200) {
        openNotification('Sukces!', (response.data && response.data.message))

        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function registerUser (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/register`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getUserProfile () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/user/profile?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateUserProfile (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/user/profile/save`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateSubscriptionCancelProgress (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/user/subscription_cancel`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function logoutUser () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/logout`,
      data: {
        refresh_token: getDeviceData('refresh_token')
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function dummy () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/dummy?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function saveOrdersConfig (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/orders_config/save`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrdersConfig (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/orders_config?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function calculateCalcPortions (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_portions/calculate`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function calculateCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/calculate`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function saveCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/save`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function saveSubmitCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/submit_save`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function getCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/calc_pricing?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function savePromoUse (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/promo/${id}/save`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function getOrders () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrdersForDay (day) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order/to_date/${day}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrder (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateOrder (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteOrder (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addOrder (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrderQuotation (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/quotation`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function setOrderStatus (id, status) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}/status`,
      data: { status }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPantry () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/pantry?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/pantry/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updatePantryItem (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deletePantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addPantry (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getRecipes () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/recipe?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addRecipe (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateRecipe (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/recipe/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getToppers () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/topper?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTopper (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/topper/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadTopper (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/topper/${id}/download?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// printouts
export function getPrintouts () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/printout?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTagPrintouts (tag) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/printout/by_tag/${tag}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPrintout (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/printout/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadPrintout (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/printout/${id}/download?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// partners
export function getPartners () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/partner?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPartner (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/partner/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPartnerCodes (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/partner/${id}/code?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// myrecipes
export function getMyRecipesPantry (onlyUsed) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/myrecipes/pantry${onlyUsed ? '?onlyused=true' : ''}${onlyUsed ? '&' : '?'}t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getMyRecipesPantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/myrecipes/pantry/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateMyRecipesPantryItem (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/pantry/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteMyRecipesPantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/pantry/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addMyRecipesPantry (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/pantry/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getMyRecipesRecipes () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/myrecipes/recipe?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addMyRecipesRecipe (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/recipe/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateMyRecipesRecipe (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/recipe/${id}/update`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteMyRecipesRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/recipe/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getMyRecipesRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/myrecipes/recipe/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function saveMyRecipesConfig (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/save_config`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getMyRecipesConfig () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/myrecipes/config?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTrainingAuthors () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/training_author?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTrainings () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/training?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getCertificateRequests () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/certificate/request?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTraining (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/training/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateTrainingPlay (id, playTime, finished, playedSeconds, tmpKey) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/training/${id}/play`,
      data: { play_time: playTime, played_seconds: playedSeconds, finished, tmp_key: tmpKey }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function startLiveTraining (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/training/${id}/start_live`,
      data: {}
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function stopLiveTraining (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/training/${id}/stop_live`,
      data: {}
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addTrainingOpinion (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/training/${id}/opinion`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadTrainingFile (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/training/${id}/download?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadCertificate (id, type, text) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/training/${id}/certificate/${type}?t=${new Date().getTime()}`,
      data: { text }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadFile (password) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/training/file`,
      data: { password }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        // openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getCertificateRequest (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/certificate/request/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateCertificateRequest (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/certificate/request/${id}/update`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function certificateSubmitRequest (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/certificate/request/${id}/submit`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getTrainingAuthor (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/training_author/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// training category
export function getTrainingCategories () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/training-category?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getInspirationImage (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/inspiration/${id}/image?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getInspirationUploadedFilePreview (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/inspiration/prv?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getInspirations () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/inspiration?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function generateInspiration (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/inspiration/generate`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function useInspiration (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/inspiration/${id}/use`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// enlarge image

// export function getInspirationImage (id) {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'GET',
//       url: `${settings.api_url}/api/inspiration/${id}/image?t=${new Date().getTime()}`
//     }).then(function (response) {
//       if (response.status === 200) {
//         resolve(response.data)
//       } else {
//         openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
//         reject(response.data)
//       }
//     }).catch(function (error) {
//       openNotification('Ups, wystąpił błąd!', error.message)
//       reject(error)
//     })
//   })
// }

export function getEnlargeImageUploadedFilePreview (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/enlarge_image/prv?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getEnlargeImageEnlargedFilePreview (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/enlarge_image/prv_enlarged?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function checkEnlargeLimit (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/enlarge_image/check_limit?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function enlargeImage (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/enlarge_image/generate`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function downloadEnlargedImage () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${settings.api_url}/api/enlarge_image/download?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// purchase
export function createSubPurchase (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/user_purchase/create_sub_purchase`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getUserPurchases () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/user_purchase?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getUserPurchase (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/user_purchase/${id}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function enableTrial (sub) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/user_purchase/enable_trial`,
      data: { sub }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addMyRecipeFromMainRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/myrecipes/recipe/add-from-main-recipe/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getStats () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/stats?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

// recipes
export function getMainRecipes () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/mainrecipes?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getMainRecipe (slug) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/mainrecipes/${slug}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getChatMessagesForTraining (trainingId) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/chat_message/${trainingId}?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function sendUpvote (module) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/upvote`,
      data: {
        module
      }
    }).then(function (response) {
      if (response.status === 200) {
        openNotification('Sukces!', (response.data && response.data.message))

        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPaymentStats () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/payment/stats?t=${new Date().getTime()}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}
