import React from 'react'

class PrivacyPolicy extends React.Component {
  render () {
    const appName = 'StrefaCukiernika.pl'
    const contactEmail = 'kontakt@strefacukiernika.pl'

    return (
      <div className='static-content'>
        <h1 style={{ textAlign: 'center' }}>Polityka prywatności serwisu <span className='colored'>{appName}</span></h1>

        <p>Dbając o prywatność poniżej przedstawiamy najważniejsze zasady związane z ochroną Twojej prywatności.</p>

        <p>Administratorem strony internetowej jest Strefa Cukiernika sp. z o.o., adres siedziby: ul. Jarzębinowa 97/2, 05-825 Książenice, NIP: 529-185-44-35, REGON: 528554570, KRS: 0001103806;</p>

        <p>Gwarantujemy poufność, bezpieczeństwo oraz nieudostępnianie jakimkolwiek podmiotom trzecim bez Twojej wyraźnej zgody Twoich danych przekazanych podczas:</p>
        <ul style={{ listStyleType: 'decimal', marginLeft: '50px' }}>
          <li>zakładania konta za pośrednictwem strony;</li>
          <li>składania zamówień;</li>
          <li>zapisu na newsletter;</li>
          <li>prowadzonej komunikacji;</li>
          <li>zapisu na darmowe szkolenie;</li>
          <li>reklamacji;</li>
          <li>odstąpienia od umowy;</li>
        </ul>

        <p>Korzystamy z  narzędzi powierzając przetwarzanie danych osobowych sprawdzonym i zaufanym podmiotom świadczącym usługi związane z przetwarzaniem danych osobowych:</p>
        <ul style={{ listStyleType: 'decimal', marginLeft: '50px' }}>
          <li>Narzędzia analityczne Google Analytics, które zbiera w domyślnej implementacji dane o odwiedzaniu podstron witryny, korzystanie z interfejsu oraz może być użyte do kierowania do Ciebie reklam.</li>
          <li>Narzędzia marketingowe takie jak Meta Pixel w celu kierowania do Ciebie reklam;</li>
          <li>Narzędzie do email marketingu Sendgrid, które służy do przechowywania list adresów email oraz do wysyłki wiadomości email;</li>
          <li>Narzędzie do tworzenia stron typu landing page Landingi, za pośrednictwem którego tworzone są formularze rejestracyjne na szkolenia;</li>
          <li>Utrzymanie serwerów strony w firmie OVH;</li>
          <li>Hosting poczty email w firmie Cyberfolks;</li>
          <li>Bramka płatności online w serwisie Stripe;</li>
          <li>Realizacja transakcji płatniczych przez serwis Paypal;</li>
          <li>Cookie consent przy użyciu narzędzia CookieBot;</li>
          <li>Dokumentowanie płatności przy pomocy serwisu wFirma;</li>
          <li>Wystawianie paragonów fiskalnych za pomocą platformy eparagony.pl.</li>
        </ul>

        <p>W ramach ustawień plików cookies w przeglądarce Ty decydujesz, czy wyrażasz zgodę na korzystanie przez nas z określonych narzędzi.</p>

        <p>W celu prawidłowego działania strony korzystamy z własnych plików cookies/i zewnętrznych.</p>

        <p>Poniżej przedstawiamy szczegółowe zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies związane z korzystaniem ze strony internetowej https://strefacukiernika.pl/;</p>

        <h1 style={{ textAlign: 'center' }}>Polityka prywatności</h1>

        <h2>Akty regulujące przetwarzanie danych osobowych</h2>

        <p>Twoje dane osobowe są zbierane i przetwarzane zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L 119, s. 1), zwanego powszechnie i dalej „RODO”. W zakresie nieuregulowanym przez RODO przetwarzanie danych osobowych jest regulowane przez przepisy ustawy z dnia 10 maja 2018 r.  o ochronie danych osobowych (Dz.U. 2019 poz. 1781).</p>

        <h2>Administrator danych osobowych</h2>

        <p>Administratorem danych osobowych gromadzonych za pomocą strony internetowej w rozumieniu przepisów o ochronie danych osobowych jest: Strefa Cukiernika sp. z o.o., adres siedziby: ul. Jarzębinowa 97/2, 05-825 Książenice, NIP: 529-185-44-35, REGON: 528554570, KRS: 0001103806;</p>

        <h2>Z Administratorem danych osobowych można skontaktować za pomocą:</h2>

        <ul style={{ marginLeft: '50px' }}>
          <li>poczty elektronicznej: kontakt@strefacukiernika.pl;</li>
          <li>pod adresem: ul. Jarzębinowa 97/2, 05-825 Książenice;</li>
          <li>telefonicznie: pod numerem: 782 070 907.</li>
        </ul>

        <h2>Administrator danych  wykorzystuje dane osobowe na podstawie:</h2>

        <ul style={{ marginLeft: '50px' }}>
          <li>Twojej dobrowolnie wyrażonej zgody (np. założenie konta, złożenie zamówienia, newsletter) – art. 6 ust. 1 lit. a RODO;</li>
          <li>Obowiązujących przepisów prawa  gdy przetwarzanie danych jest niezbędne do wypełnienia obowiązku wynikającego z przepisów podatkowych, rachunkowych i prawnych – art. 6 ust. 1 lit c RODO;</li>
          <li>Uzasadniony interes administratora w celu statystyk i profilowania klienta – art. 6 RODO.</li>
        </ul>

        <h2>Uprawnienia związane z przetwarzaniem danych osobowych</h2>
        <p>Na podstawie RODO masz prawo do następujących uprawnień związanych z przetwarzaniem Twoich danych osobowych:</p>
        <ul style={{ marginLeft: '50px' }}>
          <li>prawo dostępu do swoich danych osobowych,</li>
          <li>prawo uzyskania informacji o celach przetwarzania, kategoriach przetwarzania, okresie przetwarzania danych,</li>
          <li>prawo uzyskania kopii swoich danych osobowych,</li>
          <li>prawo do sprostowania swoich danych osobowych,</li>
          <li>prawo do usunięcia swoich danych osobowych, </li>
          <li>prawo do ograniczenia przetwarzania danych osobowych, </li>
          <li>prawo do wniesienia skargi do organu nadzorczego,</li>
          <li>prawo do przenoszenia danych osobowych,</li>
          <li>prawo do odwołania zgody na przetwarzanie danych osobowych, jeżeli zgoda została wyrażona.</li>
        </ul>

        <p>Szczegółowe zasady realizacji ww. uprawnień zawarte są w art.15-21 RODO.</p>

        <p>Aby skorzystać z powyższych uprawnień zgłoś swoje żądanie na adres: kontakt@strefacukiernika.pl;</p>

        <p>W przypadku zgłoszenia żądania skorzystania z powyższych uprawnień udzielimy Tobie informacji o podjętych działaniach  bez zbędnej zwłoki - a w każdym razie w terminie miesiąca od otrzymania zgłoszenia danego żądania. Z uwagi na skomplikowany charakter żądania lub liczbę zgłoszonych żądań możemy wydłużyć termin miesięczny o kolejne dwa miesiące. O przedłużeniu terminu oraz powodach poinformujemy Ciebie w terminie miesiąca od otrzymania żądania.</p>

        <p>Jeżeli uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy ochrony danych osobowych masz prawo do złożenia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych.</p>

        <h2>Bezpieczeństwo</h2>

        <p>Wiedząc jak ważna jest ochrona danych osobowych gwarantujemy poufność oraz bezpieczeństwo przekazanych nam danych osobowych. Podejmujemy wszelkie środki bezpieczeństwa i ochrony danych osobowych wymagane przez przepisy o ochronie danych osobowych.  Z należytą starannością  wybieramy i stosujemy środki techniczne zapewniające ochronę przetwarzanych danych osobowych. Podmioty, którym powierzamy przetwarzanie danych osobowych na podstawie umowy powierzenia gwarantują stosowanie środków bezpieczeństwa danych osobowych zgodnych z przepisami prawa.</p>

        <h2>Cele i czynności przetwarzania danych osobowych</h2>

        <p>Na naszej stronie internetowej oferujemy wiele różnych usług w związku z tym przetwarzamy dane osobowe w następujących zbiorach i celach:</p>

        <ul style={{ listStyleType: 'decimal', marginLeft: '50px' }}>
          <li>
            Zawarcie i wykonanie umowy:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail, imię, nazwisko, numer telefonu, nazwa Firmy, Numer Identyfikacji Podatkowej (NIP), adresy korespondencyjne i wysyłkowe,</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. b RODO, </li>
              <li>czas przechowywania danych: do upływu okresu przedawnienia roszczeń dotyczących wykonania umowy.</li>
            </ul>
          </li>
          <li>
            Założenie i prowadzenie konta na portalu:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail, </li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. b RODO, </li>
              <li>czas przechowywania danych: do upływu okresu przedawnienia roszczeń dotyczących wykonania umowy.</li>
            </ul>
          </li>

          <li>
            Dodawanie opinii:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: nick, adres e-mail, imię, nazwisko,  </li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit.f RODO,</li>
              <li>czas przechowywania danych: do momentu wniesienia sprzeciwu wobec przetwarzania danych osobowych.</li>
            </ul>
          </li>
          <li>
            Formularz kontaktowy:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail, imię, nazwisko,</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. f RODO,</li>
              <li>czas przechowywania danych: do momentu wniesienia sprzeciwu wobec przetwarzania danych osobowych.</li>
            </ul>
          </li>
          <li>
            Newsletter:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail, </li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. a RODO,</li>
              <li>czas przechowywania danych: do momentu wycofania zgody na przetwarzanie danych osobowych.</li>
            </ul>
          </li>
          <li>
            Szkolenia darmowe:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: e-mail,</li>
              <li>podstawa prawna przetwarzania danych: aert.6 ust. 1 lit a RODO,</li>
              <li>czas przechowywania danych: do komentuje wycofania zgody n przetwarzanie danych osobowych.</li>
            </ul>
          </li>
          <li>
            Reklamacje i odstąpienia od umowy:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: imię, nazwisko, adres zamieszkania, numer telefonu, adres e-mail, numer rachunku bankowego,</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. c RODO,</li>
              <li>czas przechowywania danych: do upływu okresu przedawnienia roszczeń dotyczących wykonania umowy.</li>
            </ul>
          </li>
          <li>
            Ustalenie, dochodzenie i egzekucja roszczeń i obrona przed roszczeniami w postępowaniu przed sądami i innymi organami państwowymi:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: imię, nazwisko, adres zamieszkania, nr PESEL, NIP, REGON, adres e‑mail, nr telefonu, nr IP, nr rachunku bankowego, nr karty płatniczej,</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. f RODO,</li>
              <li>czas przechowywania danych: do upływu okresu przedawnienia roszczeń dotyczących wykonania umowy.</li>
            </ul>
          </li>
          <li>
            Wypełnienie obowiązków prawnych, podatkowych i rachunkowych:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: imię, nazwisko, adres korespondencyjny, nr PESEL, NIP, REGON, adres e‑mail, nr telefonu, nr IP, nr rachunku bankowego, nr karty płatniczej</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. c RODO,</li>
              <li>czas przechowywania danych: do momentu wygaśnięcia obowiązków prawnych ciążących na Administratorze, które uzasadniały przetwarzanie danych osobowych</li>
            </ul>
          </li>
          <li>
            Analiza ruchu na stronie sklepu internetowego:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: nr IP, </li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. f RODO,</li>
              <li>czas przechowywania danych: do momentu wniesienia sprzeciwu wobec przetwarzania danych osobowych.</li>
            </ul>
          </li>
          <li>
            wFirma:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail; w przypadku chęci otrzymania faktury imiennej: imię, nazwisko, adres zamieszkania, NIP</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. a RODO,</li>
              <li>czas przechowywania danych: do momentu wycofania zgody na przetwarzanie danych osobowych.</li>
            </ul>
          </li>
          <li>
            eparagony:
            <ul style={{ marginLeft: '50px' }}>
              <li>dane osobowe: adres e-mail, w przypadku chęci otrzymania faktury: NIP</li>
              <li>podstawa prawna przetwarzania: art. 6 ust. 1 lit. a RODO,</li>
              <li>czas przechowywania danych: do momentu wycofania zgody na przetwarzanie danych osobowych.</li>
            </ul>
          </li>
        </ul>

        <p>Dostęp do zbiorów danych ma Administrator danych osobowych, pisemnie upoważnieni przez niego pracownicy, przetwarzający dane na potrzeby realizacji zamówień oraz zewnętrzne podmioty przetwarzające dane na podstawie umów o powierzenie przetwarzania danych osobowych.</p>

        <h2>Paragony fiskalne w postaci elektronicznej</h2>

        <p>Portal może udostępnić podmiotowi realizującemu obsługę informatyczną w zakresie wydawania, utrzymania i zapewnienia dostępu do eparagonów adres e-mail Klienta, który może być przetwarzany na potrzeby:</p>

        <ul style={{ marginLeft: '50px' }}>
          <li>weryfikacji czy dany adres e-mail został zarejestrowany w
        produktach własnych podmiotu obsługującego, a w przypadku pozytywnej weryfikacji
        udostępniania w ramach tych produktów Klientowi widoku eparagonów oraz
        dodatkowych informacji pochodzącym z procesu ich wydawania</li>
          <li>wysyłania powiadomień email do Klientów potwierdzających wydanie
        eparagonu do systemu informatycznego podmiotu realizującego obsługę
        informatyczną eparagonów</li>
        </ul>

        <h2>Wykaz i cel powierzeń</h2>

        <p>Powierzamy przetwarzanie danych następującym podmiotom:</p>

        <ul style={{ marginLeft: '50px' }}>
          <li>Sendgrid - korzystanie z systemu mailingowego, jeżeli zapisałeś się do newslettera, szkolenie, dokonałeś płatności, założyłeś konto na stronie,</li>
          <li>Landingi – rejestrowanie Twoich danych jeśli zapisujesz się na szkolenie,</li>
          <li>OVH - przechowywanie danych na serwerze,</li>
          <li>Cyberfolks – obsługa i przechowywanie poczty email,</li>
          <li>Stripe – realizacja transakcji płatniczych,</li>
          <li>Paypal – realizacja transakcji płatniczych,</li>
          <li>wFirma – dokumentowanie transakcji płatniczych,</li>
          <li>eparagony.pl – wystawianie paragonów fiskalnych w formie elektronicznej, wysyłanie powiadomień o wystawieniu eparagonu.</li>
        </ul>

        <p>Podmioty, którym powierzamy przetwarzanie danych osobowych gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych wymaganych przez obowiązujące przepisy prawa.</p>

        <h2>Dobrowolność podania danych osobowych</h2>

        <p>Warunkiem świadczenia naszych usług jest dobrowolne podanie przez Ciebie wymaganych danych osobowych (np. założenie konta lub newsletter).</p>

        <p>Zakładając konto użytkownika na podstawie regulaminu sklepu zawierasz umowę o prowadzeniu konta. Dane wprowadzone przez Ciebie na koncie użytkownika przetwarzane są w celu prowadzenia i korzystania z konta. Twoje dane na koncie użytkownika będą przetwarzane tak długo jak będziesz posiadać konto. Usunięcie konta powoduje usunięcie danych z bacy danych z wyjątkiem danych o złożonych zamówieniach. W każdym czasie po zalogowaniu do swojego konta użytkownika masz dostęp do swoich danych osobowych, możliwości modyfikacji danych oraz usunięcia konta.</p>

        <p>Pliki cookies, stosowane technologie śledzące i profilowanie</p>

        <p>Podczas przeglądania nasza strona internetowa wykorzystuje pliki cookies, czyli niewielkie informacje tekstowe, które są zapisywane w Twoim urządzeniu końcowym w celu lepszego i poprawnego korzystania ze strony internetowej.</p>

        <p>Pliki cookies odczytywane są przez naszą stronę internetową w celu zidentyfikowania wykorzystywanego przez Ciebie oprogramowania oraz dostosowania serwisu do Twoich potrzeb.</p>

        <p>Strona internetowa korzysta z sesyjnych i trwałych Plików cookies. Pliki cookies sesyjne są przechowywanie w Twoim urządzeniu do momentu zakończenia sesji danej przeglądarki, natomiast trwałe przechowywane są w Twoim urządzeniu po zakończeniu korzystania ze strony internetowej do momentu ich usunięcia (np. hasło i login).</p>

        <p>Zgoda na wykorzystywanie plików cookies jest wyświetlana do Twojej akceptacji podczas pierwszej wizyty na stronie internetowej wraz z informacją na temat stosowania plików cookies. Ustawienia dotyczące plików cookies możesz zmienić w każdym czasie z poziomu wykorzystywanej przeglądarki lub także usunąć pliki cookies. Plikami cookie możesz także zarządzać poprzez narzędzie CookieBot, które znajdziesz w lewym dolnym rogu ekranu, które umożliwi zarządzanie plikami cookies z poziomu strony.</p>

        <p>Korzystamy również z narzędzi zapewnianych przez podmioty trzecie powierzając przetwarzanie danych osobowych sprawdzonym i zaufanym podmiotom świadczącym usługi związane z przetwarzaniem danych osobowych w ramach mechanizmu do zarządzania ustawieniami plików cookies.</p>

        <p>Wykorzystujemy Pliki cookies podmiotów zewnętrznych w następujących celach:</p>

        <ul style={{ listStyleType: 'decimal', marginLeft: '50px' }}>
          <li>
            Analiza i statystyka:
            <ul style={{ marginLeft: '50px' }}>
              <li>Google Analytics, które zbiera w domyślnej implementacji dane o rodzaju przeglądarki, czasie spędzonym na stronie, odwiedzonych podstronach, sposobie korzystania z interfejsu strony;</li>
            </ul>
          </li>
          <li>
            Marketing:
            <ul style={{ marginLeft: '50px' }}>
              <li>Meta Pixel w celu kierowania do Ciebie reklam;</li>
              <li>Google Analytics w celu kierowania do Ciebie reklam;</li>
            </ul>
          </li>
        </ul>

        <p>Aby poznać zasady korzystania z Plików cookies zalecamy zapoznanie się z politykami prywatności wyżej wymienionych firm.</p>

        <p>W ramach mechanizmu zarządzania ustawieniami plików cookies w przeglądarce Ty decydujesz, czy wyrażasz zgodę na korzystanie przez nas z ww. narzędzi.</p>

        <p>Informacje o zmianie ustawień dotyczących plików Cookies oraz o ich samodzielnym usuwaniu w przeglądarkach internetowych dostępne są w dziale „Pomocy” przeglądarki internetowej oraz na poniższych stronach (należy kliknąć w link):</p>

        <ul style={{ marginLeft: '50px' }}>
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl" target="_blank" norefferer>w przeglądarce Chrome</a></li>
          <li><a href="https://support.mozilla.org/pl/kb/usuwanie-ciasteczek" target="_blank" norefferer>w przeglądarce Firefox</a></li>
          <li><a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" norefferer>w przeglądarce Internet Explorer</a></li>
          <li><a href="https://www.opera.com/help/tutorials/security/cookies/" target="_blank" norefferer>w przeglądarce Opera</a></li>
          <li><a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" norefferer>w przeglądarce Microsoft Edge</a></li>
          <li><a href="https://support.apple.com/pl-pl/HT201265" target="_blank" norefferer>w przeglądarce Safari.</a></li>
        </ul>

        <p>Twoje dane osobowe mogą zostać poddane czynnościom polegającym na profilowaniu tj. dopasowaniu oferowanych przez nas treści reklamowych do Twojego zachowania. Zgoda na pliki cookies jest jednoznaczna ze zgodą na profilowanie oraz zbieranie danych statystycznych jeżeli profilowanie nie niesie za sobą skutków prawnych.</p>

        <p>Strona internetowa może również gromadzić dane zwyczajowo zbierane przez administratorów systemów internetowych w ramach tzw. logów lub plików dziennika. Informacje zawarte w logach mogą obejmować np. adres IP, rodzaj przeglądarki internetowej, dostawcę Internetu.</p>

        <p>Przetwarzamy także poniższe dane w celu polepszania jakości świadczonych usług oraz identyfikowania tych najbardziej interesujących:</p>

        <ul style={{ marginLeft: '50px' }}>
          <li>użycie kalkulatorów</li>
          <li>użycie kodów rabatowym partnerów</li>
          <li>pobranie projektów topperów</li>
          <li>pobranie plików dołączonych do szkoleń</li>
          <li>korzystanie ze szkoleń</li>
          <li>sposób korzystania z interfejsu.</li>
        </ul>

        <h2>Czy mój dane będą przekazywane poza obszar Europejskiego Obszaru Gospodarczego (EOG)?</h2>

        <p>Tak ponieważ korzystamy z narzędzi firm znajdujących poza obszarem EOG takich jak Google LL i Meta. Gwarancje ochrony danych osobowych tych firm wynikają ze stosownych umów podpisanych z Administratorem danych osobowych oraz z tego, iż figurują one w wykazie podmiotów uczestniczących w programie „Tarcza Prywatności” oraz z zobowiązań do stosowania standardowych klauzul umownych przyjętych przez Komisję (UE).</p>
        <p>W sprawach nieuregulowanych niniejszą Polityką Prywatności stosuje się odpowiednio zapisy Regulaminu Sklepu Internetowego, RODO oraz inne właściwe przepisy prawa polskiego.</p>
      </div>
    )
  }
}

export default PrivacyPolicy
