import React from 'react'
import {
  Button, Menu, Table, List, PageHeader, Empty, Skeleton
} from 'antd'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined, DollarCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import StyledOrderMobileMenu from './menuMobile'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import OrderHelp from './help'

class StyledRecipeIndex extends React.Component {
  state = {
    orders: [],
    orders_by_date: [],
    helpVisible: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  mobileDropdownRef = React.createRef()

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()

    helpVisible && ReactGA.event({ category: 'Orders Recipe', action: 'Help Opened Mobile' })
  }

  render () {
    const { recipes, makeRedirection, isMobile, loaded } = this.props

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='add-recipe'
        >
          <Link to='/recipe/add'>Dodaj przepis</Link>
        </Menu.Item>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='add-recipe'
        type='primary'
      >
        <Link to='/recipe/add'>Dodaj przepis</Link>
      </Button>,
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    const columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Ilość porcji',
        dataIndex: 'portions',
        key: 'portions',
        render: (x, record) => (
          <span>{record.use_portions ? x : '-'}</span>
        ),
        sorter: (a, b) => { return a.portions - b.portions },
      },
      {
        title: 'Koszt składników',
        dataIndex: 'cost',
        key: 'cost',
        render: (x) => (
          <span>{x} zł</span>
        ),
        sorter: (a, b) => { return a.cost - b.cost },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={{
              pathname: '/recipe/' + record.id,
              state: { from: 'recipe' }
            }}
            >
              edytuj
            </Link>
          </span>
        )
      }
    ]

    return (
      <>
        <PageHeader
          title='Przepisy'
          onBack={() => makeRedirection()}
          extra={actions}
        />
        <OrderHelp
          visible={this.state.helpVisible}
          setVisible={this.handleHelpVisible}
          isMobile={isMobile}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        {isMobile && <Link to='/recipe/add' className={'mobile-add-btn' + (this.props.screenSize === 'xs' ? ' with-menu' : '')}><Button type="primary" shape="circle" size="large">+</Button></Link>}
        <div style={{ display: loaded ? 'block' : 'none' }} className='list'>
          {isMobile &&
            <List
              itemLayout='horizontal'
              pagination
              className='mobile-list'
              dataSource={recipes}
              locale={{ emptyText: <Empty description='Brak przepisów' /> }}
              renderItem={(item) => (
                <List.Item
                  actions={[<Link key='edit' to={'/recipe/' + item.id}>edytuj</Link>]}
                >
                  <List.Item.Meta
                    onClick={() => this.props.redirect('/recipe/' + item.id)}
                    title={<Link key='edit' to={'/recipe/' + item.id}>{item.name}</Link>}
                    description={<><DollarCircleOutlined /> {item.cost} zł</>}
                  />
                </List.Item>
              )}
            />}
          {!isMobile && (
            <Table
              className={`settings-table ${recipes && recipes.length ? 'fade-in' : ''}`}
              {...this.settings}
              columns={columns}
              dataSource={recipes}
              locale={{
                emptyText: <Empty description='Brak przepisów' />,
                triggerDesc: 'Sortuj malejąco',
                triggerAsc: 'Sortuj rosnąco',
                cancelSort: 'Kliknij aby anulować sortowanie' }}
            />)}
        </div>
        {this.props.screenSize === 'xs' && <StyledOrderMobileMenu />}
      </>
    )
  }
}

export default menuTypeHook(StyledRecipeIndex)
