import React from 'react'
import StyledTopperIndex from '../../styledComponents/topper'
import { Redirect } from 'react-router'
import { getToppers } from '../../utils/actions'
import { connect } from 'react-redux'

class TopperIndex extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getToppers()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getToppers = () => {
    getToppers().then((json) => {
      if (this._isMounted) {
        this.setState({ toppers: json.toppers, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, toppers, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to={this.props.authenticated ? '/panel' : '/'} />
      ) : (
        <StyledTopperIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          toppers={toppers}
          redirect={this.redirect}
          from_route={this.props.location.state?.from}
          loaded={loaded}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const TopperIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopperIndex)

export default TopperIndexContainer
