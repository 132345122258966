import React from 'react'
import StyledTrainingShow from '../../styledComponents/trainingAuthor/show'
import { Redirect } from 'react-router'
import { getTrainingAuthor } from '../../utils/actions'

class TrainingAuthorShow extends React.Component {
  state = {
    makeRedirection: false,
    id: undefined,
    trainingAuthor: undefined,
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    this._isMounted = true
    const { id } = this.props.match.params

    this.setState({ id })
    this.getTrainingAuthor(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getTrainingAuthor = (id) => {
    getTrainingAuthor(id).then((json) => {
      if (this._isMounted) {
        this.setState({ trainingAuthor: json })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, trainingAuthor } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/training/author' />
      ) : (trainingAuthor && (
        <>
          <StyledTrainingShow
            makeRedirection={this.makeRedirection}
            singleData={trainingAuthor}
            isMobile={isMobile}
          />
        </>
      )) || null
    )
  }
}

export default TrainingAuthorShow
