import React from 'react'
import {
  Form, Input, Checkbox, Button, Space, Select
} from 'antd'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
}

const { Option } = Select

class StyledUserProfileForm extends React.Component {
  formRef = React.createRef()

  setDefaultValues = () => {
    const { userData } = this.props

    this.formRef.current.setFieldsValue({
      password: '',
      password_repeat: '',
      email: userData?.email,
      province: userData?.province,
      town: userData?.town,
      newsletter_agreement: userData?.newsletter_agreement
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.userData !== this.props.userData) {
      this.setDefaultValues()
    }
  }

  render () {
    const { onHandleSubmit, formSaving, isMobile, userData } = this.props

    const tooltipProps = {
      trigger: [isMobile ? 'click' : 'hover'],
      placement: isMobile ? 'right' : 'top',
      icon: <InfoCircleOutlined />,
      className: isMobile ? 'help-icon-mobile' : ''
    }

    return (
      <Form
        onFinish={onHandleSubmit}
        className='profile-form'
        layout='horizontal'
        ref={this.formRef}
        style={!userData ? { display: 'none' } : {}}
      >
        <Form.Item
          name='email'
          label={this.props.t('Email')}
          tooltip={{ title: 'Chwilowo nie możesz zmienić adresu email za pomocą tego formularza, jeśli potrzebujesz zmienić email napisz na kontakt@strefacukiernika.pl', ...tooltipProps }}

          rules={[{
            required: true,
            type: 'email',
            message: this.props.t('Valid email address is required')
          }]}
          {...formItemLayout}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='password'
          label={this.props.t('Password')}
          tooltip={{ title: 'Chwilowo nie możesz zmienić hasła za pomocą tego formularza, jeśli potrzebujesz nowe hasło napisz na kontakt@strefacukiernika.pl', ...tooltipProps }}
          {...formItemLayout}
        >
          <Input.Password disabled />
        </Form.Item>
        <Form.Item
          name='password_repeat'
          label={this.props.t('Repeat password')}
          tooltip={{ title: 'Chwilowo nie możesz zmienić hasła za pomocą tego formularza, jeśli potrzebujesz nowe hasło napisz na kontakt@strefacukiernika.pl', ...tooltipProps }}
          {...formItemLayout}
        >
          <Input.Password disabled />
        </Form.Item>

        <Form.Item
          name='province'
          label={this.props.t('Province')}
          {...formItemLayout}
        >
          <Select placeholder={this.props.t('Select province')} allowClear>
            <Option value='1'>dolnośląskie</Option>
            <Option value='2'>kujawsko-pomorskie</Option>
            <Option value='3'>lubelskie</Option>
            <Option value='4'>lubuskie</Option>
            <Option value='5'>łódzkie</Option>
            <Option value='6'>małopolskie</Option>
            <Option value='7'>mazowieckie</Option>
            <Option value='8'>opolskie</Option>
            <Option value='9'>podkarpackie</Option>
            <Option value='10'>podlaskie</Option>
            <Option value='11'>pomorskie</Option>
            <Option value='12'>śląskie</Option>
            <Option value='13'>świętokrzyskie</Option>
            <Option value='14'>warmińsko-mazurskie</Option>
            <Option value='15'>wielkopolskie</Option>
            <Option value='16'>zachodniopomorskie</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='town'
          label={this.props.t('Town')}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='newsletter_agreement'
          colon={false}
          valuePropName='checked'
        >
          <Checkbox defaultChecked={userData?.newsletter_agreement || ''}>{this.props.t('I want to receive news notifications about news in application')}</Checkbox>
        </Form.Item>

        <Space>
          <Button htmlType='submit' type='primary' disabled={formSaving}>{this.props.t('Zapisz')}{formSaving && <LoadingOutlined style={{ fontSize: 14 }} spin />}</Button>
        </Space>
      </Form>
    )
  }
}

export default withTranslation()(StyledUserProfileForm)
