import React from 'react'
import { PageHeader, Alert } from 'antd'
import CalculatorBasicForm from './calculator'

class StyledCalcIndex extends React.Component {
  state = {
    headerActions: []
  }

  setHeaderActions = (headerActions) => {
    this.setState({ headerActions })
  }

  render () {
    const { makeRedirection, formSaving, isMobile, saveData, saveSubmitData, calcData, saveAlertClose, showSaveAlert } = this.props

    return (
      <>
        <PageHeader
          title='Kalkulator do wycen'
          onBack={() => makeRedirection()}
          extra={this.state.headerActions}
        />
        <div>
          {showSaveAlert && calcData
            ? (
              <div className='alert-wrapper'>
                <Alert
                  className={'calculator-alert' + (isMobile ? ' on-mobile' : '')}
                  closable
                  onClose={saveAlertClose}
                  message={
                    <>
                      Ustawienia tego kalkulatora po każdej zmianie zapisują się automatycznie w Twoim profilu więc bez obaw możesz wrócić do niego w dowolnym czasie i z dowolnego urządzenia :)
                    </>
                  }
                  type='info'
                />
              </div>) : ''}
          <CalculatorBasicForm
            formSaving={formSaving}
            calculateAction={this.props.calculateAction}
            isMobile={isMobile}
            saveData={saveData}
            saveSubmitData={saveSubmitData}
            calcData={calcData}
            setHeaderActions={this.setHeaderActions}
            headerActions={this.state.headerActions}
          />
        </div>
      </>
    )
  }
}

export default StyledCalcIndex
