import React from 'react'
import { PageHeader } from 'antd'
import { registerUser } from '../../utils/actions'
import StyledRegister from '../../styledComponents/user/register'
import { openNotification } from '../../utils/helpers'
import QueueAnim from 'rc-queue-anim'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { authenticateUser, dummy } from '../../utils/actions'
import { saveDeviceData } from '../../utils/helpers'
import { setAuth, setUserRoles, setActiveModules } from '../../store/actions'
import { connect } from 'react-redux'

class Register extends React.Component {
  state = {
    formSaving: false
  }

  redirect = (pathname, redirect) => {
    this.props.history.push({ pathname, state: { redirect } })
  }

  componentDidMount (prevProps, prevState) {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  registerUser = (data) => {
    this.setState({ formSaving: true })

    ReactGA.event({ category: 'User', action: 'Register' })

    registerUser(data).then((json) => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
        openNotification(this.props.t('Success'), 'Zarejestrowano i zalogowano pomyślnie!')

        const fromRoute = this.props.location.state?.redirect
        const { setAuth, setUserRoles, setActiveModules } = this.props

        ReactGA.event({ category: 'User', action: 'Login' })

        authenticateUser(data.email, data.password).then((json) => {
          this.setState({ formSaving: false })
          saveDeviceData('auth', json.token)
          saveDeviceData('refresh_token', json.refresh_token, 30)
          setAuth(true)

          dummy().then((result) => {
            setUserRoles(result.roles)
            setActiveModules(result.active_modules)
          })

          if (fromRoute && typeof fromRoute === 'object' && fromRoute.length && fromRoute[0] !== '/') {
            this.redirect(fromRoute[0], fromRoute.slice(1))
          } else {
            this.redirect('/panel')
          }
        }).catch(() => {
          this.setState({ formSaving: false })
        })
      }
    }).catch(() => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
      }
    })
  }

  render () {
    const { formSaving } = this.state
    const { authenticated, setAuth, ...currentProps } = this.props
    const redirectRoute = this.props.location.state?.redirect
    const requiredSubs = this.props.location.state?.requiredSubs

    return (
      <div {...currentProps} className='banner2'>
        <PageHeader
          className='back-to-hompage'
          onBack={() => this.redirect((redirectRoute?.length && redirectRoute.slice(-1)) || '/login', redirectRoute?.length > 1 && redirectRoute.slice(1))}
          title={this.props.t('Register')}
        />
        <QueueAnim
          key='QueueAnim'
          type={['bottom', 'top']}
          delay={200}
          className='banner2-text-wrapper'
        >
          <div key='content' className='banner2-content'>
            {/*<Alert
              className='registration-alert'
              closable
              message={
                <>
                  {/this.props.t('Current version of application is only available for \'Torty i wypieki - Inspiracje Cukiernicze\' fb group members. Visit')}{' '/}
                  Aktualna wersja aplikacji jest dostępna jedynie dla członków grupy FB 'Torty i wypieki - Inspiracje Cukiernicze'. Rejestracja wymaga podania tajnego hasła, które udostępniamy tylko dla członków grupy. Jeśli jeszcze nie jesteś członkiem, dołącz do nas, możesz to zrobić 
                  {/this.props.t('Obecnie aplikacja jest w fazie testów, rejestracja jeszcze nie jest dostępna dla użytkowników. Zapraszamy wkrótce!')}{' '/}
                  <a
                    href='https://www.facebook.com/groups/1719093458165351'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    tutaj
                  </a>. Następnie zapoznaj się z instrukcją <Link to='/jak-zdobyc-haslo'>Jak zdobyć hasło?</Link>{/*this.props.t('and ask administrator for secret password before registration.')/}
                </>
              }
              type='info'
            />*/}
            <StyledRegister
              makeRedirection={this.makeRedirection}
              registerUser={this.registerUser}
              formSaving={formSaving}
              isMobile={this.props.isMobile}
              redirectRoute={redirectRoute}
              requiredSubs={requiredSubs}
            />
          </div>
        </QueueAnim>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth,
  setUserRoles,
  setActiveModules
}

const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register)

export default withTranslation()(RegisterContainer)
