/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Drawer, Button
} from 'antd'
import { Popup } from 'antd-mobile'
import { withTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

class OrderHelp extends React.Component {

  render () {
    const { isMobile } = this.props

    const descMobile = (
      <>
        <h3>Lista składników</h3>
        <p>
          Każdy przepis składa się z określonych produktów. W <Link to='/myrecipes/pantry'>Liście składników</Link> możesz dodać te produkty (lub użyć i edytować już istniejące) i nie musisz martwić się o przeliczanie zużytych składników na dany przepis - zrobimy to za Ciebie :)
        </p>

        <h3>Przepisy</h3>
        <p>
          Tutaj możesz dodać swoje przepisy - są widoczne tylko dla Ciebie. Użyj składników z <Link to='/myrecipes/pantry'>Listy składników</Link> aby umożliwić przeliczanie jednostek.
        </p>

        <h3>Opcjonalne liczenie kosztów wypieków</h3>
        <p>
          Korzystając z opcji <i>Włącz liczenie kosztów</i> dla każdego przepisu zostanie obliczony koszt jego wykonania.
          Ta opcja wymaga ustawienia ceny zakupu składników w <Link to='/myrecipes/pantry'>Liście składników</Link>. Dla składników, które dodane zostały samodzielnie - dodaj cenę w jakiej przeważnie kupujesz te składniki.
          Dla tych, które istniały domyślnie w aplikacji zaktualizuj cenę - wtedy wyceny przepisów będą najdokładniejsze. Każda zmiana ceny w liście składników będzie powodowała automatyczne przeliczenie kosztów wszystkich przepisów.<br/><br/>
          W liście składników możesz użyć opcji <i>tylko użyte w moich przepisach</i>, wtedy możesz w łatwy sposób aktualizować tylko używane przez Ciebie składniki.
        </p>
      </>
    )

    const descDesktop = descMobile

    return (
      isMobile
        ? (
          <Popup
            visible={this.props.visible}
            onMaskClick={() => {
              this.props.setVisible(false)
            }}
            bodyStyle={{ height: '50vh' }}
          >
            <div className='calculator-help-popup'>
              <Button type='text' className='calculator-help-popup-close' onClick={() => { this.props.setVisible(false) }}><CloseOutlined /></Button>
              <h2>Jak działają Moje przepisy?</h2>

              {descMobile}
            </div>
          </Popup>)
        : (
          <Drawer
            title='Jak działają Moje przepisy?'
            placement='right'
            width={450}
            onClose={() => {
              this.props.setVisible(false)
            }}
            visible={this.props.visible}
          >
            <div className='calculator-help-drawer'>
              {descDesktop}
            </div>
          </Drawer>
        )
    )
  }
}

export default withTranslation()(OrderHelp)
