import React from 'react'
import StyledTrainingIndex from '../../styledComponents/trainingAuthor'
import { Redirect } from 'react-router'
import { getTrainingAuthors } from '../../utils/actions'

class TrainingAuthorIndex extends React.Component {
  state = {
    makeRedirection: false,
    authors: []
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    this._isMounted = true

    this.getTrainingAuthors()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getTrainingAuthors = () => {
    getTrainingAuthors().then((json) => {
      if (this._isMounted) {
        this.setState({ authors: json.authors, loaded: true })
      }
    })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { makeRedirection, authors } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/training' />
      ) : (
        <StyledTrainingIndex
          makeRedirection={this.makeRedirection}
          data={authors}
          redirect={this.redirect}
        />
      )
    )
  }
}

export default TrainingAuthorIndex
