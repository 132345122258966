import React from 'react'
import StyledTrainingShow from '../../styledComponents/training/show'
import { Redirect } from 'react-router'
import { downloadFile } from '../../utils/actions'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import {
  Input, Button
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const FileDownload = require('js-file-download')

class TrainingSingleFile extends React.Component {
  state = {
    downloadingFile: false
  }

  formRef = React.createRef()

  downloadFile = (type, text) => {
    this.setState({ downloadingFile: true, result: undefined })

    downloadFile(this.formRef.current?.input?.value).then((json) => {
      this.setState({ downloadingFile: false, result: 'success' })
      FileDownload(json.data, 'Tort musowy - wielkie ciacho - przepisy.pdf')
    }).catch((err) => {
      this.setState({ downloadingFile: false, result: 'failed' })
    })
  }

  render () {
    return (
      <div style={{ marginTop: '50px' }}>
        <h1>Pobierz plik: Tort musowy - wielkie ciacho - przepisy</h1>
        {this.state.result === 'failed' && <p style={{ fontWeight: 700, fontSize: '14px', color: 'red' }}>Podane hasło jest nieprawidłowe</p>}
        {this.state.result === 'success' && <p style={{ fontWeight: 700, fontSize: '14px', color: 'green' }}>Plik pobiera się...</p>}
        {this.props.subType !== 'sub2' && this.state.result !== 'success' && <>
          <p style={{ fontWeight: 700, fontSize: '14px' }}>Podaj hasło do pliku</p>
          <p><Input ref={this.formRef} style={{maxWidth: '300px', margin: 'auto' }} /></p>
        </>}
        <p><Button type='primary' onClick={this.downloadFile}>Pobierz przepisy {this.state.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin /></>}</Button></p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  subType: state.appRedux.subType
})

const mapDispatchToProps = {
}

const TrainingSingleFileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingSingleFile)

export default TrainingSingleFileContainer
