import React from 'react'
import StyledPantryIndex from '../../styledComponents/calcPro/pantry'
import { Redirect } from 'react-router'
import { getPantry } from '../../utils/actions'

class PantryIndex extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getPantry()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getPantry = () => {
    getPantry().then((json) => {
      if (this._isMounted) {
        this.setState({ pantry: json, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, pantry, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/order' />
      ) : (
        <StyledPantryIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          pantry={pantry}
          redirect={this.redirect}
          loaded={loaded}
        />
      )
    )
  }
}

export default PantryIndex
