/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Menu, Button
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import StyledCalendarShow from '../../styledComponents/calcPro/calendarShow'
import { getOrdersForDay } from '../../utils/actions'
import { niclyFormatDate, firstLetterUpperCase } from '../../utils/helpers'
import { MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import { Link } from 'react-router-dom'
import OrderHelp from '../../styledComponents/calcPro/help'

import { Redirect } from 'react-router'

class CalendarShow extends React.Component {
  state = {
    orders: [],
    helpVisible: false
  }

  mobileDropdownRef = React.createRef()

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()
  }

  getOrders = (x) => {
    getOrdersForDay(x).then((json) => {
      if (this._isMounted) {
        this.setState({ orders: json.orders })
      }
    })
  }

  componentDidMount () {
    const { year, month, day } = this.props.match.params
    this._isMounted = true

    this.setState({ year, month, day })
    this.getOrders((day && year + '-' + month + '-' + day) || year + '-' + month)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, orders, year, month, day } = this.state

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='add-order'
        >
          <Link to={'/order/add/' + year + '-' + month + '-' + day}>Dodaj zamówienie</Link>
        </Menu.Item>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='add-order'
        type='primary'
      >
        <Link to={'/order/add/' + year + '-' + month + '-' + day}>Dodaj zamówienie</Link>
      </Button>,
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to='/order' />
      ) : (
        <>
          <PageHeader
            title={(day && (isMobile ? '' : 'Zamówienia na ') + niclyFormatDate(year, month, day)) || ((isMobile ? firstLetterUpperCase(niclyFormatDate(year, month, day)) : 'Zamówienia na ' + niclyFormatDate(year, month, day)))}
            onBack={() => this.makeRedirection()}
            extra={actions}
          />
          <OrderHelp
            visible={this.state.helpVisible}
            setVisible={this.handleHelpVisible}
            isMobile={isMobile}
          />
          {isMobile && <Link to={'/order/add/' + year + '-' + month + '-' + day} className='mobile-add-btn'><Button type="primary" shape="circle" size="large">+</Button></Link>}
          <StyledCalendarShow
            orders={orders}
            isMobile={isMobile}
            date={(day && year + '/' + month + '/' + day) || year + '/' + month}
            redirect={this.redirect}
          />
        </>
      )
    )
  }
}

export default withTranslation()(CalendarShow)
