/* eslint no-unused-expressions: "off" */
import React from 'react'
import { PageHeader } from 'antd'
import { withTranslation } from 'react-i18next'
import StyledPaymentShow from '../../styledComponents/userPurchase/show'
import { getUserPurchase } from '../../utils/actions'

class PaymentShow extends React.Component {
  state = {
    id: undefined,
    payment: undefined,
    downloading: false
  }

  getUserPurchase = (id) => {
    getUserPurchase(id).then((json) => {
      if (this._isMounted) {
        this.setState({ payment: json })
      }
    })
  }

  componentDidMount () {
    const { id } = this.props.match.params
    this._isMounted = true

    this.setState({ id })
    this.getUserPurchase(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile } = this.props
    const { payment, downloading } = this.state

    return (
      <>
        <PageHeader
          title='Podgląd płatności'
          onBack={() => this.redirect('/historia-platnosci')}
        />
        <StyledPaymentShow
          payment={payment}
          download={this.download}
          isMobile={isMobile}
          redirect={this.redirect}
          downloading={downloading}
        />
      </>
    )
  }
}

export default withTranslation()(PaymentShow)
