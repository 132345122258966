/* eslint no-unused-expressions: "off" */
import React from 'react'
import { PageHeader, Button, Image, Row, Col, Typography, Divider, Popover, Skeleton } from 'antd'
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { Image as ImageMobile, Space, ImageViewer, Popup } from 'antd-mobile'
import ReactGA from 'react-ga4'
import ReactMarkdown from 'react-markdown'
import { Link as RouterLink } from 'react-router-dom'
import CtaBox from '../common/ctaBox'

const { Link } = Typography

class StyledPromoShow extends React.Component {
  state = {
    clicked: false,
    visible: false,
    defaultIndex: 0,
    visibleVerificationInfo: false
  }

  galleryRef = React.createRef()

  setVerificationInfoVisible = (flag) => {
    console.log('fired', flag)
    this.setState({ visibleVerificationInfo: flag })

    flag && ReactGA.event({ category: 'Promotion Codes', action: 'Verification Info Opened Mobile' })
  }

  handleButtonClick = () => {
    const { singleData, savePromoUse } = this.props
    savePromoUse && savePromoUse(singleData.id)

    this.props.getPartnerCodes(singleData.id, () => this.setState({ clicked: true }))

    ReactGA.event({ category: 'Promotion Codes', action: 'Show Code Clicked', label: 'Code id: ' + singleData.id })
  }

  setVisible = (visible, defaultIndex) => {
    this.setState({ visible, defaultIndex })
    !isNaN(defaultIndex) && defaultIndex !== undefined && this.galleryRef.current?.swipeTo(defaultIndex)
  }

  render () {
    const { makeRedirection, singleData, isMobile, loaded, authenticated } = this.props

    const partnerVerificationTitle = <b>Na czym polega weryfikacja partnera?</b>
    const partnerVerificationContent = (
      <>
        <p>Firmy i osoby widniejące w Strefie Cukiernika jako zaufane muszą przejść pozytywną weryfikację przez nasz zespół.</p>
        <p>Korzystając z polecanych przez nas partnerów masz pewność, że jakość oferowanych produktów/usług została potwierdzona.</p>
        <p>Proces weryfikacji partnera obejmuje:</p>
        <ul className='promo-list'>
          <li>Sprawdzenie publicznie dostępnych informacji</li>
          <li>Weryfikacja opinii klientów danej firmy/osoby/produktu</li>
          <li>Wykonanie testowego zamówienia (o ile jest taka możliwość) celem oceny jakości</li>
        </ul>
      </>
    )

    return (
      <>
        <PageHeader
          title={singleData?.title || 'Partner'}
          onBack={() => makeRedirection()}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        {this.state.visible && <span className='mobile-image-preview-close' onClick={() => this.setVisible(false) }><CloseOutlined /></span>}
        {loaded && <Popup placement='leftTop' onMaskClick={() => { this.setVerificationInfoVisible(false) }} position='bottom' visible={this.state.visibleVerificationInfo}>
          <div className='promo-help-popup'>
            <Button type='text' className='calculator-help-popup-close' onClick={() => { this.setVerificationInfoVisible(false) }}><CloseOutlined /></Button>
            <h2>{partnerVerificationTitle}</h2>
            {partnerVerificationContent}
          </div>
        </Popup>}
        {loaded && singleData && <Row>
          <Col xs={24} md={9}>
            <div>
              <Image
                alt={singleData.title + 'logo'}
                src={'/images/partners/' + singleData.logo}
                preview={false}
                onClick={() => isMobile ? this.setVisible(true, 0) : null}
                style={{ marginBottom: '20px', maxHeight: '300px' }}
              />
            </div>
            <div>
              {!isMobile
                ? (
                  <Image.PreviewGroup>
                    {singleData.gallery.map((img, idx) => {
                      return <Image key={idx} width={100} height={100} src={'/images/partners/' + img} />
                    })}
                  </Image.PreviewGroup>)
                : (
                  <>
                    <ImageViewer.Multi
                      ref={this.galleryRef}
                      images={singleData.gallery.map(x => '/images/partners/' + x)}
                      visible={this.state.visible}
                      onClose={() => {
                        this.state.visible ? this.setVisible(false) : null
                      }}
                    />
                    <Space wrap justify='center'>
                      {singleData.gallery.map((img, idx) => {
                        return idx === 3 ? <p className='images-more'><span>{'+ ' + (singleData.gallery.length - 3) + ' więcej'}</span></p> : idx < 4 ? <ImageMobile src={'/images/partners/' + img} width={100} height={100} fit='fill' onClick={() => this.setVisible(true, idx + 1)} /> : ''
                      })}
                    </Space>
                  </>)}
            </div>
          </Col>

          {isMobile ? <Divider /> : ''}

          <Col xs={24} md={14}>
            <Row gutter={[0, 10]} align='middle' style={{ marginTop: '20px' }}>
              <Col xs={24} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{ backgroundColor: '#e3fefeff', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                  <Row align='middle'>
                    <Col xs={24} md={4} className={!isMobile ? 'promo-header' : ''}>
                      <span className='verified-promo'>
                        <CheckCircleOutlined />
                      </span>
                    </Col>
                    <Col xs={24} md={20} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                      Partner zweryfikowany przez Zespół StrefaCukiernika.pl{' '}
                      {isMobile
                        ? <Button type='link' onClick={() => { this.setVerificationInfoVisible(true) }}>Na czym polega weryfikacja?</Button>
                        : (
                          <Popover placement='bottom' content={partnerVerificationContent} title={partnerVerificationTitle} trigger='click' onClick={() => { ReactGA.event({ category: 'Promotion Codes', action: 'Verification Info Opened Desktop' }) }}>
                            <Button type='link'>Na czym polega weryfikacja?</Button>
                          </Popover>)}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={24} style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}>
                <Typography style={{ backgroundColor: '#c3ebf8fd', padding: '20px', borderRadius: '10px' }}>
                    <ReactMarkdown className='partner-description'>
                      {singleData.description}
                    </ReactMarkdown>
                </Typography>
              </Col>

              <Col xs={24} md={24} className={!isMobile ? 'promo-content' : ''} style={{ textAlign: 'center', marginTop: '20px' }}>
                <Space>
                {singleData.contact_www && <Button shape='round' style={{ backgroundColor: '#e3fefeff', borderColor: '#c3ebf8fd' }}><Link href={singleData.contact_www}>Strona WWW</Link></Button>}
                {singleData.contact_fb && <Button shape='round' style={{ backgroundColor: '#e3fefeff', borderColor: '#c3ebf8fd' }}><Link href={singleData.contact_fb}>Facebook</Link></Button>}
                {singleData.contact_ig && <Button shape='round' style={{ backgroundColor: '#e3fefeff', borderColor: '#c3ebf8fd' }}><Link href={singleData.contact_ig}>Instagram</Link></Button>}
                </Space>
              </Col>

              {isMobile ? <Divider /> : ''}

              {singleData.codes && <Col xs={24} md={24} className={!isMobile ? 'promo-content' : ''} style={!isMobile ? { textAlign: 'center', marginTop: '30px', marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' } : { paddingLeft: '20px', paddingRight: '20px' }}>
                {this.state.clicked ? (this.props.codes.map((x, i) => <p key={i}><b style={{ fontWeight: 'bold' }}>{x.title}</b> - {x.desc} </p>)) : <Button onClick={this.handleButtonClick} shape='round' style={{ backgroundColor: '#c3ebf8fd', border: 'none' }} disabled={!authenticated && singleData.codes}>Pokaż Twoje kody rabatowe</Button>}

                {!authenticated && singleData.codes &&
                  <CtaBox
                    text='Kody rabatowe są dostępne dla zalogowanych użytkowników'
                    toRoute='/register'
                    redirect={['/promotions/' + singleData.id]}
                    buttonText='Dołącz do Strefy Cukiernika'
                    noMargin
                  />}
              </Col>}

              {isMobile ? <Divider /> : ''}

              {singleData.video && (
                <>
                  <Col xs={24} md={24}>
                    Film z odpakowania naszego testowego zamówienia:
                  </Col>
                  <Col xs={24} md={24}>
                    <iframe
                      src={singleData.video}
                      frameBorder='0'
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
                      className={isMobile ? 'promo-video-iframe-mobile' : 'promo-video-iframe'}
                    />
                  </Col>
                </>)}
            </Row>
          </Col>
        </Row>}
      </>
    )
  }
}

export default StyledPromoShow
