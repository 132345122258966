/* eslint no-unused-expressions: "off" */
import React from 'react'
import { Modal } from 'antd'
import { withTranslation } from 'react-i18next'
import TrainingOpinionForm from '../../styledComponents/recipe/recalcForm'

class RecalcRecipeModal extends React.Component {
  state = {
    loading: false
  }

  formRef = React.createRef()

  render () {
    const { isMobile, recipe, origRecipe } = this.props

    return (
      <Modal
        title='Przelicz przepis'
        visible={this.props.visible}
        onCancel={() => this.props.setModalVisibility(false)}
        footer={null}
      >
        <TrainingOpinionForm
          formRef={this.formRef}
          isMobile={isMobile}
          setLoading={this.setLoading}
          modalVisible={this.props.visible}
          recipe={recipe}
          origRecipe={origRecipe}
          updateRecipe={this.props.updateRecipe}
          setModalVisibility={this.props.setModalVisibility}
          setRecalcUsed={this.props.setRecalcUsed}
        />
      </Modal>
    )
  }
}

export default withTranslation()(RecalcRecipeModal)
