import React from 'react'
import {
  Skeleton, Descriptions, Row, Col, Image, Button
  // Avatar,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import HelpBox from '../common/helpBox'
import CtaBox from '../common/ctaBox'

class StyledPrintoutShow extends React.Component {
  state = {
    loaded: false,
    visibleLicenseInfo: false
  }

  setVisibleLicenseInfo = (flag) => {
    this.setState({ visibleLicenseInfo: flag })

    flag && ReactGA.event({ category: 'Printout Projects', action: 'License Info Opened Mobile' })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.printout !== this.props.printout) {
      this.setState({
        loaded: true
      })
    }
  }

  render () {
    const { printout, isMobile, downloading, authenticated } = this.props
    const { loaded } = this.state

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          <div style={{ display: loaded ? 'block' : 'none' }}>
            <Row>
              <Col xs={24} md={12}>
                <Image
                  hoverable='true'
                  style={{ width: '100%', maxWidth: '400px' }}
                  src={'/images/printouts/' + printout?.id + '_mid.png'}
                  preview={{
                    src: '/images/printouts/' + printout?.id + '.png',
                  }}
                />
              </Col>
              <Col xs={24} md={12} style={ isMobile ? { paddingLeft: '20px', paddingRight: '20px' } : {} }>
                <Descriptions title='Informacje o grafice' bordered column={1} layout={isMobile ? 'vertical' : undefined}>
                  <Descriptions.Item label='Format'>PNG</Descriptions.Item>
                  {/*<Descriptions.Item label='Autor'>
                    <Avatar size="small" icon={<img src='/images/printouts/monika.jpg' />} /> {printout?.author}<br/>
                    kontakt: <a href="mailto:monika@strefacukiernika.pl">monika@strefacukiernika.pl</a>
                  </Descriptions.Item>*/}
                  <Descriptions.Item label='Tagi'>{printout?.tags.map(tag => <><Link to={'/grafiki-do-wydrukow/tag/' + tag}>#{tag}</Link>{' '}</>)}</Descriptions.Item>
                  <Descriptions.Item label='Opis'>{printout?.description}</Descriptions.Item>
                  {/*<Descriptions.Item label='Gdzie można zamówić wykonanie printouta?'>Pobrany projekt możesz zrealizować u jednego z naszych partnerów: <Link to='/promotions/534'>Laser Project Studio</Link> lub <Link to='/promotions/533'>Tortdecor</Link></Descriptions.Item>*/}
                  <Descriptions.Item label='Licencja'>
                    <HelpBox
                      title='Informacje o licencji'
                      component='button'
                      buttonLabel='Zapoznaj się z licencją'
                      category='Printout Project Show'
                      content='Możesz wykorzystać ten projekt nieodpłatnie na potrzebny realizacji swoich prywatnych wypieków jak i na potrzeby zamówień Twoich klientów (bez ograniczeń ilościowych). Zabroniona jest dalsza odsprzedaż projektu oraz masowa produkcja wydruków z użyciem tej grafiki (np. umieszczenie we własnym sklepie z wydrukami) bez zgody Strefy Cukiernika.'
                      visible={this.state.visibleLicenseInfo}
                      setVisible={this.setVisibleLicenseInfo}
                      isMobile={isMobile} />
                  </Descriptions.Item>

                </Descriptions>
                <Button type='primary' style={{ marginTop: '30px' }} onClick={this.props.download} disabled={downloading || !authenticated}>
                  {downloading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
                  Pobierz grafikę w wysokiej rozdzielczości
                </Button>
                {!authenticated &&
                  <CtaBox
                    text='Ta grafika jest dostępna za darmo dla zarejestrowanych użytkowników'
                    toRoute='/register'
                    redirect={['/grafiki-do-wydrukow/' + printout?.id]}
                    buttonText='Dołącz do Strefy Cukiernika'
                    noMargin
                  />}
                {/*printout?.video && (
                  <div className='printout-video'>
                    <Col xs={24} md={24}>
                      Film z wyciętego printouta u naszego partnera <Link to='/promotions/534'>Laser Project Studio</Link>:
                    </Col>
                    <Col xs={24} md={24}>
                      <iframe
                        src={printout?.video}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                        title='video'
                        className={isMobile ? 'promo-video-iframe-mobile' : 'promo-video-iframe'}
                      />
                    </Col>
                  </div>)*/}
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledPrintoutShow))
