import React from 'react'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import { PageHeader, Divider } from 'antd'
import { Link } from 'react-router-dom'
import settings from '../../config'

class CommunityIndex extends React.Component {
  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { authenticated, subType } = this.props

    return (
      authenticated && subType === 'sub2' ? <>
        <PageHeader
          title='Nasze społeczności'
          onBack={() => this.redirect(this.props.authenticated ? '/panel' : '/')}
        />
        <h2>Zapraszamy Cię do dołączenia do naszych zamkniętych społeczności podzielonych na grupy tematyczne:</h2>

        <h2 style={{ fontWeight: 700 }}>Strefa Cukiernika - Monoporcje i monodesery</h2>
        <p className='community-title'>Grupa na FB: <a href="https://www.facebook.com/groups/993747732268191">LINK</a></p>
        <p className='community-password'><span>Hasło dostępowe:</span> PYSZNAMONOPORCJA</p>

        <Divider className='community-divider' />

        <h2 style={{ fontWeight: 700 }}>Strefa Cukiernika - Wszystko o tortach</h2>
        <p className='community-title'>Grupa na FB: <a href="https://www.facebook.com/groups/336532899272491">LINK</a></p>
        <p className='community-password'><span>Hasło dostępowe:</span> MEGATORT</p>

        <Divider className='community-divider' />

        <h2 style={{ fontWeight: 700 }}>Strefa Cukiernika - Dekoracje kwiatowe</h2>
        <p className='community-title'>Grupa na FB: <a href="https://www.facebook.com/groups/756705103105177">LINK</a></p>
        <p className='community-password'><span>Hasło dostępowe:</span> PIĘKNEKWIATY</p>

        <Divider className='community-divider' />

        <h2 style={{ fontWeight: 700 }}>Strefa Cukiernika - Figurki</h2>
        <p className='community-title'>Grupa na FB: <a href="https://www.facebook.com/groups/1493672688170155/">LINK</a></p>
        <p className='community-password'><span>Hasło dostępowe:</span> SŁODKAFIGURKA</p>
      </> : <ModuleNotLoggedContent
        title='Nasze społeczności'
        contentTitle='Uzyskaj dostęp do naszych prywatnych społeczności'
        // showCtaTop
        // showCtaBottom
        content={
          <h3>Tylko nasi subskrybenci mają dostęp do społeczności - <a href={settings.subscription_purchase_url}>zostań subskrybentem</a>{!authenticated && <> lub jeśli już nim jesteś to <Link to='/login'>zaloguj się</Link></>}</h3>
        }
        redirect={this.redirect}
        redirectRoute={this.props.location?.pathname}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  subType: state.appRedux.subType
})

const mapDispatchToProps = {
}

const CommunityIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityIndex)

export default CommunityIndexContainer
