import React from 'react'
import { Button, PageHeader } from 'antd'
import { Link } from 'react-router-dom'
import CtaBox from '../../styledComponents/common/ctaBox'

class NotLoggedContent extends React.Component {
  render () {
    const { prevRoute, redirectRoute } = this.props

    const cta = (<CtaBox
      text='Funkcjonalność jest dostępna dla zalogowanych użytkowników'
      toRoute='/register'
      redirect={[redirectRoute]}
      buttonText='Dołącz do Strefy Cukiernika'
    />)

    return (
      <>
        <PageHeader
          onBack={() => this.props.redirect(prevRoute ? prevRoute : '/')}
          title={this.props.title || 'Dostęp wymaga logowania'}
          extra={this.props.extra}
        />
        <div style={{ marginBottom: '30px' }}>
          <h1>{this.props.contentTitle}</h1>

          {this.props.showCtaTop && cta}

          <div>
            {this.props.content}
          </div>

          {this.props.showCtaBottom && cta}
        </div>
      </>
    )
  }
}

export default NotLoggedContent
