/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { PageHeader } from 'antd'
import { enquireScreen } from 'enquire-js'
import IndexModules from './indexModules'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

export const PanelModulesDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to='/calculator'><img src='/images/calculator.png' alt='calculator' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to='/calculator'>Podstawowy kalkulator do wycen</Link>
            }
          ]
        }
      },
      {
        name: 'block5',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: <Link to='/calculator-portions'><img src='/images/calculator-portions.png' alt='calculator portions' /></Link>
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: <Link to='/calculator-portions'>Kalkulator porcji</Link>
            }
          ]
        }
      },
    ]
  }
}

const { location = {} } = typeof window !== 'undefined' ? window : {}

class CalculatorsIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port
    }
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render () {
    const children = [
      <IndexModules
        id='Content0_0'
        key='Content0_0'
        dataSource={PanelModulesDataSource}
        isMobile={this.state.isMobile}
      />
    ]

    return (
      <>
        <PageHeader
          title='Kalkulatory'
          onBack={() => this.redirect(this.props.authenticated ? '/panel' : '/')}
        />
        <div
          className='templates-wrapper'
          ref={(d) => {
            this.dom = d
          }}
        >
          {this.state.show && children}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const CalculatorsIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorsIndex)

export default CalculatorsIndexContainer
