/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Select, Row, Col, Card, InputNumber,
  Popconfirm, Skeleton, Divider, Empty, Modal, Typography
} from 'antd'
import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import AddPantryModal from '../../components/myrecipes/addPantryModal'

// import HelpBox from '../common/helpBox'
import { unitMap/*, oneGlassGram, oneLittleSpoonGram, oneSpoonGram, oneGlassMl, oneLittleSpoonMl, oneSpoonMl, oneDropMl*/ } from '../../utils/calcUnits'
// import { Link } from 'react-router-dom'

import FloatLabel from '../common/floatLabel'

const { TextArea } = Input
const { warning } = Modal

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 6,
    lg: 6
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 17,
    lg: 17
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

const { Option } = Select

class RecipeForm extends React.Component {
  state = {
    visibleHelpUsedIngredients: false,
    visibleHelpGeneral: false,
    loaded: false,
    pantryIngredients: [],
    usePortions: false,
    loading: false,
    addPantryModalVisible: false,
    quantities: []
  }

  formRef = React.createRef()
  xref = React.createRef()
  resultRef = React.createRef()

  setVisibleHelpGeneral = (flag) => {
    this.setState({ visibleHelpGeneral: flag })

    flag && ReactGA.event({ category: 'MyRecipes Recipe', action: 'VisibleHelpGeneral Help Opened Mobile' })
  }

  setVisibleHelpUsedIngredients = (flag) => {
    this.setState({ visibleHelpUsedIngredients: flag })

    flag && ReactGA.event({ category: 'MyRecipes Recipe', action: 'VisibleHelpUsedIngredients Help Opened Mobile' })
  }

  showUpgradeModal = () => {
    ReactGA.event({ category: 'MyRecipes Recipe', action: 'Upgrade Modal Opened' })

    warning({
      title: 'Wyczerpany został limit przepisów',
      content: 'Wyczerpany został limit przepisów (10). Potrzebujesz dodać więcej przepisów? Napisz do nas na kontakt@strefacukiernika.pl i zapytaj jakie są możliwości.',
      onOk () {
        ReactGA.event({ category: 'Orders Form', action: 'Upgrade Modal Closed' })
        // console.log('OK');
      }
    })
  }

  handleSubmit = (x) => {
    this.setState({ loading: true })

    this.props.formAction({ ...this.formRef.current?.getFieldsValue() }).then((json) => {
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
    })
  }

  handleUsedIngredientsFocus = (name) => {
    this.setState({ usedIngredientsFocus: name })
  }

  getIngUnits = (name, iname) => {
    const ingId = this.formRef.current?.getFieldValue('sections')[name]['ingredients'][iname]?.ingId
    const pantryItem = this.props.pantryIngredients?.find(x => x.id === ingId)

    if (unitMap[pantryItem?.unit]) {
      return unitMap[pantryItem?.unit]
    }

    return []
  }

  componentDidMount () {
    const { formData } = this.props

    if (formData && !Object.keys(formData).length) {
      this.formRef.current.setFieldsValue({ sections: [{ name: 'Akapit bez tytułu' }] })
    }

    if (formData) {
      this.formRef.current.setFieldsValue({ has_many_sections: formData.sections?.length > 1, ...formData })

      const quantities = formData.quantities?.map(x => x.type) || []

      this.setState({
        loaded: true,
        usePortions: formData.use_portions,
        recipeHasSections: formData.sections?.length > 1,
        quantities,
        sections: formData.sections
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (formData && prevProps.formData !== formData) {
      this.formRef.current.setFieldsValue({ has_many_sections: formData.sections?.length > 1, ...formData })

      const quantities = formData.quantities.map(x => x.type)

      this.setState({
        loaded: true,
        usePortions: formData.use_portions,
        recipeHasSections: formData.sections?.length > 1,
        quantities,
        sections: formData.sections
      })
    }
  }

  handleUsePortions = (usePortions) => {
    this.setState({ usePortions })

    if (usePortions) {
      this.formRef?.current?.setFieldsValue({ portions: 10 })
    }
  }

  afterPantrySave = (item) => {
    this.props.refreshPantry()

    const used_ingredients = this.formRef.current.getFieldValue('used_ingredients')
    used_ingredients[this.state.formIndex] = {ingId: item.id, name: item.name}

    this.formRef.current.setFieldsValue({ used_ingredients })
    this.formRef.current.validateFields()
    this.setPantryModalVisible(false)
  }

  setPantryModalVisible = (addPantryModalVisible, formIndex) => {
    this.setState({ addPantryModalVisible, formIndex })
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    // const generalHelp = (
    //   <>
    //     Tutaj podaj ogólne informacje o przepisie.<br/>
    //     <ul className='calculator-help-list'>
    //       <li><b>Nazwa</b> - nazwa przepisu nie może się powtarzać</li>
    //     </ul>
    //   </>
    // )

    // const usedIngredientsHelp = (
    //   <>
    //     W tej sekcji definiujesz np. ile kg mąki zostało zużyte, ile jajek albo ile sztuk akcesoriów (wprowadzonych w <Link to='/myrecipes/pantry'>Liście składników</Link>).<br /><br />
    //     Przyjęte przeliczniki jednostek:<br /><br />
    //     <b>1 szklanka</b> = {oneGlassGram}g = {oneGlassMl}ml<br />
    //     <b>1 łyżeczka</b> = {oneLittleSpoonGram}g = {oneLittleSpoonMl}ml<br />
    //     <b>1 łyżka</b> = {oneSpoonGram}g = {oneSpoonMl}ml<br />
    //     <b>1 kropla</b> = {oneDropMl}ml<br />
    //   </>
    // )

    const quantitiesQuantityText = {
      weight: 'Waga (g)',
      portion: 'Ilość porcji',
      rim: 'Rozmiar rantu (cm)',
      rim_layer: 'Ilość warstw'
    }

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <AddPantryModal
          visible={this.state.addPantryModalVisible}
          afterSave={this.afterPantrySave}
          setModalVisibility={this.setPantryModalVisible}
          costCalc={this.props.costCalc}
          isMobile={isMobile}
        />
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          // initialValues={{ has_many_sections: this.state.sections?.length > 1 }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  {/*<Card
                    title='Informacje o przepisie'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    // extra={<HelpBox title='Informacje o przepisie' category='Recipe Form' content={generalHelp} visible={this.state.visibleHelpGeneral} setVisible={this.setVisibleHelpGeneral} isMobile={isMobile} />}
                  >*/}
                    <Form.Item
                      name='name'
                      label={this.props.t('Tytuł')}
                      rules={[{ required: true, message: 'Podaj tytuł' }]}
                      {...formItemLayout}
                    >
                      <Input />
                    </Form.Item>

                    {/*<Form.Item
                      name='has_many_sections'
                      label={'Przepis składa się z wielu części'}
                      {...formItemLayout}
                      valuePropName='checked'
                    >
                      <Switch onChange={(x) => this.setState({ recipeHasSections: x })} />
                    </Form.Item>*/}

                    <Form.List
                      name='tools'
                    >
                      {(fields, { add, remove, move }, { errors }) => (
                        <Card
                          title='Potrzebne narzędzia'
                          className={isMobile ? 'on-mobile' : ''}
                          // extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                          extra={<Button
                            type='secondary'
                            onClick={() => { add(); this.handleUsedIngredientsFocus(fields?.length); }}
                            icon={<PlusOutlined />}
                          >
                            Dodaj narzędzie
                          </Button>}
                        >
                          {fields.map(({ key, name, ...restField }, index) => (
                            <React.Fragment key={key}>
                              <Row gutter={[3, 0]}>
                                <Col xs={9} md={19} lg={20}>
                                  <FloatLabel label={this.state.usedIngredientsFocus === 't_' + name && 'Nazwa'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'content']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                    >
                                      <Input
                                        className={'input-100p' + (this.state.usedIngredientsFocus === 't_' + name ? ' field-float-input' : '')}
                                        placeholder={this.state.usedIngredientsFocus !== 't_' + name ? 'Nazwa' : ''}
                                        min={0}
                                        max={10000}
                                        onBlur={() => this.handleUsedIngredientsFocus(null)}
                                        onFocus={() => this.handleUsedIngredientsFocus('t_' + name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>

                                <Col xs={2} md={2} lg={2}>
                                  <Button type='text' size='small' title='Przenieś w górę' icon={<UpOutlined />} onClick={() => move(index, index - 1)} disabled={index === 0} />
                                  <Button type='text' size='small' title='Przenieś w dół' icon={<DownOutlined />} onClick={() => move(index, index + 1)} disabled={index === fields.length - 1} />
                                </Col>

                                <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => { remove(name); this.setState({ quantities: this.formRef?.current?.getFieldValue('quantities') }) }}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>
                                </Col>

                              </Row>
                              {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                            </React.Fragment>
                          ))}

                          <Form.ErrorList errors={errors} />

                        </Card>
                      )}
                    </Form.List>

                    <Form.List
                      name='quantities'
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <Card
                          title='Jaka ilość wyjdzie z tego przepisu?'
                          className={isMobile ? 'on-mobile' : ''}
                          // extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                          extra={this.state.quantities?.length <= 3 && <Button
                            type='secondary'
                            onClick={() => { add(); this.handleUsedIngredientsFocus(fields?.length); this.setState({ quantities: this.formRef?.current?.getFieldValue('quantities') }) }}
                            icon={<PlusOutlined />}
                          >
                            Dodaj ilość
                          </Button>}
                        >
                          {fields.map(({ key, name, ...restField }) => (
                            <React.Fragment key={key}>
                              <Row gutter={[3, 0]}>
                                <Col xs={24} md={10} lg={10}>

                                  <FloatLabel label={this.state.usedIngredientsFocus === 'q_' + name && 'Użyty składnik'}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'type']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Wybierz z listy zakupionych składników' },
                                      {
                                        validator: async (_, name) => {
                                          const quantities = this.formRef.current.getFieldValue('quantities')
                                          const typeCheck = quantities.filter(x => x.type === name)

                                          if (typeCheck.length > 1) {
                                            return Promise.reject(new Error('Określoną ilość danego typu można zdefiniować tylko raz'))
                                          }
                                        }
                                      }
                                    ]}
                                    >
                                      <Select
                                        className={this.state.usedIngredientsFocus === 'q_' + name ? 'field-float-select' : ''}
                                        placeholder={this.state.usedIngredientsFocus !== 'q_' + name ? 'Wybierz rodzaj' : ''}
                                        onBlur={() => this.handleUsedIngredientsFocus(null)}
                                        onFocus={() => this.handleUsedIngredientsFocus('q_' + name)}
                                        onChange={(x) => {
                                          const quantities = this.state.quantities

                                          quantities[name] = x
                                          this.setState({ quantities })
                                        }}
                                      >
                                       <Option key='portion' value='portion'>Ilość porcji</Option>
                                       <Option key='weight' value='weight'>Określona waga</Option>
                                       <Option key='rim_layer' value='rim_layer'>Warstwa w rancie</Option>
                                       <Option key='rim' value='rim'>Rant</Option>
                                      </Select>
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>

                                <Col xs={9} md={4} lg={4}>
                                  <FloatLabel label={this.state.usedIngredientsFocus === 'q_' + name && quantitiesQuantityText[this.state.quantities[name]]}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'quantity']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                    >
                                      <InputNumber
                                        className={'input-100p' + (this.state.usedIngredientsFocus === 'q_' + name ? ' field-float-number' : '')}
                                        placeholder={this.state.usedIngredientsFocus !== 'q_' + name ? quantitiesQuantityText[this.state.quantities[name]] : ''}
                                        min={0}
                                        max={10000}
                                        onBlur={() => this.handleUsedIngredientsFocus(null)}
                                        onFocus={() => this.handleUsedIngredientsFocus('q_' + name)}
                                      />
                                    </Form.Item>
                                  </FloatLabel>
                                </Col>

                                <Col xs={12} md={4} lg={4}>
                                  <FloatLabel label={this.state.quantities[name] === 'rim_layer' && this.state.usedIngredientsFocus === 'q_' + name && 'Rozmiar rantu'}>
                                    {this.state.quantities[name] === 'rim_layer' && <Form.Item
                                      {...restField}
                                      name={[name, 'rim_size']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      style={{ minWidth: '70px' }}
                                      rules={[{ required: true, message: 'Podaj rozmiar rantu' }]}
                                    >
                                      <InputNumber
                                        className={'input-100p' + (this.state.usedIngredientsFocus === 'q_' + name ? ' field-float-number' : '')}
                                        placeholder={this.state.usedIngredientsFocus !== 'q_' + name ? 'Rozmiar rantu' : ''}
                                        min={0}
                                        max={10000}
                                        onBlur={() => this.handleUsedIngredientsFocus(null)}
                                        onFocus={() => this.handleUsedIngredientsFocus('q_' + name)}
                                      />
                                    </Form.Item>}
                                  </FloatLabel>
                                </Col>

                                <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                  <Popconfirm
                                    title='Czy na pewno chcesz usunąć ten element?'
                                    onConfirm={() => { remove(name); this.setState({ quantities: this.formRef?.current?.getFieldValue('quantities') }) }}
                                    {...popconfirmItemDeleteProps}
                                  >
                                    <MinusCircleOutlined
                                      className='dynamic-delete-button'
                                      title='Usuń'
                                    />
                                  </Popconfirm>
                                </Col>

                              </Row>
                              {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                            </React.Fragment>
                          ))}

                          <Form.ErrorList errors={errors} />

                        </Card>
                      )}
                    </Form.List>

                    {/*<Form.Item
                      name='use_portions'
                      label={this.props.t('Przepis jest na ilość porcji')}
                      {...formItemLayout}
                    >
                      <Switch checked={this.state.usePortions} onChange={this.handleUsePortions} />
                    </Form.Item>

                    {this.state.usePortions &&
                      <Form.Item
                        name='portions'
                        label={this.props.t('Ilość porcji')}
                        rules={[
                          { required: true, message: 'Podaj ilość porcji' },
                          () => ({
                            validator (_, value) {
                              if (value === 0) {
                                return Promise.reject(new Error('Podaj ilość porcji'))
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                        {...formItemLayout}
                      >
                        <InputNumber />
                      </Form.Item>}*/}
                  {/*</Card>*/}

                  <Form.List
                    name='sections'
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names?.length) {
                            return Promise.reject(new Error('Dodaj użyte składniki'))
                          }
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Card
                        title='Treść przepisu'
                        className={isMobile ? 'on-mobile' : ''}
                        extra={
                          <Button
                            type='secondary'
                            onClick={() => { add({ name: 'Akapit bez tytułu' }); this.handleUsedIngredientsFocus(fields?.length); this.setState({ sections: this.formRef?.current?.getFieldValue('sections') }) }}
                            icon={<PlusOutlined />}
                          >
                            Dodaj kolejną
                          </Button>}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key} style={{ border: '1px solid gray', marginBottom: '20px', position: 'relative' }}>
                            <Form.Item
                              {...restField}
                              name={[name, 'id']}
                              hidden
                            >
                              <Input />
                            </Form.Item>

                            {this.state.sections?.length > 1 && <Typography.Title
                              editable={{
                                tooltip: 'kliknij aby edytować',
                                onChange: (x) => {
                                    const sections = this.formRef.current.getFieldValue('sections')
                                    sections[name].name = x
                                    this.formRef.current.setFieldsValue({ sections })
                                    this.setState({ sections })
                                  },
                                  triggerType: ['icon', 'text']
                              }}
                              className='section-name-input'
                              level={3}
                              style={{
                                margin: 0,
                              }}
                            >{this.state.sections && this.state.sections[name].name}</Typography.Title>}

                            {this.state.sections?.length > 1 && <Popconfirm
                              title='Czy na pewno chcesz usunąć ten element?'
                              onConfirm={() => { remove(name); this.setState({ sections: this.formRef?.current?.getFieldValue('sections') }) }}
                              {...popconfirmItemDeleteProps}
                            >
                              <Button style={{ position: 'absolute', top: '2px', right: '2px' }} size='small'>Usuń</Button>
                            </Popconfirm>}

                            {this.state.sections?.length > 1 && <Form.Item
                              {...formItemLayout}
                              {...restField}
                              // label='Nazwa'
                              name={[name, 'name']}
                              rules={[{ required: true, message: 'Podaj tytuł sekcji' }]}
                              hidden
                            >
                              <Input />
                            </Form.Item>}
                            {/*<Form.Item
                              {...formItemLayout}
                              {...restField}
                              label='Opis przygotowania'
                              name={[name, 'preparing_description']}
                              rules={[{ required: true, message: 'Podaj sposób przygotowania' }]}
                            >
                              <TextArea />
                            </Form.Item>*/}

                            <Form.List
                              name={[name, 'ingredients']}
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names?.length) {
                                      return Promise.reject(new Error('Dodaj użyte składniki'))
                                    }
                                  }
                                }
                              ]}
                            >
                              {(ifields, { add: iadd, remove: iremove }, { errors: ierrors }) => (
                                <Card
                                  title='Użyte składniki'
                                  className={isMobile ? 'on-mobile' : ''}
                                  // extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                                  extra={<Button
                                    type='secondary'
                                    onClick={() => { iadd(); this.handleUsedIngredientsFocus(ifields?.length) }}
                                    icon={<PlusOutlined />}
                                  >
                                    Dodaj składnik
                                  </Button>}
                                >
                                  {ifields.map(({ key: ikey, name: iname, ...irestField }) => (
                                    <React.Fragment key={ikey}>
                                      <Row gutter={[3, 0]}>
                                        <Col xs={24} md={10} lg={10}>
                                          {/*<Form.Item
                                            {...irestField}
                                            name={[name, 'id']}
                                            hidden
                                          >
                                            <Input />
                                          </Form.Item>*/}
                                          <FloatLabel label={this.state.usedIngredientsFocus === name + '_' + iname && 'Użyty składnik'}>
                                            <Form.Item
                                              {...irestField}
                                              name={[iname, 'ingId']}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[{ required: true, message: 'Wybierz z listy zakupionych składników' }]}
                                            >
                                              <Select
                                                className={this.state.usedIngredientsFocus === name + '_' + iname ? 'field-float-select' : ''}
                                                placeholder={this.state.usedIngredientsFocus !== name + '_' + iname ? 'Wybierz składnik' : ''}
                                                onBlur={() => this.handleUsedIngredientsFocus(null)}
                                                onFocus={() => this.handleUsedIngredientsFocus(name + '_' + iname)}
                                                showSearch
                                                notFoundContent={
                                                  <Empty description={
                                                    <>
                                                      <p>Nie odnaleziono składnika</p>
                                                      <Button type="text" icon={<PlusOutlined />} onClick={() => this.setPantryModalVisible(true, iname)}>
                                                        Dodaj składnik
                                                      </Button>
                                                    </>
                                                  }
                                                  />}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                              >
                                                {
                                                  this.props.pantryIngredients?.map((ing, n) =>
                                                    ing.name && <Option key={n} value={ing.id}>{ing.name}</Option>
                                                  )
                                                }
                                              </Select>
                                            </Form.Item>
                                          </FloatLabel>
                                        </Col>

                                        <Col xs={12} md={4} lg={4}>
                                          <FloatLabel label={this.state.usedIngredientsFocus === name + '_' + iname && 'Jednostka'}>
                                            <Form.Item
                                              {...irestField}
                                              name={[iname, 'unit']}
                                              validateTrigger={['onChange', 'onBlur']}
                                              style={{ minWidth: '70px' }}
                                              rules={[{ required: true, message: 'Wybierz jednostkę miary' }]}
                                            >
                                              <Select
                                                className={this.state.usedIngredientsFocus === name + '_' + iname ? 'field-float-select' : ''}
                                                placeholder={this.state.usedIngredientsFocus !== name + '_' + iname ? 'Jednostka' : ''}
                                                onBlur={() => this.handleUsedIngredientsFocus(null)}
                                                onFocus={() => this.handleUsedIngredientsFocus(name + '_' + iname)}
                                              >
                                                {
                                                  this.getIngUnits(name, iname).map((ing, n) =>
                                                    <Option key={n} value={ing}>{ing}</Option>
                                                  )
                                                }
                                              </Select>
                                            </Form.Item>
                                          </FloatLabel>
                                        </Col>

                                        <Col xs={9} md={4} lg={4}>
                                          <FloatLabel label={this.state.usedIngredientsFocus === name + '_' + iname && 'Ilość'}>
                                            <Form.Item
                                              {...irestField}
                                              name={[iname, 'quantity']}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[{ required: true, message: 'Podaj ilość' }]}
                                            >
                                              <InputNumber
                                                className={'input-100p' + (this.state.usedIngredientsFocus === name + '_' + iname ? ' field-float-number' : '')}
                                                placeholder={this.state.usedIngredientsFocus !== name + '_' + iname ? 'Ilość' : ''}
                                                min={0}
                                                max={10000}
                                                onBlur={() => this.handleUsedIngredientsFocus(null)}
                                                onFocus={() => this.handleUsedIngredientsFocus(name + '_' + iname)}
                                              />
                                            </Form.Item>
                                          </FloatLabel>
                                        </Col>
                                        <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                          <Popconfirm
                                            title='Czy na pewno chcesz usunąć ten element?'
                                            onConfirm={() => iremove(iname)}
                                            {...popconfirmItemDeleteProps}
                                          >
                                            <MinusCircleOutlined
                                              className='dynamic-delete-button'
                                              title='Usuń'
                                            />
                                          </Popconfirm>
                                        </Col>

                                      </Row>
                                      {ifields.length - 1 !== ikey && isMobile && <Divider {...formItemsDivider} />}
                                    </React.Fragment>
                                  ))}

                                  <Form.ErrorList errors={ierrors} />

                                </Card>
                              )}
                            </Form.List>

                            <Form.List
                              name={[name, 'preparings']}
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names?.length) {
                                      return Promise.reject(new Error('Dodaj kroki przygotowania'))
                                    }
                                  }
                                }
                              ]}
                            >
                              {(ifields, { add: iadd, remove: iremove, move: imove }, { errors: ierrors }) => (
                                <Card
                                  title='Kroki przygotowania'
                                  className={isMobile ? 'on-mobile' : ''}
                                  // extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                                  extra={<Button
                                    type='secondary'
                                    onClick={() => { iadd(); this.handleUsedIngredientsFocus(ifields?.length) }}
                                    icon={<PlusOutlined />}
                                  >
                                    Dodaj krok
                                  </Button>}
                                >
                                  {ifields.map(({ key: ikey, name: iname, ...irestField }, iindex) => (
                                    <React.Fragment key={ikey}>
                                      <Row gutter={[3, 0]}>
                                        <Col xs={24} md={19} lg={20}>
                                          <FloatLabel label={this.state.usedIngredientsFocus === 'sp_' + name + '_' + iname && 'Opis'}>
                                            <Form.Item
                                              {...irestField}
                                              name={[iname, 'content']}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[{ required: true, message: 'Uzupełnij opis przygotowania' }]}
                                            >
                                              <TextArea
                                                className={'input-100p' + (this.state.usedIngredientsFocus === 'sp_' + name + '_' + iname ? ' field-float-input' : '')}
                                                placeholder={this.state.usedIngredientsFocus !== 'sp_' + name + '_' + iname ? 'Opis' : ''}
                                                min={0}
                                                max={10000}
                                                onBlur={() => this.handleUsedIngredientsFocus(null)}
                                                onFocus={() => this.handleUsedIngredientsFocus('sp_' + name + '_' + iname)}
                                              />
                                            </Form.Item>
                                          </FloatLabel>
                                        </Col>

                                        <Col xs={2} md={2} lg={2}>
                                          <Button type='text' size='small' title='Przenieś w górę' icon={<UpOutlined />} onClick={() => imove(iindex, iindex - 1)} disabled={iindex === 0} />
                                          <Button type='text' size='small' title='Przenieś w dół' icon={<DownOutlined />} onClick={() => imove(iindex, iindex + 1)} disabled={iindex === ifields.length - 1} />
                                        </Col>

                                        <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                          <Popconfirm
                                            title='Czy na pewno chcesz usunąć ten element?'
                                            onConfirm={() => iremove(iname)}
                                            {...popconfirmItemDeleteProps}
                                          >
                                            <MinusCircleOutlined
                                              className='dynamic-delete-button'
                                              title='Usuń'
                                            />
                                          </Popconfirm>
                                        </Col>

                                      </Row>
                                      {ifields.length - 1 !== ikey && isMobile && <Divider {...formItemsDivider} />}
                                    </React.Fragment>
                                  ))}

                                  <Form.ErrorList errors={ierrors} />

                                </Card>
                              )}
                            </Form.List>

                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </div>
                        ))}

                        <Form.ErrorList errors={errors} />

                      </Card>
                    )}
                  </Form.List>

                  {this.state.sections?.length > 1 && <Form.List
                    name='preparings'
                  >
                    {(fields, { add, remove, move }, { errors }) => (
                      <Card
                        title='Ogólne kroki przygotowania'
                        className={isMobile ? 'on-mobile' : ''}
                        // extra={<HelpBox title='Użyte składniki' category='Recipe Form' content={usedIngredientsHelp} visible={this.state.visibleHelpUsedIngredients} setVisible={this.setVisibleHelpUsedIngredients} isMobile={isMobile} />}
                        extra={<Button
                          type='secondary'
                          onClick={() => { add(); this.handleUsedIngredientsFocus(fields?.length); }}
                          icon={<PlusOutlined />}
                        >
                          Dodaj krok
                        </Button>}
                      >
                        {fields.map(({ key, name, ...restField }, index) => (
                          <React.Fragment key={key}>
                            <Row gutter={[3, 0]}>
                              <Col xs={9} md={19} lg={20}>
                                <FloatLabel label={this.state.usedIngredientsFocus === 'mp_' + name && 'Opis'}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'content']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'Pole jest wymagane' }]}
                                  >
                                    <TextArea
                                      className={'input-100p' + (this.state.usedIngredientsFocus === 'mp_' + name ? ' field-float-input' : '')}
                                      placeholder={this.state.usedIngredientsFocus !== 'mp_' + name ? 'Opis' : ''}
                                      min={0}
                                      max={10000}
                                      onBlur={() => this.handleUsedIngredientsFocus(null)}
                                      onFocus={() => this.handleUsedIngredientsFocus('mp_' + name)}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                              </Col>

                              <Col xs={2} md={2} lg={2}>
                                <Button type='text' size='small' title='Przenieś w górę' icon={<UpOutlined />} onClick={() => move(index, index - 1)} disabled={index === 0} />
                                <Button type='text' size='small' title='Przenieś w dół' icon={<DownOutlined />} onClick={() => move(index, index + 1)} disabled={index === fields.length - 1} />
                              </Col>

                              <Col xs={3} md={3} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                                <Popconfirm
                                  title='Czy na pewno chcesz usunąć ten element?'
                                  onConfirm={() => { remove(name); this.setState({ quantities: this.formRef?.current?.getFieldValue('quantities') }) }}
                                  {...popconfirmItemDeleteProps}
                                >
                                  <MinusCircleOutlined
                                    className='dynamic-delete-button'
                                    title='Usuń'
                                  />
                                </Popconfirm>
                              </Col>

                            </Row>
                            {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                          </React.Fragment>
                        ))}

                        <Form.ErrorList errors={errors} />

                      </Card>
                    )}
                  </Form.List>}

                </Space>
              </Col>
            </Row>
            <Button htmlType='submit' type='primary' size='large' disabled={this.state.loading}>
              {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
            </Button>
          </Space>
        </Form>
      </>
    )
  }
}

export default withTranslation()(RecipeForm)
