import React from 'react'
import StyledPantryIndex from '../../styledComponents/myrecipes/pantry'
import { Redirect } from 'react-router'
import { getMyRecipesPantry, getMyRecipesConfig } from '../../utils/actions'

class MyRecipePantryIndex extends React.Component {
  state = {
    makeRedirection: false,
    loaded: false,
    costCalc: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getMyRecipesConfig()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getPantry = (onlyUsed) => {
    this.setState({ loaded: false })

    getMyRecipesPantry(onlyUsed).then((json) => {
      if (this._isMounted) {
        this.setState({ pantry: json, loaded: true })
      }
    })
  }

  getMyRecipesConfig = () => {
    getMyRecipesConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ costCalc: json.cost_calc })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, pantry, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/myrecipes' />
      ) : (
        <StyledPantryIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          pantry={pantry}
          redirect={this.redirect}
          loaded={loaded}
          costCalc={this.state.costCalc}
          getPantry={this.getPantry}
        />
      )
    )
  }
}

export default MyRecipePantryIndex
