import React from 'react'
import StyledPaymentConfirm from '../../styledComponents/userPurchase/paymentConfirm'
import { getUrlParameter } from '../../utils/helpers'
import { withTranslation } from 'react-i18next'

class PaymentConfirm extends React.Component {
  state = {
    formSaving: false,
    successfull: undefined
  }

  componentDidMount (prevProps, prevState) {
    this._isMounted = true

    const reason = getUrlParameter('reason')
    const fromTraining = getUrlParameter('from_training')
    const redirect = getUrlParameter('redirect')
    this.setState({ reason, fromTraining, redirect })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    return (
      <div>
        <StyledPaymentConfirm
          reason={this.state.reason}
          redirect={this.redirect}
          redirectRoute={this.state.redirect}
        />
      </div>
    )
  }
}

export default withTranslation()(PaymentConfirm)
