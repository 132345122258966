import React from 'react'
import StyledEnlargeImageIndex from '../../styledComponents/enlargeImage'
import {
   enlargeImage,
  getEnlargeImageUploadedFilePreview,
  downloadEnlargedImage,
  getEnlargeImageEnlargedFilePreview,
  checkEnlargeLimit,
  getUserProfile,
  sendUpvote
} from '../../utils/actions'
import ReactGA from 'react-ga4'
import ModuleNotLoggedContent from '../main/notLoggedContent'
import { connect } from 'react-redux'
import ContentLeft from '../common/contentLeft'
import ContentRight from '../common/contentRight'
import CtaModal from '../../styledComponents/common/ctaModal'
import { setCtaModalVisible } from '../../store/actions'
import { LikeOutlined } from '@ant-design/icons'

const FileDownload = require('js-file-download')

class EnlargeImageIndex extends React.Component {
  state = {
    loading: false,
    preview: undefined,
    enlargedPreview: undefined,
    wasGenerated: false,
    limitExceeded: false,
    downloading: false
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ user: json })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getEnlargeImageEnlargedFilePreview()
      // this.checkEnlargeLimit()
      this.getUserProfile()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  removeFile = () => {
    this.setState({ preview: undefined })
  }

  downloadEnlargedImage = () => {
    this.setState({ downloading: true })
    downloadEnlargedImage().then((json) => {
      if (this._isMounted) {
        this.setState({ downloading: false })
        FileDownload(json.data, 'powiekszona-grafika-strefacukiernika.png')
      }
    })

    ReactGA.event({ category: 'EnlargeImage', action: 'Downloaded Enlarged'})
  }

  getUploadedFilePreview = () => {
    getEnlargeImageUploadedFilePreview().then((preview) => {
      this.setState({ preview })
    }).catch(err => {
    })
  }

  checkEnlargeLimit = () => {
    checkEnlargeLimit().then((response) => {
      this.setState({ limitExceeded: response === false })
    }).catch(err => {
    })
  }

  getEnlargeImageEnlargedFilePreview = (after) => {
    getEnlargeImageEnlargedFilePreview().then((enlargedPreview) => {
      this.setState({ enlargedPreview }, after)
    }).catch(err => {
    })
  }

  enlargeImage = (data) => {
    this.setState({ loading: true, wasGenerated: false })
    ReactGA.event({ category: 'EnlargeImage', action: 'Image Enlarged'})

    enlargeImage(data).then((json) => {
      if (json?.limit_exceeded) {
        this.props.setCtaModalVisible(true)
        this.setState({ loading: false})
      } else {
        this.getEnlargeImageEnlargedFilePreview(() => {
          this.setState({ loading: false, wasGenerated: true, preview: undefined })
        })
      }
    }).catch(err => {
      this.setState({ loading: false })
    })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  render () {
    const { isMobile, authenticated } = this.props

    let text
    let toRoute
    let redirect
    let requiredSubs
    let buttonText
    let buttonTextSize
    let buttonType
    let buttonCallback

    text = <>Hej! Na ten moment wyczerpany został limit powiększanych grafik... Jeśli chcesz przyspieszyć prace nad powiększaniem większej ilości grafik, kliknij kciuk w górę :D</>
    requiredSubs = ['sub1', 'sub2', 'sub3']
    buttonText = <LikeOutlined />
    buttonTextSize = 50
    buttonType = 'text'
    buttonCallback = () => { sendUpvote('enlarge_image_limit') }

    // if (authenticated && user?.sub_type === 'free' && !user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit powiększania grafik. Możesz powiększyć więcej jeśli skorzystasz z któregoś ze Strefowych pakietów, wypróbuj konto testowe przez 7 dni bez opłat :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/powieksz-grafike']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet testowy i powiększ kolejną grafikę'
    // } else if (authenticated && user?.sub_type === 'free' && user?.trial_used) {
    //   text = <>Ups! Wyczerpany został limit powiększania grafik. Możesz powiększyć więcej jeśli skorzystasz z któregoś ze Strefowych pakietów :-)</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/powieksz-grafike']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i powiększ kolejną grafikę'
    // } else if (authenticated && user?.sub_type !== 'free' && user?.trial) {
    //   text = <>Ups! Wyczerpany został limit powiększania grafik. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub wybrać wyższy pakiet gdzie można powiększać więcej grafik</>
    //   toRoute = '/pakiety'
    //   redirect = [this.props.redirectRoute || '/powieksz-grafike']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Wybierz pakiet i powiększ kolejną grafikę'
    // } else if (authenticated && user?.sub_type !== 'free' && !user?.trial) {
    //   text = <>Ups! Wyczerpany został limit powiększania grafik. Możesz poczekać do kolejnego miesiąca aby limit się zresetował lub zmienić pakiet na wyższy pakiet gdzie można powiększać więcej grafik</>
    //   toRoute = '/zmien-pakiet'
    //   redirect = [this.props.redirectRoute || '/powieksz-grafike']
    //   requiredSubs = ['sub1', 'sub2', 'sub3']
    //   buttonText = 'Zmień pakiet i powiększ kolejną grafikę'
    // }

    return (
      authenticated ? <>
        <CtaModal
          text={text}
          toRoute={toRoute}
          redirect={redirect}
          requiredSubs={requiredSubs}
          buttonText={buttonText}
          buttonTextSize={buttonTextSize}
          buttonType={buttonType}
          buttonCallback={buttonCallback}
        />
        <StyledEnlargeImageIndex
          redirect={this.redirect}
          authenticated={authenticated}
          loading={this.state.loading}
          enlargeImage={this.enlargeImage}
          getUploadedFilePreview={this.getUploadedFilePreview}
          preview={this.state.preview}
          enlargedPreview={this.state.enlargedPreview}
          wasGenerated={this.state.wasGenerated}
          removeFile={this.removeFile}
          downloadEnlargedImage={this.downloadEnlargedImage}
          isMobile={isMobile}
          limitExceeded={this.state.limitExceeded}
          downloading={this.state.downloading}
        />
      </> : <ModuleNotLoggedContent
        title='Powiększ grafikę'
        contentTitle='Znaleziona grafika jest zbyt mała do druku na opłatku/masie cukrowej?'
        // showCtaTop
        showCtaBottom
        content={
          <>
            <p>Powiększ grafiki do wydruków jednym kliknięciem!</p>
            <ContentLeft
              isMobile={isMobile}
              image='/images/enlarge.png'
              title='Powiększanie grafik do wydruków'
              content='Znalazłeś grafikę, która idelanie pasuje do Twojego tortu? Ale pewnie nie nadaje się do druku pownieważ jest zbyt mała... nic straconego! Skorzystaj z naszego narzędzia do powiększania dowolnej grafiki do rozmiarów, które pozwolą wydrukować grafikę, którą chcesz a nie tylko taką, która już jest duża ;)'
            />
          </>
        }
        redirect={this.redirect}
        redirectRoute={this.props.location?.pathname}
      />
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setCtaModalVisible
}

const EnlargeImageIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnlargeImageIndex)

export default EnlargeImageIndexContainer
