import React from 'react'
import StyledRecipeIndex from '../../styledComponents/calcPro/recipe'
import { Redirect } from 'react-router'
import { getRecipes } from '../../utils/actions'

class RecipeIndex extends React.Component {
  state = {
    makeRedirection: false,
    recipes: [],
    loaded: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getRecipes()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getRecipes = () => {
    getRecipes().then((json) => {
      if (this._isMounted) {
        this.setState({ recipes: json, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection, recipes, loaded } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/order' />
      ) : (
        <StyledRecipeIndex
          makeRedirection={this.makeRedirection}
          isMobile={isMobile}
          recipes={recipes}
          redirect={this.redirect}
          loaded={loaded}
        />
      )
    )
  }
}

export default RecipeIndex
