import React from 'react'
import { PageHeader, Button, Space, Checkbox, Row, Col } from 'antd'
import { updateSubscriptionCancelProgress, getUserProfile } from '../../utils/actions'

class CancelSub extends React.Component {
  state = {
    step: 1,
    id: null,
    reasons: []
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      const d0 = new Date(json.valid_to_date)
      d0.setTime(d0.getTime() - 345600000) // substract 4 days (added extra for charging sub)

      const accessToDate = d0.toISOString().replace('T', ' ').substring(0, 16)
      const d1 = new Date(accessToDate)
      const d2 = new Date()
      const remainingDays = Math.round((d1.getTime() - d2.getTime()) / (1000 * 3600 * 24))
      const d3 = new Date('2024-12-31')
      // const trainingsToYearEnd = d3.getMonth() - d2.getMonth()
      const trainingsToYearEnd = 3

      this.setState({ user: json, accessToDate, remainingDays, trainingsToYearEnd })
    })
  }

  updateSubscriptionCancelProgress = (step, action) => {
    const { id, reasons } = this.state

    updateSubscriptionCancelProgress({ step, action, id, reasons }).then((json) => {
      if (!id) {
        this.setState({ id: json.id })
      }

      if (action === 'cancel_sub') {
        if (json.status === 'canceled') {
          this.setState({ step: 6 })
        } else {
          this.setState({ step: 7 })
        }
      }
    })
  }

  componentDidMount () {
    this.updateSubscriptionCancelProgress(1, 'step')
    this.getUserProfile()
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  setCurrentStep = (step) => {
    this.setState({ step })
    this.updateSubscriptionCancelProgress(step, 'step')
  }

  keepSub = () => {
    this.updateSubscriptionCancelProgress(this.state.step, 'keep_sub')
    this.setState({ step: 0 })
  }

  cancelSub = () => {
    this.updateSubscriptionCancelProgress(this.state.step, 'cancel_sub')
  }

  addReason = (event, reason) => {
    const reasons = this.state.reasons

    if (!reasons.includes(reason)) {
      reasons.push(reason)
      this.setState({ reasons })
    } else {
      this.setState({ reasons: reasons.filter(e => e !== reason) })
    }
  }

  render () {
    const contactEmail = 'kontakt@strefacukiernika.pl'
    const { step, user } = this.state
    const steps = 5

    return (
      <>
        <PageHeader
          title='Anuluj subskrypcję'
          onBack={() => this.redirect('/profile')}
        />

        {step > 0 && step <= steps && <p>Krok {step} z 5</p>}

        <div style={{ textAlign: 'left', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
          {step === 0 && <div>
            <p>Zrezygnowałaś z anulowania subskrypcji</p>
          </div>}
          {step === 1 && <div>
            <p>Przed nami kolejne szkolenia:</p>

            {/*<p style={{ fontWeight: '700', marginTop: '10px' }}>LISTOPAD: Tematem listopada będą torty musowe! Wykonamy aż dwa torty musowe na szkoleniach online na żywo szkolenia poprowadzą Marlena Doroś i Katarzyna-Chudy Kamola z pracowni tortów Cake Me z Elbląga.</p>*/}
            {/*<img style={{ maxWidth: '100%' }} src="/dimages/trainings/sub_cancel_10.2024.png" />*/}

            <p style={{ fontWeight: '700' }}>GRUDZIEŃ: Jeszcze w grudniu pojawi się szkolenie Cremeoux bananowe - idealna propozycja do monoporcji - autorstwa Katarzyny Chudy-Kamola z pracowni tortów Cake Me z Elbląga</p>
            <p style={{ fontWeight: '700' }}>STYCZEŃ: Z Joanną Jabłońską z pracowni Eksplozja Smaku nauczysz się jak wykonać tort bento oraz przepiękny, pełnowymiarowy tort - od A do Z!</p>
            {<img style={{ maxWidth: '100%' }} src="/dimages/trainings/sub_cancel_12.2024.jpeg" />}

            <p style={{ marginTop: '20px' }}>Rezygnując z subskrypcji, tracisz możliwość uzyskania dostępu do tych szkoleń.</p>
            <p>Czy na pewno rezygnujesz z kolejnych szkoleń?</p>
          </div>}

          {step === 2 && <div>
            {user.sub_price_gross === 4700 && <>
              <p>Twoja obecna subskrypcja to 47 zł miesięcznie (oferta promocyjna).</p>
              <p>Cena regularna tej subskrypcji to 100 zł miesięcznie.</p>
              <p style={{ fontWeight: '700' }}>Rezygnując z subskrypcji możesz zawsze do nas wrócić ale już w standardowej cenie (z oferty promocyjnej na subskrypcję można skorzystać tylko raz).</p>
              <p>Czy jesteś pewna, że chcesz zrezygnować z subskrypcji w promocyjnej cenie?</p>
            </>}

            {user.sub_price_gross === 7700 && <>
              <p>Twoja obecna subskrypcja to 77 zł miesięcznie.</p>
              <p>Cena regularna tej subskrypcji to 100zł miesięcznie - pozostając subskrybentem masz gwarancję, że cena dla Ciebie nie wzrośnie.</p>
              <p>Czy jesteś pewna, że chcesz zrezygnować z subskrypcji w obecnej cenie?</p>
            </>}
          </div>}

          {step === 3 && <div>
            <p>Rezygnując z subskrypcji stracisz dostęp do obecnych i przyszłych szkoleń:</p>

            <ul>
              <li>zostanie tylko {this.state.remainingDays} dni dostępu do szkoleń</li>
              <li>stracisz dostęp do szkoleń, które już są dostępne w portalu</li>
              <li>tracisz możliwość dostępu do kolejnych szkoleń w super cenie</li>
              <li>tylko do końca roku <b style={{ fontWeight: '700' }}>stracisz dostęp do minimum {this.state.trainingsToYearEnd} kolejnych szkoleń</b>, które poprowadzą nasze trenerki:
                <ul style={{ marginLeft: '40px', marginTop: '20px' }}>
                  <li>Joanna Jabłońska</li>
                  <li>Katarzyna Osiecka</li>
                  <li>Izabela Rink</li>
                  <li>Małgorzata Witkowska</li>
                  <li>Lidia Michalczyk</li>
                  <li>Marlena Doroś</li>
                  <li>Katarzyna Chudy-Kamola</li>
                </ul>
              </li>
            </ul>

            <p>Czy na pewno rezygnujesz z już dostępnych oraz z kolejnych szkoleń?</p>
          </div>}

          {step === 4 && <div>
            <p>Wskaż proszę, dlaczego chcesz zrezygnować z subskrypcji (możesz wybrać wiele opcji, minimum jedną)</p>

            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Row>
              <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'not_using')}>nie korzystam zbyt często z treści w portalu</Checkbox></Col>
  	          <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'price_to_high')}>subskrypcja jest za drogo</Checkbox></Col>
  	          <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'no_trainings_matching_interest')}>brak szkoleń w tematach, które mnie interesują</Checkbox></Col>
  	          <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'technical_problems')}>występują problemy techniczne</Checkbox></Col>
            </Row>
            <Row>
              <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'too_few_trainings')}>zbyt mało szkoleń dostępnych w portalu</Checkbox></Col>
  	          <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'trainings_not_necessary')}>szkolenia nie są mi już potrzebne</Checkbox></Col>
              <Col xs={24} md={12}><Checkbox onClick={x => this.addReason(x, 'too_few_new_trainings')}>zbyt mało nowych szkoleń</Checkbox></Col>
            </Row>
            </div>
            </div>}

            {step === 5 && <div>
              <p>Podsumowując co tracisz rezygnując z subskrypcji:</p>

              <ul>
                <li>dostęp do szkoleń zachowasz jeszcze jedynie przez {this.state.remainingDays} dni (do {this.state.accessToDate})</li>
                <li>stracisz dostęp do szkoleń, które już są dostępne w portalu</li>
                <li>tracisz możliwość korzystania z subskrypcji w obecnej cenie</li>
                <li>tylko do końca roku stracisz dostęp do minimum {this.state.trainingsToYearEnd} kolejnych szkoleń</li>
                <li>stracisz dostęp do najbliższego szkolenia: Róża angielska z papieru opłatkowego autorstwa Małgorzaty Witkowskiej</li>
              </ul>
            </div>}

            {step === 6 && <div>
              <h2>Potwierdzamy rezygnację z subskrypcji</h2>

              <p>Kolejne płatności nie będą pobierane.</p>

              <p>Dostęp będzie aktywny do: {this.state.accessToDate}</p>
            </div>}

            {step === 7 && <div>
              <h2>Wystapił błąd podczas anulowania subskrypcji</h2>

              <p>Napisz do nas email i daj znać, że masz problem z anulowaniem subskrypcji: <a href={'mailto:' + contactEmail}>{contactEmail}</a></p>
            </div>}
        </div>

        <Space>
          {step < steps && step > 0 && (
            <Button type="primary" onClick={() => this.setCurrentStep(step + 1)} disabled={step === 4 && this.state.reasons.length === 0}>
              {step === 1 &&
                'Tak, rezygnuję'}
              {step === 2 && user.sub_price_gross === 4700 &&
                'Tak, rezygnuję z oferty promocyjnej'}
              {step === 2 && user.sub_price_gross === 7700 &&
                'Tak, rezygnuję z obecnej oferty'}
              {step === 3 &&
                'Tak, rezygnuję ze wszystkich szkoleń'}
              {step === 4 &&
                'Przejdź dalej'}
            </Button>
          )}

          {step === steps && (
            <Button type="danger" onClick={() => this.cancelSub()}>
              Potwierdzam rezygnację z subskrypcji
          </Button>)}

          {(step === 0 || step === steps + 1) && (
            <Button type="primary" onClick={() => this.redirect('/')}>
              Przejdź do strony głównej
          </Button>)}

          {step > 0 && step < steps + 1 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => this.keepSub() }
            >
              Zachowuję obecną subskrypcję
            </Button>
          )}
        </Space>
      </>
    )
  }
}

export default CancelSub
