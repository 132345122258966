import React from 'react'
import {
  Table, PageHeader, Tag,
  Empty, Skeleton
} from 'antd'
import { Link } from 'react-router-dom'
import { getPaymentStatusColor } from '../../utils/helpers'
import 'moment/locale/pl'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledCalcIndex extends React.Component {
  state = {
    loaded: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  render () {
    const { purchases, isMobile, loaded, redirect } = this.props

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (x) => (
          <Tag color={getPaymentStatusColor(x)}>{this.props.t(x)}</Tag>
        ),
        sorter: (a, b) => { return a.status.localeCompare(b.status) },
      },
      {
        title: 'Produkt',
        dataIndex: 'item_title',
        key: 'item_title',
        sorter: (a, b) => { return a.item_title.localeCompare(b.item_title) }
      },
      // {
      //   title: 'Rodzaj',
      //   dataIndex: 'type',
      //   key: 'type',
      //   sorter: (a, b) => { return a.type.localeCompare(b.type) },
      //   render: (type) => type === 'sub' ? 'subskrypcja' : 'płatność jednorazowa'
      // },
      {
        title: 'Data',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Kwota',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => { return a.client_name.localeCompare(b.client_name) },
        render: (amount) => <>{amount} zł</>
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/historia-platnosci/' + record.id}>pokaż</Link>
          </span>
        )
      }
    ]

    return (
      <>
        <PageHeader
          title='Historia płatności'
          onBack={() => redirect('/profile')}
        />
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ display: loaded ? 'block' : 'none', maxWidth: '1000px', margin: '0 auto' }}>
          <Table
            className={`settings-table ${purchases && purchases.length ? 'fade-in' : ''}`}
            {...this.settings}
            columns={columns}
            dataSource={purchases}
            locale={{
              emptyText: <Empty description='Brak płatności' />,
              triggerDesc: this.props.t('Sortuj malejąco'),
              triggerAsc: this.props.t('Sortuj rosnąco'),
              cancelSort: this.props.t('Kliknij aby anulować sortowanie') }}
          />
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCalcIndex))
