import React from 'react'
import {
  Button, Table, Calendar, Badge, Segmented, List, Menu, PageHeader, Tag, Row, Col,
  Dropdown, Empty, Skeleton, Modal, Space
} from 'antd'

import { Dropdown as DropdownMobile } from 'antd-mobile'
import { MoreOutlined, CalendarOutlined, DollarCircleOutlined, UserOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import StyledOrderMobileMenu from './menuMobile'
import moment from 'moment'
import { saveDeviceData, getDeviceData, getOrderStatusColor } from '../../utils/helpers'
import 'moment/locale/pl'
import locale from 'antd/es/date-picker/locale/pl_PL'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'
import OrderHelp from './help'

class StyledCalcIndex extends React.Component {
  state = {
    headerActions: [],
    filteredOrders: [],
    orders_by_date: [],
    filteredOrdersByDate: [],
    panelType: 'month',
    y: undefined,
    m: undefined,
    viewType: 'list',
    status: 'new,accepted',
    loaded: false,
    welcomeModalVisible: false,
    helpVisible: false
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  mobileDropdownRef = React.createRef()

  handleHelpVisible = (helpVisible) => {
    this.setState({ helpVisible })
    this.mobileDropdownRef?.current?.close()

    helpVisible && ReactGA.event({ category: 'Orders', action: 'HelpOpened Mobile On Index' })
  }

  handleWelcomeModalVisible = (welcomeModalVisible) => {
    this.setState({ welcomeModalVisible })

    !welcomeModalVisible && ReactGA.event({ category: 'Orders', action: 'Welcome Modal Closed' })
  }

  notShowWelcomeModalAgain = () => {
    saveDeviceData('orders_disable_welcome_modal', 'true')
    this.handleWelcomeModalVisible(false)

    ReactGA.event({ category: 'Orders', action: 'Welcome Modal Not Show Again Clicked' })
  }

  openHelpAndNotShowAgain = () => {
    this.notShowWelcomeModalAgain()
    this.handleHelpVisible(true)

    ReactGA.event({ category: 'Orders', action: 'Welcome Modal Show Help Clicked' })
  }

  componentDidMount () {
    const x = moment()
    const y = parseInt(x.format('YYYY'))
    const m = parseInt(x.format('M'))

    const viewType = getDeviceData('orders_view_type')
    const status = getDeviceData('orders_status')

    if (viewType) {
      this.setState({ viewType })
    }
    if (status) {
      this.setState({ status })
    }

    this.setState({ y, m })

    const welcomeModal = getDeviceData('orders_disable_welcome_modal')

    if (!welcomeModal && this.props.from_route === 'panel_index') {
      this.handleWelcomeModalVisible(true)
    }
  }

  filterOrders (ordersByDate2, statusSplited) {
    const ordersByDate = JSON.parse(JSON.stringify(ordersByDate2))

    Object.keys(ordersByDate).map(function (key, index) {
      Object.keys(ordersByDate[key]).map(function (key2, index) {
        Object.keys(ordersByDate[key][key2]).map(function (key3, index) {
          ordersByDate[key][key2][key3] = ordersByDate[key][key2][key3].filter(x => statusSplited.includes(x.status))
        })
      })
    })

    return ordersByDate
  }

  componentDidUpdate (prevProps, prevState) {
    const { status } = this.state

    if (prevProps.orders !== this.props.orders) {
      const statusSplited = this.state.status.split(',')

      this.setState({
        filteredOrders: status === 'all' ? this.props.orders : this.props.orders.filter(x => statusSplited.includes(x.status)),
        filteredOrdersByDate: status === 'all' ? this.props.ordersByDate : this.filterOrders(this.props.ordersByDate, statusSplited),
        loaded: true
      })
    }
  }

  handleCalendarSelect = (value, x) => {
    const y = parseInt(value.format('YYYY'))
    const m = parseInt(value.format('M'))
    const d = parseInt(value.format('D'))

    if ((this.state.panelType === 'month' && y === this.state.y && m === this.state.m) || (this.state.panelType === 'year' && y === this.state.y)) {
      if (this.state.panelType === 'month') {
        this.props.redirect('/calendar/' + y + '/' + m + '/' + d)
      } else {
        this.props.redirect('/calendar/' + y + '/' + m)
      }
    } else {
      this.setState({ y, m })
    }
  }

  handlePanelChange = (value, panelType) => {
    this.setState({ panelType })

    ReactGA.event({ category: 'Orders', action: 'Calendar Panel Type Changed on Index To: ' + panelType })
  }

  handleViewType = (viewType) => {
    this.setState({ viewType })

    saveDeviceData('orders_view_type', viewType)

    ReactGA.event({ category: 'Orders', action: 'View Type Changed on Index To: ' + viewType })
  }

  handleStatus = (status) => {
    const statusSplited = status.split(',')

    this.setState({
      status,
      filteredOrders: status === 'all' ? this.props.orders : this.props.orders.filter(x => statusSplited.includes(x.status)),
      filteredOrdersByDate: status === 'all' ? this.props.ordersByDate : this.filterOrders(this.props.ordersByDate, statusSplited)
    })

    saveDeviceData('orders_status', status)

    ReactGA.event({ category: 'Orders', action: 'Status Filter Changed In Index: ' + status })
  }

  render () {
    const { orders, makeRedirection, isMobile } = this.props
    const { filteredOrders, loaded } = this.state

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='add-order'
          onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/order/add')}
        >
          Dodaj zamówienie
        </Menu.Item>
        <Menu.Item
          key='recipes'
        >
          <Link to='/recipe'>Przepisy</Link>
        </Menu.Item>
        <Menu.Item
          key='pantry'
        >
          <Link to='/pantry'>Magazyn składników</Link>
        </Menu.Item>
        <Menu.Item
          key='config'
        >
          <Link to='/order/config'>Ustawienia</Link>
        </Menu.Item>
        <Menu.Item
          key='help'
          onClick={() => this.handleHelpVisible(true)}
        >
          Pomoc
        </Menu.Item>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='add-order'
        type='primary'
        onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/order/add')}
      >
        Dodaj zamówienie
      </Button>,
      <Button
        key='recipes'
      >
        <Link to='/recipe'>Przepisy</Link>
      </Button>,
      <Button
        key='pantry'
      >
        <Link to='/pantry'>Magazyn składników</Link>
      </Button>,
      <Button
        key='config'
      >
        <Link to='/order/config'>Ustawienia</Link>
      </Button>,
      <Button
        key='help'
        type='text'
        className='help-button'
        onClick={() => this.handleHelpVisible(true)}
      >
        <QuestionCircleOutlined />
      </Button>
    ]) || [actionsMenuMobile]

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (x) => (
          <Tag color={getOrderStatusColor(x)}>{this.props.t(x)}</Tag>
        ),
        sorter: (a, b) => { return a.status.localeCompare(b.status) },
      },
      {
        title: 'Nazwa zamówienia',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Klient',
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: (a, b) => { return a.client_name.localeCompare(b.client_name) },
      },
      {
        title: 'Data realizacji',
        dataIndex: 'to_date',
        key: 'to_date',
        render: (x, record) => (
          <span>{x} {record.to_time}</span>
        ),
        sorter: (a, b) => { return (a.to_date + ' ' + a.to_time).localeCompare(b.to_date + ' ' + b.to_time) },
      },
      {
        title: 'Wartość zamówienia',
        dataIndex: 'price_to_client',
        key: 'price_to_client',
        render: (x) => (
          <span>{x && x + ' zł'}</span>
        ),
        sorter: (a, b) => { return a.price_to_client - b.price_to_client },
      },
      {
        title: 'Twój zysk',
        dataIndex: 'earnigs',
        key: 'earnigs',
        render: (x) => (
          <span>{x && x + ' zł'}</span>
        ),
        sorter: (a, b) => { return a.earnigs - b.earnigs },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/order/' + record.id}>edytuj</Link>
          </span>
        )
      }
    ]

    const monthCellRender = (value) => {
      const y = parseInt(value.format('YYYY'))
      const m = parseInt(value.format('M'))
      const ords = this.state.filteredOrdersByDate && this.state.filteredOrdersByDate[y] && this.state.filteredOrdersByDate[y][m]
      const ordsCount = ords && Object.keys(ords).map(k => ords[k].length).reduce((a, b) => a + b, 0)
      const sumPriceToClient = ords && Object.keys(ords).map(k => ords[k].map(x => x.price_to_client).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      const sumEarnigs = ords && Object.keys(ords).map(k => ords[k].map(x => x.earnigs).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

      return ords ? (
        <>
          {isMobile && <div className='calendar-badge-wrapper'><Badge className='calendar-badge' count={ordsCount} /></div>}
          {!isMobile && <div>zamówień: {ordsCount}<br />wartość: {sumPriceToClient && sumPriceToClient.toFixed(2)} zł<br />zysk: {sumEarnigs && sumEarnigs.toFixed(2)} zł</div>}
        </>
      ) : null
    }

    const dateCellRender = (value) => {
      const y = parseInt(value.format('YYYY'))
      const m = parseInt(value.format('M'))
      const d = parseInt(value.format('D'))
      const ords = this.state.filteredOrdersByDate && this.state.filteredOrdersByDate[y] && this.state.filteredOrdersByDate[y][m] && this.state.filteredOrdersByDate[y][m][d]

      return (ords &&
        <>
          {isMobile && <div className='calendar-badge-wrapper'><Badge className='calendar-badge' count={ords.length} /></div>}
          {!isMobile && ((ords.length > 1 && (
            <>
              <ul>
                <li key={ords[0].id}>
                  {ords[0].name}
                </li>
                <li key={ords[1].id}>
                  + {ords.length - 1} więcej
                </li>
              </ul>
            </>

          )) || (
            <ul>{ords.map((item) => (
              <li key={item.id}>
                {item.name}
              </li>
            ))}
            </ul>
          ))}
        </>
      )
    }

    const selectViewOptions = [{ label: isMobile ? 'Lista' : 'Tabela', value: 'list' }, { label: 'Kalendarz', value: 'calendar' }]
    const selectStatusOptions = [{ label: 'Aktywne', value: 'new,accepted' }, { label: 'Zakończone', value: 'finished' }, { label: 'Anulowane', value: 'canceled' }, { label: 'Wszystkie', value: 'all' }]

    return (
      <>
        <PageHeader
          title='Zamówienia'
          onBack={() => makeRedirection()}
          extra={actions}
        />
        <OrderHelp
          visible={this.state.helpVisible}
          setVisible={this.handleHelpVisible}
          isMobile={isMobile}
        />
        <Modal
          title='Witaj w systemie zamówień!'
          visible={this.state.welcomeModalVisible}
          className={isMobile ? 'welcome-modal' : ''}
          onCancel={() => this.handleWelcomeModalVisible(false)}
          footer={isMobile ? [
            <Space direction='vertical' style={{ textAlign: 'center' }}>
              <Button type='primary' key='show-help' onClick={this.openHelpAndNotShowAgain}>Pokaż pomoc</Button>
              <Button key='not-show-again' onClick={this.notShowWelcomeModalAgain}>Nie pokazuj ponownie</Button>
              <Button key='close' onClick={() => this.handleWelcomeModalVisible(false)}>Zamknij</Button>
            </Space>
          ] : [
            <Button type='primary' key='show-help' onClick={this.openHelpAndNotShowAgain}>Pokaż pomoc</Button>,
            <Button key='not-show-again' onClick={this.notShowWelcomeModalAgain}>Nie pokazuj ponownie</Button>,
            <Button key='close' onClick={() => this.handleWelcomeModalVisible(false)}>Zamknij</Button>
          ]}
        >
          W tym miejscu możesz dodawać zamówienia od Twoich klientów. Do dyspozycji masz listę zamówień, bazę przepisów oraz magazyn składników. Kilka podstawowych składników i przepisów dodaliśmy dla Ciebie na sam początek. Możesz je modyfikować i dodawać według swoich potrzeb. Obecna wersja aplikacji pozwala na dodawanie do 10 zamówień miesięcznie. Jeśli chcesz dowiedzieć się więcej jak działają zamówienia, kliknij przycisk 'Pokaż Pomoc'.
        </Modal>
        {isMobile && <Button className={'mobile-add-btn' + (this.props.screenSize === 'xs' ? ' with-menu' : '')} type="primary" shape="circle" size="large" onClick={() => this.props.limitExceeded ? this.props.setCtaModalVisible(true) : this.props.redirect('/order/add')}>+</Button>}
        {loaded &&
          <div className='list'>
            <Row>
              <Col xs={24} md={8} style={{ textAlign: !isMobile && 'left' }} className='orders-actions'>
                Widok:{' '}
                <Segmented
                  onChange={this.handleViewType}
                  options={selectViewOptions}
                  value={this.state.viewType}
                  style={{ marginBottom: '10px' }}
                />
              </Col>
              <Col xs={24} md={16} style={{ textAlign: !isMobile && 'right' }} className='orders-actions'>
                {this.props.screenSize !== 'xs' &&
                  <>
                    Status:{' '}
                    <Segmented
                      onChange={this.handleStatus}
                      options={selectStatusOptions}
                      value={this.state.status}
                      style={{ marginBottom: '10px' }}
                    />
                  </>}
                {this.props.screenSize === 'xs' &&
                  <>
                    Filtruj po statusie: <b style={{ fontWeight: '800' }}>{this.props.t(this.state.status)}</b>{' '}
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Menu
                          onClick={(x) => this.handleStatus(x.key)}
                          items={[
                            {
                              key: 'new,accepted',
                              label: 'Aktywne'
                            },
                            {
                              key: 'finished',
                              label: 'Zakończone'
                            },
                            {
                              key: 'canceled',
                              label: 'Anulowane'
                            },
                            {
                              key: 'all',
                              label: 'Wszystkie'
                            }
                          ]}
                        />
                      }
                    >
                      <a onClick={e => e.preventDefault()}>
                        zmień
                      </a>
                    </Dropdown>
                  </>}
              </Col>
            </Row>
          </div>}
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ display: loaded ? 'block' : 'none' }} className='list'>
          {this.state.viewType === 'list' &&
            ((isMobile &&
              <List
                itemLayout='horizontal'
                pagination
                className='mobile-list'
                dataSource={filteredOrders}
                locale={{ emptyText: <Empty description='Brak zamówień' /> }}
                renderItem={(item) => (
                  <List.Item
                    actions={[<Link key='edit' to={'/order/' + item.id}>edytuj</Link>]}
                  >
                    <List.Item.Meta
                      onClick={() => this.props.redirect('/order/' + item.id)}
                      title={
                        <>
                          <Link key='edit' to={'/order/' + item.id}>{item.name}</Link>{' '}
                          <Tag color={getOrderStatusColor(item.status)}>{this.props.t(item.status)}</Tag>
                        </>
                      }
                      description={<><CalendarOutlined /> {item.to_date} {item.to_time}<br /><UserOutlined /> {item.client_name}<br /><DollarCircleOutlined /> wartość: {item.price_to_client} zł zysk: {item.earnigs} zł</>}
                    />
                  </List.Item>
                )}
              />
            ) || (
              <Table
                className={`settings-table ${orders && orders.length ? 'fade-in' : ''}`}
                {...this.settings}
                columns={columns}
                dataSource={filteredOrders}
                locale={{
                  emptyText: <Empty description='Brak zamówień' />,
                  triggerDesc: this.props.t('Sortuj malejąco'),
                  triggerAsc: this.props.t('Sortuj rosnąco'),
                  cancelSort: this.props.t('Kliknij aby anulować sortowanie') }}
              />)
            )}
          {this.state.viewType === 'calendar' &&
            <Calendar headerRender={null} dateCellRender={dateCellRender} monthCellRender={monthCellRender} locale={locale} onSelect={this.handleCalendarSelect} onPanelChange={this.handlePanelChange} />}
        </div>
        {this.props.screenSize === 'xs' && <StyledOrderMobileMenu />}
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCalcIndex))
