/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Popconfirm, Button, Menu
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import RecipeForm from '../../styledComponents/myrecipes/recipeForm'
import { updateMyRecipesRecipe, getMyRecipesRecipe, getMyRecipesPantry, deleteMyRecipesRecipe, getMyRecipesConfig } from '../../utils/actions'
import { openNotification } from '../../utils/helpers'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'

class MyRecipeEditRecipe extends React.Component {
  state = {
    formData: undefined,
    id: undefined,
    pantryIngredients: [],
    costCalc: false
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  getPantry = () => {
    getMyRecipesPantry().then((json) => {
      if (this._isMounted) {
        this.setState({ pantryIngredients: json })
      }
    })
  }

  getRecipe = (id) => {
    getMyRecipesRecipe(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })
      }
    })
  }

  updateRecipe = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateMyRecipesRecipe(_this.state.id, data).then((json) => {
        openNotification(_this.props.t('Success'), 'Pomyślnie zaktualizowano przepis!')
        _this.redirect('/myrecipes/' + _this.state.id)
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  deleteRecipe = (data) => {
    deleteMyRecipesRecipe(this.state.id).then((json) => {
      openNotification(this.props.t('Success'), 'Pomyślnie usunięto przepis!')
      this.redirect('/myrecipes')
    })
  }

  getMyRecipesConfig = () => {
    getMyRecipesConfig().then((json) => {
      if (this._isMounted) {
        this.setState({ costCalc: json.cost_calc })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    const { id } = this.props.match.params

    this.setState({ id })
    this.getRecipe(id)
    this.getPantry()
    this.getMyRecipesConfig()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { isMobile } = this.props
    const { formData, pantryIngredients } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const confirmation = (
      <>
        <p>Czy na pewno usunąć przepis?</p>
      </>)

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Popconfirm
          title={confirmation}
          onConfirm={() => this.deleteRecipe()}
          {...popconfirmProps}
        >
          <Menu.Item key='delete-recipe'>
              Usuń
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Popconfirm
        title={confirmation}
        onConfirm={() => this.deleteRecipe()}
        {...popconfirmProps}
        key='delete-recipe'
      >
        <Button>Usuń</Button>
      </Popconfirm>
    ]) || [actionsMenuMobile]

    return (
      <>
        <PageHeader
          title='Edytuj przepis'
          onBack={() => this.redirect('/myrecipes/' + this.state.formData?.id)}
          extra={this.state.formData && actions}
        />
        <RecipeForm
          formAction={this.updateRecipe}
          refreshPantry={this.getPantry}
          formRef={this.formRef}
          isMobile={isMobile}
          formData={formData}
          makeRedirection={this.makeRedirection}
          pantryIngredients={pantryIngredients}
          costCalc={this.state.costCalc}
          redirect={this.redirect}
        />
      </>
    )
  }
}

export default withTranslation()(MyRecipeEditRecipe)
