/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Select, Row, Col, Card, InputNumber,
  Skeleton, Checkbox
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import HelpBox from '../common/helpBox'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 10,
    // sm: 10,
    md: 12,
    lg: 12
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const { Option } = Select

class PantryForm extends React.Component {
  state = {
    loaded: false,
    visibleHelpParameters: false,
    unit: undefined,
    loading: false
  }

  formRef = React.createRef()
  xref = React.createRef()
  resultRef = React.createRef()
  mobileDropdownRef = React.createRef()

  setVisibleHelpParameters = (flag) => {
    this.setState({ visibleHelpParameters: flag })

    flag && ReactGA.event({ category: 'MyRecipes Pantry Form', action: 'VisibleHelpParameters Help Opened Mobile' })
  }

  handleSubmit = (x) => {
    const { setItem } = this.props

    this.setState({ loading: true })
    setItem && setItem(this.formRef.current?.getFieldsValue())

    this.props.formAction({ ...this.formRef.current?.getFieldsValue() }).then((json) => {
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
    })
  }

  componentDidMount () {
    const { formData } = this.props

    if (formData) {
      this.formRef.current.setFieldsValue(formData)
      this.setState({ unit: formData.unit })

      this.setState({
        loaded: true
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (formData && prevProps.formData !== formData) {
      this.formRef.current.setFieldsValue(formData)
      this.setState({ unit: formData.unit })

      this.setState({
        loaded: true
      })
    }
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const parametersHelp = (
      <>
        Określ tutaj informacje o składniku (opcjonalnie podaj cenę - wtedy będzie możliwe obliczanie kosztów przygotowania przepisów).
        Jeśli składnikiem jest opakowanie zbiorcze (np. jajka 10szt.) jednostkę ustaw na 'opak.' i podaj ile sztuk jest w opakowaniu (podając cenę podaj cenę za całe opakowanie).
        Dzięki temu w przepisach będzie możliwość zdefiniowania ile sztuk z całego opakowania zostało zużytych (opcjonalny koszt zostanie obliczony proporcjonalnie w sposób automatyczny).
      </>
    )

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          initialValues={{ cakes_per_month: 0, h_price: 0, margin: 0 }}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Row gutter={[isMobile ? 0 : 20, 20]}>
              <Col xs={24} lg={24}>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                  <Card
                    title='Informacje o składniku'
                    className={'calc-params ' + (isMobile ? 'on-mobile' : '')}
                    extra={<HelpBox title='Informacje o składniku' category='Pantry Form' content={parametersHelp} visible={this.state.visibleHelpParameters} setVisible={this.setVisibleHelpParameters} isMobile={isMobile} />}
                  >
                    <Form.Item
                      name='name'
                      label={this.props.t('Nazwa')}
                      rules={[{ required: true, message: 'Podaj nazwę' }]}
                      {...formItemLayout}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name='unit'
                      validateTrigger={['onChange', 'onBlur']}
                      label={this.props.t('Jednostka')}
                      rules={[{ required: true, message: 'Wybierz jednostkę' }]}
                      {...formItemLayout}
                    >
                      <Select
                        onChange={(x) => this.setState({ unit: x })}
                        disabled={!this.props.isNew}
                      >
                        {
                          ['kg', 'g', 'opak.', 'szt', 'l', 'ml'].map((ing, n) =>
                            <Option key={n} value={ing}>{ing}</Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='quantity'
                      validateTrigger={['onChange', 'onBlur']}
                      label={this.props.t(this.state.unit === 'opak.' ? 'Ilość sztuk w opakowaniu' : 'Ilość w opakowaniu')}
                      rules={[{ required: true, message: this.state.unit === 'opak.' ? 'Podaj ilość sztuk w opakowaniu' : 'Podaj ilość w opakowaniu' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='price_gross'
                      label={this.props.t(this.state.unit === 'opak.' ? 'Cena za opakowanie brutto' : 'Cena brutto')}
                      rules={[{ required: this.props.costCalc, message: this.state.unit === 'opak.' ? 'Podaj cena za opakowanie brutto' : 'Podaj cena brutto' }]}
                      {...formItemLayout}
                    >
                      <InputNumber min={0} max={1000} className='input-100p' />
                    </Form.Item>
                    <Form.Item
                      name='indivisible'
                      label='Produkt jest niepodzielny (np. jajka)'
                      {...formItemLayout}
                      valuePropName='checked'
                    >
                      <Checkbox />
                    </Form.Item>
                  </Card>
                </Space>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Button
                htmlType='submit'
                type='primary'
                size='large'
                disabled={this.state.loading}
              >
                {this.props.t('Zapisz')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
              </Button>
            </div>
          </Space>
        </Form>
      </>
    )
  }
}

export default withTranslation()(PantryForm)
