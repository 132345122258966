import React from 'react'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender } from '../../utils/helpers'
import { Link } from 'react-router-dom'

class ContentItems extends React.PureComponent {
  state = {
    data: []
  }

  componentDidMount () {
    const { getData, items, loadingItems } = this.props

    if (getData) {
      this.setState({ data: loadingItems || [] }, () => {
        getData().then(data => this.setState({ data }))
      })
    } else {
      this.setState({ data: items || loadingItems || [] })
    }
  }

  render () {
    const { userRoles, activeModules, isMobile, title, items, renderItem, more, className, itemXs, itemSm, itemMd, itemLg, ...props } = this.props

    const dataSource = {
      wrapper: { className: 'home-page-wrapper content0-wrapper ' + className },
      page: { className: 'home-page content0' },
      OverPack: { playScale: 0.3, className: '' },
      titleWrapper: {
        className: 'title-wrapper',
        children: title ? [{ name: 'title', children: title }] : []
      },
      childWrapper: {
        className: 'content0-block-wrapper',
        children: []
      }
    }

    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper
    } = dataSource

    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          {title && titleWrapper && (
            <div {...titleWrapper}>
              {titleWrapper.children.map(getChildrenToRender)}
            </div>)}
          <OverPack {...overPackData}>
            <QueueAnim
              type='bottom'
              key='block'
              leaveReverse
              component={Row}
              componentProps={childWrapper}
            >
              {this.state.data?.map((block, i) => {
                return (
                  <Col key={i.toString()} xs={itemXs || 24} sm={itemSm || 12} md={itemMd || 10} lg={itemLg || 7}>
                    <div>
                      {renderItem(block)}
                    </div>
                  </Col>
                )
              })}
            </QueueAnim>
            {more && <div className='content-more'><Link to={more.route}>{more.title}</Link></div>}
          </OverPack>
        </div>
      </div>
    )
  }
}

export default ContentItems
