/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input,
  Rate
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 24,
    // sm: 10,
    md: 24,
    lg: 24
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const { TextArea } = Input

class TrainingOpinionForm extends React.Component {
  state = {
    rating: ''
  }

  setRating = (rating) => {
    this.setState({ rating })
  }

  handleSubmit = () => {
    this.props.setLoading(true)

    this.props.formAction({ ...this.props.formRef.current?.getFieldsValue() }).then((json) => {
      this.props.setLoading(false)
    }).catch(error => {
      this.props.setLoading(false)
    })
  }

  render () {
    const { isMobile } = this.props
    const { rating } = this.state

    const rateDesc = ['koszmarne :(', 'słabe', 'było ok', 'bardzo dobre', 'świetne!'];

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.props.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='rating'
            label='Twoja ocena'
            rules={[{ required: true, message: 'Ocena szkolenia jest polem wymaganym' }]}
            {...formItemLayout}
          >
            <Rate tooltips={rateDesc} onChange={this.setRating} />
          </Form.Item>
          <div className='training-opinion-rating'>{rating ? <span className="ant-rate-text">{rateDesc[rating - 1]}</span> : ''}</div>
          <Form.Item
            name='opinion'
            {...formItemLayout}
          >
            <TextArea placeholder='Twoja ocena szkolenia w kilku słowach (opcjonalnie)' />
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(TrainingOpinionForm)
