/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Popconfirm, Button, Menu, Row, Col
} from 'antd'
import { withTranslation } from 'react-i18next'
// import ReactGA from 'react-ga4'
import { getMyRecipesRecipe } from '../../utils/actions'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown as DropdownMobile } from 'antd-mobile'
import RecipeShowItem from '../recipe/recipeShowItem'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

class MyRecipeShow extends React.Component {
  state = {
    formData: undefined,
    id: undefined,
  }

  formRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  getRecipe = (id) => {
    getMyRecipesRecipe(id).then((json) => {
      if (this._isMounted) {
        this.setState({ recipe: json, loaded: true })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    const { id } = this.props.match.params

    this.setState({ id })
    this.getRecipe(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { makeRedirection } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const confirmation = (
      <>
        <p>Czy na pewno usunąć przepis?</p>
      </>)

    const menu = (
      <Menu selectable={false} className='calculator-actions-menu'>
        <Menu.Item
          key='edit-recipe'
        >
          <Link to={'/myrecipes/' + this.state.recipe?.id + '/edit'}>Edytuj</Link>
        </Menu.Item>
        <Popconfirm
          title={confirmation}
          onConfirm={() => this.deleteRecipe()}
          {...popconfirmProps}
        >
          <Menu.Item key='delete-recipe'>
              Usuń
          </Menu.Item>
        </Popconfirm>
      </Menu>
    )

    const actionsMenuMobile = (
      <DropdownMobile ref={this.mobileDropdownRef}>
        <DropdownMobile.Item key='sorter' arrow={<MoreOutlined style={{ fontSize: 20 }} />}>
          {menu}
        </DropdownMobile.Item>
      </DropdownMobile>)

    const actions = (!isMobile && [
      <Button
        key='edit-recipe'
      >
        <Link to={'/myrecipes/' + this.state.recipe?.id + '/edit'}>Edytuj</Link>
      </Button>,
      <Popconfirm
        title={confirmation}
        onConfirm={() => this.deleteRecipe()}
        {...popconfirmProps}
        key='delete-recipe'
      >
        <Button>Usuń</Button>
      </Popconfirm>
    ]) || [actionsMenuMobile]

    return (
      makeRedirection ? (
        <Redirect to='/myrecipes' />
      ) : (
        <>
          <PageHeader
            title={'Mój przepis: ' + this.state.recipe?.name}
            onBack={() => this.makeRedirection()}
            extra={this.state.recipe && actions}
          />
          <Row justify="center">
            <Col xs={24} lg={12}>
              <RecipeShowItem
                recipe={this.state.recipe}
                loaded={this.state.loaded}
                isMobile={isMobile}
                // authenticated={authenticated}
                user={this.state.user}
                redirect={this.redirect}
                moduleName={'Main Recipe'}
                addMyRecipeFromMainRecipe={this.addMyRecipeFromMainRecipe}
                disableAddToMyBase
              />
            </Col>
          </Row>
        </>
      )
    )
  }
}

export default withTranslation()(MyRecipeShow)
