import React from 'react'
import {
  Button
} from 'antd'
import ReactGA from 'react-ga4'

class Unsleep extends React.Component {
  state = {
    sleepLock: undefined,
    sleepLockAvailable: false
  }

  doNotSleep = async () => {
    if (this.state.sleepLock) {
      this.state.sleepLock.release().then(() => {
        this.setState({ sleepLock: undefined })
      })
      ReactGA.event({ category: this.props.moduleName, action: 'Unsleep released' })
    } else {
      try {
        const sleepLock = await navigator.wakeLock.request("screen");
        this.setState({ sleepLock })
        ReactGA.event({ category: this.props.moduleName, action: 'Unsleep enabled' })
      } catch (err) {
        console.err('Cannot use wakeLock', err)
      }
    }
  }

  componentDidMount () {
    this.setState({ sleepLockAvailable: "wakeLock" in navigator })
  }

  componentWillUnmount () {
    if (this.state.sleepLock) {
      this.state.sleepLock.release().then(() => {
      })
      ReactGA.event({ category: this.props.moduleName, action: 'Unsleep Released On Unmount' })
    }
  }

  render () {
    return (
      (this.state.sleepLockAvailable && <Button className='sc-button' onClick={this.doNotSleep}>
        {this.state.sleepLock ? 'Wyłącz blokadę wygaszania ekranu' : 'Nie wygaszaj ekranu'}
      </Button>) || ''
    )
  }
}

export default Unsleep
