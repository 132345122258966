import React from 'react'
import { PageHeader } from 'antd'
import { updateUserProfile, getUserProfile } from '../../utils/actions'
import StyledUserProfile from '../../styledComponents/user/profile'
import { openNotification } from '../../utils/helpers'
import { Redirect } from 'react-router'
import QueueAnim from 'rc-queue-anim'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

class UserProfile extends React.Component {
  state = {
    makeRedirection: false,
    formSaving: false,
    userData: undefined
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount (prevProps, prevState) {
    this._isMounted = true

    if (this.props.authenticated) {
      this.getUserProfile()
    } else {
      this.redirect('/login')
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getUserProfile = () => {
    getUserProfile().then((json) => {
      if (this._isMounted) {
        this.setState({ userData: json })
      }
    })
  }

  updateUserProfile = (data) => {
    this.setState({ formSaving: true })

    updateUserProfile(data).then((json) => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
        openNotification(this.props.t('Success'), this.props.t('Your profile data has been successfully saved!'))
      }
    }).catch(() => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
      }
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { makeRedirection, formSaving, userData } = this.state

    return (
      makeRedirection ? (
        <Redirect to='/' />
      ) : (
        <>
          <PageHeader
            onBack={() => this.makeRedirection()}
            title={this.props.t('Edytuj profil')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner2-text-wrapper'
          >
            <div key='content' className='banner2-content'>
              <StyledUserProfile
                makeRedirection={this.makeRedirection}
                updateUserProfile={this.updateUserProfile}
                formSaving={formSaving}
                isMobile={this.props.isMobile}
                userData={userData}
              />
            </div>
          </QueueAnim>
        </>
      )
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const UserProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile)

export default withTranslation()(UserProfileContainer)
